<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<h4>Update Delivery Status of load {{ load !== null ? load.loadNumber: '' }}</h4><br>

				<form class="form" @submit.prevent="confirmedDelivery">
					<div class="form-group">
						<label>Delivery Status</label>
						<select class="form-control" v-model="load.status" required>
							<option value="" disabled>Select option</option>
							<option value="DELIVERY_CONFIRMED">Delivery Confirmed</option>
							<option value="DELIVERED_WITH_DEFECTS">Delivered with defects</option>
						</select>
					</div>
					<br>
					
					<div v-show="load.status === 'DELIVERY_CONFIRMED'">
						<h4><i>You can leave a comment below (optional)</i></h4>
					</div>

					<div v-show="load.status === 'DELIVERED_WITH_DEFECTS'">
						<h4><i>Further investigation will be required. Our service agent will contact you to get more details of the load defected delivery</i></h4>
						<p class="text-danger">N.B. Payment will not be released to the carrier until issue has ben resolved.</p>
					</div>

					<div class="form-group">
						<label>Comments (optional)</label>
						<textarea class="form-control" rows="5" placeholder="Enter comments" v-model="load.deliveryComments"></textarea>
					</div>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<button class="btn btn-primary btn-block">Submit</button>
						</div>
					</div>
				</form>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService.js";
	import Load from "@/models/Load.js";

	export default{
		name: 'UpdateLoadDeliveryStatus',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    load: new Load,
			    deliveryStatus: null
			}
		},

		mounted(){
			this.getLoadByLoadNumber();
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},
		},

		methods: {
			getLoadByLoadNumber: function(){
				this.show = true;
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			confirmedDelivery: function(){
				this.show = true;
				LoadService.confirmedDelivery(this.load).then(response => {
					if (response.data.statusCode === 201){
						this.$router.push({ name: 'shipments' });
						window.location.reload();
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>