export default class ExchangeRate{
  constructor(exchangeRateId, date, currency, indices, bid, ask, midRate, bidZwl, askZwl, midZwl, interbankRate, status) {
    this.exchangeRateId = exchangeRateId;
    this.date = date;
    this.currency = currency;
    this.indices = indices;
    this.bid = bid;
    this.ask = ask;
    this.midRate = midRate;
    this.bidZwl = bidZwl;
    this.askZwl = askZwl;
    this.midZwl = midZwl;
    this.interbankRate = interbankRate;
    this.status = status;
  }
}