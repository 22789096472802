export default class Country {
  constructor(countryId, name, code, description, status, zipCodeId) {
    this.countryId = countryId;
    this.name = name;
    this.code = code;
    this.description = description;
    this.status = status;
    this.zipCodeId = zipCodeId;
  }
}
