import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class TruckService {
    findAll() {
        return axios.get(GENERIC_SERVICE_URL + "trucks");
    }

    findById(id) {
        return axios.get(GENERIC_SERVICE_URL + "trucks/" + id);
    }

    save(truck) {
        return axios.post(GENERIC_SERVICE_URL + "trucks", {
            id: truck.id,
            carrierUsername: truck.carrierUsername,
            truckNumber: truck.truckNumber,
            registrationNumber: truck.registrationNumber,
            trailerType: truck.trailerType,
            regularPickUpAddress: truck.regularPickUpAddress,
            regularDropOffAddress: truck.regularDropOffAddress,
            recurringType: truck.recurringType,
            desiredRate: truck.desiredRate,
            desiredRateCurrency: truck.desiredRateCurrency,
            desiredRateMeasurement: truck.desiredRateMeasurement,
            estimatedDeliveryTimeMeasurement: truck.estimatedDeliveryTimeMeasurement,
            length: truck.length,
            lengthMeasurement: truck.lengthMeasurement,
            maxLoadSize: truck.maxLoadSize,
            maxLoadSizeMeasurement: truck.maxLoadSizeMeasurement,
            grossVehicleMass: truck.grossVehicleMass,
            grossVehicleMassMeasurement: truck.grossVehicleMassMeasurement,
            netVehicleMass: truck.netVehicleMass,
            netVehicleMassMeasurement: truck.netVehicleMassMeasurement,
            maxHeightClearance: truck.maxHeightClearance,
            maxHeightClearanceMeasurement: truck.maxHeightClearanceMeasurement,
            loadMode: truck.loadMode
        });
    }

    findByTruckNumber(truckNumber) {
        return axios.get(GENERIC_SERVICE_URL + "trucks/findByTruckNumber/" + truckNumber);
    }
}
export default new TruckService;
