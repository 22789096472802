<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div v-if="subscription !== null">
	        <div v-if="subscription.subscriptionPlan.subscriptionType === 'BASIC'">
	          <div class="panel">
	            <div class="panel-body">
	              <h4 class="text-danger">Oops! Sorry you need to upgrade your subscription plan to view private loads.</h4>
	              <p>These have greater of business and creates relationships with brokers and shippers.</p>
	              <router-link :to="{ name: 'upgrade-subscription' }">
	              	<button class="btn btn-primary">Upgrade</button>
	              </router-link>
	            </div>
	          </div>
	        </div>
	        <div v-else>
	        	<div class="panel">
					<div class="panel-body">
						<form class="form">
							<div class="row">
								<div class="col-md-2">
										<div class="form-group">
											<label>Load type</label>
											<select class="form-control" name="loadType" v-model="PL_type">
												<option value="" disabled>Select option</option>
												<option value="NORMAL">Normal</option>
												<option value="ABNORMAL">Abnormal</option>
											</select>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<label>Load mode</label>
											<select class="form-control" name="loadMode" v-model="PL_loadMode">
												<option value="" disabled>Select option</option>
												<option value="FTL">Full truckload - FTL</option>
												<option value="LTL">Less than truckload - LTL</option>
												<option value="INTERMODAL">Intermodal</option>
												<option value="POWER_ONLY">Power-Only</option>
											</select>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<label>Trailer required</label>
											<select class="form-control" name="trailerTypeRequired" v-model="PL_trailerTypeRequired">
												<option value="" disabled>All trailers</option>
												<option value="FLATBED">Flatbed</option>
												<option value="VAN">Van</option>
												<option value="REEFER">Reefer</option>
												<option value="STEP_DECK">Step Deck</option>
												<option value="POWER_ONLY">Power Only</option>
												<option value="AUTO_CARRIER">Auto Carrier</option>
												<option value="B_TRAIN">B Train</option>
												<option value="BOX_TRUCK">Box Truck</option>
												<option value="CONESTOGA">Consetoga</option>
												<option value="CONTAINERS">Containers</option>
												<option value="DOUBLE_DROP">Double Drop</option>
												<option value="DRY_BULK">Dry Bulk</option>
												<option value="DUMP_TRAILER">Dump Trailer</option>
												<option value="HOPPER_BOTTOM">Hooper Bottom</option>
												<option value="HOTSHOT">Hotshot</option>
												<option value="LOWBOY">Lowboy</option>
												<option value="TANKER">Tanker</option>
											</select>
										</div>
									</div>
								<div class="col-md-2">
									<div class="form-group">
										<label>Pick Up Date</label>
										<input type="date" name="pickupDate" v-model="PL_pickupDate" class="form-control">
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label>Drop Off Date</label>
										<input type="date" name="dropOffDate" v-model="PL_dropOffDate" class="form-control">
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label>Pick Up</label>
										<select class="form-control" v-model="PL_pickUpCityId">
											<option value="" selected>All cities</option>
											<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
										</select>
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label>Drop Off</label>
										<select class="form-control" v-model="PL_dropOffCityId">
											<option value="" selected>All cities</option>
											<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
										</select>
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label>Weight (tonne)</label>
										<input type="number" name="weight" v-model="PL_weight" class="form-control" placeholder="Enter weight">
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label>Price</label>
										<input type="number" name="price" v-model="PL_price" class="form-control" placeholder="Enter price">
									</div>
								</div>
								<div class="col-md-2"></div>
								<div class="col-md-2">
									<div class="form-group">
										<label>&nbsp;</label><br>
										<button class="btn btn-block btn-primary" type="button" @click="search()">Search</button>
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label>&nbsp;</label><br>
										<button class="btn btn-block" type="button" @click="clearFilter()" style="background-color: #000000; color: #ffffff;">Reset filter</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="row">
					<div class="col-md-3">
						<p>{{ loads.length }} private loads available</p>
						<p>Find the best offers tendered for you below</p>
						<p>{{ new Date().toDateString() }}</p>
					</div>
					<div class="col-md-6"></div>
					<div class="col-md-3">
						<select class="form-control" v-model="itemsPerPage">
							<option value="10">Items per page</option>
							<option value="20">20</option>
							<option value="30">30</option>
							<option value="40">40</option>
							<option value="50">50</option>
						</select>
					</div>
				</div>
				<div class="panel" v-for="load in paginatedLoads" :key="load.id">
					<div class="panel-body">
						<div class="row">
							<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }, query: { source: 'PRIVATE_LOAD' }}">
								<div class="col-md-3">
									<b><i><span class="label label-primary">PICKUP</span></i></b>
									<span v-if="load.pickupAddress !== null">
										<span v-if="load.pickupAddress.province !== null && load.pickupAddress.city !== null">
											<p>{{ load.pickupAddress !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress !== null ? load.pickupAddress.city.name: "Not available" }} on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
											</p>
										</span>
										<span v-else>
											<p><span class="text-danger">Pickup n/a</span> on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
											</p>
										</span>
									</span>
									<p><span class="badge">{{ load.trailerTypeRequired }}</span> required for <span class="badge">{{ load.loadMode }}</span></p>
								</div>
								<div class="col-md-1">
									<br>
									<img src="@/assets/images/right-arrow.png" height="30" width="30">
								</div>
								<div class="col-md-3">
									<span v-if="load.dropOffAddress !== null">
										<b><i><span class="label label-warning">DELIVERY</span></i></b>&nbsp;
										<span class="badge">Estimated distance {{ load.estimatedDistance > 0 ? load.estimatedDistance / 1000 : 0 }} km</span> 
										<span v-if="load.pickupAddress !== null">
											<span v-if="load.pickupAddress.province !== null && load.pickupAddress.city !== null">
												<p>{{ load.dropOffAddress !== null ? load.dropOffAddress.province.name: "Not available" }}, {{ load.dropOffAddress !== null ? load.dropOffAddress.city.name: "Not available" }}
													{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}
												</p>
											</span>
											<span v-else>
												<p><span class="text-danger">Drop off n/a</span> on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
												</p>
											</span>
										</span>
										<p>{{ load.weight }} {{ load.weightMeasurement }} of {{ load.commodity }}</p>
									</span>
								</div>
							</router-link>
							<div class="col-md-2">
								<br>
								<p>{{ load.currency }}${{ load.price }}</p>
								<p><span class="badge">{{ load.type }} load</span></p>
							</div>
							<div class="col-md-1" v-show="getRoles.includes('CARRIER')">
								<br><br>
								<router-link :to="{ name: 'bid-load', params: { loadNumber: load.loadNumber }}">
									<button class="btn btn-default btn-block">Bid <span class="glyphicon glyphicon-ok-circle"></span></button>
								</router-link>
							</div>
							<div class="col-md-1" v-show="getRoles.includes('CARRIER')">
								<br><br>
								<router-link :to="{ name: 'book-load', params: { loadNumber: load.loadNumber }}">
									<button class="btn btn-primary btn-block">Book <span class="glyphicon glyphicon-ok-circle"></span></button>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="totalPages > 1">
					<div class="col-md-7"></div>
					<div class="col-md-3 pull-right">
						<div class="panel">
							<div class="panel-body">
								<button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="PL_currentPage === 1" @click="prevPage"></button>
								&nbsp;<span>Page {{ PL_currentPage }} of {{ totalPages }}</span>&nbsp;
								<button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="PL_currentPage === totalPages" @click="nextPage"></button>
							</div>
						</div>
					</div>
				</div>
	        </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import PrivateLoadService from "@/services/PrivateLoadService";
	import CompanyService from "@/services/CompanyService";
	import SubscriptionService from "@/services/SubscriptionService";
	import CityService from "@/services/CityService";

	export default{
		name: 'PrivateLoads',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				loads: [],
				company: null,
				subscription: null,
				cities: [],
				PL_currentPage: JSON.parse(localStorage.getItem('PL_currentPage')) !== null ? JSON.parse(localStorage.getItem('PL_currentPage')): 1,
				itemsPerPage: 10,
				PL_type: null,
				PL_loadMode: null,
				PL_trailerTypeRequired: null,
				PL_pickupDate: null,
				PL_dropOffDate: null,
				PL_pickUpCityId: null,
				PL_dropOffCityId: null,
				PL_weight: null,
				PL_price: null,
				filteredLoads: []
			}
		},

		mounted(){
			this.findByActiveSubscription();

			this.PL_type = JSON.parse(localStorage.getItem('PL_type'));
			this.PL_loadMode = JSON.parse(localStorage.getItem('PL_loadMode'));
			this.PL_trailerTypeRequired = JSON.parse(localStorage.getItem('PL_trailerTypeRequired'));
			this.PL_pickupDate = JSON.parse(localStorage.getItem('PL_pickupDate'));
			this.PL_dropOffDate = JSON.parse(localStorage.getItem('PL_dropOffDate'));
			this.PL_pickUpCityId = JSON.parse(localStorage.getItem('PL_pickUpCityId'));
			this.PL_dropOffCityId = JSON.parse(localStorage.getItem('PL_dropOffCityId'));
			this.PL_weight = JSON.parse(localStorage.getItem('PL_weight'));
			this.PL_price = JSON.parse(localStorage.getItem('PL_price'));
			
			this.findAllCities();
			this.findByAssociatedUserUsername();
		},

		methods: {
			findByActiveSubscription(){
		        this.show = true;
		        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
		          if (response.data.statusCode === 200) {
		            this.subscription = response.data.body;
		          }
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    },

			findAllCities: function(){
				CityService.findAll().then(response => {
					this.cities = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			findByAssociatedUserUsername: function() {
				this.show = true;
		        CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
		        	if (response.data.statusCode === 200) {
		        		this.company = response.data.body;
		        	}
		        	this.show = false;
		        	this.getPrivateLoads();
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    getPrivateLoads: function(){
				PrivateLoadService.getPrivateLoads(this.company.id).then(response => {
					this.loads = response.data.body;
					this.search();
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			prevPage(){
		    	if (this.currentPage > 1) {
		    		const currentPage = --this.currentPage;
		    		localStorage.setItem('PL_currentPage', JSON.stringify(currentPage));
		    	}
		    },

		    nextPage(){
		    	if (this.PL_currentPage < this.totalPages) {
		    		const PL_currentPage = ++this.PL_currentPage;
		    		localStorage.setItem('PL_currentPage', JSON.stringify(PL_currentPage));
		    	}
		    },

		    clearFilter(){
		    	localStorage.removeItem('PL_type');
		    	localStorage.removeItem('PL_loadMode');
		    	localStorage.removeItem('PL_trailerTypeRequired');
		    	localStorage.removeItem('PL_pickupDate');
		    	localStorage.removeItem('PL_dropOffDate');
		    	localStorage.removeItem('PL_pickUpCityId');
		    	localStorage.removeItem('PL_dropOffCityId');
		    	localStorage.removeItem('PL_weight');
		    	localStorage.removeItem('PL_price');
		    	localStorage.removeItem('PL_currentPage');
		    	localStorage.removeItem('PL_searchFields');
		    	window.location.reload();
		    },

		    search(){
		    	let searchFields = JSON.parse(localStorage.getItem('PL_searchFields') || '[]');
		    	if (this.PL_type !== null && this.PL_type !== undefined) {
				    let searchField = {
				    	'fieldName': 'PL_type',
				    	'value': this.PL_type
				    };
				    const index = searchFields.findIndex((field) => field.fieldName === 'PL_type');
				    if (index === -1) {
				    	searchFields.push(searchField);
				    }else{
				    	searchFields[index] = searchField;
				    }
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.PL_loadMode !== null && this.PL_loadMode !== undefined) {
		    		let searchField = {
		    			'fieldName': 'PL_loadMode',
		    			'value': this.PL_loadMode
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'PL_loadMode');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.PL_trailerTypeRequired !== null && this.PL_trailerTypeRequired !== undefined) {
		    		let searchField = {
		    			'fieldName': 'PL_trailerTypeRequired',
		    			'value': this.PL_trailerTypeRequired
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'PL_trailerTypeRequired');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.PL_pickupDate !== null && this.PL_pickupDate !== undefined) {
		    		let searchField = {
		    			'fieldName': 'PL_PL_pickupDate',
		    			'value': this.PL_pickupDate
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'PL_pickupDate');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.PL_dropOffDate !== null && this.PL_dropOffDate !== undefined) {
		    		let searchField = {
		    			'fieldName': 'PL_dropOffDate',
		    			'value': this.PL_dropOffDate
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'PL_dropOffDate');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.PL_pickUpCityId !== null && this.PL_pickUpCityId !== undefined) {
		    		let searchField = {
		    			'fieldName': 'PL_pickUpCityId',
		    			'value': this.PL_pickUpCityId
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'PL_pickUpCityId');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.PL_dropOffCityId !== null && this.PL_dropOffCityId !== undefined) {
		    		let searchField = {
		    			'fieldName': 'PL_dropOffCityId',
		    			'value': this.PL_dropOffCityId
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'PL_dropOffCityId');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.PL_weight !== null && this.PL_weight !== undefined) {
		    		let searchField = {
		    			'fieldName': 'PL_weight',
		    			'value': this.PL_weight
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'PL_weight');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.PL_price !== null && this.PL_price !== undefined) {
		    		let searchField = {
		    			'fieldName': 'PL_price',
		    			'value': this.PL_price
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'PL_price');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('PL_searchFields', JSON.stringify(searchFields));
		    	}

		    	if (searchFields.length > 0) {
		    		let theLoads = this.loads;
		    		for(let i = 0; i < searchFields.length; i++){
		    			if (searchFields[i].fieldName === 'PL_type'){
		    				theLoads = theLoads.filter(load => {
		    					return load.type === searchFields[i].value;
		    				});
		    				localStorage.setItem('PL_type', JSON.stringify(this.PL_type));
		    			}
		    			if (searchFields[i].fieldName === 'PL_loadMode'){
		    				theLoads = theLoads.filter(load => {
		    					return load.loadMode === searchFields[i].value;
		    				});
		    				localStorage.setItem('PL_loadMode', JSON.stringify(this.PL_loadMode));
		    			}
		    			if (searchFields[i].fieldName === 'PL_trailerTypeRequired'){
		    				theLoads = theLoads.filter(load => {
		    					return load.trailerTypeRequired === searchFields[i].value;
		    				});
		    				localStorage.setItem('PL_trailerTypeRequired', JSON.stringify(this.PL_trailerTypeRequired));
		    			}
		    			if (searchFields[i].fieldName === 'PL_pickupDate'){
		    				theLoads = theLoads.filter(load => {
		    					return load.pickupDate === searchFields[i].value;
		    				});
		    				localStorage.setItem('PL_pickupDate', JSON.stringify(this.PL_pickupDate));
		    			}
		    			if (searchFields[i].fieldName === 'PL_dropOffDate'){
		    				theLoads = theLoads.filter(load => {
		    					return load.dropOffDate === searchFields[i].value;
		    				});
		    				localStorage.setItem('PL_dropOffDate', JSON.stringify(this.PL_dropOffDate));
		    			}
		    			if (searchFields[i].fieldName === 'PL_pickUpCityId'){
		    				theLoads = theLoads.filter(load => {
		    					return load.pickupAddress.city.id === searchFields[i].value;
		    				});
		    				localStorage.setItem('PL_pickUpCityId', JSON.stringify(this.PL_pickUpCityId));
		    			}
		    			if (searchFields[i].fieldName === 'PL_dropOffCityId'){
		    				theLoads = theLoads.filter(load => {
		    					return load.destinationAddress.city.id === searchFields[i].value;
		    				});
		    				localStorage.setItem('PL_dropOffCityId', JSON.stringify(this.PL_dropOffCityId));
		    			}
		    			if (searchFields[i].fieldName === 'PL_weight'){
		    				theLoads = theLoads.filter(load => {
		    					return load.weight === parseFloat(searchFields[i].value);
		    				});
		    				localStorage.setItem('PL_weight', JSON.stringify(this.PL_weight));
		    			}
		    			if (searchFields[i].fieldName === 'PL_price'){
		    				theLoads = theLoads.filter(load => {
		    					return load.price === parseFloat(searchFields[i].value);
		    				});
		    				localStorage.setItem('PL_price', JSON.stringify(this.PL_price));
		    			}
		    			this.filteredLoads = theLoads;
		    		}
		    	}else{
		    		this.filteredLoads = this.loads;
		    	}
		    }
		},

		computed: {
			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },

		    totalPages(){
		    	return Math.ceil(this.filteredLoads.length / this.itemsPerPage);
		    },

		    paginatedLoads(){
				const startIndex = (this.PL_currentPage - 1) * this.itemsPerPage;
				return this.filteredLoads.slice(startIndex, startIndex + this.itemsPerPage);
			}
		}
	};
</script>