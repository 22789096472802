import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class BankAccountService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "bankAccounts");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "bankAccounts/" + id);
  }

   save(entity) {
    return axios.post(GENERIC_SERVICE_URL + "bankAccounts", {
      bankAccountId: entity.bankAccountId,
      bankName: entity.bankName,
      branch: entity.branch,
      branchCode: entity.branchCode,
      accountNumber: entity.accountNumber,
      description: entity.description,
      status: entity.status,
      accountCurrency: entity.accountCurrency
    });
  }

}

export default new BankAccountService();
