<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<h4>Congrats, you have been awarded you the <router-link :to="{ name: 'view-load', params: { loadNumber: getLoadNumber }}"><span class="text-danger">the load</span></router-link>  booking. Accept or decline the offer below.</h4>
			    <hr>
				<div class="row">
					<div class="col-md-6">
						<p>If you decline the offer, your booking for this load will be automatically closed.</p>
						<form class="form" @submit.prevent="declineOffer">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-danger">Decline Offer <span class="glyphicon glyphicon-thumbs-down"></span></button>
							</div>
						</form>
					</div>
					<div class="col-md-6">
						<p>If you accept the offer, shipper/broker will pay you and a provisional or withholding POP will be sent to you.</p>
						<form class="form" @submit.prevent="acceptOffer">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-success">Accept Offer <span class="glyphicon glyphicon-thumbs-up"></span></button>
							</div>
						</form>
					</div>
					<div class="col-md-2"></div>
				</div>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BookingService from "@/services/BookingService.js";
	import Booking from "@/models/Booking.js";

	export default{
		name: 'AcceptOrDeclineBookingOffer',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    booking: new Booking
			}
		},

		created(){

		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},

			getBookingNumber: function(){
				return this.$route.params.bookingNumber;
			}
		},

		methods: {
			acceptOffer: function() {
				this.show = true;
				this.booking.bookingNumber = this.getBookingNumber;
				this.booking.status = "ACCEPTED";
				this.booking.loadNumber = this.getLoadNumber;
		        BookingService.acceptOrDeclineOffer(this.booking).then(response => {
		        	if(response.data.statusCode === 201){
		        		this.$router.push({ name: "carrier/bookings" });
		        	}
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    declineOffer: function() {
				this.show = true;
				this.booking.bookingNumber = this.getBookingNumber;
				this.booking.status = "DECLINED";
				this.booking.loadNumber = this.getLoadNumber;
		        BookingService.acceptOrDeclineOffer(this.booking).then(response => {
		        	if(response.data.statusCode === 201){
		        		this.$router.push({ name: "carrier/bookings" });
		        	}
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },
		}
	};
</script>