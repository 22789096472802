export default class Payment {
  constructor(id, invoiceNumber, payer, referenceNumber, paymentType, transactions) {
  	this.id = id;
  	this.invoiceNumber = invoiceNumber;
    this.payer = payer;
    this.referenceNumber = referenceNumber;
    this.paymentType = paymentType;
    this.transactions = transactions;
  }
}
