export default class Truck{
	constructor(id, carrierUsername, truckNumber, registrationNumber, trailerType, regularPickUpAddress, 
		regularDropOffAddress, recurringType, desiredRate, desiredRateCurrency, desiredRateMeasurement, 
		length, lengthMeasurement, maxLoadSize, maxLoadSizeMeasurement, grossVehicleMass, grossVehicleMassMeasurement,
		netVehicleMass, netVehicleMassMeasurement, maxHeightClearance, maxHeightClearanceMeasurement, loadMode,
		type, trailerLength){
		this.id = id;
		this.carrierUsername = carrierUsername;
		this.truckNumber = truckNumber;
		this.registrationNumber = registrationNumber;
		this.trailerType = trailerType;
		this.regularPickUpAddress = regularPickUpAddress;
		this.regularDropOffAddress = regularDropOffAddress;
		this.recurringType = recurringType;
		this.desiredRate = desiredRate;
		this.desiredRateCurrency = desiredRateCurrency;
		this.desiredRateMeasurement = desiredRateMeasurement;
		this.length = length;
		this.lengthMeasurement = lengthMeasurement;
		this.maxLoadSize = maxLoadSize;
		this.maxLoadSizeMeasurement = maxLoadSizeMeasurement;
		this.grossVehicleMass = grossVehicleMass;
		this.grossVehicleMassMeasurement = grossVehicleMassMeasurement;
		this.grossVehicleMass = grossVehicleMass;
		this.netVehicleMass = netVehicleMass;
		this.netVehicleMassMeasurement = netVehicleMassMeasurement;
		this.maxHeightClearance = maxHeightClearance;
		this.maxHeightClearanceMeasurement = maxHeightClearanceMeasurement;
		this.loadMode = loadMode;
		this.type = type;
		this.trailerLength = trailerLength;
	}
}