<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<router-link :to="{ name: 'my-loads' }">
							<button class="btn btn-primary">Back</button>
	   					</router-link>
					</div>
					<div class="col-md-2" v-if="load !== null">
						<router-link :to="{ name: 'edit-load', params: { loadNumber: load.loadNumber }}">
							<!-- <button class="btn btn-warning btn-block">Edit details</button> -->
	   					</router-link>
					</div>
				</div>
	   			<br><br>

				<div class="row">
					<div class="col-md-6">
						<h4>Publish this load {{ getLoadNumber }}</h4>
						<p>After publishing the load, it will be available for search by carriers looking for potential loads.</p>
						<br>
						<form class="form" @submit.prevent="publish">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-primary" type="submit">Yes publish load</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";

	export default{
		name: 'PublishLoad',
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request',
				load: null
			}
		},

		mounted(){
			this.findByLoadNumber();
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			}
		},

		methods: {
			findByLoadNumber: function(){
				this.show = true;
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			publish: function(){
				this.show = true;
				// run due diligence check
				if (this.load.loadType === null) {
					alert('Load cannot be published because load type is missing');
					this.show = false;
					return;
				}if (this.load.loadMode === null) {
					alert('Load cannot be published because load mode is missing');
					this.show = false;
					return;
				}if (this.load.trailerTypeRequired === null) {
					alert('Load cannot be published because trailer type is missing');
					this.show = false;
					return;
				}if (this.load.pickupDate === null) {
					alert('Load cannot be published because pickup date is missing');
					this.show = false;
					return;
				}if (this.load.dropOffDate === null) {
					alert('Load cannot be published because drop off date is missing');
					this.show = false;
					return;
				}if (this.load.pickupAddress === null) {
					alert('Load cannot be published because pickup address is missing');
					this.show = false;
					return;
				}if (this.load.destinationAddress === null) {
					alert('Load cannot be published because destination address is missing');
					this.show = false;
					return;
				}if (this.load.weight === null) {
					alert('Load cannot be published because weight is missing');
					this.show = false;
					return;
				}if (this.load.price === null) {
					alert('Load cannot be published because price is missing');
					this.show = false;
					return;
				}else{
					LoadService.publish(this.getLoadNumber).then(response => {
						if (response.data.statusCode === 201) {
							this.$router.push({ name: 'my-loads' });
						}
						this.show = false;
			        }).catch(error => {
			        	console.log(error);
			        	this.show = false;
			        });
				}
			},
		}
	};
</script>