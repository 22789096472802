<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<h4>Place Your Bid for this load {{ load.loadNumber }}</h4>
		<hr>
		<p>After winning the bid you'll have 15 minutes to accept the offer.</p>
		<br>

		<div class="row">
			<div class="col-md-6">
				<form class="form" @submit.prevent="placeBid">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label>Currency</label>
								<select class="form-control" v-model="currency">
									<option value="" disabled>Select option</option>
									<option value="USD">USD</option>
									<option value="ZWL">ZWL</option>
								</select>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>Bid Price</label>
								<input type="number" name="bidPrice" id="bidPrice" class="form-control" placeholder="Enter Bid Price" v-model="bidPrice">
							</div>
						</div>
					</div>
					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-primary">PLACE BID</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import Load from "@/models/Load";
	import LoadService from "@/services/LoadService";

	export default{
		name: 'NewCarrierLoadBid',
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request...',
				load: new Load,
				bidPrice: null,
				currency: null,
			}
		},

		created(){
			this.findByLoadNumber();
		},

		computed: {
			getLoadNumber: function(){ return this.$route.params.loadNumber; }
		},

		methods: {

			findByLoadNumber: function(){
				this.show = true;
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			placeBid: function(){
				this.show = true;
			    let bids = [{
			    	id: null,
			        loadNumber: this.getLoadNumber,
			        bidNumber: null,
			        currency: null,
			        bidPrice: this.bidPrice,
			        carrierUsername: this.$store.state.auth.user.user.username,
			        awarded: false
			    }];
			    this.load.loadNumber = this.getLoadNumber;
			    this.load.bids = bids;
				LoadService.save(this.load).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'loads/loadDetails', params: { loadNumber: this.getLoadNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>