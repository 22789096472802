<template>
	<div>
		<div v-if="paginatedLoads.length > 0 && returnLoadsTriggered !== null && returnLoadsTriggered === 'FROM_DELIVERY'">
			<div class="panel" v-for="load in paginatedLoads" :key="load.id">
				<div class="panel-body">
					<div class="row">
						<router-link :to="{ name: 'view-backhaul-load', params: { loadNumber: load.loadNumber }, query: { source: 'LOAD_DETAIL', sourceLoadNumber: load.loadNumber }}">
							<div class="col-md-2">
								<b><i><span class="label label-primary">PICKUP</span></i></b>
								<h4>{{ load.pickupAddress.province !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress.province !== null ? load.pickupAddress.city.name: "Not available" }}</h4>
								<p>{{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}</p>
								<p><span class="badge">{{ load.trailerTypeRequired }}</span> required - <span class="badge">{{ load.loadMode }}</span></p>
							</div>
							<div class="col-md-1">
								<br><br><br>
								<img src="@/assets/images/right-arrow.png" height="30" width="30">
							</div>
							<div class="col-md-3">
								<span v-if="load.dropOffAddress !== null">
									<b><i><span class="label label-success">DELIVERY</span></i></b> <span class="badge">{{ load.estimatedDistance }} {{ load.estimatedDistanceMeasurement }}</span>
									<span class="badge">{{ load.distance }}{{ load.distanceMeasurement }}</span>
									<h4>{{ load.dropOffAddress.province !== null ? load.dropOffAddress.province.name: "Not available" }}, {{ load.dropOffAddress.province !== null ? load.dropOffAddress.city.name: "Not available" }}</h4>
									<p>{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}</p>
									<p>{{ load.weight }} {{ load.weightMeasurement }} of {{ load.commodity }}</p>
								</span>
							</div>
						</router-link>
						<div class="col-md-2">
							<br><br>
							<h4>{{ load.currency }}${{ load.price }}</h4>
							<p><span class="badge">{{ load.type }} load</span></p>
						</div>
						<div class="col-md-2" v-show="getRoles.includes('CARRIER')">
							<br><br>
							<router-link :to="{ name: 'bid-load', params: { loadNumber: load.loadNumber }}">
								<button class="btn btn-default btn-block">Bid Load <span class="glyphicon glyphicon-ok-circle"></span></button>
							</router-link>
						</div>
						<div class="col-md-2" v-show="getRoles.includes('CARRIER')">
							<br><br>
							<router-link :to="{ name: 'book-load', params: { loadNumber: load.loadNumber }}">
								<button class="btn btn-primary btn-block">Book Load <span class="glyphicon glyphicon-ok-circle"></span></button>
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<div class="row" v-if="totalPages > 1">
				<div class="col-md-7"></div>
				<div class="col-md-3 pull-right">
					<div class="panel">
						<div class="panel-body">
							<button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="currentPage === 1" @click="prevPage"></button>
							&nbsp;<span>Page {{ currentPage }} of {{ totalPages }}</span>&nbsp;
							<button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="currentPage === totalPages" @click="nextPage"></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="paginatedLoads.length === 0 && returnLoadsTriggered !== null">
			<h4 class="text-danger">Snap! Looks like there are no return loads at the moment.</h4>
		</div>
		<br><br>
	</div>
</template>

<script type="text/javascript">
	export default{
		props: {
			loads: {
				type: Array,
				default: function(){
					return []
				}
			},

			returnLoadsTriggered: {
				type: String,
				default: null
			}
		},

		data: function(){
			return {
				currentPage: 1,
				itemsPerPage: 7,
			}
		},

		computed: {
			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },

		    filteredLoads() {
		    	let filteredItems = this.loads;
		    	return filteredItems;
			},

		    totalPages(){
		    	return Math.ceil(this.filteredLoads.length / this.itemsPerPage);
		    },

		    paginatedLoads(){
				const startIndex = (this.currentPage - 1) * this.itemsPerPage;
				return this.filteredLoads.slice(startIndex, startIndex + this.itemsPerPage);
			}
		},

		methods: {
			prevPage(){
		    	if (this.currentPage > 1) {
		    		const currentPage = --this.currentPage;
		    		localStorage.setItem('currentPage', JSON.stringify(currentPage));
		    	}
		    },

		    nextPage(){
		    	if (this.currentPage < this.totalPages) {
		    		const currentPage = ++this.currentPage;
		    		localStorage.setItem('currentPage', JSON.stringify(currentPage));
		    	}
		    },
		}
	};
</script>