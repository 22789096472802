<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <h4>Load Bids</h4>

	    <table class="table table-bordered table-hover text-center" style="table-layout: fixed; background: #000000; color: #ffffff;">
			<thead>
				<th style="padding-left: 15px;">Load</th>
		        <th>Bid number</th>
		        <th>Load price</th>
		        <th>My bid price</th>
		        <th>Pickup</th>
		        <th>Drop off</th>
		        <th>Carrier</th>
		        <th>Assigned truck</th>
		        <th>Status</th>
		        <th>Actions</th>
			</thead>
		</table>

  		<div class="panel" v-for="load in loads" :key="load.id">
  			<div class="panel-body">
  				<div class="row">
  					<div class="col-md-1">
  						<td>
			      			<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }, query: { source: 'SHIPPER_BROKER_BIDS' }}">
			      				{{ load.loadNumber }}
			    			</router-link>
			      		</td>
  					</div>
  					<div class="col-md-11">
		      			<table class="table table-bordered table-hover" style="table-layout: fixed;">
		      				<tbody>
			      				<tr v-for="bid in load.bids" :key="bid.id">
						        	<td>{{ bid.bidNumber }}</td>
						          	<td>{{ load.currency }}{{ load.price }}</td>
						          	<td>{{ load.currency }}{{ bid.amount }}</td>
						          	<td>
						          		{{ load.pickupAddress !== null ? load.pickupAddress.city.name + ', ' + load.pickupAddress.province.name: '' }}
						          	</td>
						          	<td>
						          		{{ load.dropOffAddress !== null ? load.dropOffAddress.city.name + ', ' + load.dropOffAddress.province.name: '' }}
						          	</td>
						          	<td>{{ bid.carrier !== null ? bid.carrier.name: '' }}</td>
						          	<td>
						          		<router-link :to="{ name: 'trucks/view', params: { truckNumber: bid.assignedTruck.truckNumber }, query: { source: 'my-bids' }}">
						          			{{ bid.assignedTruck !== null ? bid.assignedTruck.registrationNumber: '' }}
						          		</router-link>
						          	</td>
						          <td>
						            <span class="label label-primary" v-if="bid.status === 'PENDING'">Not yet awarded</span>
						            <span class="label label-success" v-if="bid.status === 'AWARDED'">Awarded</span>
						            <span class="label label-success" v-if="bid.status === 'ACCEPTED'">Accepted</span>
						            <span class="label label-default" v-if="bid.status === 'DECLINED'">Declined</span>
						          </td>
						          <td>
						          	<span v-if="bid.status === 'PENDING'">
						          		<div class="row">
						          			<div class="col-md-5">
						          				<router-link :to="{ name: 'award-bid', params: { loadNumber: load.loadNumber, bidNumber: bid.bidNumber } }">
						          					<button class="btn btn-success btn-xs">Award <span class="glyphicon glyphicon-thumbs-up"></span></button>
						          				</router-link>
						          			</div>
						          			<div class="col-md-7">
						          				<button class="btn btn-danger btn-xs" @click="decline(bid.bidNumber)">Decline <span class="glyphicon glyphicon-thumbs-down"></span></button>	
						          			</div>
						          		</div>
						          	</span>&nbsp;
						            <span v-if="bid.status === 'AWARDED' && getRoles.includes('CARRIER')">
						            	<router-link :to="{ name: 'carrier/loads/bids/acceptOrDeclineOffer', params: { bookingNumber: bid.bookingNumber } }">
						            		<button class="btn btn-warning">Accept or Decline Offer <span class="glyphicon glyphicon-edit"></span></button>
						            	</router-link>
						            </span>
						          </td>
					      		</tr>
			      			</tbody>
		      			</table>
  					</div>
  				</div>
  			</div>
  		</div>
  		<div class="panel" v-if="loads.length === 0">
  			<div class="panel-body">
  				<p>No bids available</p>
  			</div>
  		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService.js";
	import BidService from "@/services/BidService.js";
	import Bid from "@/models/Bid.js";

	export default{
		name: 'Bids',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    loads: [],
			    bid: new Bid
			}
		},

		mounted(){
			this.findByOwnerUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		},

		methods: {
			findByOwnerUsername: function(){
				this.show = true;
				LoadService.findByOwnerUsername(this.$store.state.auth.user.user.username).then(response => {
					this.loads = response.data.body.filter(load => {
						return load.bids !== null && load.bids.length > 0;
					});
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			decline: function(bidNumber){
				this.show = true;
				this.bid.bidNumber = bidNumber;
				BidService.decline(this.bid).then(response => {
					if (response.data.statusCode === 201){
						this.findByOwnerUsername();
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>