<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">

        <div class="row">
          <div class="col-md-10">
            <h4>{{ editing ? "Edit": "New" }} country </h4>
          </div>
          <router-link :to="{ name: 'countries' }">
            <div class="col-md-2">
              <button class="btn btn-primary btn-block">Back</button>
            </div>
          </router-link>
        </div>
        <br>

        <p class="error-message text-center" v-if="errorMessage !== null">{{ errorMessage }}</p>
        <form class="form" @submit.prevent="save">

          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" v-model="country.name" class="form-control" placeholder="Enter Country Name" required/>
          </div>

          <div class="form-group">
            <label for="name">Country Code</label>
            <input type="number" v-model="country.code" class="form-control" placeholder="Enter Country Code" required/>
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <input type="text" v-model="country.description" class="form-control" placeholder="Enter Description"/>
          </div>

          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" v-model="country.status">
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="submit">&nbsp;</label>
                <button class="btn btn-success btn-block" type="submit">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CountryService from "@/services/CountryService";
  import Country from "@/models/Country";
  import loading from "vue-full-loading";

  export default {
    name: "NewCountry",
    components: {
      loading
    },

    data: function() {
      return {
        country: new Country,
        errors: [],
        errorMessage: null,
        show: false,
        label: "Processing request",
        editing: false
      };
    },

    mounted(){
      if ("countryId" in this.$route.params) {
        this.editing = true;
        this.findById();
      }
    },

    methods: {
      findById: function(){
        CountryService.findById(this.$route.params.countryId).then(response => {
          this.country = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      save: function() {
        this.show = true;
        if (this.editing) {
          this.country.countryId = this.$route.params.countryId;
        }
        CountryService.save(this.country).then(response => {
          this.show = false;
          if (response.data.statusCode == 201) {
            this.$router.push({ name: "countries" });
          }
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      },
    }
  };
</script>
