import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class BidService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "bids");
  }

  findByCarrierUsername(username) {
    return axios.get(GENERIC_SERVICE_URL + "bids/findByCarrierUsername/" + username);
  }

  save(bid) {
    return axios.post(GENERIC_SERVICE_URL + "bids", {
      id: bid.id,
      loadNumber: bid.loadNumber,
      bidNumber: bid.bidNumber,
      carrierUsername: bid.carrierUsername,
      awarded: bid.awarded,
      amount: bid.amount,
      currency: bid.currency,
      assignedTruckId: bid.assignedTruckId
    });
  }

  findByBidNumber(bidNumber) {
    return axios.get(GENERIC_SERVICE_URL + "bids/findByBidNumber/" + bidNumber);
  }

  award(bid) {
    return axios.post(GENERIC_SERVICE_URL + "bids/award", {
      loadNumber: bid.loadNumber,
      bidNumber: bid.bidNumber,  
    });
  }

  decline(bid) {
    return axios.post(GENERIC_SERVICE_URL + "bids/decline", {
      bidNumber: bid.bidNumber
    });
  }

  acceptOrDeclineOffer(bid){
    return axios.post(GENERIC_SERVICE_URL + "bids/acceptOrDeclineOffer", {
      bidNumber: bid.bidNumber,
      status: bid.status,
      loadNumber: bid.loadNumber
    });
  }

  findByLoadLoadNumberAndCarrierUsername(bidNumber, username) {
    return axios.get(GENERIC_SERVICE_URL + "bids/findByLoadLoadNumberAndCarrierUsername/" + bidNumber + "/" + username);
  }

  checkBidsLimitReached(loadNumber) {
    return axios.get(GENERIC_SERVICE_URL + "bids/checkBidsLimitReached/" + loadNumber);
  }
}

export default new BidService();
