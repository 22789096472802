import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class LoadBidService {
  findByLoadOwnerUsername(username) {
    return axios.get(GENERIC_SERVICE_URL + "loadBids/findByLoadOwnerUsername/" + username);
  }

  findByBidCarrierAssociatedUserUsername(username) {
    return axios.get(GENERIC_SERVICE_URL + "loadBids/findByBidCarrierAssociatedUserUsername/" + username);
  }

  checkCarrierAlreadyBidLoad(loadNumber, carrierId){ 
    return axios.get(GENERIC_SERVICE_URL + "loadBids/checkCarrierAlreadyBidLoad/" + loadNumber + "/" + carrierId); 
  }
}

export default new LoadBidService;
