var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-link',{attrs:{"to":{ name: 'configurations' },"replace":""}},[_c('button',{staticClass:"btn btn-primary"},[_c('span',{staticClass:"glyphicon glyphicon-step-backward"}),_vm._v(" Back")])]),_c('router-link',{attrs:{"to":{ name: 'provinces/new' }}},[_c('button',{staticClass:"btn btn-success pull-right"},[_vm._v(" New "),_c('span',{staticClass:"glyphicon glyphicon-plus"})])]),_c('br'),_c('br'),_c('h4',[_vm._v("Province List")]),_c('table',{staticClass:"table table-bordered table-responsive-md"},[_vm._m(0),_c('tbody',_vm._l((_vm.provinces),function(province){return _c('tr',{key:province.id},[_c('td',[_vm._v(_vm._s(province.id))]),_c('td',[_vm._v(_vm._s(province.name))]),_c('td',[(province.status === 'ACTIVE')?_c('span',[_c('span',{staticClass:"badge badge-success"},[_vm._v("Active")])]):_vm._e(),(province.status === 'INACTIVE')?_c('span',[_c('span',{staticClass:"badge badge-secondary"},[_vm._v("Inactive")])]):_vm._e()]),_c('td',[_c('router-link',{attrs:{"to":{
              name: 'provinces/view',
              params: { provinceId: province.id }
            }}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v(" View "),_c('span',{staticClass:"glyphicon glyphicon-info-sign"})])]),_vm._v("  "),_c('router-link',{attrs:{"to":{
              name: 'provinces/edit',
              params: { provinceId: province.id }
            }}},[_c('button',{staticClass:"btn btn-warning"},[_vm._v(" Edit "),_c('span',{staticClass:"glyphicon glyphicon-edit"})])])],1)])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-primary"},[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Status")]),_c('td',[_vm._v("Actions")])])
}]

export { render, staticRenderFns }