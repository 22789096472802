<template>
  <div>
    <h4>My Payments</h4>
    <br>

    <form class="form">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Payment Type</label>
            <select class="form-control">
              <option value="" disabled>Select payment type</option>
              <option value="ALL">All payments</option>
              <option value="ONLINE_PAYMENT">Online payments</option>
              <option value="BANK">Bank payments</option>
              <option value="CASH">Cash payments</option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label>Currency</label>
            <select class="form-control">
              <option value="" disabled>Select currency</option>
              <option value="ZWL">ZWL</option>
              <option value="USD">USD</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <label>From</label>
          <input type="date" name="dateFrom" id="dateFrom" class="form-control">
        </div>
        <div class="col-md-2">
          <label>To Date</label>
          <input type="date" name="dateFrom" id="dateFrom" class="form-control">
        </div>
        <div class="col-md-2">
          <label>Release Payments</label>
          <button class="btn btn-primary">Release</button>
        </div>
      </div>
    </form>

    <table class="table table-bordered table-hover table-striped table-responsive">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Reference Number</th>
        <th>Amount</th>
        <th>Channel</th>
        <th>Status</th>
        <th>Action</th>
      </thead>
      <tbody>
        <tr v-for="load in loads" :key="load.id">
          <td>{{ load.payment.id }}</td>
          <td>{{ load.payment.referenceNumber }}</td>
          <td>{{ load.payment.currency }}${{ load.payment.amount }}</td>
          <td>{{ load.payment.paymentMethod }}</td>
          <td>
            <span class="label label-primary" v-show="load.payment.status === 'ACTIVE'">{{ load.payment.status }}</span>
            <span class="label label-success" v-show="load.payment.status === 'PAID'">Awaiting Authorization</span>
          </td>
          <td>
            <span v-show="load.payment.status === 'PAID'">
              <router-link :to="{ name: 'payments/view', params: { referenceNumber: load.payment.referenceNumber }}">
                <button class="btn btn-primary btn-sm"><span class="glyphicon glyphicon-info-sign"></span> View</button>
              </router-link>&nbsp;
            </span>
            <span v-show="load.payment.status === 'ACTIVE'">
              <router-link :to="{ name: 'payments/view', params: { referenceNumber: load.payment.referenceNumber }}">
                <button class="btn btn-success btn-sm"><span class="glyphicon glyphicon-info-sign"></span> Release</button>
              </router-link>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
  import LoadService from "@/services/LoadService.js";

  export default {
    name: "CarrierPaymentList",
    data: function() {
      return {
        loads: [],
      };
    },

    computed: {

    },

    created: function() {
      this.findByCarrierContactPersonUsername();
    },

    methods: {
      findByCarrierContactPersonUsername: function() {
        LoadService.findByCarrierContactPersonUsername(this.$store.state.auth.user.user.username).then(response => {
          this.loads = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>
