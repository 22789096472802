<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
		</loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>        
    </div>

    <h4>Select your prefered subscription plan to get the best out of Freight Board</h4>
    <p><i>Based on tiered pricing</i></p>
    <div class="row">
      <div class="col-md-4" v-for="subscriptionPlan in subscriptionPlans" :key="subscriptionPlan.id">
        <div class="panel">
          <div class="panel-body">
            <ul class="list-group">
              <li class="list-group-item"><h4 class="text-center" style="color: #000000;">{{ subscriptionPlan.name }}</h4></li>
              <li class="list-group-item" style="color: #000000;">{{ subscriptionPlan.currency }}${{ subscriptionPlan.price }} / month</li>
              <li class="list-group-item">
                <BasicCarrierFeatures v-if="subscriptionPlan.subscriptionType === 'BASIC' && subscriptionPlan.identifier === 'CARRIER'"/>
                <AdvancedCarrierFeatures v-if="subscriptionPlan.subscriptionType === 'ADVANCED' && subscriptionPlan.identifier === 'CARRIER'"/>
                <PremiumCarrierFeatures v-if="subscriptionPlan.subscriptionType === 'PREMIUM' && subscriptionPlan.identifier === 'CARRIER'"/>

                <BasicShipperFeatures v-if="subscriptionPlan.subscriptionType === 'BASIC' && subscriptionPlan.identifier === 'SHIPPER'"/>
                <AdvancedShipperFeatures v-if="subscriptionPlan.subscriptionType === 'ADVANCED' && subscriptionPlan.identifier === 'SHIPPER'"/>
                <PremiumShipperFeatures v-if="subscriptionPlan.subscriptionType === 'PREMIUM' && subscriptionPlan.identifier === 'SHIPPER'"/>

                <BasicBrokerFeatures v-if="subscriptionPlan.subscriptionType === 'BASIC' && subscriptionPlan.identifier === 'BROKER'"/>
                <AdvancedBrokerFeatures v-if="subscriptionPlan.subscriptionType === 'ADVANCED' && subscriptionPlan.identifier === 'BROKER'"/>
                <PremiumBrokerFeatures v-if="subscriptionPlan.subscriptionType === 'PREMIUM' && subscriptionPlan.identifier === 'BROKER'"/>
              </li>
              <br>             
              <button :class="getSelectedSubscriptionPlanNumber === subscriptionPlan.subscriptionPlanNumber ? 'btn btn-success btn-block': 'btn btn-primary btn-block'" type="submit" @click="setSelectedSubscriptionPlanNumber(subscriptionPlan.subscriptionType, subscriptionPlan.subscriptionPlanNumber, subscriptionPlan.price, subscriptionPlan.currency)">
              <h4 class="text-center">{{ getSelectedSubscriptionPlanNumber === subscriptionPlan.subscriptionPlanNumber ? 'Selected': 'Subscribe' }}</h4>
              </button>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-show="getSelectedSubscriptionPlanNumber !== null">
      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <router-link :to="{ name: 'new-subscription-invoice' }">
            <button class="btn btn-primary btn-block">Subscribe</button>
          </router-link>
        </div>
      </div>
    </div>
	</div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import SubscriptionPlanService from "@/services/SubscriptionPlanService.js";
  import BasicCarrierFeatures from "@/views/subscriptions/BasicCarrierFeatures";
  import AdvancedCarrierFeatures from "@/views/subscriptions/AdvancedCarrierFeatures";
  import PremiumCarrierFeatures from "@/views/subscriptions/PremiumCarrierFeatures";

  import BasicShipperFeatures from "@/views/subscriptions/BasicShipperFeatures";
  import AdvancedShipperFeatures from "@/views/subscriptions/AdvancedShipperFeatures";
  import PremiumShipperFeatures from "@/views/subscriptions/PremiumShipperFeatures";

  import BasicBrokerFeatures from "@/views/subscriptions/BasicBrokerFeatures";
  import AdvancedBrokerFeatures from "@/views/subscriptions/AdvancedBrokerFeatures";
  import PremiumBrokerFeatures from "@/views/subscriptions/PremiumBrokerFeatures";

  export default {
    name: "NewSubscription",
    components: {
      loading,
      BasicCarrierFeatures,
      AdvancedCarrierFeatures,
      PremiumCarrierFeatures,

      BasicShipperFeatures,
      AdvancedShipperFeatures,
      PremiumShipperFeatures,

      BasicBrokerFeatures,
      AdvancedBrokerFeatures,
      PremiumBrokerFeatures
    },
    
    data: function() {
      return {
        show: false,
        label: "Processing subscription",
        errors: null,
        companyId: null,
        zipCodes: [],
        errorMessage: null,
        phoneNumberFields: [{
          zipCodeId: null,
          phoneNumber: null
        }],
        maxLength: 9,
        type: JSON.parse(localStorage.getItem("type")),
        username: JSON.parse(localStorage.getItem("username")),
        subscriptionPlan: null,
        subscriptionPlans: [],
        selectedSubscriptionPlanNumber: this.$store.state.selectedSubscriptionPlanNumber
      };
    },

    mounted() {
      this.findAll();
    },

    computed: {
    	getUuid(){
    		return this.$route.params.uuid;
    	},

      getSelectedSubscriptionPlanNumber(){
        return this.$store.state.selectedSubscriptionPlanNumber;
      }
    },

    methods: {
      setSelectedSubscriptionPlanNumber(subscriptionType, subscriptionPlanNumber, price, currency){
        this.$store.commit('setSelectedPrice', price);
        this.$store.commit('setSelectedCurrency', currency);
        this.$store.commit('setSelectedSubscriptionPlanNumber', subscriptionPlanNumber);

        localStorage.setItem('selectedSubscriptionType', JSON.stringify(subscriptionType));
        localStorage.setItem('selectedSubscriptionPlanNumber', JSON.stringify(subscriptionPlanNumber));
        localStorage.setItem('selectedPrice', JSON.stringify(price));
        localStorage.setItem('selectedCurrency', JSON.stringify(currency));
      },

      findAll: function() {
        this.show = true;
        SubscriptionPlanService.findAll().then(response => {
          this.subscriptionPlans = response.data.body.filter(subscriptionPlan => {
            return subscriptionPlan.identifier === this.type;
          });
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>

<style type="text/css" scoped>
  .black-btn{
    background-color: #000000;
    color: #ffffff;
  }
</style>
