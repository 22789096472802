<template>
  <div>
    <h4>Payments</h4>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-1">
            <router-link :to="{ name: 'payment-list' }">
              <button class="btn btn-default btn-block"><span class="badge">{{ allInvoices.length }}</span> All</button>  
            </router-link>
          </div>
          <div class="col-md-2">
            <router-link :to="{ name: 'withholding-payments' }">
              <button class="btn btn-primary btn-block"><span class="badge">{{ withholdingInvoices.length }}</span> Withholding</button>  
            </router-link>
          </div>
          <div class="col-md-2">
            <router-link :to="{ name: 'ready-for-authorization-payments' }">
              <button class="btn btn-primary btn-block"><span class="badge">{{ readyForAuthorizationInvoices.length }}</span> Authorization Ready</button>  
            </router-link>
          </div>
          <div class="col-md-2">
            <router-link :to="{ name: 'authorized-payments' }">
              <button class="btn btn-default btn-block"><span class="badge">{{ authorizedInvoices.length }}</span> Authorized</button>  
            </router-link>
          </div>
          <div class="col-md-2">
            <router-link :to="{ name: 'paid-payments' }">
              <button class="btn btn-success btn-block"><span class="badge">{{ paidInvoices.length }}</span> Paid from Escrow</button>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link :to="{ name: 'outstanding-payments' }">
              <button class="btn btn-danger btn-block"><span class="badge">{{ outstandingvoices.length }}</span> Outstanding/Disputed</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <router-view>

    </router-view>
  </div>
</template>

<script type="text/javascript">
  import PaymentService from "@/services/PaymentService.js";

  export default {
    name: "PaymentList",
    components: {

    },

    data: function() {
      return {
        allInvoices: [],
        withholdingInvoices: [],
        readyForAuthorizationInvoices: [],
        authorizedInvoices: [],
        paidInvoices: [],
        outstandingvoices: [],
      };
    },

    created: function() {
      this.getAllInvoices();
      this.getWithholdingInvoices();
      this.getReadyForAuthorizationInvoices();
      this.getAuthorizedInvoices();
      this.getPaidInvoices();
      this.getOutstandingvoices();
    },

    computed: {
      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    methods: {
      getAllInvoices: function() {
        PaymentService.findAll().then(response => {
          this.allInvoices = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      getWithholdingInvoices: function() {
        PaymentService.getWitholding().then(response => {
          this.withholdingInvoices = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      getReadyForAuthorizationInvoices: function() {
        PaymentService.getReadyForAuthorization().then(response => {
          this.readyForAuthorizationInvoices = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      getAuthorizedInvoices: function() {
        PaymentService.getAuthorized().then(response => {
          this.authorizedInvoices = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      getPaidInvoices: function() {
        PaymentService.getPaid().then(response => {
          this.paidInvoices = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      getOutstandingvoices: function() {
        PaymentService.getOutstanding().then(response => {
          this.outstandingvoices = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>
