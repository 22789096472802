export default class LoadCharge{
	constructor(id, percentageContribution, ceiling, currencyId, effectiveYear, startDate, endDate, expired,
		enabled, active, current, status, description){
		this.id = id;
		this.percentageContribution = percentageContribution;
		this.ceiling = ceiling;
		this.currencyId = currencyId;
		this.effectiveYear = effectiveYear;
		this.startDate = startDate;
		this.endDate = endDate;
		this.expired = expired;
		this.enabled = enabled;
		this.active = active;
		this.current = current;
		this.status = status;
		this.description = description;
	}
}