<template>
	<div>
		<br>
		<div v-if="accounts !== null && accounts.length > 0">
			<ul v-for="(account, index) in accounts" :key="account.id">
				<span class="text-danger">Account number {{ index + 1 }}</span><br><br>
				<li class="list-group-item">Payment Method: {{ account.paymentMethod }}</li>
				<li class="list-group-item">Account Name: {{ account.accountName }}</li>
				<li class="list-group-item">Account Number: {{ account.accountNumber }}</li>
				<li class="list-group-item">Account currency: {{ account.accountCurrency }}</li>
				<span v-if="account.paymentMethod === 'CARD'">
					<li class="list-group-item">Bank: {{ account.bankName }}</li>
					<li class="list-group-item">Bank Code : {{ account.bankCode }}</li>
				</span>
				<li class="list-group-item">Branch: {{ account.branch }}</li>
				<li class="list-group-item">Branch code: {{ account.branchCode }}</li>
				<span v-if="account.paymentMethod === 'MOBILE'">
					<li class="list-group-item">Operator Name: {{ account.operatorName }}</li>
					<li class="list-group-item">Phone Number: {{ account.phoneNumber }}</li>
				</span>
				<br>
			</ul>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default{
		props: {
			accounts: {
				type: Object,
				default: null
			}
		}
	}
</script>