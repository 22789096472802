<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-10">
                    <h4>Treaty Reinsurance Details</h4>
                </div>
                <router-link :to="{ name: 'nssa-list' }">
                    <div class="col-md-2">
                        <button class="btn btn-primary btn-block">Back</button>
                    </div>
                </router-link>
            </div>
        </div>
    </div>

    <div class="panel">
        <div class="panel-body">
            <ul class="list-group" v-if="treatyReinsurance !== null">
                <li class="list-group-item">Reinsurance Type: {{ treatyReinsurance.reinsuranceType }}</li>
                <li class="list-group-item">Proportional Reinsurance Type: {{ treatyReinsurance.proportionalReinsuranceType }}</li>
                <li class="list-group-item">Year: {{ treatyReinsurance.effectiveYear }}</li>
                <li class="list-group-item">Treaty Reinsurance Type: {{ treatyReinsurance.treatyReinsuranceType }}</li>
                <li class="list-group-item">Broker: {{ treatyReinsurance.broker !== null ? treatyReinsurance.broker.name: 'Not available' }}</li>
                <li class="list-group-item">Product Type: {{ treatyReinsurance.productType }}</li>
                <li class="list-group-item">Max Limit: {{ treatyReinsurance.currency }}{{ treatyReinsurance.maxLimit }}</li>
                <li class="list-group-item">Quota Share Percentage: {{ treatyReinsurance.quotaSharePercentage }}%</li>
                <li class="list-group-item">Commission Percentage: {{ treatyReinsurance.commissionPercentage }}%</li>
                <li class="list-group-item">Active: <span class="label label-primary">{{ treatyReinsurance.active }}</span></li>
                <li class="list-group-item">Current: <span class="label label-primary">{{ treatyReinsurance.current }}</span></li>
                <li class="list-group-item">Enabled: <span class="label label-primary">{{ treatyReinsurance.enabled }}</span></li>
                <li class="list-group-item">Expired: <span class="label label-primary">{{ treatyReinsurance.expired }}</span></li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script type="text/javascript">
	import LoadChargeService from "@/services/LoadChargeService";
	import loading from "vue-full-loading";

	export default {
	  name: "ViewNSSA",
	  components: {
	    loading
	  },

	  data: function() {
	    return {
	      show: false,
	      label: "Processing request",
	      treatyReinsurance: null
	    };
	  },

	  computed: {
	    getReinsuranceId() {
	      return this.$route.params.reinsuranceId;
	    }
	  },

	  mounted() {
	    this.findById();
	  },

	  methods: {
	    findById: function() {
	    	LoadChargeService.findById(this.getReinsuranceId).then(response => {
	    		this.show = false;
	    		this.treatyReinsurance = response.data.body;
	    	}).catch(error => {
	    		console.log(error);
	    		this.show = false;
	        });
	    }
	  }
	};
</script>
