<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <h4>Welcome, {{ company != null ? company.name: '' }}</h4>
    <br>

    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <h4><router-link :to="{ name: 'carrier/bookings' }">{{ bookings.length }}</router-link></h4>
                <p><router-link :to="{ name: 'carrier/bookings' }">Bookings</router-link></p>
              </div>
              <div class="col-md-6">
                <h4><router-link :to="{ name: 'carrier/bids' }">{{ loadBids.length }}</router-link></h4>
                <p><router-link :to="{ name: 'carrier/bids' }">Bids</router-link></p>
              </div>
            </div>
            <br><br>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="panel">
          <div class="panel-body text-center">
            <div class="row">
              <div class="col-md-2">
                <img src="@/assets/images/notification-bell.png" height="30" width="30">
              </div>
              <div class="col-md-8"></div>
              <div class="col-md-2">
                <router-link :to="{ name: 'carrier/loads/load-alerts' }">
                  View More <span class="glyphicon glyphicon-next"></span>
                </router-link>
              </div>
            </div>
            <br>
            <p>Post your load alert and view loads matching load alert for your convinience</p>
            <router-link :to="{ name: 'carrier/loads/load-alerts/new' }">
              <button class="btn btn-primary"><span class="glyphicon glyphicon-plus"></span> Add a load alert</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <h4>Stats</h4>
        <div class="row">
          <div class="col-md-6">
            <img src="@/assets/images/expenses.png" height="300" width="600" class="img img-responsive">
          </div>
          <div class="col-md-6">
            <img src="@/assets/images/gross-profit.png" height="300" width="600" class="img img-responsive">
          </div>
        </div>
      </div>
    </div>

    <h4>Find loads by State/Province</h4>
    <div class="row">
        <div class="col-md-2" v-for="province in provinces" :key="province.id">
          <router-link :to="{ name: 'dashboard-search-loads', query: { pickUpProvinceId: province.id }}">
            <div class="panel" style="padding-top: 10px; padding-left: 20px;">
              <p>{{ province.name }}</p>
            </div>
          </router-link>
        </div>
    </div>
</div>
</template>

<script>
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService.js";
  import ProvinceService from "@/services/ProvinceService.js";
  import BookingService from "@/services/BookingService.js";
  import LoadBidService from "@/services/LoadBidService.js";

  export default {
    name: "CarrierDashboard",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request...",
        company: null,
        provinces: [],
        loadBids: [],
        bookings: []
      };
    },

    mounted: function() {
      this.findByAssociatedUserUsername();
      this.findProvinces();
      this.findByBidCarrierAssociatedUserUsername();
      this.findByCarrierAssociatedUserUsername();
    },

    computed: {

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      }
    },

    methods: {
      findByAssociatedUserUsername: function() {
        this.show = true;
        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findProvinces: function() {
        this.show = true;
        ProvinceService.findAll().then(response => {
          if (response.data.statusCode == 200) {
            this.provinces = response.data.body;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findByBidCarrierAssociatedUserUsername: function() {
        this.show = true;
        LoadBidService.findByBidCarrierAssociatedUserUsername(this.getUser.username).then(response => {
          this.loadBids = response.data.body.filter(loadBid => {
              return loadBid.bid.status === 'AWARDED';
            });
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findByCarrierAssociatedUserUsername: function() {
        this.show = true;
          BookingService.findByCarrierAssociatedUserUsername(this.getUser.username).then(response => {
            this.bookings = response.data.body.filter(booking => {
              return booking.status === 'AWARDED';
            });
            this.show = false;
          }).catch(error => {
            console.log(error);
            this.show = false;
          });
      },
    }
  };
</script>
