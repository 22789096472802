<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <router-link :to="{ name: 'configurations' }" replace
      ><button class="btn btn-primary">
        <span class="glyphicon glyphicon-step-backward"></span> Back
      </button></router-link
    >

    <router-link :to="{ name: 'exchangeRates/new' }" replace><button class="btn btn-success pull-right">New Exchange Rate
      <span class="glyphicon glyphicon-plus"></span></button>
      </router-link><br/><br/>

    <h4>Exchange Rates</h4>
    <table class="table table-responsive table-bordered">
      <thead class="bg-primary">
        <th>ID</th>
        <th>currency</th>
        <th>Mid Rate</th>
        <th>Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="exchangeRate in exchangeRates" :key="exchangeRate.id">
          <td>{{ exchangeRate.id }}</td>
          <td>{{ exchangeRate.currency }}</td>
          <td>{{ exchangeRate.midRate }}</td>
          <td>
            <span class="label label-success" v-if="exchangeRate.status == 'ACTIVE'">Active</span>
            <span class="label label-default" v-if="exchangeRate.status == 'INACTIVE'">Inactive</span>
            <span class="label label-danger" v-if="exchangeRate.status == 'DELETED'">Deleted</span>
          </td>
          <td>
            <router-link :to="{ name: 'exchangeRates/edit', params: { exchangeRateId: exchangeRate.id } }">
              <button class="btn btn-primary">Edit</button>
              </router-link>&nbsp;
            <router-link :to="{ name: 'exchangeRates/view', params: { exchangeRateId: exchangeRate.id } }">
            <button class="btn btn-primary">View</button></router-link>&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
  import ExchangeRateService from "@/services/ExchangeRateService";
  import loading from "vue-full-loading";

  export default {
    name: "ExchangeRateList",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing...",
        exchangeRates: []
      };
    },
    created() {
      this.getAllBanks();
    },
    methods: {
      getAllBanks() {
        this.show = true;
        ExchangeRateService.findAll()
          .then(response => {
            this.show = false;
            this.exchangeRates = response.data.body;
          })
          .catch(error => {
            this.show = false;
            console.log(error);
          });
      }
    }
  };
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
