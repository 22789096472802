<template>
  <div>
    <h4>My Loads Payments</h4>

    <div class="panel">
      <div class="panel-body">
        <form class="form">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Payment Type</label>
                <select class="form-control" v-model="paymentType" @change="filterPaymentsData">
                  <option value="" disabled>Select payment type</option>
                  <option value="ALL">All payments</option>
                  <option value="ONLINE_PAYMENT">Online payments</option>
                  <option value="BANK">Bank payments</option>
                  <option value="CASH">Cash payments</option>
                </select>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label>Currency</label>
                <select class="form-control" v-model="currency">
                  <option value="" disabled>Select currency</option>
                  <option value="ZWL">ZWL</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <label>From</label>
              <input type="date" name="dateFrom" id="dateFrom" class="form-control">
            </div>
            <div class="col-md-2">
              <label>To Date</label>
              <input type="date" name="dateFrom" id="dateFrom" class="form-control">
            </div>
          </div>
        </form>
        
        <table class="table table-bordered table-hover table-striped table-responsive">
          <thead class="bg-primary">
            <!-- <th>ID</th> -->
            <th>Load Number</th>
            <th>Invoice Number</th>
            <th>Debit Reference</th>
            <th>Amount</th>
            <th>Channel</th>
            <!-- <th>Status</th> -->
            <th>Action</th>
          </thead>
          <tbody v-if="loads !== null">
            <tr v-for="load in paginatedPayments" :key="load.id">
              <!-- <td>{{ load.invoice.payment.id }}</td> -->
              <td>
                <router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }}">
                  {{ load.loadNumber }}
                </router-link>
              </td>
              <td>{{ load.invoice.invoiceNumber }}</td>
              <td>{{ load.invoice.payment.debitReference }}</td>
              <td>{{ load.invoice.payment.currency }}{{ load.invoice.payment.amount }}</td>
              <td>{{ load.invoice.payment.paymentMethod }}</td>
             <!--  <td>
                <span class="label label-primary" v-show="load.invoice.status === 'ACTIVE'">Submitted</span>
                <span class="label label-success" v-show="load.invoice.status === 'AUTHORIZED'">Authorized</span>
                <span class="label label-success" v-show="load.invoice.status === 'RELEASED'">Released</span>
                <span class="label label-success" v-show="load.invoice.status === 'WITHHOLDING'">In Escrow</span>
              </td> -->
              <td>
                <span>
                  <router-link :to="{ name: 'view-payment', params: { loadNumber: load.loadNumber }}">
                    <button class="btn btn-primary btn-sm">View<span class="glyphicon glyphicon-info-sign"></span></button>
                  </router-link>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" v-if="totalPages > 1">
      <div class="col-md-7"></div>
      <div class="col-md-3 pull-right">
        <div class="panel">
          <div class="panel-body">
            <button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="currentPage === 1" @click="prevPage"></button>
            &nbsp;<span>Page {{ currentPage }} of {{ totalPages }}</span>&nbsp;
            <button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="currentPage === totalPages" @click="nextPage"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import PaymentService from "@/services/PaymentService.js";
  import CityService from "@/services/CityService";

  export default {
    name: "MyPayments",
    data: function() {
      return {
        paymentType: null,
        payments: [],
        filterPayments: [],
        loads: [],
        cities: [],
        currentPage: JSON.parse(localStorage.getItem('currentPage')) !== null ? JSON.parse(localStorage.getItem('currentPage')): 1,
        itemsPerPage: 3,
        currency: null,
        loadMode: null,
        trailerTypeRequired: null,
        pickupDate: null,
        dropOffDate: null,
        pickUpCityId: null,
        dropOffCityId: null,
        weight: null,
        price: null
      };
    },

    created: function() {
      this.findAllCities();
      this.findByPayerUsername();
      this.findByCarrier();
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },

            totalPages(){
        return Math.ceil(this.filteredLoads.length / this.itemsPerPage);
      },

      filteredLoads(){
        let filteredLoads;
        if ((this.currency !== null && this.currency !== undefined) || 
          (this.loadMode !== null && this.loadType !== undefined) ||
          (this.trailerTypeRequired !== null && this.trailerTypeRequired !== undefined) || 
          (this.pickupDate !== null && this.pickupDate !== undefined) || 
          (this.dropOffDate !== null && this.dropOffDate !== undefined) || 
          (this.weight !== null && this.weight !== undefined) || 
          (this.price !== null && this.price !== undefined) || 
          (this.pickUpCityId !== null && this.pickUpCityId !== undefined) || 
          (this.dropOffCityId !== null && this.dropOffCityId !== undefined)) {

          localStorage.setItem('currency', JSON.stringify(this.currency));
          localStorage.setItem('loadMode', JSON.stringify(this.loadMode));
          localStorage.setItem('trailerTypeRequired', JSON.stringify(this.trailerTypeRequired));
          localStorage.setItem('pickupDate', JSON.stringify(this.pickupDate));
          localStorage.setItem('dropOffDate', JSON.stringify(this.dropOffDate));
          localStorage.setItem('weight', JSON.stringify(this.weight));
          localStorage.setItem('price', JSON.stringify(this.price));
          localStorage.setItem('pickUpCityId', JSON.stringify(this.pickUpCityId));
          localStorage.setItem('dropOffCityId', JSON.stringify(this.dropOffCityId));

          filteredLoads = this.loads.filter(load => {
            return load.currency === this.currency || 
              load.loadMode === this.loadMode ||
              load.trailerTypeRequired === this.trailerTypeRequired || 
              load.pickupDate === this.pickupDate || 
              load.dropOffDate === this.dropOffDate || 
              load.weight === parseFloat(this.weight) || 
              load.price === parseFloat(this.price) || 
              load.pickupAddress.city.id === this.pickUpCityId || // may fail due to nulls
              load.destinationAddress.city.id === this.dropOffCityId
          });
        }else{
          filteredLoads = this.loads;
        }
        return filteredLoads;
      },

      paginatedPayments(){
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        return this.filteredLoads.slice(startIndex, startIndex + this.itemsPerPage);
      }
    },

    methods: {
      findAllCities: function(){
        CityService.findAll().then(response => {
          this.cities = response.data.body;
            }).catch(error => {
              console.log(error);
            });
      },

      findByPayerUsername: function(){
        PaymentService.findByPayerUsername(this.$store.state.auth.user.user.username).then(response => {
          this.payments = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      filterInvoicesData: function() {},

      filterPaymentsData: function(){
        let cashPayments = this.payments;
        cashPayments = cashPayments.filter(payment => {
          switch (this.paymentType) {
            case "CASH":
              return payment.paymentType === "CASH";
            case "BANK":
              return payment.paymentType === "BANK";
            case "ONLINE":
              return payment.paymentType === "ONLINE";
            case "ALL":
              return this.payments;
            default:
              return this.payments;
          }
        });
        this.filterPayments = cashPayments;
      },

      findByCarrier: function(){
        PaymentService.findByCarrier(this.$store.state.auth.user.user.username).then(response => {
          this.loads = response.data.body.filter(load => {
            return load.invoice !== null && load.invoice.payment !== null;
          });
          console.log(this.loads);
        }).catch(error => {
          console.log(error);
        });
      },

      prevPage(){
        if (this.currentPage > 1) {
          const currentPage = --this.currentPage;
          localStorage.setItem('currentPage', JSON.stringify(currentPage));
        }
      },

      nextPage(){
        if (this.currentPage < this.totalPages) {
          const currentPage = ++this.currentPage;
          localStorage.setItem('currentPage', JSON.stringify(currentPage));
        }
      },

      clearFilter(){
        localStorage.removeItem('currency');
        localStorage.removeItem('loadMode');
        localStorage.removeItem('trailerTypeRequired');
        localStorage.removeItem('pickupDate');
        localStorage.removeItem('dropOffDate');
        localStorage.removeItem('pickUpCityId');
        localStorage.removeItem('dropOffCityId');
        localStorage.removeItem('weight');
        localStorage.removeItem('price');
        localStorage.removeItem('currentPage');
        window.location.reload();
      }
    }
  };
</script>
