<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div v-if="company !== null">
      <form class="form" @submit.prevent="update">
        <div class="form-group" v-show="isDisabled">
          <label>City</label>
          <input type="text" name="cityId" id="cityId" class="form-control" v-model="company.address.cityId" :placeholder="company.address.city !== null ? company.address.city.name: 'Not available'" :disabled="isDisabled"/>
        </div>

        <div class="form-group" v-show="!isDisabled">
          <label>City</label>
          <select class="form-control" v-model="company.address.cityId" :disabled="isDisabled" required>
            <option value="">Select City</option>
            <option v-for="city in cities" v-bind:key="city.id" v-bind:value="city.id">{{ city.name }}</option>
          </select>
        </div>

         <div class="form-group">
          <label>Physical Address</label>
          <textarea rows="5" class="form-control" v-model="company.address.physicalAddress" :disabled="isDisabled" placeholder="Enter Physical Address" required></textarea>
        </div>
      </form>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService.js";
  import loading from "vue-full-loading";
  import CityService from "@/services/CityService.js";

  export default {
    name: "BasicDetailsComponent",
    components: {
      loading
    },

    props: {
      company: {
        type: Object,
        default: null
      }
    },

    data: function() {
      return {
        isDisabled: true,
        label: "Processing request",
        show: false,
        cities: [],
        address: {
          id: null,
          zipCodeId: null,
          countryId: null,
          provinceId: null,
          cityId: null,
          areaId: null,
          physicalAddress: null,
          sameAsPostalAddress: false
        },
      }
    },

    created(){
      this.findAllCities();
    },

    methods: {
      enableEdit: function(){
        if(this.isDisabled) {
          this.isDisabled = false;
        }
      },

      update: function(){
        this.show = true;
        this.company.companyId = this.getCompanyId;
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode == 201) {
            this.show = false;
            this.isDisabled = true;
            this.$router.push({ name: 'companies' });
          }}).catch(error => {
            console.log(error);
          });
        },

        findAllCities: function() {
          CityService.findAll().then(response => {
            this.cities = response.data.body;
          }).catch(error => {
            console.log(error);
          });
        },
    },

    computed: {
      getCompanyId: function(){
        return this.$route.params.companyId;
      },
    }
  };
</script>
