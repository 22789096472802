<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div v-if="subscription !== null">
        <div v-if="subscription.subscriptionPlan.subscriptionType !== 'PREMIUM'">
          <div class="panel">
            <div class="panel-body">
              <h4 class="text-danger">Oops, your current subscription plan does not allow you to search for trucks.</h4>
              <p>Please consider upgrading your plan!</p>
              <br>
              <router-link :to="{ name: 'upgrade-subscription' }">
                <button class="btn btn-primary">Upgrade Subscription</button>
              </router-link>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="panel">
            <div class="panel-body">
              <h4 class="text-danger">Find available carriers based on your load requirements</h4>
              <br>
              <form class="form" @submit.prevent="searchTrucks">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Truck Type</label>
                      <select class="form-control" name="truckType" v-model="type" required>
                        <option value="" disabled>Select option</option>
                        <option value="STANDARD">STANDARD</option>
                        <option value="BOX_TRUCK">BOX_TRUCK</option>
                        <option value="POWER_ONLY">POWER_ONLY</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Load Mode</label>
                      <select class="form-control" name="loadMode" v-model="loadMode" required>
                        <option value="" disabled>Select option</option>
                        <option value="FTL">Full truckload - FTL</option>
                        <option value="LTL">Less than truckload - LTL</option>
                        <option value="INTERMODAL">Intermodal</option>
                        <option value="POWER_ONLY">Power-Only</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Trailer Type</label>
                      <select class="form-control" v-model="trailerType" required>
                        <option value="" disabled>Select option</option>    
                        <option value="FLATBED">FLATBED</option>
                        <option value="VAN">VAN</option>
                        <option value="REEFER">REEFER</option>
                        <option value="STEP_DECK">STEP_DECK</option>
                        <option value="POWER_ONLY">POWER_ONLY</option>
                        <option value="AUTO_CARRIER">AUTO_CARRIER</option>
                        <option value="B_TRAIN">B_TRAIN</option>
                        <option value="BOX_TRUCK">BOX_TRUCK</option>
                        <option value="CONESTOGA">CONESTOGA</option>
                        <option value="CONTAINERS">CONTAINERS</option>
                        <option value="DOUBLE_DROP">DOUBLE_DROP</option>
                        <option value="DRY_BULK">DRY_BULK</option>
                        <option value="DUMP_TRAILER">DUMP_TRAILER</option>
                        <option value="HOPPER_BOTTOM">HOPPER_BOTTOM</option>
                        <option value="HOTSHOT">HOTSHOT</option>
                        <option value="LOWBOY">LOWBOY</option>
                        <option value="TANKER">TANKER</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Max Load Size (tonne)</label>
                      <input type="number" class="form-control" placeholder="Enter max load size" v-model="maxLoadSize"/>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Trailer Length (m)</label>
                      <input type="number" class="form-control" placeholder="Enter trailer length" v-model="trailerLength"/>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>&nbsp;</label><br>
                      <button class="btn btn-block btn-primary" @click="search()">Search</button>
                    </div>
                  </div>
                  <div class="col-md-10"></div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>&nbsp;</label><br>
                      <button class="btn btn-block" type="button" @click="clearFilter()" style="background-color: #000000; color: #ffffff;">Reset filter</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="panel" v-if="paginatedTrucks.length > 0">
            <div class="panel-body">
              <span v-if="selectedCarriersByTruckSearch.length > 0">
                <router-link :to="{ name: 'new-load-basic-details' }">
                  <button class="btn btn-danger pull-right" @click="createLoadFromSelectedTruckers">Create load from selected truckers</button>
                </router-link>
              </span>
              <br><br><br><br>

              <table class="table table-responsive table-bordered table-hover" v-show="carrierTrucks.length > 0">
                <thead class="bg-primary">
                  <th>Type</th>
                  <th>Truck Number</th>
                  <th>Carrier</th>
                  <th>Reg. Number</th>
                  <th>Trailer Type</th>
                  <th>Trailer Length</th>
                  <th>Max Load Size</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  <tr v-for="carrierTruck in paginatedTrucks" :key="carrierTruck.id">
                    <td>{{ carrierTruck.truck.type }}</td>
                    <td>{{ carrierTruck.truck.truckNumber }}</td>
                    <td>{{ carrierTruck.carrier.name }}</td>
                    <td>{{ carrierTruck.truck.registrationNumber }}</td>
                    <td>{{ carrierTruck.truck.trailerType }}</td>
                    <td>{{ carrierTruck.truck.trailer !== null ? carrierTruck.truck.trailer.trailerLength: 'N/A' }}</td>
                    <td>{{ carrierTruck.truck.maxLoadSize }}</td>
                    <td>
                      <router-link :to="{ name: 'trucks/view', params: { truckNumber: carrierTruck.truck.truckNumber }, query: { source: 'search-trucks'}}">
                        <button class="btn btn-primary col-4">View <span class="glyphicon glyphicon-info-sign"></span></button>
                      </router-link>&nbsp;&nbsp;
                      Select <input type="checkbox" name="selectedCarriersByTruckSearch" :value="carrierTruck.carrier.id" v-model="selectedCarriersByTruckSearch">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- <div v-if="paginatedTrucks.length === 0">
            <div v-if="type !== null && loadMode !== null && trailerType !== null">
              <div class="panel">
                <div class="panel-body">
                  <h4 class="text-danger">Oops, no trucks that meet search criteria found.</h4>
                </div>
              </div>
            </div>  
          </div> -->
          
          <div class="row" v-if="totalPages > 1">
            <div class="col-md-7"></div>
            <div class="col-md-3 pull-right">
              <div class="panel">
                <div class="panel-body">
                  <button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="currentPage === 1" @click="prevPage"></button>
                  &nbsp;<span>Page {{ currentPage }} of {{ totalPages }}</span>&nbsp;
                  <button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="currentPage === totalPages" @click="nextPage"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div v-else>
      <div class="panel">
        <div class="panel-body">
          <h4 class="text-danger">Oops, your current subscription plan does not allow you to search for trucks for your load.</h4>
          <p>Please consider upgrading your plan!</p>
          <br>
          <router-link :to="{ name: 'upgrade-subscription' }">
            <button class="btn btn-primary">Upgrade Subscription</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CarrierTruckService from "@/services/CarrierTruckService";
  import loading from "vue-full-loading";
  import Truck from "@/models/Truck";
  import SubscriptionService from "@/services/SubscriptionService";

  export default {
    name: "SearchTruck",
    components: {
      loading
    },

    data: function() {
      return {
        carrierTrucks: [],
        show: false,
        label: "Processing request",
        truck: new Truck,
        selectedCarriersByTruckSearch: [],
        type: null,
        loadMode: null,
        trailerType: null,
        maxLoadSize: null,
        trailerLength: null,
        filteredCarrierTrucks: [],
        currentPage: JSON.parse(localStorage.getItem('currentPage')) !== null ? JSON.parse(localStorage.getItem('currentPage')): 1,
        itemsPerPage: 10,
        subscription: null
      };
    },

    mounted(){
      this.findByActiveSubscription();

      this.type = JSON.parse(localStorage.getItem('type'));
      this.loadMode = JSON.parse(localStorage.getItem('loadMode'));
      this.trailerType = JSON.parse(localStorage.getItem('trailerType'));
      this.maxLoadSize = JSON.parse(localStorage.getItem('maxLoadSize'));
      this.trailerLength = JSON.parse(localStorage.getItem('trailerLength'));
      if (this.type !== null && this.loadMode !== null && this.trailerType !== null) {
        this.searchTrucks();
      }
    },

    methods: {

      prevPage(){
        if (this.currentPage > 1) {
          const currentPage = --this.currentPage;
          localStorage.setItem('currentPage', JSON.stringify(currentPage));
        }
      },

      nextPage(){
        if (this.currentPage < this.totalPages) {
          const currentPage = ++this.currentPage;
          localStorage.setItem('currentPage', JSON.stringify(currentPage));
        }
      },

      clearFilter(){
        localStorage.removeItem('type');
        localStorage.removeItem('loadMode');
        localStorage.removeItem('trailerType');
        localStorage.removeItem('maxLoadSize');
        localStorage.removeItem('trailerLength');
        localStorage.removeItem('searchFields');      
        window.location.reload();
      },

      searchTrucks: function() {
        this.show = true;
        this.truck.type = this.type;
        this.truck.loadMode = this.loadMode;
        this.truck.trailerType = this.trailerType;
        this.truck.maxLoadSize = this.maxLoadSize;
        this.truck.trailerLength = this.trailerLength;
        CarrierTruckService.searchTrucks(this.truck).then(response => {
          this.carrierTrucks = response.data.body;
          this.search();
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      search(){
        this.show = true;
        let searchFields = JSON.parse(localStorage.getItem('searchFields') || '[]');
        if (this.type !== null && this.type !== undefined) {
          let searchField = {
            'fieldName': 'type',
            'value': this.type
          };
          const index = searchFields.findIndex((field) => field.fieldName === 'type');
          if (index === -1) {
            searchFields.push(searchField);
          }else{
            searchFields[index] = searchField;
          }
          localStorage.setItem('searchFields', JSON.stringify(searchFields));
        }
        if (this.loadMode !== null && this.loadMode !== undefined) {
          let searchField = {
            'fieldName': 'loadMode',
            'value': this.loadMode
          };
          const index = searchFields.findIndex((field) => field.fieldName === 'loadMode');
          if (index === -1) {
            searchFields.push(searchField);
          }else{
            searchFields[index] = searchField;
          }
          localStorage.setItem('searchFields', JSON.stringify(searchFields));
        }
        if (this.trailerType !== null && this.trailerType !== undefined) {
          let searchField = {
            'fieldName': 'trailerType',
            'value': this.trailerType
          };
          const index = searchFields.findIndex((field) => field.fieldName === 'trailerType');
          if (index === -1) {
            searchFields.push(searchField);
          }else{
            searchFields[index] = searchField;
          }
          localStorage.setItem('searchFields', JSON.stringify(searchFields));
        }
        if (this.trailerLength !== null && this.trailerLength !== undefined) {
          let searchField = {
            'fieldName': 'trailerLength',
            'value': this.trailerLength
          };
          const index = searchFields.findIndex((field) => field.fieldName === 'trailerLength');
          if (index === -1) {
            searchFields.push(searchField);
          }else{
            searchFields[index] = searchField;
          }
          localStorage.setItem('searchFields', JSON.stringify(searchFields));
        }
        if (this.maxLoadSize !== null && this.maxLoadSize !== undefined) {
          let searchField = {
            'fieldName': 'maxLoadSize',
            'value': this.maxLoadSize
          };
          const index = searchFields.findIndex((field) => field.fieldName === 'maxLoadSize');
          if (index === -1) {
            searchFields.push(searchField);
          }else{
            searchFields[index] = searchField;
          }
          localStorage.setItem('searchFields', JSON.stringify(searchFields));
        }
        if (this.trailerLength !== null && this.trailerLength !== undefined) {
          let searchField = {
            'fieldName': 'trailerLength',
            'value': this.trailerLength
          };
          const index = searchFields.findIndex((field) => field.fieldName === 'trailerLength');
          if (index === -1) {
            searchFields.push(searchField);
          }else{
            searchFields[index] = searchField;
          }
          localStorage.setItem('searchFields', JSON.stringify(searchFields));
        }
        if (searchFields.length > 0) {
          let theCarrierTrucks = this.carrierTrucks;
          for(let i = 0; i < searchFields.length; i++){
            if (searchFields[i].fieldName === 'type'){
              theCarrierTrucks = theCarrierTrucks.filter(carrierTruck => {
                return carrierTruck.truck.type === searchFields[i].value;
              });
              localStorage.setItem('type', JSON.stringify(this.type));
            }
            if (searchFields[i].fieldName === 'loadMode'){
              theCarrierTrucks = theCarrierTrucks.filter(carrierTruck => {
                return carrierTruck.truck.loadMode === searchFields[i].value;
              });
              localStorage.setItem('loadMode', JSON.stringify(this.loadMode));
            }
            if (searchFields[i].fieldName === 'trailerType'){
              theCarrierTrucks = theCarrierTrucks.filter(carrierTruck => {
                return carrierTruck.truck.trailerType === searchFields[i].value;
                // return carrierTruck.truck.trailer.trailerType === searchFields[i].value; :: truck has attached trailer
              });
              localStorage.setItem('trailerType', JSON.stringify(this.trailerType));
            }
            if (searchFields[i].fieldName === 'maxLoadSize'){
              theCarrierTrucks = theCarrierTrucks.filter(carrierTruck => {
                return carrierTruck.truck.maxLoadSize === parseFloat(searchFields[i].value);
              });
              localStorage.setItem('maxLoadSize', JSON.stringify(this.maxLoadSize));
            }
            if (searchFields[i].fieldName === 'trailerLength'){
              theCarrierTrucks = theCarrierTrucks.filter(carrierTruck => {
                return carrierTruck.truck.trailerLength === parseFloat(searchFields[i].value);
              });
              localStorage.setItem('trailerLength', JSON.stringify(this.trailerLength));
            }
            this.filteredCarrierTrucks = theCarrierTrucks;
          }
        }else{
          this.filteredCarrierTrucks = this.carrierTrucks;
        }
        this.show = false;
      },

      createLoadFromSelectedTruckers(){
        console.log(this.selectedCarriersByTruckSearch);
        this.$store.commit('setSelectedCarriers', this.selectedCarriersByTruckSearch); // we lose state on refresh
        localStorage.setItem('selectedCarriersByTruckSearch', JSON.stringify(this.selectedCarriersByTruckSearch));
      },

      findByActiveSubscription(){
        this.show = true;
        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
          if (response.data.statusCode === 200) {
            this.subscription = response.data.body;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    },

    computed: {
      getUser(){
        return this.$store.state.auth.user.user;
      },

      totalPages(){
        return Math.ceil(this.filteredCarrierTrucks.length / this.itemsPerPage);
      },

      paginatedTrucks(){
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        return this.filteredCarrierTrucks.slice(startIndex, startIndex + this.itemsPerPage);
      },
    }
  };
</script>