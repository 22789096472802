<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<router-link :to="{ name: 'my-bookings' }">
	   				<button class="btn btn-primary">Back</button>
	   			</router-link>
	   			<br><br>

				<div class="row">
					<div class="col-md-3"></div>
					<div class="col-md-6">
						<h4>Cancel this load {{ getLoadNumber }} booking</h4>
						<p>After cancelling this booking, you will not be able to pay carrier the offer they accepted</p>
						<hr>
						<form class="form" @submit.prevent="cancel">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-danger" type="submit">Yes cancel booking</button>
							</div>
						</form>
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import BookingService from "@/services/BookingService";

	export default{
		name: 'CancelBooking',
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request...',
			}
		},

		created(){
			this.findByLoadNumber();
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},

			getBookingNumber: function(){
				return this.$route.params.bookingNumber;
			}
		},

		methods: {
			findByLoadNumber: function(){
				this.show = true;
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			cancel: function(){
				this.show = true;
				BookingService.cancel(this.getBookingNumber, this.getLoadNumber).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'my-bookings' });
					}
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},
		}
	};
</script>