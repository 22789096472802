<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="row">
      <div class="col-md-4" v-if="company !== null">
        <h4>Welcome, {{ company !== null ? company.name: '' }}</h4>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-2">
        <!-- <select class="form-control">
          <option value="">Last 15 days</option>
          <option value="">Last 30 days</option>
          <option value="">Last 45 days</option>
        </select> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <h4><router-link :to="{ name: 'my-bookings' }">{{ newBookings }}</router-link></h4>
                <p><router-link :to="{ name: 'my-bookings' }">New Bookings</router-link></p>
              </div>
              <div class="col-md-6">
                <h4><router-link :to="{ name: 'my-bookings' }">{{ newBids }}</router-link></h4>
                <p><router-link :to="{ name: 'my-bids' }">New Bids</router-link></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="panel">
          <div class="panel-body">
            <h4>{{ openLoads }}</h4>
            <p>Open Loads <span v-if="openLoads.length > 0"><router-link :to="{ name: 'search-trucks' }">Find Trucks</router-link></span></p>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="panel">
          <div class="panel-body">
            <h4>{{ coveredLoads }} Covered Loads</h4>
            <h6>Covered Loads are loads booked by carrier and loads with accepted bid offer</h6>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="panel">
          <div class="panel-body">
            <h4>{{ loads.length }}</h4>
            <p>Loads posted</p>
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <h4>My Loads Performance</h4>
        <hr>
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6">
                <h4>0</h4>
                <p>Load Impressions <a href="#"><span class="glyphicon glyphicon-info-sign" title="A load impression is counted when a carrier sees the load preview card in the load search result or load recommendation list."></span></a></p>
              </div>
              <div class="col-md-6">
                <h4>0.00%</h4>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6">
                <h4>0</h4>
                <p>Load Detail Views</p>
              </div>
              <div class="col-md-6">
                <h4>0.00%</h4>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-6">
                <h4>0</h4>
                <p>Key Load Actions <a href="#"><span class="glyphicon glyphicon-info-sign" title="Key load actions include the sum number of carrier users' bids, books, messages, phone checks, and email checks to your loads."></span></a></p>
              </div>
              <div class="col-md-6">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-3">
            <h4>Verify your identity</h4>
            <p>Verify your identity before posting your loads.</p>
            <hr>
            <img src="@/assets/images/verifyIdentity.png">
            <div v-if="company !== null">
              <span v-if="company.verified">
                <h4><b>PROFILE VERIFIED</b> <img src="@/assets/images/verified.png" width="50" height="50"></h4>
              </span>
            </div>
          </div>

          <div class="col-md-3">
            <h4>Post Your First Load</h4>
            <p>Post your load to 1000+ qualified carriers in the Nelspruit Freight app.</p>
            <hr>
            <img src="@/assets/images/postLoad.png">
            <div class="row">
              <div class="col-md-6">
                <router-link :to="{ name: 'new-load-basic-details' }">
                  <button class="btn btn-success">POST LOAD</button>
                </router-link>
              </div>
              <div class="col-md-6">
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <h4>Post Your Private Load</h4>
            <p>Tender your private load to 1000+ qualified carriers in the Nelspruit Freight app.</p>
            <hr>
            <img src="@/assets/images/postLoad.png">
            <div class="row">
              <div class="col-md-6" v-if="subscription !== null">
                <div v-if="subscription.subscriptionPlan.subscriptionType === 'ADVANCED' || subscription.subscriptionPlan.subscriptionType === 'PREMIUM'">
                  <router-link :to="{ name: 'new-load-basic-details', query: { privateLoadEnabled: 'privateLoadEnabled' }}">
                    <button class="btn btn-warning" @click="privateLoadEnabled">POST PRIVATE LOAD</button>
                  </router-link>
                </div>
                <div v-else>
                  <p class="text-danger">Oops, your current subscription plan does not allow you to post private loads.</p>
                  <p>Please consider upgrading your plan!</p>
                  <br>
                  <router-link :to="{ name: 'upgrade-subscription' }">
                    <button class="btn btn-primary">Upgrade Subscription</button>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6">
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <h4>Search for Truck</h4>
            <p>Conduct your truck search that match your load requirements.</p>
            <hr>
            <img src="@/assets/images/searchTruck.png">
            <div class="row">
              <div class="col-md-6">
                <router-link :to="{ name: 'search-trucks' }">
                  <button class="btn btn-success">START SEARCH</button>
                </router-link>
              </div>
              <div class="col-md-6">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <h4>Stats</h4>
        <div class="row">
          <div class="col-md-6">
            <img src="@/assets/images/expenses.png" height="300" width="600" class="img img-responsive">
          </div>
          <div class="col-md-6">
            <img src="@/assets/images/gross-profit.png" height="300" width="600" class="img img-responsive">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService.js";
  import LoadService from "@/services/LoadService.js";
  import SubscriptionService from "@/services/SubscriptionService.js";

  export default {
    name: "BrokerDashboard",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        company: null,
        loads: [],
        subscription: null
      };
    },

    mounted: function() {
      this.findByAssociatedUserUsername();
      this.findByOwnerUsername();
      this.findByActiveSubscription();
    },

    computed: {

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },

      newBookings(){
        let bookings = [];
        for(var i = 0; i < this.loads.length; i++){
          for(var j = 0; j < this.loads[i].bookings.length; j++){
            bookings.push(this.loads[i].bookings[j]);
          }
        }
        if (bookings.length > 0) {
          bookings = bookings.filter(booking => {
            return booking.status === 'PENDING';
          });
        }
        return bookings.length;
      },

      newBids(){
        let bids = [];
        for(var i = 0; i < this.loads.length; i++){
          for(var j = 0; j < this.loads[i].bids.length; j++){
            bids.push(this.loads[i].bids[j]);
          }
        }
        if (bids.length > 0) {
          bids = bids.filter(bid => {
            return bid.status === 'PENDING';
          });
        }
        return bids.length;
      },

      openLoads(){
        return this.loads.filter(load => { return load.status === 'ACTIVE' }).length;
      },

      coveredLoads(){
        var booked = this.loads.filter(load => { return load.status === 'BOOKED' }).length;
        let bids = [];
        for(var i = 0; i < this.loads.length; i++){
          for(var j = 0; j < this.loads[i].bids.length; j++){
            bids.push(this.loads[i].bids[j]);
          }
        }
        if (bids.length > 0) {
          bids = bids.filter(bid => {
            return bid.status === 'PENDING';
          });
        }
        var acceptedBidOffer = bids.length;
        return booked + acceptedBidOffer;
      }
    },

    methods: {
      findByAssociatedUserUsername: function() {
        this.show = true;
        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findByOwnerUsername: function(){
        LoadService.findByOwnerUsername(this.$store.state.auth.user.user.username).then(response => {
          this.loads = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      privateLoadEnabled(){
        localStorage.setItem("privateLoadEnabled", JSON.stringify(true));
      },

      findByActiveSubscription(){
        this.show = true;
        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
          if (response.data.statusCode === 200) {
            this.subscription = response.data.body;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>
