<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>
    
		<div class="panel">
			<div class="panel-body">
				<fieldset>
					<legend>Delivery contact details</legend>
					<form class="form" @submit.prevent="save">
						<input type="checkbox" v-model="load.copyFromMyAccount"> <span class="text-danger">Copy from my account</span>
						<br><br>
						<div v-show="!load.copyFromMyAccount">
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label>First name</label>
										<input type="text" class="form-control" name="firstName" id="firstName" v-model="contactInformationForCarrier.firstName" placeholder="Enter first name"/>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label>Last name</label>
										<input type="text" class="form-control" name="lastName" id="lastName" v-model="contactInformationForCarrier.lastName" placeholder="Enter last name"/>
									</div>
								</div>
							</div>

							<button class="btn btn-success btn-sm pull-right" @click="addPhoneNumberField" type="button">Add more <span class="glyphicon glyphicon-plus"></span></button><br>

							<div v-show="contactInformationForCarrier.phoneNumbers.length > 0">
								<div class="row" v-for="(phoneNumberField, phoneNumberIndex) in contactInformationForCarrier.phoneNumbers" :key="phoneNumberIndex">
									<div class="col-md-4">
										<div class="form-group">
											<label for="countryId">Country code</label>
				                  			<select class="form-control" v-model="phoneNumberField.countryId">
				                  				<option value="" disabled>Select option</option>
				                  				<option v-for="country in countries" :key="country.id" :value="country.id">+{{ country.code }} - {{ country.name }}</option>
					                        </select>
				                  		</div>
				                  	</div>
				                  	<div class="col-md-4">
				                  		<div class="form-group">
				                  			<label for="number">Phone number</label>
				                  			<input type="number" v-model="phoneNumberField.number" name="number" class="form-control" placeholder="Enter phone number" minlength="6" maxlength="10" oninput="javascript: if (this.value.length > this.maxLength) { return this.value = this.value.slice(0, this.maxLength); }"/>
				                  		</div>
				                  	</div>
				                  	<div class="col-md-2">
				                  		<div class="form-group" v-show="contactInformationForCarrier.phoneNumbers.length > 1">
				                  			<label>&nbsp;</label><br>
				                  			<button class="btn btn-danger btn-sm pull-left" @click="contactInformationForCarrier.phoneNumbers.splice(phoneNumberIndex, 1)" type="button">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
				                  		</div>
				                  	</div>
					              </div>
					        </div>
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label>Email</label>
										<input type="text" class="form-control" name="email" id="email" placeholder="Enter email" v-model="contactInformationForCarrier.email"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import Load from "@/models/Load";
	import CountryService from "@/services/CountryService.js";

	export default{
		name: 'NewContactInformationForCarrier',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				provinces: [],
				pickupCities: [],
				deliveryCities: [],
				provinceId: null,
				load: new Load,
			    contactInformationForCarrier: {
			    	firstName: null,
			    	lastName: null,
			    	email: null,
			    	phoneNumbers: [{
				    	id: null,
				    	countryId: null,
				    	number: null
				    }],
			    },
			    countries: []
			}
		},

		mounted(){
			this.findAllCountries();
		},

		computed: {
			getRoles: function(){
				let roles = [];
				for(var i = 0; i < this.$store.state.auth.user.user.authorities.length; i++){
					roles.push(this.$store.state.auth.user.user.authorities[i].authority);
				}
				return roles;
			},

			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			}
		},

		methods: {
			addPhoneNumberField: function(){
				this.contactInformationForCarrier.phoneNumbers.push({
					id: null,
					countryId: null,
		        	number: null
		        });
			},

			save: function(){
				this.show = true;
				this.load.loadNumber = this.getLoadNumber;
				this.load.contactInformationForCarrier = this.contactInformationForCarrier;
				LoadService.save(this.load).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'my-loads' });
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			findAllCountries: function() {
		        this.show = true;
		        return CountryService.findAll().then(response => {
		          this.countries = response.data.body;
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    },
		}
	};
</script>