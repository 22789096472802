<template>
	<div>
		<loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img
                  src="@/assets/images/station-loading.gif"
                  height="100"
                  width="100"
                />
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

		<form class="form" @submit.prevent="savePhoto">
			<div class="form-group">
		      <ul class="list-group">
		        <li class="list-group-item">
		          <div class="form-group">
		            <label>User Photo</label>
		            <input
		              class="form-control"
		              type="file"
		              v-on:change="selectPhoto"
		            />
		          </div>
		        </li>
		      </ul>
		    </div>

		    <div class="form-group">
		    	<label>&nbsp;</label>
		    	<button class="btn btn-success" type="submit">Submit</button>
		    </div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import UserService from "@/services/UserService";
	import loading from "vue-full-loading";
	export default{
		components: {
			loading
		},
		
		name: "UserPhoto",
		data: function(){
			return {
				formData: new FormData,
				label: "Processing request...",
				show: false,
			}
		},

		computed: {
			getUserId: function(){
				return this.$route.params.userId;
			}
		},

		created: function(){
			
		},

		methods: {
			selectPhoto: function(event) {
				this.formData.append("photo", event.target.files[0]);
			},

			savePhoto: function(){
				this.show = true;
				this.formData.append("userId", this.getUserId);
				UserService.savePhoto(this.formData).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "users", params: { userId: response.data.body.id } });
					}
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>