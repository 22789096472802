<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/LucecoPLC-Loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-5">
            <br><br><br><br><br>
            <router-link :to="{ name: '/' }">
              <img src="@/assets/images/backgroundLogoLogin.png" width="400px" height="150px" class="img img-responsive img-rounded"/>
            </router-link>
            <br><br><br><br>
          </div>
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-7">
                <h3 v-if="getSource ==='shipper'">
                  <b>Sign in to start shipping</b>
                </h3>
                <h3 v-if="getSource === 'carrier'">
                  <b>Sign in to start hauling</b>
                </h3>
                <h3 v-if="getSource === 'broker'">
                  <b>Sign in to start shipping your goods</b>
                </h3>
                <h3 v-if="getSource !== 'shipper' && getSource !== 'carrier' && getSource !== 'broker'">
                  <b>Welcome back. Sign in to Nelspruit Freight</b>
                </h3>
                <p class="error-message">{{ message }}</p>
                <form class="form" @submit.prevent="login">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-addon"><span class="glyphicon glyphicon-user"></span></div>
                      <input type="text" v-model="user.username" placeholder="Enter username" class="form-control" autocomplete="on" required/>
                    </div>
                  </div>
                  <br>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-addon"><span class="glyphicon glyphicon-lock"></span></div>
                      <input type="password" v-model="user.password" placeholder="Enter password" class="form-control" autocomplete="on" required/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="btn btn-success btn-block">Submit</button>
                  </div>
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <a href="#"><img src="@/assets/images/reset-password.png" height="30" width="30"> <router-link to="forgotPassword">Reset password</router-link></a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import User from "@/models/User.js";

  export default {
    components: {
      loading
    },

    data() {
      return {
        user: new User(),
        show: false,
        label: "Signing in. Please wait...",
        errorz: [],
        message: null,
        path: "/dashboard",
        errorMessage: null
      };
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },

      getSource(){
        return this.$route.query.source;
      }
    },

    mounted() {
      if (this.loggedIn) {
        if (this.$route.path != this.path) {
          this.$router.push({ name: 'dashboard' });
        }
      }
    },

    methods: {
      login() {
        this.show = true;
        this.$store.dispatch("auth/login", this.user).then(() => {
          this.show = false;
          this.$router.replace(this.$route.query.redirect || 'check-subscription');
        }).catch(error => {
          if (error.response.status === 401) {
            this.message = error.response.data.error_description;
          }

          if (error.response.status === 400) {
            this.message = error.response.data.error_description;
          }
          if (error.response.status === 500) {
            this.message = "Login failed. Invalid username or password.";
          }
          this.show = false;
        });
      }
    }
  };
</script>
