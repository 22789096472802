<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>
    
		<div class="panel">
			<div class="panel-body">
				<fieldset>
					<legend>Load Pricing and Offers</legend>
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Price</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="load.price" placeholder="Enter load price" required/>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Currency</label>
									<select class="form-control" v-model="load.currency" required>
										<option value="" disabled>Select option</option>
										<option value="USD">USD</option>
										<option value="ZWG">ZWG</option>
									</select>
								</div>
							</div>
						</div>
						<br>
						<div class="row" v-if="selectedCarriersByTruckSearch === null && !privateLoadEnabled">
							<div class="col-md-4" v-if="!load.privateLoadEnabled">
								<input type="checkbox" name="" v-model="load.bookNowEnabled"> Enable book now <br><br>
								<p class="text-danger"><i>Allow carriers to book your load instantly</i></p>
								<br><br>
							</div>
							<div class="col-md-4" v-if="!load.privateLoadEnabled">
								<input type="checkbox" name="" v-model="load.collectLoadOffersEnabled"> Enable collect load offers/bids
								<br><br>
								<p class="text-danger"><i>Allow carriers to bid your load or negotiate offer</i></p>
								<br><br>
							</div>
							<div class="col-md-4">
								<div v-if="subscription !== null">
									<div v-if="subscription.subscriptionPlan.subscriptionType === 'ADVANCED' || subscription.subscriptionPlan.subscriptionType === 'PREMIUM'">
										<input type="checkbox" name="" v-model="load.privateLoadEnabled"> Set to private load <br><br>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group" v-if="premiumCarriers.length > 0 && (load.privateLoadEnabled || privateLoadEnabled)">
							<p class="text-danger"><i>This load will be tendered to the selected carriers</i></p>
							<label>Offer this load to whitelisted carriers</label><br>
							<hr>
							<span v-for="premiumCarrier in premiumCarriers" :key="premiumCarrier.id" >
								<input type="checkbox" name="selectedCarriers" v-model="selectedCarriers" :value="premiumCarrier.id" required> {{ premiumCarrier.name }}<br>
							</span>
						</div>

						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import CompanyService from "@/services/CompanyService";
	import Load from "@/models/Load";
	import SubscriptionService from "@/services/SubscriptionService";

	export default{
		name: 'NewLoadPriceAndOffers',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				premiumCarriers: [],
				pickupCities: [],
				deliveryCities: [],
				provinceId: null,
				load: new Load,
			    zipCodes: [],
			    brokerId: null,
			    selectedCarriers: [],
			    selectedCarriersByTruckSearch: JSON.parse(localStorage.getItem('selectedCarriersByTruckSearch')),
			    subscription: null,
			    privateLoadEnabled: JSON.parse(localStorage.getItem("privateLoadEnabled"))
			}
		},

		mounted(){
			this.findByActiveSubscription();
			this.findPremiumCarriers();
		},

		computed: {
			getUser(){
				return this.$store.state.auth.user.user;
			},

			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			}
		},

		methods: {
			findByActiveSubscription(){
		        this.show = true;
		        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
		          if (response.data.statusCode === 200) {
		            this.subscription = response.data.body;
		          }
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    },

			findPremiumCarriers: function(){
				this.show = true;
				CompanyService.findPremiumCarriers().then(response => {
					this.premiumCarriers = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			save: function(){
				this.show = true;
				let carrierPostDto = [];
				if (this.selectedCarriers.length > 0) {
					for(var i = 0; i < this.selectedCarriers.length; i++){
						carrierPostDto.push({
							id: this.selectedCarriers[i]
						});
					}
				}
				if (this.selectedCarriersByTruckSearch !== null) {
					for(var j = 0; j < this.selectedCarriersByTruckSearch.length; j++){
						carrierPostDto.push({
							id: this.selectedCarriersByTruckSearch[j]
						});
					}
				}
				this.load.carriers = carrierPostDto;
				this.load.loadNumber = this.getLoadNumber;
				LoadService.save(this.load).then(response => {
					if (response.data.statusCode === 201) {
						localStorage.removeItem('selectedCarriersByTruckSearch');
						localStorage.removeItem('privateLoadEnabled');
						this.$router.push({ name: 'new-load-contact-information-for-carrier', params: { loadNumber: this.getLoadNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},
		},
	};
</script>