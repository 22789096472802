import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class LoadBookingService {
  findByLoadOwnerUsername(username) {
    return axios.get(GENERIC_SERVICE_URL + "loadBookings/findByLoadOwnerUsername/" + username);
  }

  findByBookingCarrierAssociatedUserUsername(username) {
    return axios.get(GENERIC_SERVICE_URL + "loadBookings/findByBookingCarrierAssociatedUserUsername/" + username);
  }

  checkCarrierAlreadyBookedLoad(loadNumber, carrierId){ 
    return axios.get(GENERIC_SERVICE_URL + "loadBookings/checkCarrierAlreadyBookedLoad/" + loadNumber + "/" + carrierId); 
  }
}

export default new LoadBookingService;
