import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";
import axios from "axios";

class CurrencyService {
  getCurrencies() {
    return axios.get(GENERIC_SERVICE_URL + "currencies");
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "currencies");
  }
}

export default new CurrencyService();
