<template>
  <div>
    <router-link :to="{ name: 'configurations' }" replace><button class="btn btn-primary">
      <span class="glyphicon glyphicon-step-backward"></span> Back</button>
    </router-link>

    <router-link :to="{ name: 'provinces/new' }">
      <button class="btn btn-success pull-right">
        New <span class="glyphicon glyphicon-plus"></span>
      </button> </router-link
    ><br /><br />

    <h4>Province List</h4>

    <table class="table table-bordered table-responsive-md">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Name</th>
        <th>Status</th>
        <td>Actions</td>
      </thead>
      <tbody>
        <tr v-for="province in provinces" :key="province.id">
          <td>{{ province.id }}</td>
          <td>{{ province.name }}</td>
          <td>
            <span v-if="province.status === 'ACTIVE'">
              <span class="badge badge-success">Active</span>
            </span>
            <span v-if="province.status === 'INACTIVE'">
              <span class="badge badge-secondary">Inactive</span>
            </span>
          </td>
          <td>
            <router-link
              :to="{
                name: 'provinces/view',
                params: { provinceId: province.id }
              }"
            >
              <button class="btn btn-primary">
                View <span class="glyphicon glyphicon-info-sign"></span>
              </button> </router-link
            >&nbsp;
            <router-link
              :to="{
                name: 'provinces/edit',
                params: { provinceId: province.id }
              }"
            >
              <button class="btn btn-warning">
                Edit <span class="glyphicon glyphicon-edit"></span>
              </button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
import ProvinceService from "@/services/ProvinceService.js";

export default {
  name: "ProvinceList",
  data: function() {
    return {
      provinces: []
    };
  },

  created: function() {
    this.findAll();
  },

  methods: {
    findAll: function() {
      ProvinceService.findAll()
        .then(response => {
          this.provinces = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
