<template>
  <div>
    <ul class="nav" v-for="menu in getUserMenu" :key="menu.name">
      <li>
        <router-link :to="{ name: menu.link }" replace>
          <div class="row">
            <div class="col-md-3">
              <img :src="'/menuIcons/' + menu.icon + imageExtension" v-bind:alt="menu.name" height="40" width="40" class="img img-rounded img-responsive"/>
            </div>
            <div class="col-md-9">
              {{ dashboardLinkNameHidden ? "" : menu.name }}
            </div>
          </div>
          <div v-show="sideBarMenuToggle">
            <img :src="'/menuIcons/' + menu.icon + imageExtension" v-bind:alt="menu.name" height="30" width="30" class="img img-rounded img-responsive"/>
            {{ dashboardLinkNameHidden ? "" : menu.name }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script type="text/javascript">
  export default {
    props: {
      roles: {
        type: Array,
        default: function() {
          return [];
        }
      },

      dashboardLinkNameHidden: {
        // hide link name when we toggle sidebar
        type: Boolean,
        default: false
      },
      sideBarMenuToggle: {
        type: Boolean,
        default: false
      }
    },

    data: function() {
      return {
        menuArray: [],
        imageExtension: ".png"
      };
    },

    created() {},

    methods: {},

    computed: {
      getUserMenu: function() {
        // need to extent default menu and also load from a JSON file
        let superAdminMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Settings",
            link: "settings",
            icon: "configuration"
          },

          {
            name: "Users",
            link: "users",
            icon: "users"
          },

          {
            name: "Companies",
            link: "companies",
            icon: "company"
          },

          {
            name: "Invoices",
            link: "invoice-list",
            icon: "payment"
          },

          {
            name: "Payments",
            link: "payment-list",
            icon: "payment"
          },

          // InvoicePayments.vue

          {
            name: "Subscriptions",
            link: "subscriptions",
            icon: "subscription"
          }
        ];

        let brokerMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },
          
          {
            name: "My Loads",
            link: "my-load-list",
            icon: "cubes"
          },

          {
            name: "Post Loads",
            link: "new-load-basic-details",
            icon: "cubes"
          },

          {
            name: "Find Trucks",
            link: "search-trucks",
            icon: "box-truck"
          },

          {
            name: "Shipments",
            link: "shipments",
            icon: "tracking"
          },

          // {
          //   name: "Load Activities",
          //   link: "shipper/loads/leads",
          //   icon: "loading"
          // },

          {
            name: "Payments",
            link: "my-payments",
            icon: "coin"
          },

          {
            name: "Help Center",
            link: "help-center",
            icon: "customer-service"
          }

          // {
          //   name: "TMS Integration",
          //   link: "broker/loads/tms-integration",
          //   icon: "payments"
          // },

          // {
          //   name: "API Integration",
          //   link: "broker/loads/api-integration",
          //   icon: "claim"
          // },

          // {
          //   name: "My Carriers",
          //   link: "broker/truck-capacity/my-carriers",
          //   icon: "reinsurance"
          // },

          // {
          //   name: "Rate Check",
          //   link: "broker/truck-capacity/my-carriers",
          //   icon: "reinsurance"
          // },

          // {
          //   name: "Truck-to-Loads Ratio",
          //   link: "broker/truck-capacity/my-carriers",
          //   icon: "reinsurance"
          // },

          // {
          //   name: "Load Density Ratio",
          //   link: "broker/broker/tools/capacity-ratio",
          //   icon: "reinsurance"
          // },
        ];

        let shipperMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "My Loads",
            link: "my-loads",
            icon: "cubes"
          },

          {
            name: "Post Loads",
            link: "new-load-basic-details",
            icon: "cubes"
          },

          {
            name: "Find Trucks",
            link: "search-trucks",
            icon: "box-truck"
          },

          {
            name: "Shipments",
            link: "shipments",
            icon: "tracking"
          },

          // {
          //   name: "Load Activities",
          //   link: "shipper/loads/leads",
          //   icon: "loading"
          // },

          {
            name: "Payments",
            link: "my-payments",
            icon: "coin"
          },

          {
            name: "Help Center",
            link: "help-center",
            icon: "customer-service"
          }

          // {
          //   name: "TMS Integration",
          //   link: "broker/loads/tms-integration",
          //   icon: "payments"
          // },

          // {
          //   name: "API Integration",
          //   link: "broker/loads/api-integration",
          //   icon: "claim"
          // },

          // {
          //   name: "My Carriers",
          //   link: "broker/truck-capacity/my-carriers",
          //   icon: "reinsurance"
          // },

          // {
          //   name: "Rate Check",
          //   link: "broker/truck-capacity/my-carriers",
          //   icon: "reinsurance"
          // },

          // {
          //   name: "Truck-to-Loads Ratio",
          //   link: "broker/truck-capacity/my-carriers",
          //   icon: "reinsurance"
          // },

          // {
          //   name: "Load Density Ratio",
          //   link: "broker/broker/tools/capacity-ratio",
          //   icon: "reinsurance"
          // },
        ];

        let carrierMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Search Loads",
            link: "search-loads",
            icon: "search",
          },

          {
            name: "Private Loads",
            link: "private-loads",
            icon: "search"
          },

          {
            name: "My Loads",
            link: "carrier-my-loads",
            icon: "cubes"
          },

          {
            name: "Payments",
            //link: "carrier-my-payments",
            link: 'carrier/my-payments',
            icon: "coin"
          },

          // {
          //   name: "Post Truck",
          //   link: "my-trucks",
          //   icon: "box-truck"
          // },

          {
            name: "Load Alert",
            link: "carrier/loads/load-alerts",
            icon: "notification-bell"
          },

          {
            name: "Load Density",
            link: "load-density",
            icon: "cubes"
          },

          {
            name: "Help Center",
            link: "help-center",
            icon: "customer-service"
          }
        ];

        let defaultMenu = [];

        if (this.roles.includes("SUPER_ADMIN") || this.roles.includes("ADMIN")) {
          return superAdminMenu;
        }
        if (this.roles.includes("SHIPPER")) {
          return shipperMenu;
        }
        if (this.roles.includes("BROKER")) {
          return brokerMenu;
        }
        if (this.roles.includes("CARRIER")) {
          return carrierMenu;
        }
        else {
          return defaultMenu;
        }
      }
    }
  };
</script>
