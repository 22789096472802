<template>
	<div>
		<loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

		<h4>Bid Offer</h4>
		<hr>

		<p>Ahoy, Congrats. You have been awarded the load contract.</p>
		<br>
		<p>After winning the bid you'll have 15 minutes to accept the offer.</p>
		<br>
		<p>Failing to accept the offer will render it awarded to someone else after 15 min.</p>
		<br>

		<div class="row">
			<div class="col-md-8"></div>
			<div class="col-md-2">
				<button class="btn btn-primary btn-block" @click="declineOffer">Decline the offer</button>
			</div>
			<div class="col-md-2">
        <button class="btn btn-success btn-block" @click="acceptOffer">Accept the offer</button>
			</div>
		</div>

	</div>
</template>

<script type="text/javascript">
  import BidService from "@/services/BidService.js";
  import Bid from "@/models/Bid.js";
  import loading from "vue-full-loading";

  export default {
    components: {
      loading
    },

    name: "BidOffer",
    data: function() {
      return {
        show: false,
        label: "Processing request...",
        errorMessage: null,
        errors: null,
        zipCodes: [],
        countries: [],
        provinces: [],
        cities: [],
        areas: [],
        bid: new Bid,
      };
    },

    computed: {
      getBidNumber() {
        return this.$route.params.bidNumber;
      }
    },

    mounted() {
    	this.findByBidNumber();
    },

    methods: {
    	findByBidNumber: function() {
    		BidService.findAll(this.getBidNumber).then(response => {
    			this.bid = response.data.body;
    		}).catch(error => {
    			console.log(error);
    		});
    	},

      acceptOffer: function(){
        this.show = true;
        let bidPostDto = {
          bidNumber: this.getBidNumber,
          status: "ACCEPTED"
        };
        console.log(bidPostDto);
        BidService.acceptOrDeclineOffer(bidPostDto).then(response => {
          if (response.data.statusCode === 201){
            this.$router.push({ name: 'loads' });
          }
        this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      declineOffer: function(){
        this.show = true;
        let bidPostDto = {
          bidNumber: this.getBidNumber,
          status: "DECLINED"
        };
        console.log(bidPostDto);
        BidService.acceptOrDeclineOffer(bidPostDto).then(response => {
          if (response.data.statusCode === 201){
            this.$router.push({ name: 'loads' });
          }
        this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>