<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>        
    </div>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <h3 class="text-center">Reset your password.</h3>
        <router-link to="login">
          <h4 class="text-center" v-on:click="changeAccessType('login')"><a href="#">Back to Sign In</a></h4>
        </router-link>
        <div class="panel">
          <div class="panel-header"><div class="header-title"></div></div>
          <div class="panel-body">
            <p class="text-danger" v-if="message !== null">{{ message }}</p>
            <form class="form" @submit.prevent="sendResetToken">
              <div class="form-group">
                <label>Your username</label>
                <input type="text" v-model="user.username" placeholder="Enter username associated with your account" class="form-control" required/>
              </div>
              <div class="row">
                <div class="col-md-9"></div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="btn btn-primary btn-block" type="submit">Send me instructions</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import PasswordResetService from "@/services/PasswordResetService";
  import User from "@/models/User";

  export default{
    components: {
      loading
    },

    name: 'ForgotPassword',
    data: function(){
      return {
        user: new User,
        show: false,
        label: "Processing request",
        message: null
      }
    },

    methods: {
      sendResetToken: function(){
        this.show = true;
        PasswordResetService.sendResetToken(this.user.username).then(response => {
          if (response.data.statusCode === 201) {
            if (response.data.body) {
              this.message = "We'll send you a link to sign in and reset your password if an account with this username exists in our records";
              this.user = new User
            }
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  }
</script>
