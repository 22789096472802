<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <h4>My Bookings</h4>
	    <div class="panel">
	    	<div class="panel-body">
	    		<table class="table table-responsive table-bordered table-hover">
			    	<thead class="bg-primary" style="background: #000000; color: #ffffff;">
				        <th>Load number</th>
				        <th>Booking number</th>
				        <th>Load price</th>
				        <th>Pickup</th>
				        <th>Drop off</th>
				        <th>Assigned truck</th>
				        <th>Load status</th>
				        <th>Booking</th>
				        <th>Actions</th>
				      </thead>
				      <tbody>
				        <tr v-for="loadBooking in loadBookings" :key="loadBooking.id">
				        	<td>
				        		<router-link :to="{ name: 'view-load', params: { loadNumber: loadBooking.load.loadNumber }, query: { source: 'CARRIER_BOOKINGS' }}">
				        			{{ loadBooking.load.loadNumber }}
				        		</router-link>
				        	</td>
				        	<td>{{ loadBooking.booking.bookingNumber }}</td>
				        	<td>{{ loadBooking.load.currency }}{{ loadBooking.load.price }}</td>
				          	<td>
				          		{{ loadBooking.load.pickupAddress !== null ? loadBooking.load.pickupAddress.city.name + ' ' + loadBooking.load.pickupAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		{{ loadBooking.load.dropOffAddress !== null ? loadBooking.load.dropOffAddress.city.name + ' ' + loadBooking.load.dropOffAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		<span v-if="loadBooking.booking.assignedTruck !== null">
				          			<router-link :to="{ name: 'trucks/view', params: { truckNumber: loadBooking.booking.assignedTruck.truckNumber }, query: { source: 'carrier/bookings' }}">
				          				{{ loadBooking.booking.assignedTruck !== null ? loadBooking.booking.assignedTruck.registrationNumber: '' }}
				          			</router-link>
				          		</span>
				          	</td>
				          	<td>{{ loadBooking.load.status }}</td>
				          	<td>
				          		<span class="label label-primary" v-if="loadBooking.booking.status === 'PENDING'">Not yet awarded</span>
				          		<span class="label label-success" v-if="loadBooking.booking.status === 'AWARDED'">Awarded</span>
				          		<span class="label label-warning" v-if="loadBooking.booking.status === 'ACCEPTED'">Accepted</span>
				          		<span class="label label-default" v-if="loadBooking.booking.status === 'DECLINED'">Declined</span>
				          		<span class="label label-danger" v-if="loadBooking.booking.status === 'CANCELLED'">Cancelled</span>
				          		<span class="label label-success" v-if="loadBooking.booking.status === 'BOOKED'">Booked</span>
				          	</td>
				          	<td>
				          		<span v-if="loadBooking.booking.status === 'AWARDED' && getRoles.includes('CARRIER')">
				          			<router-link :to="{ name: 'carrier/bookings/accept-or-decline-offer', params: { loadNumber: loadBooking.load.loadNumber, bookingNumber: loadBooking.booking.bookingNumber }}">
				          				<button class="btn btn-primary btn-xs"><span class="glyphicon glyphicon-thumbs-up"></span> Accept or decline offer <span class="glyphicon glyphicon-thumbs-down"></span></button>
				          			</router-link>
				          		</span>
				          	</td>
				          </tr>
				      </tbody>
				  </table>
	    	</div>
	    </div>    
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadBookingService from "@/services/LoadBookingService.js";

	export default{
		name: 'CarrierBookings',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    loadBookings: []
			}
		},

		mounted(){
			this.findByBookingCarrierAssociatedUserUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    }
		},

		methods: {
		    findByBookingCarrierAssociatedUserUsername: function() {
				this.show = true;
		        LoadBookingService.findByBookingCarrierAssociatedUserUsername(this.getUser.username).then(response => {
		        	this.loadBookings = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    }
		}
	};
</script>