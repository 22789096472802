<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Payment Details</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: this.getFilter }">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>
				<br>

				<div v-if="invoice !== null">
					<ul class="list-group" v-if="invoice.payment !== null">
						<li class="list-group-item">Reference Number: {{ invoice.payment.referenceNumber }}</li>
						<li class="list-group-item">Amount: {{ invoice.payment.currency }}{{ invoice.payment.amount }}</li>
						<li class="list-group-item">Narration: {{ invoice.narration }}</li>
						<li class="list-group-item">Description: {{ invoice.description }}</li>
						<li class="list-group-item">Status: {{ invoice.status }}</li>
						<li class="list-group-item">
	                		<a :href="PDF_API_URL + 'pdf/generateInvoice/' + invoice.id " target="_blank" class="btn btn-primary">View invoice details</a>
						</li>
						<span v-if="load !== null">
							<li class="list-group-item" v-if="load.invoice.status === 'RELEASED' && load.invoice.payment.status === 'RELEASED'">
								<h4>Payee Details:</h4>
								<ul class="list-group" v-if="load.carrier !== null">
									<li class="list-group-item">Carrier - {{ load.carrier.name }}</li>
									<li class="list-group-item">
										<ul class="list-group" v-for="account in load.carrier.bankAccounts" :key="account.id">
											<li class="list-group-item">Bank - {{ account.financialInstitution.name }}</li>
											<li class="list-group-item">Account Number/Mobile Wallet  - {{ account.accountNumber }}</li>
											<li class="list-group-item">Account Currency  - {{ account.accountCurrency }}</li>
											<li class="list-group-item">Branch - {{ account.branch }}</li>
											<li class="list-group-item">Branch code - {{ account.branchCode }}</li>
										</ul>
									</li>
									<li class="list-group-item text-danger">Percentage load charge is {{ VUE_APP_LOAD_PERCENTAGE_CHARGE }}</li>
									<li class="list-group-item text-danger">Load charge amount - {{ load.invoice.payment.currency }}{{ parseFloat(load.invoice.payment.amount * .07).toFixed(3) }}</li>
									<li class="list-group-item text-danger">Amount to be credited - {{ load.invoice.payment.currency }}{{ (parseFloat( load.invoice.payment.amount) - parseFloat(load.invoice.payment.amount * VUE_APP_LOAD_PERCENTAGE_CHARGE)).toFixed(3) }}</li>
								</ul>
							</li>
						</span>
					</ul>
					<span v-else>
						<p class="text-danger">Payment not available!</p>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import PaymentService from "@/services/PaymentService.js";
  import loading from "vue-full-loading";
  import Payment from "@/models/Payment.js";  
  import InvoiceService from "@/services/InvoiceService.js";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";
  import LoadService from "@/services/LoadService.js";

  export default {
    components: {
      loading
    },

    name: "AuthorizePayment",
    data: function() {
      return {
        show: false,
        label: "Processing request",
        payment: new Payment,
        invoice: null,
        PDF_API_URL: PDF_API_URL,
        load: null,
        VUE_APP_LOAD_PERCENTAGE_CHARGE: process.env.VUE_APP_LOAD_PERCENTAGE_CHARGE
      };
    },

    computed: {
      getInvoiceNumber() {
        return this.$route.params.invoiceNumber;
      },

      getFilter(){
      	return this.$route.query.filter;
      }
    },

    mounted() {
    	this.findByInvoiceNumber();
    	this.findByInvoiceNumber2();
    },

    methods: {
    	findByInvoiceNumber: function(){
    		this.show = true;
	        InvoiceService.findByInvoiceNumber(this.getInvoiceNumber).then(response => {
	        	this.invoice = response.data.body;
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },

	    findByInvoiceNumber2: function(){
    		this.show = true;
	        LoadService.findByInvoiceNumber(this.getInvoiceNumber).then(response => {
	        	this.load = response.data.body;
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },

	    authorizePayment: function(){
    		this.show = true;
    		this.payment.invoiceNumber = this.invoice.invoiceNumber;
    		this.payment.referenceNumber = this.invoice.payment.referenceNumber;
	        PaymentService.authorizePayment(this.payment).then(response => {
	        	if (response.data.body) {
	        		this.$router.push({ name: 'payment-list' });
	        	}
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },
    }
  };
</script>