<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>        
    </div>

    <div class="panel">
      <div class="panel-body">
        <p class="error-message" v-if="message !== null">{{ message }}</p>
        <br>
        <div v-if="getToken !== null">
          <form class="form" @submit.prevent="confirmAccount">
          	<h4>Thanks for registering on Nelspruit Freight, Welcome on-board.</h4>
          	<br>
            <h4 class="text-danger">Verify your account to start accessing Nelspruit Freight Services</h4>
            <br>
            <h4>The Nelspruit Freight Team</h4>
            <p>Connecting you to the right business</p>

            <div class="row">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>&nbsp;</label>
                  <button class="btn btn-success btn-block" type="sumbit">Verify account</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import AccountConfirmationService from "@/services/AccountConfirmationService.js";
  import loading from "vue-full-loading";

  export default {
    name: "AccountVerification",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Verifying account. Please wait",
        message: null
      };
    },

    mounted() {

    },

    computed: {
    	getToken(){
    		return this.$route.query.token;
    	}
    },

    methods: {
      confirmAccount: function() {
        this.show = true;
        AccountConfirmationService.confirmAccount(this.getToken).then(response => {
        	console.log(response.data.body);
          if (response.data.statusCode === 200) {
          	if (response.data.body === true) {
          		this.$router.push({ name: "login" });
          	}else{
          		this.message = 'Failed to verify your account.';
          	}
          }
          if (response.data.statusCode === 500) {
            this.message = response.data.body.message;
          }
          this.show = false;
        }).catch(error => {
          console.log(error.response);
            if (error.response.status === 500) {
              this.message = error.response.data.message;
            }
            this.show = false;
          });
      },
    }
  };
</script>
