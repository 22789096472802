<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <h4>New Load Charge</h4>
          </div>
          <router-link :to="{ name: 'load-charge-list' }">
            <div class="col-md-2">
              <button class="btn btn-primary btn-block">Back</button>
            </div>
          </router-link>
        </div>
        <br>

        <form class="form" @submit.prevent="save">
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label>Effective Year</label>
                <select class="form-control" v-model="nssa.effectiveYear">
                  <option value="" disabled>Select Year</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </select>
              </div>             
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Percentage Charge</label>
                <nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="nssa.percentageCharge" placeholder="Enter Percentage Charge"/>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Currency</label>
                <select v-model="nssa.currencyId" class="form-control">
                  <option value="">Select status</option>
                  <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Start Date</label>
                <input type="date" name="startDate" class="form-control" v-model="nssa.startDate">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>End Date</label>
                <input type="date" name="endDate" class="form-control" v-model="nssa.endDate">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Active</label>&nbsp;
            <input type="radio" v-model="nssa.active" value="true"/> Yes
            <input type="radio" v-model="nssa.active" value="false"/> No
          </div>

          <div class="form-group">
            <label>Current</label>&nbsp;
            <input type="radio" v-model="nssa.current" value="true"/> Yes
            <input type="radio" v-model="nssa.current" value="false"/> No
          </div>

          <div class="form-group">
            <label>Enabled</label>&nbsp;
            <input type="radio" v-model="nssa.enabled" value="true"/> Yes
            <input type="radio" v-model="nssa.enabled" value="false"/> No
          </div>

          <div class="form-group">
            <label>Expired</label>&nbsp;
            <input type="radio" v-model="nssa.expired" value="true"/> Yes
            <input type="radio" v-model="nssa.expired" value="false"/> No
          </div>

          <div class="form-group">
            <label>Description</label>
            <textarea rows="5" placeholder="Enter description" class="form-control" v-model="nssa.description"></textarea>
          </div>

          <div class="form-group">
            <label>Status</label>
            <select v-model="nssa.status" class="form-control">
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <div class="form-group"><label>&nbsp;</label>
                <button class="btn btn-success btn-block">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import LoadCharge from "@/models/LoadCharge";
  import LoadChargeService from "@/services/LoadChargeService";
  import loading from "vue-full-loading";
  import CurrencyService from "@/services/CurrencyService";

  export default {
    name: "NewNSSA",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        nssa: new LoadCharge,
        errors: [],
        message: null,
        currencies: []
      }
    },

    mounted(){
      this.findAll();
    },

    methods: {
      save: function() {
        this.show = true;
        LoadChargeService.save(this.nssa).then(response => {        
          if (response.data.statusCode == 201) {
            this.show = false;
            this.$router.push({ name: "load-charge-list" });
          }
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findAll: function() {
        this.show = true;
        CurrencyService.findAll(this.nssa).then(response => {        
          this.currencies = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    },

    computed: {
      years: function() {
        const year = new Date().getFullYear();
        const years = Array.from({ length: (year + 1) - 1850 }, (value, index) => year - index);
        return years;
      }
    }
  };
</script>
