<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <h4>{{ editing ? "Edit": "New" }} Exchange Rate</h4>
    <br/>
    <p v-if="message !== null" class="error-message text-center">
      {{ message }}
    </p>
    <form class="form" @submit.prevent="save">
      <div class="form-group">
        <label> Name</label>
        <input type="text" v-model="exchangeRate.midRate" placeholder="Enter Mid Rate" class="form-control"/>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-message">
            <span v-if="error.name">{{ error.name }}</span>
          </p>
        </div>
      </div>

      <div class="form-group">
        <label>Currency</label>
        <select v-model="exchangeRate.currency" class="form-control">
          <option value="">Select status</option>
          <option value="USD">USD</option>
        </select>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-message">
            <span v-if="error.status">{{ error.status }}</span>
          </p>
        </div>
      </div>

      <div class="form-group">
        <label>Date</label>
        <input type="date" v-model="exchangeRate.date" class="form-control"/>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-message">
            <span v-if="error.description">{{ error.description }}</span>
          </p>
        </div>
      </div>

      <div class="form-group">
        <label>Description</label>
        <input type="text" v-model="exchangeRate.description" placeholder="Enter description" class="form-control"/>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-message">
            <span v-if="error.description">{{ error.description }}</span>
          </p>
        </div>
      </div>

      <div class="form-group">
        <label>Status</label>
        <select v-model="exchangeRate.status" class="form-control">
          <option value="">Select status</option>
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
          <option value="DELETED">Deleted</option>
        </select>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-message">
            <span v-if="error.status">{{ error.status }}</span>
          </p>
        </div>
      </div>

      <div class="form-group">
        <label>&nbsp;</label>
        <router-link :to="{ name: 'roles' }" replace>
          <button class="btn btn-default" data-dismiss="modal">Cancel</button>
        </router-link>
        &nbsp; <button class="btn btn-success pull-right">Submit</button>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import ExchangeRate from "@/models/ExchangeRate";
  import ExchangeRateService from "@/services/ExchangeRateService";
  import loading from "vue-full-loading";

  export default {
    name: "NewExchangeRate",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing...",
        exchangeRate: new ExchangeRate,
        errors: [],
        message: null,
        editing: false
      };
    },

    mounted(){
      if("exchangeRateId" in this.$route.params){
        this.editing = true;
        this.findById();
      }
    },

    computed: {
      getExchangeRateId: function(){
        return this.$route.params.exchangeRateId;
      }
    },

    methods: {
      save: function() {
        this.exchangeRate.exchangeRateId = this.getExchangeRateId;
        this.show = true;
        ExchangeRateService.save(this.exchangeRate).then(response => {
          if (response.data.statusCode == 201) {
            this.$router.push({ name: "exchangeRates" });
          }
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
