export default class Load{
	constructor(id, loadNumber, hazmat, unNumber, type, loadMode, expiryDate, pickupAddress, destinationAddress, pickupDate,
	 pickupTime, dropOffDate, dropOffTime, estimatedDistance,  estimatedDistanceMeasurement, estimatedLoadingTime,
		estimatedLoadingTimeMeasurement, estimatedDeliveryTime, estimatedDeliveryTimeMeasurement, trailerTypeRequired, weight, weightMeasurement, price, currency, carrierId, marketAveragePrice, estimatedDrivingTime,
		estimatedDrivingTimeMeasurement, ownerUsername, bookNowEnabled, collectLoadOffersEnabled, contactInformationForCarrier, 
		copyFromMyAccount, privateLoadEnabled, payment, status, deliveryComments, assignedTruckNumber, releasedDate, releasedTime,
		carriers, description){
		this.id = id;
		this.loadNumber = loadNumber;
		this.type = type;
		this.hazmat = hazmat;
		this.unNumber = unNumber;
		this.loadMode = loadMode;
		this.expiryDate = expiryDate;
		this.pickupAddress = pickupAddress;
		this.destinationAddress = destinationAddress;
		this.pickupDate = pickupDate;
		this.pickupTime = pickupTime;
		this.dropOffDate = dropOffDate;
		this.dropOffTime = dropOffTime;
		this.estimatedDistance = estimatedDistance;
		this.estimatedDistanceMeasurement = estimatedDistanceMeasurement;
		this.estimatedLoadingTime = estimatedLoadingTime;
		this.estimatedLoadingTimeMeasurement = estimatedLoadingTimeMeasurement;
		this.estimatedDeliveryTime = estimatedDeliveryTime;
		this.estimatedDeliveryTimeMeasurement = estimatedDeliveryTimeMeasurement;
		this.trailerTypeRequired = trailerTypeRequired;
		this.weight = weight;
		this.weightMeasurement = weightMeasurement;
		this.price = price;
		this.currency = currency;
		this.carrierId = carrierId;
		this.marketAveragePrice = marketAveragePrice;
		this.estimatedDrivingTime = estimatedDrivingTime;
		this.estimatedDrivingTimeMeasurement = estimatedDrivingTimeMeasurement;
		this.ownerUsername = ownerUsername;
		this.bookNowEnabled = bookNowEnabled;
		this.collectLoadOffersEnabled = collectLoadOffersEnabled;
		this.contactInformationForCarrier = contactInformationForCarrier;
		this.copyFromMyAccount = copyFromMyAccount;
		this.privateLoadEnabled = privateLoadEnabled;
		this.payment = payment;
		this.status = status;
		this.deliveryComments = deliveryComments;
		this.assignedTruckNumber = assignedTruckNumber;
		this.releasedDate = releasedDate;
		this.releasedTime = releasedTime;
		this.carriers = carriers;
		this.description = description;
	}
}