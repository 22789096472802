<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<router-link :to="{ name: 'my-bookings' }">
					<button class="btn btn-primary">Back</button>
				</router-link>
				<h4 class="text-center">Booking Details</h4>

				<ul class="list-group">
					<li class="list-group-item">bookingNumber: {{ booking.bookingNumber }}</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BookingService from "@/services/BookingService";
	import Booking from "@/models/Booking";

	export default{
		name: 'ViewBooking',
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request...',
				booking: new Booking,
			}
		},

		created(){
			this.findByBookingNumber();
		},

		computed: {
			getBookingNumber: function(){
				return this.$route.params.bookingNumber;
			},

			getUser: function() {
				return this.$store.state.auth.user.user;
		    }
		},

		methods: {
			findByBookingNumber: function(){
				this.show = true;
				BookingService.findByBookingNumber(this.getBookingNumber).then(response => {
					this.booking = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>