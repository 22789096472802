<template>
  <div>
  	<loading :show="show" :label="label">
		<div class="processing-row" slot="loading-container">
			<div class="text-center">
				<div slot="loading-spinner">
					<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
				</div>
				<p class="text-center" slot="loading-text">{{ label }}</p>
			</div>
		</div>
	</loading>

  	<div class="panel" v-if="invoice !== null">
  		<div class="panel-body">
  			<h4>Subscription renewal invoice</h4>
  			<ul class="list-group">
  				<li class="list-group-item">Invoice number : {{ invoice.invoiceNumber }}</li>
			    <li class="list-group-item">Narration : {{ invoice.narration }}</li>
			    <li class="list-group-item">Total amount : {{ invoice.currency }}{{ invoice.total }}</li>
			    <li class="list-group-item">Due date : {{ invoice.dueDate }}</li>
		    </ul>
		    <p class="text-danger">You will be redirected to payment gateway and automatically redirected back when payment is processed.</p>
		    <div class="row">
		    	<div class="col-md-10"></div>
		    	<div class="col-md-2" v-if="invoice !== null">
		    		<form class="form text-center" @submit.prevent="getRedirectUrl">
		    			<button class="btn btn-primary btn-block" type="submit">Proceed to Payment</button>
			       	</form>
		    	</div>
		    </div>
  		</div>
  	</div>
  </div>
</template>

<script type="text/javascript">
	import SubscriptionService from "@/services/SubscriptionService.js";
	import Subscription from "@/models/Subscription.js";
	import PaymentService from "@/services/PaymentService.js";
	import loading from "vue-full-loading";

	export default {
		components: {
			loading
		},
	  
	  	data: function() {
	  		return {
	  			show: false,
        		label: "Processing subscription",
	  			subscription: new Subscription,
	    		subscriptionPlanNumber: JSON.parse(localStorage.getItem("selectedSubscriptionPlanNumber")),
	    		price: JSON.parse(localStorage.getItem("selectedPrice")),
	    		currency: JSON.parse(localStorage.getItem("selectedCurrency")),
	    		invoice: null,
	    	};
	  },

	  computed: {
	  	getUser: function() {
	    	return this.$store.state.auth.user.user;
	    }
	  },

	  mounted: function() {
	  	console.log('subscriptionPlanNumber', this.subscriptionPlanNumber);
	  	console.log('price', this.price);
	  	console.log('currency', this.currency);

	  	this.upgrade();
	  },

	  methods: {
	    upgrade: function() {
	        this.show = true;
	        this.subscription.associatedUserUsername = this.getUser.username;
	        this.subscription.subscriptionPlanNumber = this.subscriptionPlanNumber;

	        SubscriptionService.upgrade(this.getUser.username, this.subscriptionPlanNumber).then(response => {
	          if (response.data.statusCode == 201) {
				this.invoice = response.data.body.invoice;
				console.log(this.invoice);
				if (this.invoice !== null) {
					localStorage.setItem('invoiceNumber', JSON.stringify(this.invoice.invoiceNumber));
				}
	          }
	          this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },

	    getRedirectUrl: function(){
	        this.show = true;
	        let paymentPostDto = {
	            uid: this.getUuid,
	            payerUsername: this.getUser.username,
	            amount: this.invoice.total,
	            currency: this.invoice.currency,
	            description: this.invoice.narration,
	            method: "ONLINE",
	            gateway: "PAYNOW",
	            paymentType: "SUB"
	        };
	        PaymentService.getRedirectUrl(paymentPostDto).then(response => {
	          if (response.status === 201) {
	            if (response.data.body !== null) {
	              window.location.replace(response.data.body);
	            }
	          }
	          this.show = false;
	        }).catch(error => {
	          console.log(error);
	          this.show = false;
	        });
	      this.show = false;
	    }
	  }
	};
</script>
