<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Authorize Payment</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'withholding-payments' }">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>
				<br>

				<ul class="list-group" v-if="invoice !== null">
					<li class="list-group-item">Reference Number: {{ invoice.payment.referenceNumber }}</li>
					<li class="list-group-item">Amount: {{ invoice.payment.currency }}{{ invoice.payment.amount }}</li>
					<li class="list-group-item">Narration: {{ invoice.narration }}</li>
					<li class="list-group-item">Description: {{ invoice.description }}</li>
					<li class="list-group-item">Status: {{ invoice.status }}</li>
					<li class="list-group-item">
                		<a :href="PDF_API_URL + 'pdf/generateInvoice/' + invoice.id " target="_blank" class="btn btn-primary">View invoice details</a>
					</li>
				</ul>
				<div v-if="invoice !== null && invoice.status === 'WITHHOLDING' && invoice.payment.status === 'PAID'">
					<form class="form" @submit.prevent="authorizePayment">
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<button class="btn btn-success btn-block" type="submit">Authorize Payment</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import PaymentService from "@/services/PaymentService.js";
  import loading from "vue-full-loading";
  import Payment from "@/models/Payment.js";  
  import InvoiceService from "@/services/InvoiceService.js";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default {
    components: {
      loading
    },

    name: "AuthorizePayment",
    data: function() {
      return {
        show: false,
        label: "Processing request",
        payment: new Payment,
        invoice: null,
        PDF_API_URL: PDF_API_URL,
      };
    },

    computed: {
      getInvoiceNumber() {
        return this.$route.params.invoiceNumber;
      }
    },

    created() {
    	this.findByInvoiceNumber();
    },

    methods: {
    	findByInvoiceNumber: function(){
    		this.show = true;
	        InvoiceService.findByInvoiceNumber(this.getInvoiceNumber).then(response => {
	        	this.invoice = response.data.body;
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },

	    authorizePayment: function(){
    		this.show = true;
    		this.payment.invoiceNumber = this.invoice.invoiceNumber;
    		this.payment.referenceNumber = this.invoice.payment.referenceNumber;
	        PaymentService.authorizePayment(this.payment).then(response => {
	        	if (response.data.body) {
	        		window.location.reload();
	        		this.$router.push({ name: 'payment-list' });
	        	}
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },
    }
  };
</script>