<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <ul class="nav nav-tabs">
	    	<li><a href="#bidsOrOffers" data-toggle="tab">Bids/Offers</a></li>
	    	<li><a href="#" data-toggle="tab">Bookings</a></li>
	    </ul>
	    <br>

	    <div class="nav-content">
	    	<div class="tab-content fade in active" id="bidsOrOffers">
	    		<br>
	    		

	    		<table class="table table-responsive table-bordered">
			    	<thead class="bg-primary">
				        <th>Load Ref ID</th>
				        <th>Event Time</th>
				        <th>Pick Up</th>
				        <th>Drop Off</th>
				        <th>Carrier Name</th>
				        <th>Trailer</th>
				        <th>Status</th>
				        <th>Actions</th>
				      </thead>
				      <tbody>
				        <tr v-for="bid in bids" :key="bid.id">
				          <td>{{ bid.load.loadNumber }}</td>
				          <td>{{ bid.load.pickupTime }}</td>
				          <td>{{ bid.load.pickupTime }}</td>
				          <td>{{ bid.load.dropOffTime }}</td>
				          <td>{{ bid.carrier !== null ? bid.carrier.firstName: 'Not available' }} {{ bid.carrier !== null ? bid.carrier.lastName: 'Not available' }}</td>
				          <td>{{ bid.load.trailerTypeRequired }}</td>
				          <td>
				            <span class="label label-primary" v-if="bid.status === 'PENDING'">Not yet awarded</span>
				            <span class="label label-success" v-if="bid.status === 'AWARDED'">Awarded</span>
				            <span class="label label-success" v-if="bid.status === 'ACCEPTED'">Accepted</span>
				            <span class="label label-success" v-if="bid.status === 'DECLINED'">Declined</span>
				          </td>
				          <td>
				          	<span>
				          		<router-link :to="{ name: 'shipper/loads/leads', params: { bidNumber: bid.bidNumber } }">
				          			<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
				            	</router-link>&nbsp;
				          	</span>
				          	<span v-if="bid.status === 'PENDING'">
				          		<router-link :to="{ name: 'shipper/loads/leads/awardBid', params: { bidNumber: bid.bidNumber } }">
				          			<button class="btn btn-warning">Award <span class="glyphicon glyphicon-edit"></span></button>
				            	</router-link>&nbsp;
				          	</span>
				            <span v-if="bid.status === 'AWARDED' && getRoles.includes('CARRIER')">
				            	<router-link :to="{ name: 'carrier/loads/bids/acceptOrDeclineOffer', params: { bidNumber: bid.bidNumber } }">
				            		<button class="btn btn-warning">Accept or Decline Offer <span class="glyphicon glyphicon-edit"></span></button>
				            	</router-link>
				            </span>
				          </td>
				        </tr>
				      </tbody>
			    </table>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BidService from "@/services/BidService.js";

	export default{
		name: 'Bookings',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				bids: []
			}
		},

		created(){
			this.findAll();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		},

		methods: {
			findAll: function() {
				this.show = true;
		        BidService.findAll().then(response => {
		        	this.bids = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },
		}
	};
</script>