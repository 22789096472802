<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <h4>Load Bookings</h4>

	    <table class="table table-bordered table-hover text-center" style="table-layout: fixed; background: #000000; color: #ffffff;">
			<thead>
				<th style="padding-left: 15px;">Load</th>
				<th>Booking number</th>
				<th>Load price</th>
				<th>Pickup</th>
				<th>Drop off</th>
				<th>Carrier</th>
				<th>Assigned truck</th>
				<th>Load status</th>
				<th>Booking</th>
				<th>Load payment</th>
				<th>Actions</th>
			</thead>
		</table>

		<div class="panel" v-for="load in loads" :key="load.id">
  			<div class="panel-body">
  				<div class="row">
  					<div class="col-md-1">
  						<td>
			      			<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }, query: { source: 'SHIPPER_BROKER_BOOKINGS' }}">{{ load.loadNumber }}
			      			</router-link>
			      		</td>
  					</div>
  					<div class="col-md-11">
		      			<table class="table table-bordered table-hover" style="table-layout: fixed;">
		      				<tbody>
			      				<tr v-for="booking in load.bookings" :key="booking.id">
						        	<td>{{ booking.bookingNumber }}</td>
						        	<td>{{ load.currency }}{{ load.price }}</td>
						          	<td>
						          		{{ load.pickupAddress !== null ? load.pickupAddress.city.name + ', ' + load.pickupAddress.province.name: '' }}
						          	</td>
						          	<td>
						          		{{ load.dropOffAddress !== null ? load.dropOffAddress.city.name + ', ' + load.dropOffAddress.province.name: '' }}
						          	</td>
						          	<td>{{ booking.carrier !== null ? booking.carrier.name: '' }}</td>
						          	<td>
						          		<span v-if="booking.assignedTruck !== null">
						          			<router-link :to="{ name: 'trucks/view', params: { truckNumber: booking.assignedTruck.truckNumber }, query: { source: 'my-bookings' }}">
						          				{{ booking.assignedTruck !== null ? booking.assignedTruck.registrationNumber: '' }}
						          			</router-link>
						          		</span>
						          	</td>
						          	<td><span class="label label-primary">{{ load.status }}</span></td>
						          	<td>
						          		<span class="label label-primary" v-if="booking.status === 'PENDING'">Not yet awarded</span>
						            	<span class="label label-success" v-if="booking.status === 'AWARDED'">Awarded</span>
						            	<span class="label label-warning" v-if="booking.status === 'ACCEPTED'">Carrier Accepted</span>
						            	<span class="label label-default" v-if="booking.status === 'DECLINED'">Declined</span>
						            	<span class="label label-danger" v-if="booking.status === 'CANCELLED'">Cancelled</span>
						            	<span class="label label-success" v-if="booking.status === 'BOOKED'">Booked</span>
						          </td>
						          <td>
						          	<span v-if="load.invoice !== null">
						          		<span v-if="load.invoice !== null && load.invoice.payment !== null">
						          			{{ load.carrier.id === booking.carrier.id ? 'Paid': '' }}
						          		</span>
						          		<span v-else>Not yet paid</span>
						          	</span>
						          </td>
						          <td>
						          	<span v-if="booking.status === 'PENDING' && (getRoles.includes('SHIPPER') || getRoles.includes('BROKER'))">
						          		<div class="row">
						          			<div class="col-md-5">
						          				<router-link :to="{ name: 'award-booking', params: { loadNumber: load.loadNumber, bookingNumber: booking.bookingNumber } }">
						          					<button class="btn btn-success btn-responsive btn-xs">Award <span class="glyphicon glyphicon-thumbs-up"></span></button>&nbsp;
						          				</router-link>	
						          			</div>
						          			<div class="col-md-5">
						          				<router-link :to="{ name: 'decline-booking-confirmation', params: { bookingNumber: booking.bookingNumber }}">
						          				<button class="btn btn-danger btn-responsive btn-xs">Decline <span class="glyphicon glyphicon-thumbs-down"></span></button>
						          			</router-link>
						          			</div>
						          		</div>
						          	</span>
						            <span v-if="booking.status === 'AWARDED' && getRoles.includes('CARRIER')">
						            	<router-link :to="{ name: 'carrier/loads/bids/acceptOrDeclineOffer', params: { bookingNumber: booking.bookingNumber } }">
						            		<button class="btn btn-warning btn-xs">Accept or Decline Offer <span class="glyphicon glyphicon-edit"></span></button>
						            	</router-link>
						            </span>&nbsp;
						            <span v-if="booking.status === 'ACCEPTED' && load.invoice !== null && load.invoice.payment === null">
						            	<div class="row">
						            		<div class="col-md-2">
						            			<router-link :to="{ name: 'cancel-booking', params: { bookingNumber: booking.bookingNumber, loadNumber: load.loadNumber }}">
						            				<button class="btn btn-danger btn-xs">Cancel <span class="glyphicon glyphicon-remove"></span></button>
						            			</router-link>
						            		</div>
						            		<div class="col-md-4"></div>
						            		<div class="col-md-3">
						            			<router-link :to="{ name: 'pay-invoice', params: { loadNumber: load.loadNumber, bookingNumber: booking.bookingNumber }}">
								            		<button class="btn btn-success btn-xs">Pay <img src="@/assets/images/coin.png" height="17" width="17"></button>
								            	</router-link>
						            		</div>
						            	</div>
						            </span>
						          </td>
					      		</tr>
			      			</tbody>
		      			</table>
  					</div>
  				</div>
  			</div>
  		</div>
  		<div class="panel" v-if="loads.length === 0">
  			<div class="panel-body">
  				<p>No bookings available</p>
  			</div>
  		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService.js";

	export default{
		name: 'Bookings',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    loads: []
			}
		},

		mounted(){
			this.findByOwnerUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		},

		methods: {
			findByOwnerUsername: function(){
				this.show = true;
				LoadService.findByOwnerUsername(this.getUser.username).then(response => {
					this.loads = response.data.body.filter(load => {
						return load.bookings !== null && load.bookings.length > 0;
					});
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        });
			}
		}
	};
</script>