<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img
                  src="@/assets/images/station-loading.gif"
                  height="100"
                  width="100"
                />
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

        <router-link :to="{ name: 'cities' }">
          <button class="btn btn-primary">
            <span class="fa fa-step-backward"></span> Back
          </button> </router-link
        ><br /><br />

        <h4>{{ editing ? "Edit": "New" }}</h4>
        <p class="error-message text-center" v-if="errorMessage !== null">
          {{ errorMessage }}
        </p>
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label>Province</label>
            <select class="form-control" v-model="city.provinceId" required>
              <option value="">Select province</option>
              <option
                v-for="province in provinces"
                :key="province.id"
                :value="province.id"
                >{{ province.name }}</option
              >
            </select>
          </div>

          <div class="form-group">
            <label for="code">Name</label>
            <input
              type="text"
              v-model="city.name"
              class="form-control"
              placeholder="Enter City Name"
            />
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.name }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <input
              type="text"
              v-model="city.description"
              class="form-control"
              placeholder="Enter Description"
            />
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.description }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" v-model="city.status" required>
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.status }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="submit">&nbsp;</label>
            <button class="btn btn-success" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import ProvinceService from "@/services/ProvinceService";
import CityService from "@/services/CityService";
import City from "@/models/City";
import loading from "vue-full-loading";

export default {
  name: "NewZipCode",
  components: {
    loading
  },

  data: function() {
    return {
      city: new City(),
      errors: [],
      errorMessage: null,
      show: false,
      label: "Processing...",
      provinces: [],
      editing: false
    };
  },

  computed: {
    getCityId: function(){
      return this.$route.params.cityId;
    }
  },

  created: function() {
    this.findAllProvinces();
  },

  mounted(){
    if ('cityId' in this.$route.params) {
      this.editing = true;
      this.findById();
    }
  },

  methods: {
    findAllProvinces: function() {
      ProvinceService.findAll()
        .then(response => {
          this.provinces = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findById: function() {
      CityService.findById(this.getCityId)
        .then(response => {
          this.city = response.data.body;
        }).catch(error => {
          console.log(error);
        });
    },

    save: function() {
      this.showLoader();
      this.city.cityId = this.getCityId;
      CityService.save(this.city)
        .then(response => {
          this.cancelLoader();
          if (response.data.statusCode == 422) {
            this.setNotificationData(response);
          }
          if (response.data.statusCode == 201) {
            this.clearNotificationData();
            this.$router.push({ name: "cities" });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // make these functions global :: reuse, via the app instance
    clearNotificationData: function() {
      this.errors = null;
      this.errorMessage = null;
    },

    setNotificationData: function(response) {
      this.errors = response.data.errors;
      this.errorMessage = response.data.message;
    },

    cancelLoader: function() {
      this.show = false;
    },

    showLoader: function() {
      this.show = true;
    }
  }
};
</script>
<style type="text/css" scoped>
.error-message {
  color: red;
}
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
