<template>
  <div>
   <div class="panel">
     <div class="panel-body">
        <router-link :to="{ name: 'users' }" replace
          ><button class="btn btn-primary">
            <span class="glyphicon glyphicon-step-backward"></span> Back
          </button></router-link
        >

        <h4>User Details</h4><br>

        <router-link :to="{ name: 'users/profile/editProfile', params: { userId: this.getUser.user.id }}">
          <button class="btn btn-warning pull-right">Edit Profile</button>
        </router-link>

        <ul class="list-group">
          <li class="list-group-item">User ID: {{ user.id }}</li>
          <li class="list-group-item">Username: {{ user.username }}</li>
          <li class="list-group-item">First Name: {{ user.firstName === null ? "No available" : user.lastName }}</li>
          <li class="list-group-item">Last Name: {{ user.lastName === null ? "No available" : user.lastName }}</li>
          <li class="list-group-item">Email: {{ user.email }}</li>
          <li class="list-group-item">Salutation: {{ user.salutation }}</li>
          <li class="list-group-item">Gender: {{ user.gender }}</li>
          <li class="list-group-item">Roles: 
            <ul class="list-group" v-for="role in user.roles" :key="role.id">
              <li class="list-group-item">{{ role.name }}</li>
            </ul>
          </li>
          <li class="list-group-item">Person Type: {{ user.personType }}</li>
          <li class="list-group-item">Date Of Birth: {{ user.dateOfBirth }}</li>
          <li class="list-group-item">Phone Numbers: {{ user.phoneNumbers }}</li>
          <li class="list-group-item">Fax Number: {{ user.faxNumber }}</li>
          <li class="list-group-item">Skype Address: {{ user.skypeAddress }}</li>
          <li class="list-group-item">National ID Number: {{ user.nationalIdNumber }}</li>
          <li class="list-group-item">Passport Number: {{ user.passportNumber }}</li>
          <li class="list-group-item">Drivers Licence Number: {{ user.driversLicenceNumber }}</li>
          <li class="list-group-item">Nationality: {{ user.nationality }}</li>
          <li class="list-group-item">Home Address: 
            <ul class="list-group" v-if="user.homeAddress !== null">
              <li class="list-group-item">City: {{ user.homeAddress.city === null ? "Not available": user.homeAddress.city.name }}{{ user.homeAddress.physicalAddress }}</li>
              <li class="list-group-item">Physical Address: {{ user.homeAddress.physicalAddress }}</li>
            </ul>
          </li>
          <li class="list-group-item">Postal Address: 
            <ul class="list-group" v-if="user.postalAddress !== null">
              <li class="list-group-item">City: {{ user.postalAddress.city === null ? "Not available": user.postalAddress.city.name }}{{ user.postalAddress.physicalAddress }}</li>
              <li class="list-group-item">Physical Address: {{ user.postalAddress.physicalAddress }}</li>
            </ul>
          </li>
          <li class="list-group-item">Marital Status: {{ user.maritalStatus }}</li>
          <li class="list-group-item">Place Of Birth: {{ user.placeOfBirth === null ? "Not available": user.placeOfBirth.name }}</li>
          <li class="list-group-item">Country Of Birth: {{ user.countryOfBirth === null ? "Not available": user.countryOfBirth.name }}</li>
          <li class="list-group-item">Ethnic Group: {{ user.ethnicGroup }}</li>
          <li class="list-group-item">Religion: {{ user.religion }}</li>
          <li class="list-group-item">Photo: {{ user.photo.id }}</li>
          <li class="list-group-item">Digital Signature: {{ user.digitalSignature.id }}</li>
        </ul>

        <router-link :to="{ name: 'users' }">
          <button class="btn btn-default">Back</button>
        </router-link>
     </div>
   </div>
  </div>
</template>

<script type="text/javascript">
import UserService from "@/services/UserService.js";
import User from "@/models/User.js";

export default {
  name: "ViewUser",
  data: function() {
    return {
      user: new User()
    };
  },

  computed: {
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    },

    getUserId: function() {
      return this.$route.params.userId;
    }
  },

  created: function() {
    this.findById();
  },

  methods: {
    findById: function() {
      UserService.findById(this.getUserId)
        .then(response => {
          this.user = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
