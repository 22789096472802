<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <h3>My account details
        <hr>
        <div class="row">
          <div class="col-md-6">
            <img src="@/assets/images/people.svg" class="img img-responsive img-rounded" height="100" width="100"/>
          </div>
          <!-- <div class="col-md-6">
            <router-link :to="{ name: 'users/profile/editProfile', params: { userId: this.currentUser.id }}">
              <button class="btn btn-warning pull-right">Edit Profile</button>
            </router-link>
          </div> -->
        </div>
      </h3>
      
      <br/>
      <div v-if="user !== null">
        <ul class="list-group">
          <li class="list-group-item">Salutation: {{ capitalizeFirstLetter(user.salutation) }}</li>
          <li class="list-group-item">First name: {{ user.firstName }}</li>
          <li class="list-group-item">Last name: {{ user.lastName }}</li>
          <li class="list-group-item">Username <span class="label label-success">{{ user.username }}</span></li>
          <li class="list-group-item">Email <span class="label label-primary">{{ user.email }}</span></li>
          <!-- <li class="list-group-item">
            I am a 
            <ul class="list-group" v-for="authority in currentUser.authorities" :key="authority.authority">
              <li class="list-group-item">{{ authority.authority }}</li>
            </ul>
          </li> -->
        </ul>
      </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import UserService from "@/services/UserService";

  export default {
    data: function() {
      return {
        user: null
      }
    },

    mounted() {
      this.findByUsername();
    },

    computed: {
      currentUser: function() {
        return this.$store.state.auth.user.user;
      }
    },

    methods: {
      findByUsername(){
        UserService.findByUsername(this.currentUser.username).then(response => {
          this.user = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      capitalizeFirstLetter(string){
        return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
      }
    }
  };
</script>
