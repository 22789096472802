<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
        <div class="row">
          <div class="col-md-11">
            <h4>Cancel subscription</h4>
          </div>
          <div class="col-md-1">
            <router-link :to="{ name: 'my-subscription' }">
              <button class="btn btn-primary btn-block">Back</button>
            </router-link>
          </div>
        </div>
				<hr>

				<p class="text-danger" v-if="message !== null">{{ message }}</p>
				<br>
				<h4 class="text-danger">Cancelling your subscription plan may cause you to lose your services in Freight Board</h4>
        <br>
        <p><i>Check out our cancellation policy on our website.</i></p>

        <form class="form text-center" @submit.prevent="cancel">
          <div class="row">
            <div class="col-md-11"></div>
            <div class="col-md-1">
              <div class="form-group">
                <label>&nbsp;</label>
                <button class="btn btn-danger btn-block" type="submit">Cancel</button>
              </div>
            </div>
          </div>
        </form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import SubscriptionService from "@/services/SubscriptionService";

  export default {
    name: "CancelSubscription",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing request",
        message: null
      };
    },

    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      }
    },

    methods: {
      cancel: function(){
        this.show = true;
        SubscriptionService.cancel(this.currentUser.user.username).then(response => {
        	if (response.data.statusCode === 201) {
        		localStorage.clear();
            this.$router.push({ name: 'login'});
        	}
          this.show = false;
        }).catch(error => {
        	console.log(error);
          if (error.response.status === 500) {
            this.message = error.response.data.message;
          }
          this.show = false;
        });
      }
    }
  };
</script>