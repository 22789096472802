import { LOAD_SERVICE_URL } from "@/config/LoadServiceConfig";
import axios from "axios";

class LoadAlertService {
  findAll() { 
    return axios.get(LOAD_SERVICE_URL + "loadAlerts"); 
  }

  findById(id){ 
    return axios.get(LOAD_SERVICE_URL + "loadAlerts/" + id); 
  }

  findByLoadAlertNumber(loadAlertNumber){ 
    return axios.get(LOAD_SERVICE_URL + "loadAlerts/findByLoadAlertNumber/" + loadAlertNumber); 
  }

  save(loadAlert) {
    return axios.post(LOAD_SERVICE_URL + "loadAlerts", {
      id: loadAlert.id,
      name: loadAlert.name,
      carrierUsername: loadAlert.carrierUsername,
      loadMode: loadAlert.loadMode,
      trailerType: loadAlert.trailerType,
      loadType: loadAlert.loadType,
      pickupAddress: loadAlert.pickupAddress,
      deadHeadOrigin: loadAlert.deadHeadOrigin,
      deadHeadOriginMeasurement: loadAlert.deadHeadOriginMeasurement,
      deliveryAddress: loadAlert.deliveryAddress,
      deadHeadDestination: loadAlert.deadHeadDestination,
      deadHeadDestinationMeasurement: loadAlert.deadHeadDestinationMeasurement,
      pickupDate: loadAlert.pickupDate,
      dropOffDate: loadAlert.dropOffDate,
      length: loadAlert.length,
      lengthMeasurement: loadAlert.lengthMeasurement,
      weight: loadAlert.weight,
      weightMeasurement: loadAlert.weightMeasurement,
      price: loadAlert.price,
      currency: loadAlert.currency
    });
  }
}

export default new LoadAlertService;
