<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
	    </loading>

	    <div class="panel">
	    	<img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>        
    	</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-3"></div>
					<div class="col-md-6">
						<h4>One Time Verification</h4>
						<p class="text-danger">A verification code has been sent to your registered mobile number and email. Please enter that code to complete the process.</p>
						<h4 class="text-danger" v-show="message !== null">{{ message }}</h4>
						<br>
						<form @submit.prevent="verifyOTP">
							<div class="form-group">
								<h4>Verification OTP</h4>
								<input type="number" name="otp" id="otp" v-model="otpCode" class="form-control" placeholder="Enter verification code" required>
							</div>
							<div class="row">
								<div class="col-md-10">
									<button class="btn btn-danger btn-sm" type="button" v-if="resendOTPDelayShow" @click="resendVerifyUserOTP">Resend Code</button>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label>&nbsp;</label>
										<button class="btn btn-success btn-block" type="submit">Verify OTP</button>
									</div>
								</div>
							</div>
						</form>
						<p class="text-danger" v-if="resendMessage !== null">{{ resendMessage }}</p>
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import OneTimePasswordService from "@/services/OneTimePasswordService";
	import loading from "vue-full-loading";
	import UserService from "@/services/UserService";

	export default{
		components: {
			loading,
    	},

		data: function(){
			return {
				show: false,
        		label: "Processing request",
				otpCode: null,
				message: null,
				OTPExpired: null,
				resendOTPDelayShow:false,
				username: JSON.parse(localStorage.getItem("username")),
				resendMessage: null
			}
		},

		mounted(){
			setTimeout(() => {
				this.resendOTPDelayShow = true;
			}, 30000);
		},

		methods: {
			verifyOTP: function(){
				this.show = true;
				OneTimePasswordService.OTPExpired(this.otpCode).then(response => {
					console.log(response);
					if (response.data.body === true) {
						this.message = "Oops! OTP Expired. Please request new OTP";
					}else{
						this.$router.push({ name: 'new-business-profile' });
					}
					this.show = false;
				}).catch(error => {
					if (error.response.status === 500) {
						this.message = error.response.data.message;
					}
					this.show = false;
				});
			},

			resendVerifyUserOTP: function(){
				this.show = true;
				UserService.resendVerifyUserOTP(this.username).then(response => {
					if (response.data.statusCode === 200) {
						this.resendMessage = 'OTP sent again, check your mobile phone.';
						this.resendOTPDelayShow = false;
						// allow resend again
						setTimeout(() => {
							this.resendOTPDelayShow = true;
						}, 30000);
					}
					this.show = false;
				}).catch(error => {
					if (error.response.status === 500) {
						this.message = error.response.data.message;
					}
					this.show = false;
				});
			}
		}
	};
</script>