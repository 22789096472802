<template>
	<div>
		<table class="table table-bordered table-hover table-responsive">
			<thead>
				<th>&nbsp;&nbsp;<input type="checkbox"></th>
				<th>Age</th>
				<th>ID</th>
				<th>Trailer</th>
				<th>Pick Up</th>
				<th>Pick Up Date</th>
				<th>Drop Off</th>
				<th>Trailer</th>
				<th>Weight</th>
				<th>Price</th>
				<th>Actions</th>
			</thead>
			<tbody>
				<tr v-for="load in loads" :key="load.id">
					<td><input type="checkbox"></td>
					<td>1h</td>
					<td>{{ load.id }}</td>
					<td>{{ load.trailerTypeRequired }}</td>
					<td>{{ load.pickupAddress !== null ? load.pickupAddress.province.name: '' }}, {{ load.pickupAddress !== null ? load.pickupAddress.city.name: '' }}</td>
					<td>{{ load.pickupDate }}</td>
					<td>{{ load.dropOffAddress !== null ? load.dropOffAddress.province.name: '' }}, {{ load.dropOffAddress !== null ? load.dropOffAddress.city.name: '' }}</td>
					<td>{{ load.dropOffDate }}</td>
					<td>{{ load.estimatedDistance }} {{ load.estimatedDistanceMeasurement }}</td>
					<td>{{ load.weight }} {{ load.weightMeasurement }}</td>
					<td>
						<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }, query: { source: 'LOAD_DENSITY' }}">
							<button class="btn btn-primary btn-sm">View Load</button>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script type="text/javascript">
	export default{
		components: {

		},

		props: {
			loads: {
				type: Array,
				default: function(){
					return []
				}
			}
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request'
			}
		},

		mounted(){

		},

		methods: {

		},

		computed: {

		}
	}
</script>