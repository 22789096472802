<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <h4>My Bids</h4>
	    <div class="panel">
	    	<div class="panel-body">
			    <table class="table table-responsive table-bordered table-hover">
			    	<thead class="bg-primary" style="background: #000000; color: #ffffff;">
				        <th>Load number</th>
				        <th>Bid number</th>
				        <th>Load price</th>
				        <th>My bid price</th>
				        <th>Pickup</th>
				        <th>Drop off</th>
				        <th>Assigned truck</th>
				        <th>Status</th>
				        <th>Actions</th>
				      </thead>
				      <tbody>
				        <tr v-for="loadBid in loadBids" :key="loadBid.id">
				        	<td>
				        		<router-link :to="{ name: 'view-load', params: { loadNumber: loadBid.load.loadNumber }, query: { source: 'CARRIER_BIDS' }}">
				        			{{ loadBid.load.loadNumber }}
				        		</router-link>
				        	</td>
				        	<td>{{ loadBid.bid.bidNumber }}</td>
				          	<td>{{ loadBid.load.currency }}{{ loadBid.load.price }}</td>
				          	<td>{{ loadBid.load.currency }}{{ loadBid.bid.amount }}</td>
				          	<td>
				          		{{ loadBid.load.pickupAddress !== null ? loadBid.load.pickupAddress.city.name + ' ' + loadBid.load.pickupAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		{{ loadBid.load.dropOffAddress !== null ? loadBid.load.dropOffAddress.city.name + ' ' + loadBid.load.dropOffAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		<router-link :to="{ name: 'trucks/view', params: { truckNumber: loadBid.bid.assignedTruck.truckNumber }, query: { source: 'carrier/bids' }}">
				          			{{ loadBid.bid.assignedTruck !== null ? loadBid.bid.assignedTruck.registrationNumber: '' }}
				          		</router-link>
				          	</td>
				          	<td>
				          		<span class="label label-primary" v-if="loadBid.bid.status === 'PENDING'">Not yet awarded</span>
				          		<span class="label label-success" v-if="loadBid.bid.status === 'AWARDED'">Awarded</span>
				          		<span class="label label-success" v-if="loadBid.bid.status === 'ACCEPTED'">Accepted</span>
				          		<span class="label label-default" v-if="loadBid.bid.status === 'DECLINED'">Declined</span>
				          		<span class="label label-default" v-if="loadBid.bid.status === 'PAID'">Declined</span>
				          	</td>
				          	<td>
				          		<span v-if="loadBid.bid.status === 'AWARDED' && getRoles.includes('CARRIER')">
				          			<router-link :to="{ name: 'carrier/bids/accept-or-decline-offer', params: { loadNumber: loadBid.load.loadNumber, bidNumber: loadBid.bid.bidNumber }}">
				          				<button class="btn btn-primary btn-xs"><span class="glyphicon glyphicon-thumbs-up"></span> Accept or Decline Offer <span class="glyphicon glyphicon-thumbs-down"></span></button>
				          			</router-link>
				          		</span>
				          	</td>
				          </tr>
				      </tbody>
				  </table>
	    	</div>
	    </div>    
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BookingService from "@/services/BookingService.js";
	import LoadBidService from "@/services/LoadBidService.js";

	export default{
		name: 'Bookings',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    bookings: [],
			    loadBids: []
			}
		},

		mounted(){
			this.findByBidCarrierAssociatedUserUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		},

		methods: {
			findByCarrierAssociatedUserUsername: function() {
				this.show = true;
		        BookingService.findByCarrierAssociatedUserUsername(this.getUser.username).then(response => {
		        	this.bookings = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    findByBidCarrierAssociatedUserUsername: function() {
				this.show = true;
		        LoadBidService.findByBidCarrierAssociatedUserUsername(this.getUser.username).then(response => {
		        	this.loadBids = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },
		}
	};
</script>