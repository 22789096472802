<template>
  <div>
    <nav class="navbar navbar-default" role="navigation">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>    
        <a class="navbar-brand">
          <router-link to="/" class="navbar-brand" data-toggle="collapse" data-target=".in">
            <img src="@/assets/logo.png" class="img img-responsive img-rounded center-block logo" align="Freight Board">
          </router-link>
        </a>
      </div>
      <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav navbar-left">
        </ul>
        <ul class="nav navbar-nav navbar-center">
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li v-if="!getRoles.includes('SUPER_ADMIN') || !getRoles.includes('SUPER_ADMIN')">
            <router-link :to="{ name: getRoles.includes('CARRIER') ? 'carrier/bids': 'my-bids' }" data-toggle="collapse" data-target=".in">
              <button class="btn btn-login">Bids/Offers</button>
            </router-link>
          </li>
          <li v-if="!getRoles.includes('SUPER_ADMIN') || !getRoles.includes('SUPER_ADMIN')">
            <router-link :to="{ name: getRoles.includes('CARRIER') ? 'carrier/bookings': 'my-bookings' }" data-toggle="collapse" data-target=".in">
              <button class="btn btn-primary btn-getstarted">Bookings</button>
            </router-link>
          </li>
          <li class="dropdown" v-if="loggedIn">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" style="color: blue;"><h4>{{ companyName !== null ? companyName: currentUser.username }} <span class="caret"></span></h4>
            </a>
            <ul class="dropdown-menu" role="menu">
              <li>
                <router-link :to="{ name: 'userProfile'}" data-toggle="collapse" data-target=".in"><img src="@/assets/images/user.png" height="20" width="20" data-toggle="collapse" data-target=".in"> Account</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'business-profile'}" data-toggle="collapse" data-target=".in"><img src="@/assets/images/user.png" height="20" width="20"> Business Profile</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'my-subscription'}" data-toggle="collapse" data-target=".in"><img src="@/assets/images/subscription.png" height="20" width="20"> Subscription</router-link>
              </li>
              <li><a href="#" v-on:click="logOut" style="color: red"> <img src="@/assets/images/logout.png" height="20" width="20" data-toggle="collapse" data-target=".in"> Sign Out</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService";
  import SubscriptionService from "@/services/SubscriptionService";

  export default {
    data: function(){
      return {
        company: null,
        companyName: null
      }
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },

      currentUser() {
        return this.$store.state.auth.user.user;
      },

      getUser: function() {
          return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    mounted() {
      if (this.loggedIn) {
        this.findByAssociatedUserUsername();
      }
      // check subscription
      if (!this.getRoles.includes('SUPER_ADMIN') || !this.getRoles.includes('ADMIN')) {
        this.subscriptionExpired();
        console.log('check subscription');
      }
    },

    methods: {
      logOut() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      },

      findByAssociatedUserUsername: function() {
        this.show = true;
        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
            if (this.company !== null) {
              this.companyName = this.company.name;
            }
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      subscriptionExpired: function(){
        this.show = true;
        SubscriptionService.subscriptionExpired(this.getUser.username).then(response => {
          this.isSubscriptionExpired = response.data.body;
          if (this.isSubscriptionExpired) {
            this.$router.push({ name: 'subscription-expired'});
          }
          this.show = false;
        }).catch(error => {
          if (error.response.status === 500) {
            this.message = error.response.data.message;
          }
          this.show = false;
        });
      }
    },
  };
</script>

<style type="text/css" scoped>
.navbar{
  background: #ffffff;
}
.navbar-brand {
    padding-top: 2px; /* makes logo fit in navbar*/

}

.test{
  margin: 2px;
}

.btn-circle {
  width: 85px;
  height: 35px;
  text-align: center;
  padding: 6px 0;
  line-height: 1.42;
  border-radius: 15px;
}

  .btn-login {
    /*width: 85px;*/
    /*height: 35px;*/
    text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #000000;
    color: #ffffff;
  }


  .btn-getstarted {
     text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #ffffff; 
    color: #000000;
  }

   .logo {
    display: block;
    height: 55px;
    width: 165px;
    margin-top: 5px
  }

  @media(max-width: 768px){
    .navbar-brand img{
      width: 100px;
      max-height: 60px;
      /*padding-bottom: 20px;*/
      padding-bottom: 20px;
    }
  }
</style>
