import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class SubscriptionService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "subscriptions");
  }

  findById(subscriptionId) {
    return axios.get(GENERIC_SERVICE_URL + "subscriptions/" + subscriptionId);
  }

  save(subscription) {
    return axios.post(GENERIC_SERVICE_URL + "subscriptions", {
      id: subscription.id,
      associatedUserUsername: subscription.associatedUserUsername,
      subscriptionPlanNumber: subscription.subscriptionPlanNumber,
      subscriptionNumber: subscription.subscriptionNumber,
      startDate: subscription.startDate,
      endDate: subscription.endDate
    });
  }

  renew(username) {
    return axios.post(GENERIC_SERVICE_URL + "subscriptions/renew/" + username);
  }

  findByActiveSubscription(username) {
    return axios.get(GENERIC_SERVICE_URL + "subscriptions/findByActiveSubscription/" + username);
  }

  upgrade(username, subscriptionPlanNumber) {
    return axios.post(GENERIC_SERVICE_URL + "subscriptions/upgrade/" + username + "/" + subscriptionPlanNumber);
  }

  downgrade(username, subscriptionPlanNumber) {
    return axios.post(GENERIC_SERVICE_URL + "subscriptions/downgrade/" + username + "/" + subscriptionPlanNumber);
  }

  subscriptionExpired(username) {
    return axios.get(GENERIC_SERVICE_URL + "subscriptions/subscriptionExpired/" + username);
  }

  cancel(username) {
    return axios.post(GENERIC_SERVICE_URL + "subscriptions/cancel/" + username);
  }
}

export default new SubscriptionService;
