<template>
  <div>
    <loading :show="show" :label="label">
        <div class="processing-row" slot="loading-container">
          <div class="text-center">
            <div slot="loading-spinner">
              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
            </div>
            <p class="text-center" slot="loading-text">{{ label }}</p>
          </div>
        </div>
      </loading>
    
    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-11">
            <h4>Trailer Details</h4>
          </div>
          <div class="col-md-1">
            <router-link :to="{ name: 'trucks/view', params: { truckNumber: getTruckNumber }}">
              <button class="btn btn-primary btn-block">Back</button>
            </router-link>
          </div>
        </div>
        <hr>

        <h4>Trailer photos</h4>
        <div class="row" v-if="trailer !== null">
          <div class="col-md-2" v-for="photo in trailer.photos" :key="photo.id">
             <span v-if="ENV === 'development' || ENV === 'test'">
              <img :src="GENERIC_SERVICE_URL + 'photos/getTrailerPhotos/' + photo.id" class="img img-responsive img-rounded img-view">
            </span>
            <span v-if="ENV === 'production'">
              <img :src="AWS_S3_BUCKET + '/' + photo.fileName" class="img img-responsive img-rounded img-view">
            </span>
          </div>
        </div>
        <br>
        
        <ul class="list-group" v-if="trailer !== null">
          <li class="list-group-item">Truck Number: {{ trailer.trailerNumber }}</li>
          <li class="list-group-item">Registration Number (Horse): {{ trailer.registrationNumber }}</li>
          <li class="list-group-item">Trailer Registration Number: {{ trailer.registrationNumber }}</li>
          <li class="list-group-item">Trailer Type: {{ trailer.trailerType }}</li>
          <li class="list-group-item">Load Mode: {{ trailer.loadMode }}</li>
          <li class="list-group-item">Recurring Type: {{ trailer.recurringType }}</li>
          <li class="list-group-item">Desired Rate: {{ trailer.desiredRateCurrency }}{{ trailer.desiredRate }} per {{ trailer.desiredRateMeasurement }}</li>
          <li class="list-group-item">Length: {{ trailer.length }}{{ trailer.lengthMeasurement }}</li>
          <li class="list-group-item">Max Load Size: {{ trailer.maxLoadSize }}{{ trailer.maxLoadSizeMeasurement }}</li>
          <li class="list-group-item">Gross Vehicle Mass(GVM): {{ trailer.grossVehicleMass }}{{ trailer.grossVehicleMassMeasurement }}</li>
          <li class="list-group-item">Net Vehicle Mass (NVM): {{ trailer.netVehicleMass }}{{ trailer.netVehicleMassMeasurement }}</li>
          <li class="list-group-item">Max Height Clearance: {{ trailer.maxHeightClearance }}{{ trailer.maxHeightClearanceMeasurement }}</li>
          <li class="list-group-item">Notes: {{ trailer.description }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import TrailerService from "@/services/TrailerService";
  import loading from "vue-full-loading";
  import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

  export default {
    name: "ViewTrailer",
    components: {
      loading,
    },

    data: function() {
      return {
        trucks: [],
        show: false,
        label: "Processing request",
        trailer: null,
        GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
        AWS_S3_BUCKET: process.env.VUE_APP_AWS_TRAILER_S3_BUCKET,
        ENV: process.env.NODE_ENV
      };
    },

    created(){
      this.findByTrailerNumber();
    },

    computed: {
      getTrailerNumber(){
        return this.$route.params.trailerNumber;
      },

      getTruckNumber(){
        return this.$route.query.truckNumber;
      }
    },

    methods: {
      findByTrailerNumber() {
        this.show = true;
        TrailerService.findByTrailerNumber(this.getTrailerNumber).then(response => {
          this.trailer = response.data.body;
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>

<style type="text/css">
  .img-view {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
</style>