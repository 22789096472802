<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>        
    	</div>

	    <div class="panel">
	    	<div class="panel-body">
		        <div class="row" v-if="paymentResponse !== null">
					<div class="col-md-4"></div>
					<div class="col-md-4">
						<fieldset>
							<legend><h4 class="text-center text-primary">Nelspruit Freight subscription renewal payment details</h4></legend>
							<div class="list-group">
								<li class="list-group-item">
									<p class="text-center" v-show="paymentResponse.status === 'PAID'">
										<img src="@/assets/images/paymentSuccess.png" width="185" height="180">
									</p>
									<p class="text-center" v-show="paymentResponse.status !== 'PAID'">
										<img src="@/assets/images/paymentFail.png" height="190" width="230">
									</p>
								</li>
								<li class="list-group-item">Amount: <span class="pull-right">{{ paymentResponse.currency }}{{ paymentResponse.amount }}</span></li>
								<li class="list-group-item">Status: <span class="pull-right">{{ paymentResponse.status }}</span></li>
								<li class="list-group-item">Reference Number: <span class="pull-right">{{ paymentResponse.debitReference }}</span></li>
								<li class="list-group-item">Description: <span class="pull-right">{{ paymentResponse.description }}</span></li>
								<li class="list-group-item">Date: <span class="pull-right">{{ paymentResponse.createdAt }}</span></li>
							</div>
							<!-- <router-link :to="{ name: 'login' }"> -->
								<button class="btn btn-primary pull-right" @click="proceedLogin">Proceed to login</button>
							<!-- </router-link> -->
						</fieldset>
					</div>
					<div class="col-md-4"></div>
				</div>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import PaymentService from "@/services/PaymentService";
	import InvoiceService from "@/services/InvoiceService";
	import loading from "vue-full-loading";
	import SubscriptionService from "@/services/SubscriptionService";

	export default {
		components: {
			loading
		},

		name: 'SubscriptionPaymentResponse',
		data: function(){
			return {
				show: false,
				label: "Processing Payment",
				gateway: null,
				merchantReference: null,
				paymentResponse: null,
				hash: null,
				activeSubscription: null,
				message: null,
				invoice: null
			}
		},

		mounted(){
			this.findByActiveSubscription();
			this.gateway = this.$route.query.gateway;
			this.merchantReference = this.$route.query.merchantReference;
			this.hash = this.$route.query.hash;
			this.receivePaymentUpdate(this.gateway, this.merchantReference, this.hash);
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
			},
		},

		methods: {
			findByActiveSubscription: function(){
		        this.show = true;
		        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
		        	this.activeSubscription = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		          if (error.response.status === 500) {
		            this.message = error.response.data.message;
		          }
		          this.show = false;
		        });
		    },

			receivePaymentUpdate: function(){
				this.show = true;
				PaymentService.receivePaymentUpdate(this.gateway, this.merchantReference, this.hash).then(response => {
					if (response.status === 200) {
						this.paymentResponse = response.data.body;
						if (this.paymentResponse.status === 'PAID') {
							let paymentPostDto = {
								referenceNumber: response.data.body.referenceNumber
							};
							if (this.activeSubscription.expired) {
								this.renew(paymentPostDto);	
							}
						}else{
							alert('Snap! Subscription payment failed.');
						}
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = true;
				});
			},

			renew: function(paymentPostDto){
				this.show = true;
				SubscriptionService.renew(this.getUser.username).then(response => {
					if (response.data.statusCode === 201) {
						this.invoice = response.data.body.invoice;
						if (this.invoice !== null) {
							this.updateInvoice(paymentPostDto, this.invoice.invoiceNumber);
						}
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			updateInvoice: function(paymentPostDto, invoiceNumber) {
		        this.show = true;
		        let invoice = {
		        	invoiceNumber: invoiceNumber,
		        	payment: paymentPostDto,
		        	payerUsername: this.getUser.username
		        };
		        InvoiceService.save(invoice).then(response => {
		          if (response.data.statusCode == 201) {
		          	console.log('Yay, payment successful');
		          }
		          this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    proceedLogin(){
		    	localStorage.clear();
		    	this.$router.push({ name: 'login'});
		    }
		}
	};
</script>