<template>
  <div>
  	<loading :show="show" :label="label">
		<div class="processing-row" slot="loading-container">
			<div class="text-center">
				<div slot="loading-spinner">
					<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
				</div>
				<p class="text-center" slot="loading-text">{{ label }}</p>
			</div>
		</div>
	</loading>

  	<div class="panel">
  		<div class="panel-body">
  			<h4>Subscription upgrade details</h4>
  			<ul class="list-group">
  				<li class="list-group-item">Subscription Plan : {{ subscriptionType }}</li>
			    <li class="list-group-item">Narration : Subscription Upgrade</li>
			    <li class="list-group-item">Total amount : {{ currency }}{{ price }}</li>
			    <li class="list-group-item">Due date : {{ new Date() }}</li>
		    </ul>
		    <p class="text-danger">You will be redirected to payment gateway and automatically redirected back when payment is processed.</p>
		    <div class="row">
		    	<div class="col-md-10"></div>
		    	<div class="col-md-2">
		    		<form class="form text-center" @submit.prevent="getRedirectUrl">
		    			<button class="btn btn-primary btn-block" type="submit">Proceed to Payment</button>
			       	</form>
		    	</div>
		    </div>
  		</div>
  	</div>
  </div>
</template>

<script type="text/javascript">
	import PaymentService from "@/services/PaymentService.js";
	import loading from "vue-full-loading";

	export default {
		components: {
			loading
		},
	  
	  	data: function() {
	  		return {
	  			show: false,
        		label: "Processing request",
        		subscriptionType: JSON.parse(localStorage.getItem('selectedSubscriptionType')),
	    		subscriptionPlanNumber: JSON.parse(localStorage.getItem('selectedSubscriptionPlanNumber')),
	    		price: JSON.parse(localStorage.getItem('selectedPrice')),
	    		currency: JSON.parse(localStorage.getItem('selectedCurrency'))
	    	};
	  },

	  computed: {
	  	getUser: function() {
	    	return this.$store.state.auth.user.user;
	    },

	    getSubscriptionPlanNumber(){
	    	return this.$route.params.subscriptionPlanNumber;
	    }
	  },

	  mounted: function() {
        console.log('selectedSubscriptionPlanNumber: ', JSON.parse(localStorage.getItem('selectedSubscriptionPlanNumber')));
        console.log('selectedPrice: ', JSON.parse(localStorage.getItem('selectedPrice')));
        console.log('selectedCurrency: ', JSON.parse(localStorage.getItem('selectedCurrency')));
	  },

	  methods: {
	    getRedirectUrl: function(){
	        this.show = true;
	        let paymentPostDto = {
	            uid: this.getUuid,
	            payerUsername: this.getUser.username,
	            amount: this.price,
	            currency: this.currency,
	            description: 'SUBSCRIPTION',
	            method: "ONLINE",
	            gateway: "PAYNOW",
	            paymentType: "SUB_UPGRADE"
	        };
	        PaymentService.getRedirectUrl(paymentPostDto).then(response => {
	          if (response.status === 201) {
	            if (response.data.body !== null) {
	              window.location.replace(response.data.body);
	            }
	          }
	          this.show = false;
	        }).catch(error => {
	          console.log(error);
	          this.show = false;
	        });
	      this.show = false;
	    }
	  }
	};
</script>
