<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<h4>Are you sure you want to award this carrier this load bid {{ getBidNumber }}?</h4>
		<p>After winning the bid they'll have 15 minutes to accept the offer. After which will be automatically declined.</p>
		<hr>
		
		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Aggree to terms and conditions</label><br>
				<input type="checkbox" name="" required>
			</div>
			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="form">
						<label>&nbsp;</label><br>
						<button class="btn btn-primary btn-block" type="submit">Submit</button>
					</div>
				</div>
			</div>
		</form>

		<br>
		<p><i>N.B Submitting this carrier means you agree to the terms and conditions that bind the business transaction between you and the carrier.</i></p>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BidService from "@/services/BidService";

	export default{
		name: 'AwardShipperLoad',
		components: {
			loading,
		},
		data: function(){
			return {
				show: false,
				label: "Processing request..."
			}
		},

		computed: {
			getBidNumber(){
				return this.$route.params.bidNumber;
			}
		},

		methods: {
			save: function(){
				this.show = true;
				let bidPostDto = {
					bidNumber: this.getBidNumber
				};
				console.log(bidPostDto);
				BidService.award(bidPostDto).then(response => {
					if (response.data.statusCode === 201){
						this.$router.push({ name: 'shipper/loads/leads' });
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>