<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <router-link :to="{ name: 'settings' }" replace>
      <button class="btn btn-primary">Back</button>
    </router-link>

    <router-link :to="{ name: 'new-financial-institution' }">
      <button class="btn btn-success pull-right">New financial institution <span class="glyphicon glyphicon-plus"></span></button>
    </router-link>
    <br><br>

    <h4>Financial Institutions / Mobile Money</h4>
    <div class="panel">
      <div class="panel-body">
        <table class="table table-responsive table-bordered">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="financialInstitution in financialInstitutions" :key="financialInstitution.id">
              <td>{{ financialInstitution.id }}</td>
              <td>{{ financialInstitution.name }}</td>
              <td>{{ financialInstitution.description }}</td>
              <td>
                <span class="label label-primary">{{ financialInstitution.status }}</span>
              </td>
              <td>
                <router-link :to="{ name: 'view-financial-institution', params: { financialInstitutionId: financialInstitution.id } }">
                  <button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService";
  import loading from "vue-full-loading";

  export default {
    name: "FinancialInstitutionList",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        financialInstitutions: []
      };
    },

    mounted() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        CompanyService.findAll().then(response => {
          this.show = false;
          this.financialInstitutions = response.data.body.filter(financialInstitution => {
            return financialInstitution.type === 'FINANCIAL_INSTITUTION'
          });
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>
