<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<form class="form">
					<div class="row">
						<div class="col-md-2">
								<div class="form-group">
									<label>Load type</label>
									<select class="form-control" name="loadType" v-model="LS_type">
										<option value="" disabled>Select option</option>
										<option value="NORMAL">Normal</option>
										<option value="ABNORMAL">Abnormal</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Load mode</label>
									<select class="form-control" name="loadMode" v-model="LS_loadMode">
										<option value="" disabled>Select option</option>
										<option value="FTL">Full truckload - FTL</option>
										<option value="LTL">Less than truckload - LTL</option>
										<option value="INTERMODAL">Intermodal</option>
										<option value="POWER_ONLY">Power-Only</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Trailer required</label>
									<select class="form-control" name="trailerTypeRequired" v-model="LS_trailerTypeRequired">
										<option value="" disabled>All trailers</option>
										<option value="FLATBED">Flatbed</option>
										<option value="VAN">Van</option>
										<option value="REEFER">Reefer</option>
										<option value="STEP_DECK">Step Deck</option>
										<option value="POWER_ONLY">Power Only</option>
										<option value="AUTO_CARRIER">Auto Carrier</option>
										<option value="B_TRAIN">B Train</option>
										<option value="BOX_TRUCK">Box Truck</option>
										<option value="CONESTOGA">Consetoga</option>
										<option value="CONTAINERS">Containers</option>
										<option value="DOUBLE_DROP">Double Drop</option>
										<option value="DRY_BULK">Dry Bulk</option>
										<option value="DUMP_TRAILER">Dump Trailer</option>
										<option value="HOPPER_BOTTOM">Hooper Bottom</option>
										<option value="HOTSHOT">Hotshot</option>
										<option value="LOWBOY">Lowboy</option>
										<option value="TANKER">Tanker</option>
									</select>
								</div>
							</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Pick Up Date</label>
								<input type="date" name="pickupDate" v-model="LS_pickupDate" class="form-control">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Drop Off Date</label>
								<input type="date" name="dropOffDate" v-model="LS_dropOffDate" class="form-control">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Pick Up</label>
								<select class="form-control" v-model="LS_pickUpCityId">
									<option value="" selected>All cities</option>
									<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Drop Off</label>
								<select class="form-control" v-model="LS_dropOffCityId">
									<option value="" selected>All cities</option>
									<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Weight (tonne)</label>
								<input type="number" name="weight" v-model="LS_weight" class="form-control" placeholder="Enter weight">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Price</label>
								<input type="number" name="price" v-model="LS_price" class="form-control" placeholder="Enter price">
							</div>
						</div>
						<div class="col-md-2"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-block btn-primary" type="button" @click="search()">Search</button>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-block" type="button" @click="clearFilter()" style="background-color: #000000; color: #ffffff;">Reset filter</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="row">
			<div class="col-md-3">
				<p>{{ loads.length }} loads available</p>
				<p>Find the best offers for you below</p>
				<p>{{ new Date().toDateString() }}</p>
			</div>
			<div class="col-md-6"></div>
			<div class="col-md-3">
				<select class="form-control" v-model="itemsPerPage">
					<option value="10">Items per page</option>
					<option value="20">20</option>
					<option value="30">30</option>
					<option value="40">40</option>
					<option value="50">50</option>
				</select>
			</div>
		</div>

		<div class="panel" v-for="load in paginatedLoads" :key="load.id">
			<div class="panel-body">
				<div class="row">
					<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }, query: { source: 'LOAD_SEARCH' }}">
						<div class="col-md-3">
							<b><i><span class="label label-primary">PICKUP</span></i></b>
							<span v-if="load.pickupAddress !== null">
								<span v-if="load.pickupAddress.province !== null && load.pickupAddress.city !== null">
									<p>{{ load.pickupAddress !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress !== null ? load.pickupAddress.city.name: "Not available" }} on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
									</p>
								</span>
								<span v-else>
									<p><span class="text-danger">Pickup n/a</span> on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
									</p>
								</span>
							</span>
							<p><span class="badge">{{ load.trailerTypeRequired }}</span> required for <span class="badge">{{ load.loadMode }}</span></p>
						</div>
						<div class="col-md-1">
							<br>
							<img src="@/assets/images/right-arrow.png" height="30" width="30">
						</div>
						<div class="col-md-3">
							<span v-if="load.dropOffAddress !== null">
								<b><i><span class="label label-warning">DELIVERY</span></i></b>&nbsp;
								<span class="badge">Estimated distance {{ load.estimatedDistance > 0 ? load.estimatedDistance / 1000 : 0 }} km</span> 
								<span v-if="load.pickupAddress !== null">
									<span v-if="load.pickupAddress.province !== null && load.pickupAddress.city !== null">
										<p>{{ load.dropOffAddress !== null ? load.dropOffAddress.province.name: "Not available" }}, {{ load.dropOffAddress !== null ? load.dropOffAddress.city.name: "Not available" }}
											{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}
										</p>
									</span>
									<span v-else>
										<p><span class="text-danger">Drop off n/a</span> on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
										</p>
									</span>
								</span>
								<p>{{ load.weight }} {{ load.weightMeasurement }} of {{ load.commodity }}</p>
							</span>
						</div>
					</router-link>
					<div class="col-md-2">
						<br>
						<p>{{ load.currency }}${{ load.price }}</p>
						<p><span class="badge">{{ load.type }} load</span></p>
					</div>
					<div class="col-md-1" v-show="getRoles.includes('CARRIER')">
						<br><br>
						<router-link :to="{ name: 'bid-load', params: { loadNumber: load.loadNumber }}">
							<button class="btn btn-danger btn-block">Bid <span class="glyphicon glyphicon-ok-circle"></span></button>
						</router-link>
					</div>
					<div class="col-md-1" v-show="getRoles.includes('CARRIER')">
						<br><br>
						<router-link :to="{ name: 'book-load', params: { loadNumber: load.loadNumber }}">
							<button class="btn btn-primary btn-block">Book <span class="glyphicon glyphicon-ok-circle"></span></button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-if="totalPages > 1">
			<div class="col-md-7"></div>
			<div class="col-md-3 pull-right">
				<div class="panel">
					<div class="panel-body">
						<button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="LS_currentPage === 1" @click="prevPage"></button>
						&nbsp;<span>Page {{ LS_currentPage }} of {{ totalPages }}</span>&nbsp;
						<button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="LS_currentPage === totalPages" @click="nextPage"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import CityService from "@/services/CityService";

	export default{
		name: 'SearchLoads',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				loads: [],
				cities: [],
				LS_currentPage: JSON.parse(localStorage.getItem('LS_currentPage')) !== null ? JSON.parse(localStorage.getItem('LS_currentPage')): 1,
				itemsPerPage: 10,
				LS_type: null,
				LS_loadMode: null,
				LS_trailerTypeRequired: null,
				LS_pickupDate: null,
				LS_dropOffDate: null,
				LS_pickUpCityId: null,
				LS_dropOffCityId: null,
				LS_weight: null,
				LS_price: null,
				filteredLoads: [],
				pickUpProvinceId: null,
			}
		},

		mounted(){
			this.LS_type = JSON.parse(localStorage.getItem('LS_type'));
			this.LS_loadMode = JSON.parse(localStorage.getItem('LS_loadMode'));
			this.LS_trailerTypeRequired = JSON.parse(localStorage.getItem('LS_trailerTypeRequired'));
			this.LS_pickupDate = JSON.parse(localStorage.getItem('LS_pickupDate'));
			this.LS_dropOffDate = JSON.parse(localStorage.getItem('LS_dropOffDate'));
			this.LS_pickUpCityId = JSON.parse(localStorage.getItem('LS_pickUpCityId'));
			this.LS_dropOffCityId = JSON.parse(localStorage.getItem('LS_dropOffCityId'));
			this.LS_weight = JSON.parse(localStorage.getItem('LS_weight'));
			this.LS_price = JSON.parse(localStorage.getItem('LS_price'));
			if (this.getPickUpProvinceId !== null || this.getPickUpProvinceId !== undefined) {
				localStorage.setItem('pickUpProvinceId', JSON.stringify(this.getPickUpProvinceId));
				this.pickUpProvinceId = this.getPickUpProvinceId;
			}else{
				this.pickUpProvinceId = JSON.parse(localStorage.getItem('pickUpProvinceId'));
			}
			this.findAllCities();
			this.searchLoads();
		},

		methods: {
			findAllCities: function(){
				this.show = true;
				CityService.findAll().then(response => {
					this.cities = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			searchLoads: function(){
				this.show = true;
				LoadService.searchLoads().then(response => {
					this.loads = response.data.body;
					this.search();
		        }).catch(error => {
		        	console.log(error);
		        	this.show = true;
		        });
			},

			prevPage(){
		    	if (this.LS_currentPage > 1) {
		    		const LS_currentPage = --this.LS_currentPage;
		    		localStorage.setItem('LS_currentPage', JSON.stringify(LS_currentPage));
		    	}
		    },

		    nextPage(){
		    	if (this.LS_currentPage < this.totalPages) {
		    		const LS_currentPage = ++this.LS_currentPage;
		    		localStorage.setItem('LS_currentPage', JSON.stringify(LS_currentPage));
		    	}
		    },

		    clearFilter(){
		    	localStorage.removeItem('LS_type');
		    	localStorage.removeItem('LS_loadMode');
		    	localStorage.removeItem('LS_trailerTypeRequired');
		    	localStorage.removeItem('LS_pickupDate');
		    	localStorage.removeItem('LS_dropOffDate');
		    	localStorage.removeItem('LS_pickUpCityId');
		    	localStorage.removeItem('LS_dropOffCityId');
		    	localStorage.removeItem('LS_weight');
		    	localStorage.removeItem('LS_price');
		    	localStorage.removeItem('LS_pickUpProvinceId');
		    	localStorage.removeItem('LS_currentPage');
				localStorage.removeItem('LS_searchFields');
		    	window.location.reload();
		    },

		    search(){
		    	this.show = true;
		    	let searchFields = JSON.parse(localStorage.getItem('LS_searchFields') || '[]');
		    	if (this.LS_type !== null && this.LS_type !== undefined) {
				    let searchField = {
				    	'fieldName': 'LS_type',
				    	'value': this.LS_type
				    };
				    const index = searchFields.findIndex((field) => field.fieldName === 'LS_type');
				    if (index === -1) {
				    	searchFields.push(searchField);
				    }else{
				    	searchFields[index] = searchField;
				    }
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LS_loadMode !== null && this.LS_loadMode !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LS_loadMode',
		    			'value': this.LS_loadMode
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LS_loadMode');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LS_trailerTypeRequired !== null && this.LS_trailerTypeRequired !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LS_trailerTypeRequired',
		    			'value': this.LS_trailerTypeRequired
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LS_trailerTypeRequired');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LS_pickupDate !== null && this.LS_pickupDate !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LS_pickupDate',
		    			'value': this.LS_pickupDate
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LS_pickupDate');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LS_dropOffDate !== null && this.LS_dropOffDate !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LS_dropOffDate',
		    			'value': this.LS_dropOffDate
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LS_dropOffDate');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LS_pickUpCityId !== null && this.LS_pickUpCityId !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LS_pickUpCityId',
		    			'value': this.LS_pickUpCityId
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LS_pickUpCityId');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LS_dropOffCityId !== null && this.LS_dropOffCityId !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LS_dropOffCityId',
		    			'value': this.LS_dropOffCityId
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LS_dropOffCityId');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LS_weight !== null && this.LS_weight !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LS_weight',
		    			'value': this.LS_weight
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LS_weight');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LS_price !== null && this.LS_price !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LS_price',
		    			'value': this.LS_price
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LS_price');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.pickUpProvinceId !== null && this.pickUpProvinceId !== undefined) {
				    let searchField = {
				    	'fieldName': 'pickUpProvinceId',
				    	'value': this.pickUpProvinceId
				    };
				    const index = searchFields.findIndex((field) => field.fieldName === 'pickUpProvinceId');
				    if (index === -1) {
				    	searchFields.push(searchField);
				    }else{
				    	searchFields[index] = searchField;
				    }
		    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (searchFields.length > 0) {
		    		let theLoads = this.loads;
		    		for(let i = 0; i < searchFields.length; i++){
		    			if (searchFields[i].fieldName === 'LS_type'){
		    				theLoads = theLoads.filter(load => {
		    					return load.type === searchFields[i].value;
		    				});
		    				localStorage.setItem('LS_type', JSON.stringify(this.LS_type));
		    			}
		    			if (searchFields[i].fieldName === 'LS_loadMode'){
		    				theLoads = theLoads.filter(load => {
		    					return load.loadMode === searchFields[i].value;
		    				});
		    				localStorage.setItem('LS_loadMode', JSON.stringify(this.LS_loadMode));
		    			}
		    			if (searchFields[i].fieldName === 'LS_trailerTypeRequired'){
		    				theLoads = theLoads.filter(load => {
		    					return load.trailerTypeRequired === searchFields[i].value;
		    				});
		    				localStorage.setItem('LS_trailerTypeRequired', JSON.stringify(this.LS_trailerTypeRequired));
		    			}
		    			if (searchFields[i].fieldName === 'LS_pickupDate'){
		    				theLoads = theLoads.filter(load => {
		    					return load.pickupDate === searchFields[i].value;
		    				});
		    				localStorage.setItem('LS_pickupDate', JSON.stringify(this.LS_pickupDate));
		    			}
		    			if (searchFields[i].fieldName === 'LS_dropOffDate'){
		    				theLoads = theLoads.filter(load => {
		    					return load.dropOffDate === searchFields[i].value;
		    				});
		    				localStorage.setItem('LS_dropOffDate', JSON.stringify(this.LS_dropOffDate));
		    			}
		    			if (searchFields[i].fieldName === 'LS_pickUpCityId'){
		    				theLoads = theLoads.filter(load => {
		    					return load.pickupAddress.city.id === searchFields[i].value;
		    				});
		    				localStorage.setItem('LS_pickUpCityId', JSON.stringify(this.LS_pickUpCityId));
		    			}
		    			if (searchFields[i].fieldName === 'LS_dropOffCityId'){
		    				theLoads = theLoads.filter(load => {
		    					return load.dropOffAddress.city.id === searchFields[i].value;
		    				});
		    				localStorage.setItem('LS_dropOffCityId', JSON.stringify(this.LS_dropOffCityId));
		    			}
		    			if (searchFields[i].fieldName === 'LS_weight'){
		    				theLoads = theLoads.filter(load => {
		    					return load.weight === parseFloat(searchFields[i].value);
		    				});
		    				localStorage.setItem('LS_weight', JSON.stringify(this.LS_weight));
		    			}
		    			if (searchFields[i].fieldName === 'LS_price'){
		    				theLoads = theLoads.filter(load => {
		    					return load.price === parseFloat(searchFields[i].value);
		    				});
		    				localStorage.setItem('LS_price', JSON.stringify(this.LS_price));
		    			}

		    			if (this.currentRouteName === 'dashboard-search-loads') {
		    				if (searchFields[i].fieldName === 'pickUpProvinceId'){
			    				theLoads = theLoads.filter(load => {
			    					return load.pickupAddress.province.id === searchFields[i].value;
			    				});
			    				localStorage.setItem('pickUpProvinceId', JSON.stringify(this.pickUpProvinceId));
			    			}
		    			}
		    			this.filteredLoads = theLoads;
		    		}
		    	}else{
		    		this.filteredLoads = this.loads;
		    	}
		    	this.show = false;
		    }
		},

		computed: {
			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },

		    totalPages(){
		    	return Math.ceil(this.filteredLoads.length / this.itemsPerPage);
		    },

		    paginatedLoads(){
				const startIndex = (this.LS_currentPage - 1) * this.itemsPerPage;
				return this.filteredLoads.slice(startIndex, startIndex + this.itemsPerPage);
			},

			getPickUpProvinceId(){
				return this.$route.query.pickUpProvinceId;
			},

			currentRouteName() {
				return this.$route.name;
    		}
		}
	};
</script>