<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <router-link :to="{ name: 'subscriptions/new' }" replace>
        <button class="btn btn-success pull-right">New subscription <span class="glyphicon glyphicon-plus"></span></button>
      </router-link>
      <br><br>

      <h4>All subscriptions</h4>
      <table class="table table-responsive table-bordered">
        <thead class="bg-primary">
          <th>ID</th>
          <th>Customer</th>
          <th>Subscription Number</th>
          <th>Subscription Plan</th>
          <th>Period</th>
          <th>Expired</th>
          <th>Enabled</th>
          <th>Description</th>
          <th>Invoice</th>
          <th>Status</th>
          <th>Actions</th>
        </thead>
        <tbody>
          <tr v-for="subscription in subscriptions" :key="subscription.id">
            <td>{{ subscription.id }}</td>
            <td>{{ subscription.customer !== null ? subscription.customer.name: '' }}</td>
            <td>{{ subscription.subscriptionNumber }}</td>
            <td>{{ subscription.subscriptionPlan !== null ? subscription.subscriptionPlan.name: '' }} {{ subscription.subscriptionPlan !== null ? subscription.subscriptionPlan.subscriptionType: '' }}</td>
            <td>{{ subscription.startDate }}  to {{ subscription.endDate }}</td>
            <td>{{ subscription.expired }}</td>
            <td>{{ subscription.enabled }}</td>
            <td>{{ subscription.description }}</td>
            <td>
              <span class="label label-success" v-if="subscription.status == 'ACTIVE'">Active</span>
              <span class="label label-default" v-if="subscription.status == 'INACTIVE'">Inactive</span>
              <span class="label label-danger" v-if="subscription.status == 'DELETED'">Deleted</span>
            </td>
            <td>SUBSCRIPTION</td>
            <td>
              <router-link :to="{ name: 'subscriptions/view', params: { subscriptionNumber: subscription.subscriptionNumber } }">
                <button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import SubscriptionService from "@/services/SubscriptionService";
  import loading from "vue-full-loading";

  export default {
    name: "SubscriptionList",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing request",
        subscriptions: []
      };
    },

    created() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        SubscriptionService.findAll().then(response => {
          this.subscriptions = response.data.body;
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>
