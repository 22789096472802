<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<div class="row">
	    			<div class="col-md-10">
	    				<h4>New subscription plan</h4>
	    			</div>
	    			<div class="col-md-2">
	    				<router-link :to="{ name: 'subscription-plans'}">
	    					<button class="btn btn-default pull-right">Close</button>
	    				</router-link>
	    			</div>
	    		</div>
	    		<br>
				<form class="form" @submit.prevent="save">
					<div class="form-group">
						<label>Name</label>
						<input type="text" name="name" class="form-control" v-model="subscriptionPlan.name" placeholder="Enter name">
					</div>
					<div class="form-group">
						<label>Title</label>
						<input type="text" name="title" class="form-control" v-model="subscriptionPlan.title" placeholder="Enter title">
					</div>
					<div class="form-group">
						<label>Status</label>
						<select class="form-control" v-model="subscriptionPlan.status">
							<option value="" disabled>Select option</option>
							<option value="ACTIVE">Active</option>
							<option value="INACTIVE">In active</option>
						</select>
					</div>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-primary btn-block">Submit</button>
							</div>
						</div>
					</div>
				</form>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import SubscriptionPlanService from "@/services/SubscriptionPlanService";
	import SubscriptionPlan from "@/models/SubscriptionPlan";

	export default{
		name: 'NewSubscriptionPlanFeature',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				subscriptionPlan: new SubscriptionPlan
			}
		},

		created(){
			
		},

		methods: {
			save: function(){
				this.show = true;
				SubscriptionPlanService.save(this.subscriptionPlan).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'subscription-plans' });
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>