<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>
	    
		<div v-if="load !== null">
			<div class="row" v-show="load.pickupAddress !== null">
				<div class="col-md-6">
					<div class="panel">
						<div class="panel-body">
							<div class="row">
							   	<div class="col-md-6">
							   		<span v-if="getRoles.includes('SHIPPER') || getRoles.includes('BROKER')">
							   			<router-link :to="{ name: 'my-loads' }">
							   				<button class="btn btn-primary">Back</button>
							   			</router-link>
							   		</span>

							   		<span v-if="getRoles.includes('CARRIER')">
									   	<span v-if="getSource === 'LOAD_DETAIL'">
									   		<router-link :to="{ name: 'view-load', params: { loadNumber: getSourceLoadNumber }}">
										   		<button class="btn btn-primary">Back</button>
										    </router-link>
									   	</span>
									   	<span v-if="getSource === 'PRIVATE_LOAD'">
									   		<router-link :to="{ name: 'private-loads' }">
										   		<button class="btn btn-primary">Back</button>
										    </router-link>
									   	</span>
									   	<span v-if="getSource === 'LOAD_ALERT'">
									   		<router-link :to="{ name: 'matched-loads', params: { loadAlertNumber: getLoadAlertNumber }}">
										   		<button class="btn btn-primary">Back</button>
										    </router-link>
									   	</span>
									</span>
									<br>
								</div>
								<div class="col-md-6">
									<h4>{{ load.owner !== null ? load.owner.name: '' }}</h4>
									<div class="row">
										<div class="col-md-2" v-show="getRoles.includes('SHIPPER') || getRoles.includes('BROKER')">
											<router-link :to="{ name: 'my-bookings' }">
												<div v-if="load.bookings">
													<h4>
														<span class="label label-primary">{{ load.bookings !== null ? load.bookings.length: 0 }} Bookings</span>
													</h4>
												</div>
											</router-link>
										</div>
										<div class="col-md-2"></div>
										<div class="col-md-2" v-show="getRoles.includes('SHIPPER') || getRoles.includes('BROKER')">
											<router-link :to="{ name: 'my-bookings' }">
												<div v-if="load.bids">
													<h4>
														<span class="label label-warning">{{ load.bids !== null ? load.bids.length: 0 }} Bids</span>
													</h4>
												</div>
											</router-link>
										</div>
									</div>
								</div>
							</div>
							<br><br>

							<h4>Load Information</h4>
							<div class="row">
								<div class="col-md-6" v-if="load !== null">
									<span class="label label-primary">PICKUP</span>
									<h4>{{ load.pickupAddress.province !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress.city !== null ? load.pickupAddress.city.name: "Not available" }}</h4>
									<p>FACILITY ADDRESS</p>
									<p>{{ load.pickupAddress.physicalAddress !== null ? load.pickupAddress.physicalAddress: "Not available" }}</p>
								</div>
								<div class="col-md-2">
									<img src="@/assets/images/right-arrow.png" height="30" width="30">
								</div>
								<div class="col-md-4">
									<h4>PICKUP DATE</h4>
									<p>{{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}</p>
									<p>AVG Loading TIME - {{ load.estimatedLoadingTime }}{{ load.estimatedLoadingTimeMeasurement }}</p>
								</div>
							</div>
							<hr>

							<div class="row">
								<div class="col-md-6" v-if="load.dropOffAddress !== null">
									<span class="label label-warning">DELIVERY</span>
									<h4>{{ load.dropOffAddress.province !== null ? load.dropOffAddress.province.name: "Not available" }}, {{ load.dropOffAddress.city !== null ? load.dropOffAddress.city.name: "Not available" }}</h4>
									<p>FACILITY ADDRESS</p>
									<p>{{ load.dropOffAddress.physicalAddress !== null ? load.dropOffAddress.physicalAddress: "Not available" }}</p>
								</div>
								<div class="col-md-2">
									<img src="@/assets/images/right-arrow.png" height="30" width="30">
								</div>
								<div class="col-md-4">
									<p>DELIVERY DATE</p>
									<p>{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}</p>
									<p>AVG Delivery TIME - {{ load.estimatedDeliveryTime }}{{ load.estimatedDeliveryTimeMeasurement }}</p>
								</div>
							</div>
							<hr>

							<div class="row">
								<div class="col-md-6">
									<h4>Trailer type required</h4>
									<p><span class="badge">{{ load.trailerTypeRequired }}</span></p>
									<h4>Load description</h4>
									<p>{{ load.description }}</p>
								</div>
								<div class="col-md-3">
									<h4>Load Mode</h4>
									<p><span class="badge">{{ load.loadMode }}</span></p>
								</div>
								<div class="col-md-3">
									<h4>Hazmat</h4>
									<p><span class="badge">{{ load.hazmat }}</span></p>
								</div>
							</div>
						</div>
					</div>

					<div class="panel">
						<div class="panel-body">
							<div class="row">
								<div class="col-md-3">
									<h4>{{ load.currency }}${{ load.price }}</h4>
									<p><span class="badge">{{ load.type }} load</span></p>
									<p>Weight: {{ load.weight }} {{ load.weightMeasurement }}</p>
								</div>
								<div class="col-md-2">
									<h4>DISTANCE <br><br> <span class="badge">{{ load.estimatedDistance/1000 }} km</span></h4>
								</div>
								<div class="col-md-2" v-show="getRoles.includes('CARRIER') && !carrierAlreadyBidLoad && !carrierAlreadyBookedLoad">
									<router-link :to="{ name: 'bid-load', params: { loadNumber: load.loadNumber }}">
										<button class="btn btn-default">Bid Load <span class="glyphicon glyphicon-ok-circle"></span></button>
									</router-link>
								</div>
								<div class="col-md-2" v-show="getRoles.includes('CARRIER') && carrierAlreadyBidLoad">
									<p><span class="label label-warning">Already bid</span></p>
								</div>
								<div class="col-md-1"></div>
								<div class="col-md-2" v-show="getRoles.includes('CARRIER') && !carrierAlreadyBidLoad && !carrierAlreadyBookedLoad">
									<router-link :to="{ name: 'book-load', params: { loadNumber: load.loadNumber }}">
										<button class="btn btn-primary">Book Load <span class="glyphicon glyphicon-ok-circle"></span></button>
									</router-link>
								</div>
								<div class="col-md-2" v-show="getRoles.includes('CARRIER') && carrierAlreadyBookedLoad">
									<p><span class="label label-warning">Already booked</span></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="panel">
						<div class="panel-body">
							<p>Map View of load destination from source</p>
							<ShowDistanceComponent
								:pickupAddressLatitude="load.pickupAddress.latitude"
								:pickupAddressLongitude="load.pickupAddress.longitude"
								:destinationAddressLatitude="load.dropOffAddress.latitude"
								:destinationAddressLongitude="load.dropOffAddress.longitude"/>
						</div>
					</div>
				</div>
			</div>
			<div v-if="getRoles.includes('CARRIER')">
				<div class="panel">
					<div class="panel-body">
						<p>Back loads are great for maximizing your truck or fleet and increase your carrier profits.</p>
						<div class="row">
							<div class="col-md-6">
								<h4>Suggested back haul within the delivery vicinity of this load</h4>
							</div>
							<div class="col-md-3">
								<h4>Showing loads with:</h4>
							</div>
							<div class="col-md-3">
								<select class="form-control" @change="findReturnLoads(load.dropOffAddress.city.name, load.loadNumber, load.pickupAddress.city.name)" v-model="returnLoadsTriggered">
									<option value="">Select option</option>
									<option value="FROM_DELIVERY">From delivery</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<BackHaulComponent :loads="backHaul" :returnLoadsTriggered="returnLoadsTriggered"/>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import ShowDistanceComponent from "@/views/distance/ShowDistanceComponent";
	import BackHaulComponent from "@/views/loads/BackHaulComponent";
	import LoadBookingService from "@/services/LoadBookingService";
	import LoadBidService from "@/services/LoadBidService";
	import CompanyService from "@/services/CompanyService";

	export default{
		name: 'ViewLoad',
		components: {
			loading,
			ShowDistanceComponent,
			BackHaulComponent
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				load: null,
				backHaul: [],
				carrierAlreadyBookedLoad: false,
				carrierAlreadyBidLoad: false,
				returnLoadsTriggered: null
			}
		},

		mounted(){
			this.getLoadByLoadNumber();
			this.findByAssociatedUserUsername();
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},

			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },

		    getSource(){
		    	return this.$route.query.source;
		    },

		    getLoadAlertNumber(){
		    	return this.$route.query.loadAlertNumber;
		    },

		    getSourceLoadNumber(){
		    	return this.$route.query.sourceLoadNumber;
		    }
		},

		methods: {
			getLoadByLoadNumber: function(){
				this.show = true;
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			findByAssociatedUserUsername: function(){
				this.show = true;
				CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
					this.company = response.data.body;
					if (this.company !== null) {
						this.checkCarrierAlreadyBookedLoad();
						this.checkCarrierAlreadyBidLoad();
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			findReturnLoads: function(city, loadNumber, sourceCity){
				this.show = true;
				LoadService.findReturnLoads(city, loadNumber, sourceCity).then(response => {
					this.backHaul = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			checkCarrierAlreadyBookedLoad: function(){
				this.show = true;
				LoadBookingService.checkCarrierAlreadyBookedLoad(this.getLoadNumber, this.company.id).then(response => {
					this.carrierAlreadyBookedLoad = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			checkCarrierAlreadyBidLoad: function(){
				this.show = true;
				LoadBidService.checkCarrierAlreadyBidLoad(this.getLoadNumber, this.company.id).then(response => {
					this.carrierAlreadyBidLoad = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			}
		}
	};
</script>