<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>        
    </div>

    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <h3 class="text-center">Change your password</h3>
        <router-link to="login">
          <h4 class="text-center" v-on:click="changeAccessType('login')">
            <a href="#">Back to Sign In</a>
          </h4>
        </router-link>
        <div class="panel">
          <div class="panel-header"><div class="header-title"></div></div>
          <div class="panel-body">
          	<h4 class="text-danger" v-if="message !== null">{{ message }}</h4>
            <form class="form" @submit.prevent="changePassword">
            	<div class="row">
            		<div class="col-md-5">
            			<div class="form-group">
		                <label>New Password</label>
		                <input :type="fieldType" v-model="user.password" placeholder="Enter new password" class="form-control" autocomplete="on" required/>
		              </div>	
            		</div>
            		<div class="col-md-5">
            			<div class="form-group">
			                <label>Confirm Password</label>
			                <input :type="fieldType" v-model="user.confirmPassword" placeholder="Confirm new password" class="form-control" autocomplete="on" required/>
			              </div>	
            		</div>
            		<div class="col-md-2">
            			<div class="form-group">
			                <label>&nbsp;</label><br>
			                <button class="btn btn-primary pull-right" @click="showPasswords" type="button">{{ btnText }}</button>
			              </div>
            		</div>
            	</div>
            	<div class="row">
            		<div class="col-md-10"></div>
            		<div class="col-md-2">
            			 <div class="form-group">
		            		<label>&nbsp;</label>
		                	<button class="btn btn-success btn-block pull-right">Submit</button>
		              	</div>
            		</div>
            	</div>
              	<p>
              		<a href="#">Security & Privacy</a>&nbsp;&nbsp;&nbsp;
                	<a href="#">Terms of Use&nbsp;&nbsp;&nbsp;</a>
                	<a href="#">Contact Support</a>
              	</p>
              </form>
          </div>
      </div>
  </div>
  <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import PasswordResetService from "@/services/PasswordResetService";
  import User from "@/models/User";

  export default{
    components: {
      loading
    },

    name: 'ChangePassword',
    data: function(){
      return {
        user: new User,
        show: false,
        label: "Processing request",
        btnText: "Show passwords",
        fieldType: 'password',
        token: this.$route.query.token,
        message: null
      }
    },

    methods: {
    	showPasswords: function(){
        if (this.fieldType === 'password') {
          this.btnText = "Hide passwords";
          this.fieldType = 'text';
        }else{
          this.fieldType = 'password';
          this.btnText = "Show passwords";
        }
      },

      changePassword: function(){
      	this.show = true;
        PasswordResetService.changePassword(this.token, this.user).then(response => {
          if (response.data.statusCode === 201) {
            this.$router.push({ name: "login" });
          }
          this.show = true;
        }).catch(error => {
          console.log(error);
          this.show = false;
          this.message = error.response.data.message;
        });
      }
    }
  };
</script>
