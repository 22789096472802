export default class LoadAlert{
	constructor(id, name, trailerType, loadMode, loadType, carrierUsername, pickupAddress, deadHeadOrigin, deadHeadOriginMeasurement, 
		deliveryAddress, deadHeadDestination, desiredRate, deadHeadDestinationMeasurement, pickupDate, dropOffDate,
		length, lengthMeasurement, weight, weightMeasurement, price, currency){
		this.id = id;
		this.name = name;
		this.trailerType = trailerType;
		this.loadMode = loadMode;
		this.loadType = loadType;
		this.carrierUsername = carrierUsername;
		this.pickupAddress = pickupAddress;
		this.deadHeadOrigin = deadHeadOrigin;
		this.deadHeadOriginMeasurement = deadHeadOriginMeasurement;
		this.deliveryAddress = deliveryAddress;
		this.deadHeadDestination = deadHeadDestination;
		this.deadHeadDestinationMeasurement = deadHeadDestinationMeasurement;
		this.pickupDate = pickupDate;
		this.dropOffDate = dropOffDate;
		this.loadMode =loadMode;
		this.length = length;
		this.lengthMeasurement = lengthMeasurement;
		this.weight = weight;
		this.weightMeasurement = weightMeasurement;
		this.price = price;
		this.currency = currency;
	}
}