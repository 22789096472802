<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <h4>Users list</h4>
        <router-link :to="{ name: 'users/new' }">
          <button class="btn btn-success pull-right">New User
            <span class="glyphicon glyphicon-plus"></span></button>
        </router-link><br/><br/>

        <form class="form">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Search</label>
                <input type="text" v-model="searchValue" placeholder="Enter First Name, Last Name, Email or Username" class="form-control" v-on:input="getFilteredUsers"/>
              </div>
            </div>
          </div>
        </form>

        <table class="table table-bordered table-responsive">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Names</th>
            <th>Username</th>
            <th>Roles</th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="user in displayedUsers" v-bind:key="user.id">
              <td>{{ user.id }}</td>
              <td>
                {{ user.firstName === null ? "Not available" : user.firstName }}
                {{ user.lastName === null ? "Not available" : user.lastName }}
              </td>
              <td>{{ user.username }}</td>
              <td>
                <ul class="list-group" v-for="role in user.roles" :key="role.id">
                  <li class="list-group-item">{{ role.name }}</li>
                </ul>
              </td>
              <td>
                <span class="label label-success" v-if="user.status === 'ACTIVE'">{{ user.status }}</span>
                <span class="label label-default" v-if="user.status === 'INACTIVE'">{{ user.status }}</span>
              </td>
              <td>
                <router-link :to="{ name: 'users/view', params: { userId: user.id } }">
                  <button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button> </router-link
                >&nbsp;
                <router-link :to="{ name: 'users/edit', params: { userId: user.id } }">
                  <button class="btn btn-warning">Edit <span class="glyphicon glyphicon-edit"></span></button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>

        <ul class="pagination">
          <li v-show="page != 1"><a href="#"><span v-if="page != 1" @click="page--">Prev</span></a></li>
          <li v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"><a href="#">{{ pageNumber }}</a></li>
          <li v-show="page < pages.length"><a href="#" v-if="page < pages.length" @click="page++"><span>Next</span></a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import UserService from "@/services/UserService.js";
import loading from "vue-full-loading";

export default {
  name: "UserList",
  components: {
    loading
  },

  data: function() {
    return {
      show: false,
      label: "Processing request",
      users: [],
      searchValue: null,
      filteredUsers: [],
      page: 1, // current page
      perPage: 10, // number of items per page
      pages: [] // array of page numbers
    };
  },

  mounted: function() {
    this.findAll();
  },

  methods: {
    findAll: function() {
      this.show = true;
      UserService.findAll().then(response => {
        if (response.data.statusCode === 200) {
          this.users = response.data.body;
          this.filteredUsers = this.users;
        }
        this.show = false;
      }).catch(error => {
        console.log(error);
        this.show = false;
      });
    },

    getFilteredUsers: function() {
      const users = this.users;
      const filteredData = users.filter(user => {
        console.log(this.searchValue);
        return (
          user.username
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          user.firstName
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          user.lastName
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          user.email.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      });
      this.filteredUsers = filteredData;
    },
    setPages(){
      let numberOfPages = Math.ceil(this.filteredUsers.length / this.perPage);
      for(var index = 0; index <= numberOfPages; index++){
        this.pages.push(index);
      }
    },
    paginate(users){
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return users.slice(from, to);
    }
  },

  computed: {
      displayedUsers(){
          return this.paginate(this.users); // return watched method
      },
    },

    watch: {
      users(){
        this.setPages();
      }
    },
};
</script>
