<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div v-if="booking !== null">
			<div class="panel">
				<div class="panel-body">
					<h4>Are you sure you want to award <span class="text-danger">{{ booking.carrier !== null ? booking.carrier.name: '' }}</span> this load booking {{ getBookingNumber }}?</h4> 
					<p>After awarded the booking carrier will have 15 minutes to accept the offer. After which will be automatically declined.</p>
					<hr>
					
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form">
									<label>&nbsp;</label><br>
									<button class="btn btn-primary btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
					<hr>
					<p><i>N.B Submitting this booking award to the carrier means you agree to the terms and conditions that bind the business transaction between you and the carrier as outlined in the Terms and conditions of service.</i></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BookingService from "@/services/BookingService";

	export default{
		name: 'AwardBooking',
		components: {
			loading,
		},

		data: function(){
			return {
				show: false,
				label: "Processing request",
				booking: null,
			}
		},

		computed: {
			getLoadNumber(){
				return this.$route.params.loadNumber;
			},

			getBookingNumber(){
				return this.$route.params.bookingNumber;
			}
		},

		mounted(){
			this.findByBookingNumber();
		},

		methods: {
			save: function(){
				this.show = true;
				this.booking.loadNumber = this.getLoadNumber;
				this.booking.bookingNumber = this.getBookingNumber;
				BookingService.award(this.booking).then(response => {
					if (response.data.statusCode === 201){
						this.$router.push({ name: 'my-bookings' });
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			findByBookingNumber: function(){
				this.show = true;
				BookingService.findByBookingNumber(this.getBookingNumber).then(response => {
					if (response.data.statusCode === 200){
						this.booking = response.data.body;
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},
		}
	};
</script>