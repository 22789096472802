<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <h4>Edit treaty reinsurance</h4>
          </div>
          <router-link :to="{ name: 'nssa-list' }">
            <div class="col-md-2">
              <button class="btn btn-primary btn-block">Back</button>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <form class="form" @submit.prevent="save" v-if="treatyReinsurance !== null">
          <div class="form-group">
            <label>Reinsurance Type</label>
            <select v-model="treatyReinsurance.reinsuranceType" class="form-control" required>
              <option value="">Select status</option>
              <option value="TREATY">Treaty</option>
              <option value="FACULTATIVE">Facultative</option>
            </select>
          </div>

          <div class="form-group">
            <label>Proportion Type</label>
            <select v-model="treatyReinsurance.proportionType" class="form-control" required>
              <option value="">Select status</option>
              <option value="PROPORTIONAL">Proportional</option>
              <option value="NON_PROPORTIONAL">Non Proportional</option>
            </select>
          </div>

          <div class="form-group">
            <label>Effective Year</label>
            <select class="form-control" v-model="treatyReinsurance.effectiveYear">
              <option value="" disabled>Select Year</option>
              <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
            </select>
          </div>

          <div class="form-group">
            <label>Proportional Reinsurance Type</label>
            <select v-model="treatyReinsurance.treatyReinsuranceType" class="form-control">
              <option value="">Select status</option>
              <option value="QUOTA_SHARE">Quota Share</option>
              <option value="SURPLUS_TREATY">Surplus Treaty</option>
            </select>
          </div>

          <div class="form-group">
            <label>Broker</label>
            <select class="form-control" v-model="treatyReinsurance.brokerId">
              <option value="">Select status</option>
              <option v-for="reinsurer in reinsurers" :key="reinsurer.id" :value="reinsurer.id">{{ reinsurer.name }}</option>
            </select>
          </div>

          <div class="form-group">
            <label>Product Type</label>
            <select v-model="treatyReinsurance.guaranteeType" class="form-control">
              <option value="">Select status</option>
              <option value="FG">Financial Guarantee</option>
              <option value="MSME">MSME Credit Guarantee</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Max Limit</label>
              <nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="treatyReinsurance.maxLimit" placeholder="Enter max limit"/>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Currency</label>
                <select v-model="treatyReinsurance.currency" class="form-control">
                  <option value="">Select status</option>
                  <option value="US">USD</option>
                  <option value="ZWL">ZWL</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Commission Percentage</label>
                <nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="treatyReinsurance.commissionPercentage" placeholder="Enter commission percentage"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Quota Share Percentage</label>
                <nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="treatyReinsurance.quotaSharePercentage" placeholder="Enter quota share percentage"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Active</label>&nbsp;
            <input type="radio" v-model="treatyReinsurance.active" value="true"/> Yes
            <input type="radio" v-model="treatyReinsurance.active" value="false"/> No
          </div>

          <div class="form-group">
            <label>Current</label>&nbsp;
            <input type="radio" v-model="treatyReinsurance.current" value="true"/> Yes
            <input type="radio" v-model="treatyReinsurance.current" value="false"/> No
          </div>

          <div class="form-group">
            <label>Enabled</label>&nbsp;
            <input type="radio" v-model="treatyReinsurance.enabled" value="true"/> Yes
            <input type="radio" v-model="treatyReinsurance.enabled" value="false"/> No
          </div>

          <div class="form-group">
            <label>Expired</label>&nbsp;
            <input type="radio" v-model="treatyReinsurance.expired" value="true"/> Yes
            <input type="radio" v-model="treatyReinsurance.expired" value="false"/> No
          </div>

          <div class="form-group">
            <label>Description</label>
            <textarea rows="5" placeholder="Enter description" class="form-control" v-model="treatyReinsurance.description"></textarea>
          </div>

          <div class="form-group">
            <label>Status</label>
            <select v-model="treatyReinsurance.status" class="form-control">
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <div class="form-group"><label>&nbsp;</label>
                <button class="btn btn-success btn-block">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import LoadChargeService from "@/services/LoadChargeService";
  import loading from "vue-full-loading";

  export default {
    name: "EditNSSA",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        treatyReinsurance: null,
        errors: [],
        message: null,
        reinsurers: []
      };
    },

    mounted(){
      this.findById();
    },

    methods: {
      findById: function() {
        LoadChargeService.findById(this.getReinsuranceId).then(response => {
          this.show = false;
          this.treatyReinsurance = response.data.body;
        }).catch(error => {
          console.log(error);
          this.show = false;
          });
      },

      save: function() {
        this.show = true;
        LoadChargeService.save(this.treatyReinsurance).then(response => {        
          if (response.data.statusCode == 201) {
            this.show = false;
            this.$router.push({ name: "treaty-reinsurance" });
          }
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    },

    computed: {
      getReinsuranceId() {
        return this.$route.params.reinsuranceId;
      },

      years: function() {
        const year = new Date().getFullYear();
        const years = Array.from({ length: (year + 1) - 1850 }, (value, index) => year - index);
        return years;
      }
    }
  };
</script>
