<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="row">
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('OPEN')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-default">{{ carrierAssignedLoads }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checkmark.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								OPEN
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('PENDING')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary">{{ loadingLoads }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/shipped.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								PENDING
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('DRAFT')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-warning">{{ deliveredLoads }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checklist.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								DRAFT
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('EXPIRED')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-success">{{ confirmedDeliveryLoads }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/delivered.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								EXPIRED
							</p>					
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<OpenLoadsComponent v-show="selectedComponent === 'OPEN'"
			:loadBookings="loadBookings"/>
		<DraftLoadsComponent v-show="selectedComponent === 'DRAFT'"
			:loadBookings="loadBookings"/>
		<PendingLoadsComponent v-show="selectedComponent === 'PENDING'"
			:loadBookings="loadBookings"/>
		<ExpiredLoadsComponent v-show="selectedComponent === 'EXPIRED'"
			:loadBookings="loadBookings"/>
	</div>
</template>

<script type="text/javascript">
	import OpenLoadsComponent from "@/views/loads/OpenLoadsComponent";
	import DraftLoadsComponent from "@/views/loads/DraftLoadsComponent";
	import PendingLoadsComponent from "@/views/loads/PendingLoadsComponent";
	import ExpiredLoadsComponent from "@/views/loads/ExpiredLoadsComponent";
	import loading from "vue-full-loading";
	import LoadBookingService from "@/services/LoadBookingService";

	export default{
		name: 'MyLoads',
		data: function(){
			return {
				label: "Processing request",
				show: false,
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent')),
				loadBookings: []
			}
		},

		components: {
			loading,
			OpenLoadsComponent,
			DraftLoadsComponent,
			PendingLoadsComponent,
			ExpiredLoadsComponent
		},

		mounted(){
			this.findByLoadOwnerUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    carrierAssignedLoads(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.booking.status === 'BOOKED' && !loadBooking.load.released
		    	}).length;
		    },

		    loadingLoads(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && loadBooking.load.released;
		    	}).length;
		    },

		    enrouteLoads(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'IN_TRANSIT' && loadBooking.load.released && loadBooking.load.collected;
		    	}).length;
		    },

		    deliveredLoads(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'DELIVERED' || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS' && loadBooking.load.invoice !== null && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered
		    	}).length;
		    },

		    confirmedDeliveryLoads(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.invoice !== null && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery && loadBooking.load.status === 'DELIVERY_CONFIRMED';
		    	}).length;
		    },
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			},

			findByLoadOwnerUsername: function() {
				this.show = true;
		        LoadBookingService.findByLoadOwnerUsername(this.getUser.username).then(response => {
		        	this.loadBookings = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    }
		}
	}
</script>