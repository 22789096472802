<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <h4>Upgrade your prefered subscription plan to get the best out of Freight Board</h4>
    <p><i>Based on tiered pricing</i></p>
    <div class="row">
      <div class="col-md-4" v-for="subscriptionPlan in subscriptionPlans" :key="subscriptionPlan.id">
        <div class="panel">
          <div class="panel-body">
            <ul class="list-group">
              <li class="list-group-item">
                <h4 class="text-center" style="color: #000000;">
                  {{ subscriptionPlan.name }}
                  <span class="label label-success" v-if="subscription.subscriptionPlan.subscriptionType === subscriptionPlan.subscriptionType">Current Plan</span>
                </h4>
              </li>
              <li class="list-group-item">{{ subscriptionPlan.currency }}${{ subscriptionPlan.price }} / month</li>
              <li class="list-group-item">
                <BasicCarrierFeatures v-if="subscriptionPlan.subscriptionType === 'BASIC' && subscriptionPlan.identifier === 'CARRIER'"/>
                <AdvancedCarrierFeatures v-if="subscriptionPlan.subscriptionType === 'ADVANCED' && subscriptionPlan.identifier === 'CARRIER'"/>
                <PremiumCarrierFeatures v-if="subscriptionPlan.subscriptionType === 'PREMIUM' && subscriptionPlan.identifier === 'CARRIER'"/>

                <BasicShipperFeatures v-if="subscriptionPlan.subscriptionType === 'BASIC' && subscriptionPlan.identifier === 'SHIPPER'"/>
                <AdvancedShipperFeatures v-if="subscriptionPlan.subscriptionType === 'ADVANCED' && subscriptionPlan.identifier === 'SHIPPER'"/>
                <PremiumShipperFeatures v-if="subscriptionPlan.subscriptionType === 'PREMIUM' && subscriptionPlan.identifier === 'SHIPPER'"/>

                <BasicBrokerFeatures v-if="subscriptionPlan.subscriptionType === 'BASIC' && subscriptionPlan.identifier === 'BROKER'"/>
                <AdvancedBrokerFeatures v-if="subscriptionPlan.subscriptionType === 'ADVANCED' && subscriptionPlan.identifier === 'BROKER'"/>
                <PremiumBrokerFeatures v-if="subscriptionPlan.subscriptionType === 'PREMIUM' && subscriptionPlan.identifier === 'BROKER'"/>
              </li>
              <br>
              <button :class="getSelectedSubscriptionPlanNumber === subscriptionPlan.subscriptionPlanNumber ? 'btn btn-success btn-block': 'btn btn-primary btn-block'" type="submit" @click="setSelectedSubscriptionPlanNumber(subscriptionPlan.subscriptionType, subscriptionPlan.subscriptionPlanNumber, subscriptionPlan.price, subscriptionPlan.currency)" 
              :disabled="subscription.subscriptionPlan.subscriptionType === subscriptionPlan.subscriptionType">
              <h4 class="text-center">{{ getSelectedSubscriptionPlanNumber === subscriptionPlan.subscriptionPlanNumber ? 'Selected': 'Upgrade' }}</h4>
              </button>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="subscriptionPlans.length === 0">
        <div class="panel">
          <div class="panel-body">
            <h4>Cannot change subscription plan. Contact us if you have any more queries.</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="panel" v-show="getSelectedSubscriptionPlanNumber !== null">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10"></div>
          <div class="col-md-2">
            <router-link :to="{ name: 'upgrade-subscription-invoice', params: { subscriptionPlanNumber: this.getSelectedSubscriptionPlanNumber }}">
              <button class="btn btn-primary pull-right">Proceed to upgrade</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import SubscriptionPlanService from "@/services/SubscriptionPlanService.js";
  import BasicCarrierFeatures from "@/views/subscriptions/BasicCarrierFeatures";
  import AdvancedCarrierFeatures from "@/views/subscriptions/AdvancedCarrierFeatures";
  import PremiumCarrierFeatures from "@/views/subscriptions/PremiumCarrierFeatures";

  import BasicShipperFeatures from "@/views/subscriptions/BasicShipperFeatures";
  import AdvancedShipperFeatures from "@/views/subscriptions/AdvancedShipperFeatures";
  import PremiumShipperFeatures from "@/views/subscriptions/PremiumShipperFeatures";

  import BasicBrokerFeatures from "@/views/subscriptions/BasicBrokerFeatures";
  import AdvancedBrokerFeatures from "@/views/subscriptions/AdvancedBrokerFeatures";
  import PremiumBrokerFeatures from "@/views/subscriptions/PremiumBrokerFeatures";

  import CompanyService from "@/services/CompanyService";
  import SubscriptionService from "@/services/SubscriptionService";

  export default {
    name: "UpgradeSubscription",
    components: {
      loading,
      BasicCarrierFeatures,
      AdvancedCarrierFeatures,
      PremiumCarrierFeatures,

      BasicShipperFeatures,
      AdvancedShipperFeatures,
      PremiumShipperFeatures,

      BasicBrokerFeatures,
      AdvancedBrokerFeatures,
      PremiumBrokerFeatures
    },
    
    data: function() {
      return {
        show: false,
        label: "Processing Subscription",
        errors: null,
        companyId: null,
        zipCodes: [],
        errorMessage: null,
        phoneNumberFields: [{
          zipCodeId: null,
          phoneNumber: null
        }],
        maxLength: 9,
        subscriptionPlan: null,
        subscriptionPlans: [],
        selectedSubscriptionPlanNumber: this.$store.state.selectedSubscriptionPlanNumber,
        company: null,
        subscription: null
      };
    },

    mounted() {
      this.findByActiveSubscription();
      this.findByAssociatedUserUsername();
    },

    computed: {
      getUuid(){
        return this.$route.params.uuid;
      },

      getSelectedSubscriptionPlanNumber(){
        return this.$store.state.selectedSubscriptionPlanNumber;
      },

      getUser: function() {
        return this.$store.state.auth.user.user;
      }
    },

    methods: {
       findByAssociatedUserUsername: function() {
          this.show = true;
          CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
            if (response.data.statusCode == 200) {
              this.company = response.data.body;
              if (this.company !== null) {
                this.findAll();
              }
            }
            this.show = false;
          }).catch(error => {
            console.log(error);
            this.show = false;
          });
      },

      setSelectedSubscriptionPlanNumber(subscriptionType, subscriptionPlanNumber, price, currency){
        localStorage.removeItem('selectedSubscriptionPlanNumber');
        localStorage.removeItem('selectedPrice');
        localStorage.removeItem('selectedCurrency');

        this.$store.commit('setSelectedPrice', price);
        this.$store.commit('setSelectedCurrency', currency);
        this.$store.commit('setSelectedSubscriptionPlanNumber', subscriptionPlanNumber);

        localStorage.setItem('selectedSubscriptionType', JSON.stringify(subscriptionType));
        localStorage.setItem('selectedSubscriptionPlanNumber', JSON.stringify(subscriptionPlanNumber));
        localStorage.setItem('selectedPrice', JSON.stringify(price));
        localStorage.setItem('selectedCurrency', JSON.stringify(currency));
      },

      findAll: function() {
        this.show = true;
        SubscriptionPlanService.findAll(this.company.companyType).then(response => {
          this.subscriptionPlans = response.data.body.filter(subscriptionPlan => {
            return subscriptionPlan.identifier === this.company.type;
          });
          this.subscriptionPlans = this.subscriptionPlans.filter(subscriptionPlan => {
            if (this.subscription.subscriptionPlan.subscriptionType === 'BASIC') {
              return subscriptionPlan.subscriptionType === 'BASIC' || subscriptionPlan.subscriptionType === 'ADVANCED' || subscriptionPlan.subscriptionType === 'PREMIUM'
            }
            if (this.subscription.subscriptionPlan.subscriptionType === 'ADVANCED') {
              return subscriptionPlan.subscriptionType === 'ADVANCED' || subscriptionPlan.subscriptionType === 'PREMIUM'
            }
          });
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findByActiveSubscription(){
            this.show = true;
            SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
              if (response.data.statusCode === 200) {
                this.subscription = response.data.body;
              }
              this.show = false;
            }).catch(error => {
              console.log(error);
              this.show = false;
            });
        },
    }
  };
</script>
