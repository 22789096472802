import { BOOKING_SERVICE_URL } from "@/config/BookingServiceConfig.js";
import axios from "axios";

class BookingService {
  findAll() {
    return axios.get(BOOKING_SERVICE_URL + "bookings");
  }

  findByCarrierUsername(username) {
    return axios.get(BOOKING_SERVICE_URL + "bookings/findByCarrierUsername/" + username);
  }

  save(booking) {
    return axios.post(BOOKING_SERVICE_URL + "bookings", {
      id: booking.id,
      loadNumber: booking.loadNumber,
      bookingNumber: booking.bookingNumber,
      carrierUsername: booking.carrierUsername,
      awarded: booking.awarded,
      assignedTruckId: booking.assignedTruckId
    });
  }

  findByBookingNumber(bookingNumber) {
    return axios.get(BOOKING_SERVICE_URL + "bookings/findByBookingNumber/" + bookingNumber);
  }

  award(booking) {
    return axios.post(BOOKING_SERVICE_URL + "bookings/award", {
      loadNumber: booking.loadNumber,
      bookingNumber: booking.bookingNumber,  
    });
  }

  decline(booking) {
    return axios.post(BOOKING_SERVICE_URL + "bookings/decline", {
      bookingNumber: booking.bookingNumber      
    });
  }

  acceptOrDeclineOffer(booking) {
    return axios.post(BOOKING_SERVICE_URL + "bookings/acceptOrDeclineOffer", {
      bookingNumber: booking.bookingNumber,
      status: booking.status,
      loadNumber: booking.loadNumber
    });
  }

  findByLoadLoadNumberAndCarrierUsername(bookingNumber, username) {
    return axios.get(BOOKING_SERVICE_URL + "bookings/findByLoadLoadNumberAndCarrierUsername/" + bookingNumber + "/" + username);
  }

  findByCarrierAssociatedUserUsername(username) {
    return axios.get(BOOKING_SERVICE_URL + "bookings/findByCarrierAssociatedUserUsername/" + username);
  }

  cancel(bookingNumber, loadNumber) {
    return axios.post(BOOKING_SERVICE_URL + "bookings/cancel/" + bookingNumber + "/" + loadNumber);
  }

  checkBookingsLimitReached(loadNumber) {
    return axios.get(BOOKING_SERVICE_URL + "bookings/checkBookingsLimitReached/" + loadNumber);
  }
}

export default new BookingService;
