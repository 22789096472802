<template>
  <div>
   <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-2">
            <router-link :to="{ name: 'dashboard' }">
              <button class="btn btn-primary">Back</button>
            </router-link>
          </div>
          <div class="col-md-8"><h4>{{ company.name }} business details</h4></div>
          <div class="col-md-2">
            <div v-if="company !== null">
              <h4 class="pull-right" v-if="company.verified">
                <b>PROFILE VERIFIED</b> <img src="@/assets/images/verified.png" width="50" height="50">
              </h4>
              <h4 class="pull-right" v-else>
                <b>PROFILE NOT VERIFIED</b> <img src="@/assets/images/unverified.png" width="50" height="50">
              </h4>
            </div>
          </div>
        </div>
        <br><br>
        
        <ul class="nav nav-tabs">
          <li class="active"><a href="#basicDetails" data-toggle="pill">Primary Details</a></li>
          <li><a href="#addressDetails" data-toggle="pill">Address Details</a></li>
          <li><a href="#postalAddress" data-toggle="pill">Postal Details</a></li>
          <li><a href="#businessDocumentsDetails" data-toggle="pill">Business Documents</a></li>
          <li><a href="#account" data-toggle="pill" v-if="getRoles.includes('CARRIER')">Account Details</a></li>
        </ul>
        <br/>

        <div class="tab-content">
          <div class="tab-pane active" id="basicDetails">
            <basic-details-component v-bind:company="company"/>
          </div>

          <div class="tab-pane" id="addressDetails">
            <address-component :company="company"/>
          </div>

          <div class="tab-pane" id="postalAddress">
            <postal-address-component :company="company"/>
          </div>

          <div class="tab-pane" id="businessDocumentsDetails">
            <BusinessDocumentsComponent
              :companyId="company.id"
              :attachments="company.businessDocuments"/>
          </div>
          
          <div class="tab-pane" id="account" v-if="getRoles.includes('CARRIER')">
            <BankAccountsComponent
              :companyId="company.id"
              :accounts="company.accounts"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService";
  import BasicDetailsComponent from "@/components/companies/BasicDetailsComponent.vue";
  import AddressComponent from "@/components/companies/AddressComponent.vue";
  import PostalAddressComponent from "@/components/companies/PostalAddressComponent.vue";
  import BusinessDocumentsComponent from "@/components/companies/BusinessDocumentsComponent.vue";
  import Company from "@/models/Company";
  import loading from "vue-full-loading";
  import BankAccountsComponent from "@/components/companies/BankAccountsComponent.vue";

  export default {
    name: "BusinessProfile",
    components: {
    	loading,
      	BasicDetailsComponent,
      	AddressComponent,
      	PostalAddressComponent,
      	BusinessDocumentsComponent,
        BankAccountsComponent
    },

    data: function() {
      return {
        company: new Company,
        companyDetailsShow: false,
        companyLocationShow: false,
        companyPostalAddressShow: false,
        contactPersonShow: false,
        companyStampShow: false,
        socialMediaAccountsShow: false,
        directorsShow: false,
        shareholdersShow: false,
        financialHistoriesShow: false,
        otherCreditFacilitiesShow: false,
        attachmentsShow: false,
        branchesShow: false,
        show: false,
        label: "Processing request",
      };
    },

    computed: {
      getCompanyId() {
        return this.$route.params.companyId;
      },

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    mounted() {
    	this.findByAssociatedUserUsername();
    },

    methods: {
    	findByAssociatedUserUsername: function() {
        this.show = true;
        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>