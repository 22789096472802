<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <router-link :to="{ name: 'configurations' }" replace
      ><button class="btn btn-primary">
        <span class="glyphicon glyphicon-step-backward"></span> Back
      </button></router-link
    >

    <router-link :to="{ name: 'bankAccounts/new' }" replace
      ><button class="btn btn-success pull-right">
        New Bank Account
        <span class="glyphicon glyphicon-plus"></span></button></router-link
    ><br /><br />

    <h4>Bank Accounts</h4>
    <table class="table table-responsive table-bordered">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Bank Name</th>
        <th>Branch</th>
        <th>Branch Code</th>
        <th>Account Number</th>
        <th>Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="bankAccount in bankAccounts" :key="bankAccount.id">
          <td>{{ bankAccount.id }}</td>
          <td>{{ bankAccount.bankName }}</td>
          <td>{{ bankAccount.branch }}</td>
          <td>{{ bankAccount.branchCode }}</td>
          <td>{{ bankAccount.accountNumber }}</td>
          <td>
            <span class="label label-success" v-if="bankAccount.status == 'ACTIVE'"
              >Active</span
            >
            <span class="label label-default" v-if="bankAccount.status == 'INACTIVE'"
              >Inactive</span
            >
            <span class="label label-danger" v-if="bankAccount.status == 'DELETED'"
              >Deleted</span
            >
          </td>
          <td>
            <router-link
              :to="{ name: 'bankAccounts/view', params: { bankAccountId: bankAccount.id } }"
              ><button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button></router-link
            >&nbsp;
            <router-link
              :to="{ name: 'bankAccounts/edit', params: { bankAccountId: bankAccount.id } }"
              ><button class="btn btn-warning">Edit <span class="glyphicon glyphicon-edit"></span></button></router-link
            >&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
import BankAccountService from "@/services/BankAccountService";
import loading from "vue-full-loading";

export default {
  name: "BankList",
  components: {
    loading
  },
  data: function() {
    return {
      show: false,
      label: "Processing request...",
      bankAccounts: []
    };
  },
  created() {
    this.getAllBanks();
  },
  methods: {
    getAllBanks() {
      this.show = true;
      BankAccountService.findAll()
        .then(response => {
          this.show = false;
          this.bankAccounts = response.data.body;
        })
        .catch(error => {
          this.show = false;
          console.log(error);
        });
    }
  }
};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
