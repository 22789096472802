<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<div class="row">
	    			<div class="col-md-2">
	    				<h4>Add load alert</h4>
	    			</div>
	    			<div class="col-md-8"></div>
	    			<div class="col-md-2">
	    				<router-link :to="{ name: 'carrier/loads/load-alerts' }">
	    					<button class="btn btn-danger pull-right">Cancel</button>
	    				</router-link>
	    			</div>
	    		</div>
	    		<br>

	    		<form class="form" @submit.prevent="save">
					<fieldset>
						<legend>Load Information</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Name</label>
									<input type="text" name="name" v-model="loadAlert.name" class="form-control" placeholder="Enter load alert name">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Load type</label>
									<select class="form-control" name="loadType" v-model="loadAlert.loadType" required>
										<option value="" disabled>Select option</option>
										<option value="NORMAL">Normal</option>
										<option value="ABNORMAL">Abnormal</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Trailer Type</label>
									<select class="form-control" name="trailerType" v-model="loadAlert.trailerType" required>
										<option value="" disabled>Select option</option>
										<option value="FLATBED">Flatbed</option>
										<option value="VAN">Van</option>
										<option value="REEFER">Reefer</option>
										<option value="STEP_DECK">Step Deck</option>
										<option value="POWER_ONLY">Power Only</option>
										<option value="AUTO_CARRIER">Auto Carrier</option>
										<option value="B_TRAIN">B Train</option>
										<option value="BOX_TRUCK">Box Truck</option>
										<option value="CONESTOGA">Consetoga</option>
										<option value="CONTAINERS">Containers</option>
										<option value="DOUBLE_DROP">Double Drop</option>
										<option value="DRY_BULK">Dry Bulk</option>
										<option value="DUMP_TRAILER">Dump Trailer</option>
										<option value="HOPPER_BOTTOM">Hooper Bottom</option>
										<option value="HOTSHOT">Hotshot</option>
										<option value="LOWBOY">Lowboy</option>
										<option value="TANKER">Tanker</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Load Mode</label>
									<select class="form-control" name="loadMode" v-model="loadAlert.loadMode" required>
										<option value="" disabled>Select option</option>
										<option value="FTL">Full truckload</option>
										<option value="LTL">Less than truckload</option>
										<option value="FCL">Full Container Load</option>
									</select>
								</div>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Pickup Location</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Province</label>
									<select class="form-control" v-model="pickupAddress.provinceId" @change="getPickupCities">
										<option value="" disabled>Select option</option>
										<option v-for="province in provinces" v-bind:key="province.id" :value="province.id">{{ province.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>City</label>
									<select class="form-control" v-model="pickupAddress.cityId">
										<option value="" disabled>Select option</option>
										<option v-for="city in pickupCities" v-bind:key="city.id" :value="city.id">{{ city.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>DH-P (Dead head pickup)</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="loadAlert.deadHeadOrigin" placeholder="Enter dead head origin"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Distance Measurement</label>
									<select class="form-control" v-model="loadAlert.deadHeadOriginMeasurement">
										<option value="" disabled>Select option</option>
										<option value="KM">Kilometers</option>
										<option value="MILES">Miles</option>
										<option value="METERS">Meters</option>
									</select>
								</div>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Drop Off Location</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Province</label>
									<select class="form-control" v-model="deliveryAddress.provinceId" @change="getDeliveryCities">
										<option value="" disabled>Select option</option>
										<option v-for="province in provinces" v-bind:key="province.id" :value="province.id">{{ province.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>City</label>
									<select class="form-control" v-model="deliveryAddress.cityId">
										<option value="" disabled>Select option</option>
										<option v-for="city in deliveryCities" v-bind:key="city.id" :value="city.id">{{ city.name }}</option>
									</select>
								</div>
							</div>
				        	<div class="col-md-3">
								<div class="form-group">
									<label>DH-D (Dead head destination)</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="loadAlert.deadHeadDestination" placeholder="Enter dead head destination"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Distance Measurement</label>
									<select class="form-control" v-model="loadAlert.deadHeadDestinationMeasurement">
										<option value="" disabled>Select option</option>
										<option value="KM">Kilometers</option>
										<option value="MILES">Miles</option>
										<option value="METERS">Meters</option>
									</select>
								</div>
							</div>
				        </div>
					</fieldset>

					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Pick Up Date</label>
								<input type="date" name="pickupDate" class="form-control" v-model="loadAlert.pickupDate">
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Drop Off Date</label>
								<input type="date" name="dropOffDate" class="form-control" v-model="loadAlert.dropOffDate">
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Length</label>
								<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="loadAlert.length" placeholder="Enter truck length"/>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Length Measurement</label>
								<select class="form-control" v-model="loadAlert.lengthMeasurement">
									<option value="" disabled>Select option</option>
									<option value="CM">CM</option>
									<option value="METER">Meter</option>
								</select>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Weight</label>
								<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="loadAlert.weight" placeholder="Enter desired rate"/>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Weight Measurement</label>
								<select class="form-control" v-model="loadAlert.weightMeasurement">
									<option value="" disabled>Select option</option>
									<option value="KG">Kilogram</option>
									<option value="TONNE">Tonne</option>
									<option value="GT">Gigatonne</option>
									<option value="CU">Cubic</option>
								</select>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Desired Price</label>
								<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="loadAlert.price" placeholder="Enter desired rate"/>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Price Currency</label>
								<select class="form-control" v-model="loadAlert.currency">
									<option value="" disabled>Select option</option>
									<option value="ZWL">ZWL</option>
									<option value="USD">USD</option>
								</select>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Save this alert</button>
							</div>
						</div>
					</div>
				</form>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadAlertService from "@/services/LoadAlertService";
	import CityService from "@/services/CityService";
	import ProvinceService from "@/services/ProvinceService";
	import LoadAlert from "@/models/LoadAlert";
	import ZipCodeService from "@/services/ZipCodeService.js";

	export default{
		name: 'NewLoadAlert',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				provinces: [],
				pickupCities: [],
				deliveryCities: [],
				provinceId: null,
				loadAlert: new LoadAlert,
				pickupAddress: {
			        id: null,
			        zipCodeId: null,
			        countryId: null,
			        provinceId: null,
			        cityId: null,
			        areaId: null,
			        physicalAddress: null,
			        sameAsPostalAddress: false
			    },
			    deliveryAddress: {
			        id: null,
			        zipCodeId: null,
			        countryId: null,
			        provinceId: null,
			        cityId: null,
			        areaId: null,
			        physicalAddress: null,
			        sameAsPostalAddress: false
			    },
			    zipCodes: []
			}
		},

		created(){
			this.getProvinces();
			this.getZipCodes();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
			}
		},

		methods: {

			getProvinces: function(){
				ProvinceService.findAll().then(response => {
					this.provinces = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			getPickupCities: function() {
		      CityService.findByProvinceId(this.pickupAddress.provinceId).then(response => {
		      	this.pickupCities = response.data.body;
		      }).catch(error => {
		      	console.log(error);
		      });
		    },

		    getDeliveryCities: function() {
		      CityService.findByProvinceId(this.deliveryAddress.provinceId).then(response => {
		      	this.deliveryCities = response.data.body;
		      }).catch(error => {
		      	console.log(error);
		      });
		    },

			save: function(){
				this.show = true;
				this.loadAlert.carrierUsername = this.getUser.username;
				this.loadAlert.pickupAddress = this.pickupAddress;
				this.loadAlert.deliveryAddress = this.deliveryAddress;
				LoadAlertService.save(this.loadAlert).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'carrier/loads/load-alerts' });
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			getZipCodes: function() {
		        ZipCodeService.findAll().then(response => {
		        	this.zipCodes = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
		    },
		}
	};
</script>