<t<template>
  <div>
    <p>All payments</p>
    <div class="panel">
      <div class="panel-body">
        <form class="form">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Payment Type</label>
                <select class="form-control">
                  <option value="" disabled>Select payment type</option>
                  <option value="ALL">All payments</option>
                  <option value="ONLINE_PAYMENT">Online payments</option>
                  <option value="BANK">Bank payments</option>
                  <option value="CASH">Cash payments</option>
                </select>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label>Currency</label>
                <select class="form-control">
                  <option value="">Select currency</option>
                  <option value="ZWL">ZWL</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <label>From</label>
              <input type="date" name="dateFrom" id="dateFrom" class="form-control">
            </div>
            <div class="col-md-2">
              <label>To Date</label>
              <input type="date" name="dateFrom" id="dateFrom" class="form-control">
            </div>
          </div>
        </form>

        <table class="table table-bordered table-hover table-striped table-responsive">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Reference Number</th>
            <th>Invoice</th>
            <th>Narration</th>
            <th>Amount</th>
            <th>Channel</th>
            <th>Payment Status</th>
            <th>Invoice Status</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr v-for="invoice in invoices" :key="invoice.id">
              <td>{{ invoice.id }}</td>
              <td>{{ invoice.payment.debitReference }}</td>
              <td>
                <a :href="PDF_API_URL + 'pdf/generateInvoice/' + invoice.id " target="_blank">{{ invoice.invoiceNumber }}</a>
              </td>
              <td>{{ invoice.narration }}</td>
              <td>{{ invoice.payment.currency }}{{ invoice.payment.amount }}</td>
              <td>{{ invoice.payment.paymentMethod }}</td>
              <td>{{ invoice.payment.status }}</td>
              <td>{{ invoice.status }}</td>
              <td>
                <router-link :to="{ name: 'view-payment-details', params: { invoiceNumber: invoice.invoiceNumber }, query: { filter: 'payment-list' }}">
                  <button class="btn btn-primary btn-sm">View <span class="glyphicon glyphicon-info-sign"></span></button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <router-view>

    </router-view>
  </div>
</template>

<script type="text/javascript">
  import PaymentService from "@/services/PaymentService.js";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default {
    name: "PaymentList",
    components: {

    },

    data: function() {
      return {
        invoices: [],
        PDF_API_URL: PDF_API_URL,
      };
    },

    mounted: function() {
      this.findAll();
    },

    computed: {
      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    methods: {
      findAll: function() {
        PaymentService.findAll().then(response => {
          this.invoices = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>
