<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div v-if="subscription !== null">
	        <div v-if="subscription.subscriptionPlan.subscriptionType !== 'PREMIUM'">
	          <div class="panel">
	            <div class="panel-body">
	              <h4 class="text-danger">Oops, Sorry you need to upgrade your subscription plan to view load density.</h4>
	              <p>Load density allows you to view the where the most loads are found inter state or intra state!</p>
	              <br>
	              <router-link :to="{ name: 'upgrade-subscription' }">
	                <button class="btn btn-primary">Upgrade Subscription</button>
	              </router-link>
	            </div>
	          </div>
	        </div>
	        <div v-else>
	        	<div class="panel">
					<div class="panel-body">
						<div class="row">
							<div class="col-md-2">
								<div class="form-group">
									<label>Trailer Type</label>
									<select class="form-control" v-model="LD_trailerTypeRequired">
										<option value="" disabled>Select option</option>
										<option value="FLATBED">Flatbed</option>
										<option value="VAN">Van</option>
										<option value="REEFER">Reefer</option>
										<option value="STEP_DECK">Step Deck</option>
										<option value="POWER_ONLY">Power Only</option>
										<option value="AUTO_CARRIER">Auto Carrier</option>
										<option value="B_TRAIN">B Train</option>
										<option value="BOX_TRUCK">Box Truck</option>
										<option value="CONESTOGA">Consetoga</option>
										<option value="CONTAINERS">Containers</option>
										<option value="DOUBLE_DROP">Double Drop</option>
										<option value="DRY_BULK">Dry Bulk</option>
										<option value="DUMP_TRAILER">Dump Trailer</option>
										<option value="HOPPER_BOTTOM">Hooper Bottom</option>
										<option value="HOTSHOT">Hotshot</option>
										<option value="LOWBOY">Lowboy</option>
										<option value="TANKER">Tanker</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Region</label>
									<select class="form-control" v-model="LD_pickUpProvinceId">
										<option value="" disabled>Select option</option>
										<option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label><br>
									<button class="btn btn-block" type="button" @click="clearFilter()" style="background-color: #000000; color: #ffffff;">Reset filter</button>
								</div>
							</div>
							<div class="col-md-4"></div>
							<div class="col-md-1">
								<label>&nbsp;</label><br>
								<button class="btn btn-primary" @click="changeLoadDensityType('list')">View list</button>
							</div>
							<div class="col-md-1">
								<label>&nbsp;</label><br>
								<button class="btn btn-success" @click="changeLoadDensityType('map')">View map</button>
							</div>
						</div>
					</div>
				</div>

				<div class="panel">
					<div class="panel-body">
						<div class="row">
							<div class="col-md-3">
								<table class="table table-bordered table-hover table-responsive">
									<thead>
										<th>State</th>
										<th>Inbound</th>
										<th>Instrastate</th>
										<th>Out</th>
									</thead>
									<tbody>
										<tr v-for="load in loads" :key="load.id">
											<td>{{ load.dropOffAddress !== null ? load.dropOffAddress.province.name: '' }}</td>
											<td><span class="label label-warning">{{ loads.length }}</span></td>
											<td><span class="label label-primary">{{ loads.length }}</span></td>
											<td><span class="label label-default">{{ loads.length }}</span></td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="col-md-9">
								<span v-if="loadDensityType === 'list'">
									<ListLoadDensity :loads="paginatedLoads"/>
									<div class="row" v-if="totalPages > 1">
								      <div class="col-md-7"></div>
								      <div class="col-md-3 pull-right">
								        <div class="panel">
								          <div class="panel-body">
								            <button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="currentPage === 1" @click="prevPage"></button>
								            &nbsp;<span>Page {{ LD_currentPage }} of {{ totalPages }}</span>&nbsp;
								            <button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="LD_currentPage === totalPages" @click="nextPage"></button>
								          </div>
								        </div>
								      </div>
								    </div>
								</span>
								
								<span v-if="loadDensityType === 'map' && markerCoordinates.length > 0"> 
									<!-- wait for coordinates to fully load since map executes lazily! -->
									<MapLoadDensity :markerCoordinates="markerCoordinates"/>
								</span>
							</div>
						</div>
					</div>
				</div>
	        </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import ListLoadDensity from "@/components/loadDensity/ListLoadDensity";
	import MapLoadDensity from "@/components/loadDensity/MapLoadDensity";
	import LoadService from "@/services/LoadService";
	import loading from "vue-full-loading";
	import ProvinceService from "@/services/ProvinceService";
	import SubscriptionService from "@/services/SubscriptionService";

	export default{
		components: {
			ListLoadDensity,
			MapLoadDensity,
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request',
				loadDensityType: 'list',
				loads: [],
				markerCoordinates: [],
				provinces: [],
				LD_trailerTypeRequired: null,
				LD_pickUpProvinceId: null,
				LD_currentPage: JSON.parse(localStorage.getItem('LD_currentPage')) !== null ? JSON.parse(localStorage.getItem('LD_currentPage')): 1,
				itemsPerPage: 10,
				subscription: null
			}
		},

		mounted(){
			this.findByActiveSubscription();

			this.LD_trailerTypeRequired = JSON.parse(localStorage.getItem('LD_trailerTypeRequired'));
      		this.LD_pickUpProvinceId = JSON.parse(localStorage.getItem('LD_pickUpProvinceId'));
			this.getLoads();
			this.getProvinces();
		},

		methods: {
			findByActiveSubscription(){
		        this.show = true;
		        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
		          if (response.data.statusCode === 200) {
		            this.subscription = response.data.body;
		          }
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    },

			changeLoadDensityType: function(type){
				this.loadDensityType = type;
			},

			prevPage(){
	          if (this.LD_currentPage > 1) {
	            const LD_currentPage = --this.LD_currentPage;
	            localStorage.setItem('LD_currentPage', JSON.stringify(LD_currentPage));
	          }
	        },

	        nextPage(){
	          if (this.LD_currentPage < this.totalPages) {
	            const LD_currentPage = ++this.LD_currentPage;
	            localStorage.setItem('LD_currentPage', JSON.stringify(LD_currentPage));
	          }
	        },

	        clearFilter(){
	          localStorage.removeItem('LD_trailerTypeRequired');
	          localStorage.removeItem('LD_pickUpProvinceId');
	          localStorage.removeItem('LD_currentPage');
	          window.location.reload();
	      },

			getLoads: function(){
				this.show = true;
				LoadService.findAll().then(response => {
					this.loads = response.data.body.filter(load => {
						return load.status === 'ACTIVE' && load.carrier === null;
					});
					for (var i = 0; i < this.loads.length; i++) {
						if(this.loads[i].pickupAddress !== null){
							this.markerCoordinates.push({
								latitude: this.loads[i].pickupAddress.latitude !== null ? this.loads[i].pickupAddress.latitude: 0,
								longitude: this.loads[i].pickupAddress.longitude !== null ? this.loads[i].pickupAddress.longitude: 0
							});
						}
					}
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			getProvinces: function(){
				this.show = true;
				ProvinceService.findAll().then(response => {
					this.provinces = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			}
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
        	},

	        totalPages(){
	          return Math.ceil(this.filteredLoads.length / this.itemsPerPage);
	        },

	        filteredLoads(){
	          let filteredLoads;
	          if ((this.LD_trailerTypeRequired !== null && this.LD_trailerTypeRequired !== undefined) || 
	            (this.LD_pickUpProvinceId !== null && this.LD_pickUpProvinceId !== undefined)) {

	            localStorage.setItem('LD_trailerTypeRequired', JSON.stringify(this.LD_trailerTypeRequired));
	            localStorage.setItem('LD_pickUpProvinceId', JSON.stringify(this.LD_pickUpProvinceId));
	            filteredLoads = this.loads.filter(truck => {
	              return truck.trailerTypeRequired === this.LD_trailerTypeRequired || 
	                truck.pickupAddress.province.id === this.LD_pickUpProvinceId
	            });
	          }else{
	            filteredLoads = this.loads;
	          }
	          return filteredLoads;
	        },

	        paginatedLoads(){
	          const startIndex = (this.LD_currentPage - 1) * this.itemsPerPage;
	          return this.filteredLoads.slice(startIndex, startIndex + this.itemsPerPage);
	      }
		}
	}
</script>