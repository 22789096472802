import { PAYMENT_SERVICE_URL } from "@/config/PaymentServiceConfig.js";
import axios from "axios";

class PaymentService {
  findAll() {
    return axios.get(PAYMENT_SERVICE_URL + "payments");
  }

  findByReferenceNumber(referenceNumber) {
    return axios.get(PAYMENT_SERVICE_URL +"payments" + "/findByReferenceNumber/" + referenceNumber);
  }

  findByPayerUsername(payerUsername) {
    return axios.get(PAYMENT_SERVICE_URL +"payments" + "/findByPayerUsername/" + payerUsername);
  }

  save(payment) {
    return axios.post(PAYMENT_SERVICE_URL + "payments", {
      payer: payment.payer,
      studentInvoiceNumber: payment.studentInvoiceNumber,
      transactions: payment.transactions
    });
  }
  
  authorizePayment(payment) {
    return axios.post(PAYMENT_SERVICE_URL + "payments/authorizePayment", {
      referenceNumber: payment.referenceNumber,
      invoiceNumber: payment.invoiceNumber
    });
  }

  releasePayments(fromDate, toDate) {
    return axios.post(PAYMENT_SERVICE_URL + "payments/releasePayments/" + fromDate + "/" + toDate);
  }

  getRedirectUrl(payment) {
    return axios.post(PAYMENT_SERVICE_URL + "payments/getRedirectUrl/", {
      payerUsername: payment.payerUsername,
      amount: payment.amount,
      currency: payment.currency,
      description: payment.description,
      method: payment.method,
      gateway: payment.gateway,
      paymentType: payment.paymentType
    });
  }

  receivePaymentUpdate(gateway, merchantReference, hash) {
    return axios.post(PAYMENT_SERVICE_URL + "payments/receivePaymentUpdate/" + gateway + "/" + merchantReference + "/" + hash);
  }

  findByOwner(username) {
    return axios.get(PAYMENT_SERVICE_URL +"payments" + "/findByOwner/" + username);
  }

  findByCarrier(username) {
    return axios.get(PAYMENT_SERVICE_URL +"payments" + "/findByCarrier/" + username);
  }

  getWitholding() {
    return axios.get(PAYMENT_SERVICE_URL + "payments/withholding");
  }

  getAuthorized() {
    return axios.get(PAYMENT_SERVICE_URL + "payments/authorized");
  }

  releasePayment(payment) {
    return axios.post(PAYMENT_SERVICE_URL + "payments/releasePayment", {
      referenceNumber: payment.referenceNumber,
      invoiceNumber: payment.invoiceNumber
    });
  }

  getPaid() {
    return axios.get(PAYMENT_SERVICE_URL + "payments/paid");
  }

  getOutstanding() {
    return axios.get(PAYMENT_SERVICE_URL + "payments/outstanding");
  }

  getReadyForAuthorization() {
    return axios.get(PAYMENT_SERVICE_URL + "payments/readyForAuthorization");
  }
}

export default new PaymentService;
