<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <h4>{{ editing ? "Edit": "New" }} Role</h4>
        <hr>
        <br/>

        <p v-if="message !== null" class="error-message text-center">
          {{ message }}
        </p>
        
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label> Name</label>
            <input type="text" v-model="role.name" placeholder="Enter Role Name" class="form-control"/>
            <div v-if="errors">
              <p v-for="error in errors" :key="error.id" class="error-message">
                <span v-if="error.name">{{ error.name }}</span>
              </p>
            </div>
          </div>

          <div class="form-group">
            <label>Description</label>
            <input type="text" v-model="role.description" placeholder="Enter Role Description" class="form-control"/>
            <div v-if="errors">
              <p v-for="error in errors" :key="error.id" class="error-message">
                <span v-if="error.description">{{ error.description }}</span>
              </p>
            </div>
          </div>

          <div class="form-group">
            <label>Status</label>
            <select v-model="role.status" class="form-control">
              <option value="" disabled>Select option</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
            <div v-if="errors">
              <p v-for="error in errors" :key="error.id" class="error-message">
                <span v-if="error.status">{{ error.status }}</span>
              </p>
            </div>
          </div>
          <br>
          
          <div class="form-group">
            <label>&nbsp;</label>
            <router-link :to="{ name: 'roles' }" replace>
              <button class="btn btn-default" data-dismiss="modal">Cancel</button>
            </router-link>
            &nbsp; <button class="btn btn-success pull-right">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import Role from "@/models/Role";
  import RoleService from "@/services/RoleService";
  import loading from "vue-full-loading";

  export default {
    name: "NewOrEditRole",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request...",
        role: new Role(),
        errors: [],
        message: null,
        editing: false
      };
    },

    mounted(){
      if("roleId" in this.$route.params){
        this.editing = true;
        this.findById();
      }
    },

    computed: {
      getRoleId: function(){
        return this.$route.params.roleId;
      }
    },

    methods: {
      save: function() {
        this.role.roleId = this.getRoleId;
        this.show = true;
        RoleService.save(this.role)
          .then(response => {        
            if (response.data.statusCode == 422) {
              this.show = false;
              this.errors = response.data.errors;
            }
            if (response.data.statusCode == 400) {
              this.show = false;
              this.errors = response.data.errors;
              this.message = response.data.message;
            }
            if (response.data.statusCode == 201) {
              this.show = false;
              this.$router.push({ name: "roles" });
            }
          }).catch(error => {
            this.show = false;
            console.log(error);
            this.errors = error.response.data.errors;
            this.message = error.response.data.message;
        });
      },

      findById: function(){
        RoleService.findById(this.$route.params.roleId).then(response => {
          this.role = response.data.body;
        }).catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
