<template>
  <div>
    <router-link :to="{ name: 'configurations' }" replace><button class="btn btn-primary">
      <span class="glyphicon glyphicon-step-backward"></span> Back</button>
    </router-link>

    <router-link :to="{ name: 'zipCodes/new' }">
      <button class="btn btn-success pull-right">
        New <span class="glyphicon glyphicon-plus"></span>
      </button> </router-link
    ><br /><br />

    <h4>Zip Code List</h4>

    <table class="table table-bordered table-responsive-md">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Code</th>
        <th>Description</th>
        <th>Status</th>
        <td>Actions</td>
      </thead>
      <tbody>
        <tr v-for="zipCode in zipCodes" :key="zipCode.id">
          <td>{{ zipCode.id }}</td>
          <td>+{{ zipCode.code }}</td>
          <td>{{ zipCode.description }}</td>
          <td>
            <span v-if="zipCode.status === 'ACTIVE'">
              <span class="label label-success">Active</span>
            </span>
            <span v-if="zipCode.status === 'INACTIVE'">
              <span class="badge badge-secondary">Inactive</span>
            </span>
          </td>
          <td>
            <router-link :to="{ name: 'zipCodes/view', params: { zipCodeId: zipCode.id }}">
              <button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
            </router-link>&nbsp;
            <router-link :to="{ name: 'zipCodes/edit', params: { zipCodeId: zipCode.id }}">
              <button class="btn btn-warning">Edit <span class="glyphicon glyphicon-edit"></span></button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
  import ZipCodeService from "@/services/ZipCodeService.js";

  export default {
    name: "ZipCodeList",
    data: function() {
      return {
        zipCodes: []
      };
    },

    created: function() {
      this.findAll();
    },

    methods: {
      findAll: function() {
        ZipCodeService.findAll()
          .then(response => {
            this.zipCodes = response.data.body;
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };
</script>
