<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    		<div class="panel">
			<div class="panel-body">
				<form class="form">
					<div class="row">
						<div class="col-md-2">
								<div class="form-group">
									<label>Load type</label>
									<select class="form-control" name="type" v-model="ML_type">
										<option value="" disabled>Select option</option>
										<option value="NORMAL">Normal</option>
										<option value="ABNORMAL">Abnormal</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Load mode</label>
									<select class="form-control" name="loadMode" v-model="ML_loadMode">
										<option value="" disabled>Select option</option>
										<option value="FTL">Full truckload - FTL</option>
										<option value="LTL">Less than truckload - LTL</option>
										<option value="INTERMODAL">Intermodal</option>
										<option value="POWER_ONLY">Power-Only</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Trailer required</label>
									<select class="form-control" name="trailerTypeRequired" v-model="ML_trailerTypeRequired">
										<option value="" disabled>All trailers</option>
										<option value="FLATBED">Flatbed</option>
										<option value="VAN">Van</option>
										<option value="REEFER">Reefer</option>
										<option value="STEP_DECK">Step Deck</option>
										<option value="POWER_ONLY">Power Only</option>
										<option value="AUTO_CARRIER">Auto Carrier</option>
										<option value="B_TRAIN">B Train</option>
										<option value="BOX_TRUCK">Box Truck</option>
										<option value="CONESTOGA">Consetoga</option>
										<option value="CONTAINERS">Containers</option>
										<option value="DOUBLE_DROP">Double Drop</option>
										<option value="DRY_BULK">Dry Bulk</option>
										<option value="DUMP_TRAILER">Dump Trailer</option>
										<option value="HOPPER_BOTTOM">Hooper Bottom</option>
										<option value="HOTSHOT">Hotshot</option>
										<option value="LOWBOY">Lowboy</option>
										<option value="TANKER">Tanker</option>
									</select>
								</div>
							</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Pick Up Date</label>
								<input type="date" name="pickupDate" v-model="ML_pickupDate" class="form-control">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Drop Off Date</label>
								<input type="date" name="dropOffDate" v-model="ML_dropOffDate" class="form-control">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Pick Up</label>
								<select class="form-control" v-model="ML_pickUpCityId">
									<option value="" selected>All cities</option>
									<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Drop Off</label>
								<select class="form-control" v-model="ML_dropOffCityId">
									<option value="" selected>All cities</option>
									<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Weight (tonne)</label>
								<input type="number" name="weight" v-model="ML_weight" class="form-control" placeholder="Enter weight">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Price</label>
								<input type="number" name="price" v-model="ML_price" class="form-control" placeholder="Enter price">
							</div>
						</div>
						<div class="col-md-2"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-block btn-primary" type="button" @click="search()">Search</button>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-block" type="button" @click="clearFilter()" style="background-color: #000000; color: #ffffff;">Reset filter</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="row">
			<div class="col-md-3">
				<p class="text-danger">{{ loads.length }} loads match load alert {{ getLoadAlertNumber }}</p>
				<p>{{ new Date().toDateString() }}</p>
			</div>
			<div class="col-md-6"></div>
			<div class="col-md-3">
				<select class="form-control" v-model="itemsPerPage">
					<option value="10">Items per page</option>
					<option value="20">20</option>
					<option value="30">30</option>
					<option value="40">40</option>
					<option value="50">50</option>
				</select>
			</div>
		</div>

		<div class="panel" v-for="load in paginatedLoads" :key="load.id">
			<div class="panel-body">
				<div class="row">
					<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }, query: { source: 'LOAD_ALERT', loadAlertNumber: getLoadAlertNumber }}">
						<div class="col-md-3">
							<b><i><span class="label label-primary">PICKUP</span></i></b>
							<span v-if="load.pickupAddress !== null">
								<span v-if="load.pickupAddress.province !== null && load.pickupAddress.city !== null">
									<p>{{ load.pickupAddress !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress !== null ? load.pickupAddress.city.name: "Not available" }} on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
									</p>
								</span>
								<span v-else>
									<p><span class="text-danger">Pickup n/a</span> on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
									</p>
								</span>
							</span>
							<p><span class="badge">{{ load.trailerTypeRequired }}</span> required - <span class="badge">{{ load.loadMode }}</span></p>
						</div>
						<div class="col-md-1">
							<br>
							<img src="@/assets/images/right-arrow.png" height="30" width="30">
						</div>
						<div class="col-md-3">
							<span v-if="load.dropOffAddress !== null">
								<b><i><span class="label label-warning">DELIVERY</span></i></b>&nbsp;
								<span class="badge">Estimated distance {{ load.estimatedDistance > 0 ? load.estimatedDistance / 1000 : 0 }} km</span> 
								<span v-if="load.pickupAddress !== null">
									<span v-if="load.pickupAddress.province !== null && load.pickupAddress.city !== null">
										<p>{{ load.dropOffAddress !== null ? load.dropOffAddress.province.name: "Not available" }}, {{ load.dropOffAddress !== null ? load.dropOffAddress.city.name: "Not available" }}
											{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}
										</p>
									</span>
									<span v-else>
										<p><span class="text-danger">Drop off n/a</span> on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
										</p>
									</span>
								</span>
								<p>{{ load.weight }} {{ load.weightMeasurement }} of {{ load.commodity }}</p>
							</span>
						</div>
					</router-link>
					<div class="col-md-2">
						<br>
						<p>{{ load.currency }}${{ load.price }}</p>
						<p><span class="badge">{{ load.loadType }} load</span></p>
					</div>
					<div class="col-md-1" v-show="getRoles.includes('CARRIER')">
						<br><br>
						<router-link :to="{ name: 'bid-load', params: { loadNumber: load.loadNumber }}">
							<button class="btn btn-default btn-block">Bid <span class="glyphicon glyphicon-ok-circle"></span></button>
						</router-link>
					</div>
					<div class="col-md-1" v-show="getRoles.includes('CARRIER')">
						<br><br>
						<router-link :to="{ name: 'book-load', params: { loadNumber: load.loadNumber }}">
							<button class="btn btn-primary btn-block">Book <span class="glyphicon glyphicon-ok-circle"></span></button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-if="totalPages > 1">
			<div class="col-md-7"></div>
			<div class="col-md-3 pull-right">
				<div class="panel">
					<div class="panel-body">
						<button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="ML_currentPage === 1" @click="prevPage"></button>
						&nbsp;<span>Page {{ ML_currentPage }} of {{ totalPages }}</span>&nbsp;
						<button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="ML_currentPage === totalPages" @click="nextPage"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import CityService from "@/services/CityService";

	export default{
		name: 'MatchedLoads',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				loads: [],
				cities: [],
				ML_currentPage: JSON.parse(localStorage.getItem('ML_currentPage')) !== null ? JSON.parse(localStorage.getItem('ML_currentPage')): 1,
				itemsPerPage: 10,
				ML_type: null,
				ML_loadMode: null,
				ML_trailerTypeRequired: null,
				ML_pickupDate: null,
				ML_dropOffDate: null,
				ML_pickUpCityId: null,
				ML_dropOffCityId: null,
				ML_weight: null,
				ML_price: null,
				filteredLoads: []
			}
		},

		mounted(){
			this.ML_type = JSON.parse(localStorage.getItem('ML_type'));
			this.ML_loadMode = JSON.parse(localStorage.getItem('ML_loadMode'));
			this.ML_trailerTypeRequired = JSON.parse(localStorage.getItem('ML_trailerTypeRequired'));
			this.ML_pickupDate = JSON.parse(localStorage.getItem('ML_pickupDate'));
			this.ML_dropOffDate = JSON.parse(localStorage.getItem('ML_dropOffDate'));
			this.ML_pickUpCityId = JSON.parse(localStorage.getItem('ML_pickUpCityId'));
			this.ML_dropOffCityId = JSON.parse(localStorage.getItem('ML_dropOffCityId'));
			this.ML_weight = JSON.parse(localStorage.getItem('ML_weight'));
			this.ML_price = JSON.parse(localStorage.getItem('ML_price'));
			
			this.findAllCities();
			this.getLoads();
		},

		methods: {
			findAllCities: function(){
				CityService.findAll().then(response => {
					this.cities = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			getLoads: function(){
				this.show = true;
				LoadService.findMatchedLoads(this.getLoadAlertNumber).then(response => {
					this.loads = response.data.body;
					this.search();
					this.show = false;
		        }).catch(error => {
		        	this.show = false;
		        	console.log(error);
		        });
			},

			prevPage(){
		    	if (this.ML_currentPage > 1) {
		    		const ML_currentPage = --this.ML_currentPage;
		    		localStorage.setItem('ML_currentPage', JSON.stringify(ML_currentPage));
		    	}
		    },

		    nextPage(){
		    	if (this.ML_currentPage < this.totalPages) {
		    		const ML_currentPage = ++this.ML_currentPage;
		    		localStorage.setItem('ML_currentPage', JSON.stringify(ML_currentPage));
		    	}
		    },

		    clearFilter(){
		    	localStorage.removeItem('ML_type');
		    	localStorage.removeItem('ML_loadMode');
		    	localStorage.removeItem('ML_trailerTypeRequired');
		    	localStorage.removeItem('ML_pickupDate');
		    	localStorage.removeItem('ML_dropOffDate');
		    	localStorage.removeItem('ML_pickUpCityId');
		    	localStorage.removeItem('ML_dropOffCityId');
		    	localStorage.removeItem('ML_weight');
		    	localStorage.removeItem('ML_price');
		    	localStorage.removeItem('ML_currentPage');
		    	localStorage.removeItem('ML_searchFields');
		    	window.location.reload();
		    },

		    search(){
		    	let searchFields = JSON.parse(localStorage.getItem('ML_searchFields') || '[]');
		    	if (this.ML_type !== null && this.ML_type !== undefined) {
				    let searchField = {
				    	'fieldName': 'ML_type',
				    	'value': this.ML_type
				    };
				    const index = searchFields.findIndex((field) => field.fieldName === 'ML_type');
				    if (index === -1) {
				    	searchFields.push(searchField);
				    }else{
				    	searchFields[index] = searchField;
				    }
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.ML_loadMode !== null && this.ML_loadMode !== undefined) {
		    		let searchField = {
		    			'fieldName': 'ML_loadMode',
		    			'value': this.ML_loadMode
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'ML_loadMode');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.ML_trailerTypeRequired !== null && this.ML_trailerTypeRequired !== undefined) {
		    		let searchField = {
		    			'fieldName': 'ML_trailerTypeRequired',
		    			'value': this.ML_trailerTypeRequired
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'ML_trailerTypeRequired');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.ML_pickupDate !== null && this.ML_pickupDate !== undefined) {
		    		let searchField = {
		    			'fieldName': 'ML_pickupDate',
		    			'value': this.ML_pickupDate
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'ML_pickupDate');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.ML_dropOffDate !== null && this.ML_dropOffDate !== undefined) {
		    		let searchField = {
		    			'fieldName': 'ML_dropOffDate',
		    			'value': this.ML_dropOffDate
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'ML_dropOffDate');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.ML_pickUpCityId !== null && this.ML_pickUpCityId !== undefined) {
		    		let searchField = {
		    			'fieldName': 'ML_pickUpCityId',
		    			'value': this.ML_pickUpCityId
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'ML_pickUpCityId');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.ML_dropOffCityId !== null && this.ML_dropOffCityId !== undefined) {
		    		let searchField = {
		    			'fieldName': 'ML_dropOffCityId',
		    			'value': this.ML_dropOffCityId
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'ML_dropOffCityId');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.ML_weight !== null && this.ML_weight !== undefined) {
		    		let searchField = {
		    			'fieldName': 'ML_weight',
		    			'value': this.ML_weight
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'ML_weight');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.ML_price !== null && this.ML_price !== undefined) {
		    		let searchField = {
		    			'fieldName': 'ML_price',
		    			'value': this.ML_price
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'ML_price');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('ML_searchFields', JSON.stringify(searchFields));
		    	}

		    	if (searchFields.length > 0) {
		    		let theLoads = this.loads;
		    		for(let i = 0; i < searchFields.length; i++){
		    			if (searchFields[i].fieldName === 'ML_type'){
		    				theLoads = theLoads.filter(load => {
		    					return load.type === searchFields[i].value;
		    				});
		    				localStorage.setItem('ML_type', JSON.stringify(this.ML_type));
		    			}
		    			if (searchFields[i].fieldName === 'ML_loadMode'){
		    				theLoads = theLoads.filter(load => {
		    					return load.loadMode === searchFields[i].value;
		    				});
		    				localStorage.setItem('ML_loadMode', JSON.stringify(this.ML_loadMode));
		    			}
		    			if (searchFields[i].fieldName === 'ML_trailerTypeRequired'){
		    				theLoads = theLoads.filter(load => {
		    					return load.trailerTypeRequired === searchFields[i].value;
		    				});
		    				localStorage.setItem('ML_trailerTypeRequired', JSON.stringify(this.ML_trailerTypeRequired));
		    			}
		    			if (searchFields[i].fieldName === 'ML_pickupDate'){
		    				theLoads = theLoads.filter(load => {
		    					return load.pickupDate === searchFields[i].value;
		    				});
		    				localStorage.setItem('ML_pickupDate', JSON.stringify(this.ML_pickupDate));
		    			}
		    			if (searchFields[i].fieldName === 'ML_dropOffDate'){
		    				theLoads = theLoads.filter(load => {
		    					return load.dropOffDate === searchFields[i].value;
		    				});
		    				localStorage.setItem('ML_dropOffDate', JSON.stringify(this.ML_dropOffDate));
		    			}
		    			if (searchFields[i].fieldName === 'ML_pickUpCityId'){
		    				theLoads = theLoads.filter(load => {
		    					return load.pickupAddress.city.id === searchFields[i].value;
		    				});
		    				localStorage.setItem('ML_pickUpCityId', JSON.stringify(this.ML_pickUpCityId));
		    			}
		    			if (searchFields[i].fieldName === 'ML_dropOffCityId'){
		    				theLoads = theLoads.filter(load => {
		    					return load.dropOffAddress.city.id === searchFields[i].value;
		    				});
		    				localStorage.setItem('ML_dropOffCityId', JSON.stringify(this.ML_dropOffCityId));
		    			}
		    			if (searchFields[i].fieldName === 'ML_weight'){
		    				theLoads = theLoads.filter(load => {
		    					return load.weight === parseFloat(searchFields[i].value);
		    				});
		    				localStorage.setItem('ML_weight', JSON.stringify(this.ML_weight));
		    			}
		    			if (searchFields[i].fieldName === 'ML_price'){
		    				theLoads = theLoads.filter(load => {
		    					return load.price === parseFloat(searchFields[i].value);
		    				});
		    				localStorage.setItem('ML_price', JSON.stringify(this.ML_price));
		    			}
		    			this.filteredLoads = theLoads;
		    		}
		    	}else{
		    		this.filteredLoads = this.loads;
		    	}
		    }
		},

		computed: {
			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },

		    getLoadAlertNumber(){
		    	return this.$route.params.loadAlertNumber;
		    },

		    totalPages(){
		    	return Math.ceil(this.filteredLoads.length / this.itemsPerPage);
		    },

		    paginatedLoads(){
				const startIndex = (this.ML_currentPage - 1) * this.itemsPerPage;
				return this.filteredLoads.slice(startIndex, startIndex + this.itemsPerPage);
			}
		}
	};
</script>