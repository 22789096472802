<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Release Payment</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'authorized-payments' }">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>
				<br>

				<div v-if="load !== null">
					<ul class="list-group" v-if="load.invoice !== null">
						<li class="list-group-item">Reference Number: {{ load.invoice.payment.referenceNumber }}</li>
						<li class="list-group-item">Amount: {{ load.invoice.payment.currency }}{{ load.invoice.payment.amount }}</li>
						<li class="list-group-item">Narration: {{ load.invoice.narration }}</li>
						<li class="list-group-item">Description: {{ load.invoice.description }}</li>
						<li class="list-group-item">Status: {{ load.invoice.status }}</li>
						<li class="list-group-item">
	                		<a :href="PDF_API_URL + 'pdf/generateInvoice/' + load.invoice.id " target="_blank" class="btn btn-primary">View invoice details</a>
						</li>
						<li class="list-group-item">
							<h4>Payee Details:</h4>
							<ul class="list-group" v-if="load.carrier !== null">
								<li class="list-group-item">Carrier - {{ load.carrier.name }}</li>
								<li class="list-group-item">
									<ul class="list-group" v-for="account in load.carrier.bankAccounts" :key="account.id">
										<li class="list-group-item">Bank - {{ account.financialInstitution.name }}</li>
										<li class="list-group-item">Account Number/Mobile Wallet  - {{ account.accountNumber }}</li>
										<li class="list-group-item">Account Currency  - {{ account.accountCurrency }}</li>
										<li class="list-group-item">Branch - {{ account.id }}</li>	
									</ul>
								</li>
								<li class="list-group-item text-danger">Percentage load charge is {{ VUE_APP_LOAD_PERCENTAGE_CHARGE }}</li>
								<li class="list-group-item text-danger">Load charge amount - {{ load.invoice.payment.currency }}{{ parseFloat(load.invoice.payment.amount * .07).toFixed(3) }}</li>
								<li class="list-group-item text-danger">Amount to be credited - {{ load.invoice.payment.currency }}{{ (parseFloat( load.invoice.payment.amount) - parseFloat(load.invoice.payment.amount * VUE_APP_LOAD_PERCENTAGE_CHARGE)).toFixed(3) }}</li>
							</ul>
						</li>
					</ul>
					<div v-if="load.invoice !== null && load.invoice.status === 'AUTHORIZED' && load.invoice.payment.status === 'AUTHORIZED'">
						<form class="form" @submit.prevent="releasePayment">
							<div class="row">
								<div class="col-md-10"></div>
								<div class="col-md-2">
									<button class="btn btn-success btn-block" type="submit">Pay from escrow</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import PaymentService from "@/services/PaymentService.js";
  import loading from "vue-full-loading";
  import Payment from "@/models/Payment.js";  
  import LoadService from "@/services/LoadService.js";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default {
    components: {
      loading
    },
    name: "ReleasePayment",

    data: function() {
      return {
        show: false,
        label: "Processing request",
        payment: new Payment,
        load: null,
        PDF_API_URL: PDF_API_URL,
        VUE_APP_LOAD_PERCENTAGE_CHARGE: process.env.VUE_APP_LOAD_PERCENTAGE_CHARGE
      };
    },

    computed: {
      getInvoiceNumber() {
        return this.$route.params.invoiceNumber;
      }
    },

    created() {
    	this.findByInvoiceNumber();
    },

    methods: {
    	findByInvoiceNumber: function(){
    		this.show = true;
	        LoadService.findByInvoiceNumber(this.getInvoiceNumber).then(response => {
	        	this.load = response.data.body;
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },

	    releasePayment: function(){
    		this.show = true;
    		this.payment.invoiceNumber = this.load.invoice.invoiceNumber;
    		this.payment.referenceNumber = this.load.invoice.payment.referenceNumber;
	        PaymentService.releasePayment(this.payment).then(response => {
	        	if (response.data.body) {
	        		window.location.reload();
	        		this.$router.push({ name: 'authorized-payments' });
	        	}
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },
    }
  };
</script>