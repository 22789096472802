<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div v-if="load !== null">
			<div class="panel">
				<div class="panel-body">
					<div class="row">
						<div class="col-md-11">
							<h4>Delivery contact information</h4>
						</div>
						<div class="col-md-1">
							<router-link :to="{ name: 'carrier-my-loads'}">
								<button class="btn btn-primary btn-block">Close</button>
							</router-link>
						</div>
					</div>
					<br>
					
					<ul class="list-group" v-if="load.contactInformationForCarrier !== null">
						<li class="list-group-item">First Name: {{ load.contactInformationForCarrier.firstName }}</li>
						<li class="list-group-item">Last Name: {{ load.contactInformationForCarrier.lastName }}</li>
						<li class="list-group-item">Email: {{ load.contactInformationForCarrier.email }}</li>
						<li class="list-group-item">Phone Number: <br><br>
							<span v-for="phoneNumber in load.contactInformationForCarrier.phoneNumbers" :key="phoneNumber.id">
								<ul class="list-group">
									<li class="list-group-item">
										<p>+{{ phoneNumber.country.code }}{{ phoneNumber.number }}</p>
									</li>
								</ul>
							</span>
						</li>
					</ul>
					<br>

					<p class="text-danger" v-if="load.contactInformationForCarrier === null">Contact information for carrier not available</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService.js";

	export default{
		name: 'ContactInformationForCarrier',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				load: null
			}
		},

		mounted(){
			this.findByLoadNumber();
		},

		computed: {
			getLoadNumber: function() {
				return this.$route.params.loadNumber;
		    }
		},

		methods: {
		    findByLoadNumber: function(){
				this.show = true;
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>