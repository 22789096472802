<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div v-if="bid !== null">
			<div class="panel">
				<div class="panel-body">
					<h4>Are you sure you want to award <span class="text-danger">{{ bid.carrier !== null ? bid.carrier.name: '' }}</span>  this load bid {{ getBidNumber }}?</h4>
					<p>After awarded the bid carrier will have 15 minutes to accept the offer. After which will be automatically declined.</p>
					<hr>
					
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form">
									<label>&nbsp;</label><br>
									<button class="btn btn-primary btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
					<hr>
					<p><i>N.B Submitting this bid award to the carrier means you agree to the terms and conditions that bind the business transaction between you and the carrier as outlined in the Terms and conditions of service.</i></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BidService from "@/services/BidService";

	export default{
		name: 'AwardBid',
		components: {
			loading,
		},
		data: function(){
			return {
				show: false,
				label: "Processing request",
				bid: null,
			}
		},

		computed: {
			getLoadNumber(){
				return this.$route.params.loadNumber;
			},

			getBidNumber(){
				return this.$route.params.bidNumber;
			}
		},

		mounted(){
			this.findByBidNumber();
		},

		methods: {
			save: function(){
				this.show = true;
				this.bid.loadNumber = this.getLoadNumber;
				this.bid.bidNumber = this.getBidNumber;
				BidService.award(this.bid).then(response => {
					if (response.data.statusCode === 201){
						this.$router.push({ name: 'my-bids' });
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			findByBidNumber: function(){
				this.show = true;
				BidService.findByBidNumber(this.getBidNumber).then(response => {
					if (response.data.statusCode === 200){
						this.bid = response.data.body;
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},
		}
	};
</script>