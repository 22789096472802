<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<form class="form" @submit.prevent="getRedirectUrl">
			<button class="btn btn-success" type="submit">Make Payment</button>
		</form>
	</div>
</template>

<script type="text/javascript">
	import PaymentService from "@/services/PaymentService";
	import loading from "vue-full-loading";

	export default {
		components: {
			loading
		},
		name: 'TestPayment',
		data: function(){
			return {
				redirectURL: null,
				show: false,
				label: "Processing Payment",
				amount: 4.5,
				currency: "ZWL",
			}
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
			},
		},

		methods: {
			getRedirectUrl: function(){
				this.show = true;
				let paymentPostDto = {
				    payerUsername: this.getUser.username,
				    amount: this.amount,
				    currency: this.currency,
				    description: "Test Transaction TXN", // plug load number later when we go live
				    method: "ONLINE",
				    gateway: "PAYNOW",
				    paymentType: "LXD"
				}
				PaymentService.getRedirectUrl(paymentPostDto).then(response => {
					if (response.status === 201) {
						this.redirectURL = response.data.body;
						if (this.redirectURL !== null) {
							window.location.replace(this.redirectURL);
						}
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = true;
				});
			}
		}
	};
</script>