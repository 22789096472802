<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<h4>Load <span class="text-danger">{{ getLoadNumber }}</span> invoice details</h4>
				<ul class="list-group" v-if="load !== null">
					<li class="list-group-item">Invoice Number: {{ load.invoice.invoiceNumber }}</li>
					<li class="list-group-item">Due date: {{ load.invoice.dueDate }}</li>
					<li class="list-group-item">Total amount: {{ load.invoice.currency }}{{ load.invoice.total }}</li>
					<li class="list-group-item">Narration: {{ load.invoice.narration }}</li>
					<li class="list-group-item">Billed to: {{ load.invoice.billedTo !== null ? load.invoice.billedTo.name: '' }}</li>
					<li class="list-group-item">Customer: {{ load.invoice.customer !== null ? load.invoice.customer.name: '' }}</li>
				</ul>

				<div class="row">
					<div class="col-md-10"></div>
					<div class="col-md-2">
						<router-link :to="{ name: 'loads/payment-checkout', params: { loadNumber: getLoadNumber, bookingNumber: getBookingNumber }}">
							<button class="btn btn-success pull-right">Pay invoice</button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService.js";

	export default{
		name: 'PayInvoice',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    loads: [],
			    load: null
			}
		},

		mounted(){
			this.getLoadByLoadNumber();
			this.findByOwnerUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getLoadNumber() {
		    	return this.$route.params.loadNumber;
	    	},

	    	getBookingNumber() {
	    		return this.$route.params.bookingNumber;
	    	}
		},

		methods: {
			getLoadByLoadNumber: function(){
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			findByOwnerUsername: function(){
				this.show = true;
				LoadService.findByOwnerUsername(this.$store.state.auth.user.user.username).then(response => {
					this.loads = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        });
			}
		}
	};
</script>