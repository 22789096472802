<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<fieldset>
	    			<legend>Load information</legend>
	    			<p><span class="text-danger">N.B Please complete the load creation steps one time, the data forms do not support going back yet !</span></p>
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Load type</label>
									<select class="form-control" name="type" v-model="load.type" required>
										<option value="" disabled>Select option</option>
										<option value="NORMAL">Normal</option>
										<option value="ABNORMAL">Abnormal</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Load mode</label>
									<select class="form-control" name="loadMode" v-model="load.loadMode" required>
										<option value="" disabled>Select option</option>
										<option value="FTL">Full truckload - FTL</option>
										<option value="LTL">Less than truckload - LTL</option>
										<option value="INTERMODAL">Intermodal</option>
										<option value="POWER_ONLY">Power-Only</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Trailer required</label>
									<select class="form-control" name="trailerTypeRequired" v-model="load.trailerTypeRequired" required>
										<option value="" disabled>Select option</option>
										<option value="FLATBED">Flatbed</option>
										<option value="VAN">Van</option>
										<option value="REEFER">Reefer</option>
										<option value="STEP_DECK">Step Deck</option>
										<option value="POWER_ONLY">Power Only</option>
										<option value="AUTO_CARRIER">Auto Carrier</option>
										<option value="B_TRAIN">B Train</option>
										<option value="BOX_TRUCK">Box Truck</option>
										<option value="CONESTOGA">Consetoga</option>
										<option value="CONTAINER">Container</option>
										<option value="DOUBLE_DROP">Double Drop</option>
										<option value="DRY_BULK">Dry Bulk</option>
										<option value="DUMP_TRAILER">Dump Trailer</option>
										<option value="HOPPER_BOTTOM">Hooper Bottom</option>
										<option value="HOTSHOT">Hotshot</option>
										<option value="LOWBOY">Lowboy</option>
										<option value="TANKER">Tanker</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Expiry date</label>
									<input type="date" class="form-control" name="expiryDate" v-model="load.expiryDate" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Hazmat</label>
									<select class="form-control" name="hazmat" v-model="load.hazmat">
										<option value="NONE">None</option>
    									<option value="EXPLOSIVES">Explosives</option>
										<option value="GASES">Gases</option>
										<option value="FLAMMABLE_LIQUIDS">Flammable Liquids</option>
										<option value="FLAMMABLE_SOLIDS">Flammable Solids</option>
										<option value="OXIDIZERS">Oxidizers or Organic Peroxides</option>
										<option value="POISONOUS_MATERIAL">Poisonous Material</option>
										<option value="INFECTIOUS_SUBSTANCE">Infectious Substance</option>
										<option value="RADIOACTIVE_MATERIAL">Radioactive Material</option>
										<option value="CORROSIVES">Corrosives</option>
										<option value="MISCELLANEOUS_HAZARDOUS_MATERIAL">Miscellaneous Hazardous Material</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>UN Number</label>
									<input type="text" class="form-control" name="unNumber" v-model="load.unNumber" placeholder="Enter UN Number"/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-9">
								<div class="form-group">
									<label>Load description</label>
									<textarea type="text" name="description" class="form-control" placeholder="Enter description e.g full truckload of maize" v-model="load.description" rows="5" minlength="5" maxlength="250" required></textarea>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-4">
								<div class="form-group">
									<label>Weight</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="load.weight" placeholder="Enter Weight" required/>
								</div>
							</div>
							<div class="col-md-5">
								<div class="form-group">
									<label>Weight measurement</label>
									<select class="form-control" v-model="load.weightMeasurement" required>
										<option value="" disabled>Select option</option>
										<option value="KG">Kilogram</option>
										<option value="TONNE">Tonne</option>
										<option value="GT">Gigatonne</option>
										<option value="CU">Cubic</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import Load from "@/models/Load";

	export default{
		name: 'NewLoad',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				provinceId: null,
				load: new Load
			}
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},

			getCarrierName(){
				return this.$route.query.carrierName;
			},

			getUser(){
				return this.$store.state.auth.user.user;
			}
		},

		methods: {
			save: function(){
				this.show = true;
				this.load.loadNumber = this.getLoadNumber;
				this.load.ownerUsername = this.getUser.username;
				LoadService.save(this.load).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'new-load-timing', params: { loadNumber: response.data.body.loadNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},
		}
	};
</script>