import { LOAD_SERVICE_URL } from "@/config/LoadServiceConfig";
import axios from "axios";

class LoadService {
  findAll() { 
    return axios.get(LOAD_SERVICE_URL + "loads"); 
  }

  findByOwnerUsername(username){
    return axios.get(LOAD_SERVICE_URL + "loads/findByOwnerUsername/" + username);
  }

  findAllFiltered(page, size){
    return axios.get(LOAD_SERVICE_URL + "loads/findAllFiltered?page=" + page + "&size=" + size);
  }

  findReturnLoads(city, loadNumber, sourceCity){
    return axios.get(LOAD_SERVICE_URL + "loads/findReturnLoads/" + city + "/" + loadNumber + "/" + sourceCity);
  }

  findByCarrierContactPersonUsername(username){
    return axios.get(LOAD_SERVICE_URL + "loads/findByCarrierContactPersonUsername/" + username);
  }

  findByCarrierAssociatedUserUsername(username){
    return axios.get(LOAD_SERVICE_URL + "loads/findByCarrierAssociatedUserUsername/" + username);
  }

  findById(id){ 
    return axios.get(LOAD_SERVICE_URL + "loads/" + id); 
  }

  findByLoadNumber(loadNumber){ 
    return axios.get(LOAD_SERVICE_URL + "loads/findByLoadNumber/" + loadNumber); 
  }

  save(load) {
    return axios.post(LOAD_SERVICE_URL + "loads", {
      id: load.id,
      hazmat: load.hazmat,
      unNumber: load.unNumber,
      type: load.type,
      loadNumber: load.loadNumber,
      loadMode: load.loadMode,
      expiryDate: load.expiryDate,
      pickupAddress: load.pickupAddress,
      dropOffAddress: load.dropOffAddress,
      pickupDate: load.pickupDate,
      pickupTime: load.pickupTime,
      dropOffDate: load.dropOffDate,
      dropOffTime: load.dropOffTime,
      estimatedDistance: load.estimatedDistance,
      estimatedDistanceMeasurement: load.estimatedDistanceMeasurement,
      estimatedLoadingTime: load.estimatedLoadingTime,
      estimatedLoadingTimeMeasurement: load.estimatedLoadingTimeMeasurement,
      estimatedDeliveryTime: load.estimatedDeliveryTime,
      estimatedDeliveryTimeMeasurement: load.estimatedDeliveryTimeMeasurement,
      trailerTypeRequired: load.trailerTypeRequired,
      weight: load.weight,
      weightMeasurement: load.weightMeasurement,
      price: load.price,
      currency: load.currency,
      carrierId: load.carrierId,
      marketAveragePrice: load.marketAveragePrice,
      estimatedDrivingTime: load.estimatedDrivingTime,
      estimatedDrivingTimeMeasurement: load.estimatedDrivingTimeMeasurement,
      ownerUsername: load.ownerUsername,
      bookNowEnabled: load.bookNowEnabled,
      collectLoadOffersEnabled: load.collectLoadOffersEnabled,
      privateLoadEnabled: load.privateLoadEnabled,
      contactInformationForCarrier: load.contactInformationForCarrier,
      copyFromMyAccount: load.copyFromMyAccount,
      bookings: load.bookings,
      bids: load.bids,
      payment: load.payment,
      status: load.status,
      deliveryComments: load.deliveryComments,
      assignedTruckNumber: load.assignedTruckNumber,
      carriers: load.carriers,
      description: load.description
    });
  }

  release(load) {
    return axios.post(LOAD_SERVICE_URL + "loads/release", {
      loadNumber: load.loadNumber,
      releasedDate: load.releasedDate,
      releasedTime: load.releasedTime,
    });
  }

  collected(load, bookingNumber) {
    return axios.post(LOAD_SERVICE_URL + "loads/collected/" + bookingNumber, {
      loadNumber: load.loadNumber,
    });
  }

  delivered(load) {
    return axios.post(LOAD_SERVICE_URL + "loads/delivered", {
      loadNumber: load.loadNumber
    });
  }

  confirmedDelivery(load) {
    return axios.post(LOAD_SERVICE_URL + "loads/confirmedDelivery", {
      loadNumber: load.loadNumber,
      status: load.status,
      deliveryComments: load.deliveryComments
    });
  }
  
  searchByLoadAlerts(username, loadAlerts){
    return axios.post(LOAD_SERVICE_URL + "loads/searchByLoadAlerts/" + username, loadAlerts); 
  }

  findByLoadLoadNumberWhereBidIsAwarded(loadNumber){ 
    return axios.get(LOAD_SERVICE_URL + "loads/findByLoadLoadNumberWhereBidIsAwarded/" + loadNumber); 
  }

  publish(loadNumber) {
    return axios.post(LOAD_SERVICE_URL + "loads/publish/" + loadNumber);
  }

  findMatchedLoads(loadAlertNumber) {
    return axios.post(LOAD_SERVICE_URL + "loads/findMatchedLoads/" + loadAlertNumber);
  }

  searchLoads() {
    return axios.get(LOAD_SERVICE_URL + "loads/searchLoads");
  }

  findByInvoiceNumber(invoiceNumber){ 
    return axios.get(LOAD_SERVICE_URL + "loads/findByInvoiceNumber/" + invoiceNumber); 
  }

  updateLoadPayment(load, bookingNumber) {
    return axios.post(LOAD_SERVICE_URL + "loads/updateLoadPayment/" + bookingNumber, {
      loadNumber: load.loadNumber,
      payment: load.payment,
      bookingNumber: load.bookingNumber
    });
  }
}

export default new LoadService;
