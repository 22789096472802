<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div v-if="subscription !== null">
	        <div v-if="subscription.subscriptionPlan.subscriptionType === 'BASIC'">
	          <div class="panel">
	            <div class="panel-body">
	              <h4 class="text-danger">Oops, Sorry you need to upgrade your subscription plan to create load alerts.</h4>
	              <p>Post your load alert and view loads matching load alert for your convinience</p>
	              <br>
	              <router-link :to="{ name: 'upgrade-subscription' }">
	                <button class="btn btn-primary">Upgrade Subscription</button>
	              </router-link>
	            </div>
	          </div>
	        </div>
	        <div v-else>
	        	<div class="panel">
					<div class="panel-body">
						<h4 class="text-center">
						<img src="@/assets/images/loadalert.png">
						</h4>
						<h4 class="text-center" v-if="alerts.length === 0">You haven't added any load alert yet.</h4>
						<p class="text-center">Post your load alert and view loads matching load alert for your convinience</p>

						<p class="text-center">
							<router-link :to="{ name: 'carrier/loads/load-alerts/new' }">
								<button class="btn btn-primary"><span class="glyphicon glyphicon-plus"></span> Add load alert</button>
							</router-link>
						</p>
					</div>
				</div>
				<div class="panel" v-for="alert in alerts" :key="alert.id">
					<div class="panel-body">
						<p>{{ alert.loadAlert.name }}</p>
						<div class="row">
							<div class="col-md-4">
								<p>
									<span class="badge">{{ alert.loadAlert.trailerType }}</span> required for 
									<span class="badge">{{ alert.loadAlert.loadMode }}</span>&nbsp;
									<span class="badge">{{ alert.loadAlert.loadType !== null ? alert.loadAlert.loadType + ' LOAD': '' }}</span>
								</p>
							</div>
							<div class="col-md-6">
								<router-link :to="{ name: 'view-load-alert', params: { loadAlertNumber: alert.loadAlert.loadAlertNumber }}">
									<button class="btn btn-primary">View alert</button>
								</router-link>
							</div>
							<div class="col-md-2">
								<router-link :to="{ name: 'matched-loads', params: { loadAlertNumber: alert.loadAlert.loadAlertNumber }}">
									<h4><span class="label label-warning">View  {{ alert.loads.length }} matches</span></h4>
								</router-link>
							</div>
						</div>
					</div>
				</div>
	        </div>
	    </div>
	</div>
</template>


<script type="text/javascript">
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";
  import LoadService from "@/services/LoadService";
  import SubscriptionService from "@/services/SubscriptionService";

  export default {
    name: "CarrierLoadAlert",
    components: {
      loading
    },

    data: function() {
      return {
        trucks: [],
        show: false,
        label: "Processing request",
        company: null,
        alerts: [],
        subscription: null
      };
    },

    created(){
    	this.findByActiveSubscription();
    	this.findByAssociatedUserUsername();
    },

    computed: {
		getUser: function() {
	        return this.$store.state.auth.user.user;
	    }
	},

    methods: {
    	findByActiveSubscription(){
	        this.show = true;
	        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
	          if (response.data.statusCode === 200) {
	            this.subscription = response.data.body;
	          }
	          this.show = false;
	        }).catch(error => {
	          console.log(error);
	          this.show = false;
	        });
	    },

	    findByAssociatedUserUsername: function() {
	    	this.show = true;
	    	CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
	    		this.company = response.data.body;
	    		this.searchByLoadAlerts();
	    		this.show = false;
	    	}).catch(error => {
	    		console.log(error);
	    		this.show = false;
	    	});
	    },

	    searchByLoadAlerts: function() {
	    	this.show = true;
	    	let transformedLoadAlerts = [];
	    	for(var i = 0; i < this.company.loadAlerts.length; i++){
	    		transformedLoadAlerts.push({
	    			trailerType: this.company.loadAlerts[i].trailerType,
        			loadMode: this.company.loadAlerts[i].loadMode
        		});
	    	}
	    	LoadService.searchByLoadAlerts(this.getUser.username, transformedLoadAlerts).then(response => {
	    		this.alerts = response.data.body;
        		this.show = false;
        	}).catch(error => {
        		console.log(error);
          		this.show = false;
        	});
        }
    }
  };
</script>