<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <h4>Country List</h4>    
          </div>
          <router-link :to="{ name: 'countries/new' }">
            <div class="col-md-2">
              <button class="btn btn-success btn-block">New <span class="glyphicon glyphicon-plus"></span></button>
            </div>
          </router-link>
        </div>
        <br>

        <table class="table table-bordered table-responsive-md">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Name</th>
            <th>Country Code</th>
            <th>Status</th>
            <td>Actions</td>
          </thead>
          <tbody>
            <tr v-for="country in countries" :key="country.id">
              <td>{{ country.id }}</td>
              <td>{{ country.name }}</td>
              <td>{{ country.code }}</td>
              <td>
                <span v-if="country.status === 'ACTIVE'">
                  <span class="badge badge-success">Active</span>
                </span>
                <span v-if="country.status === 'INACTIVE'">
                  <span class="badge badge-secondary">Inactive</span>
                </span>
              </td>
              <td>
                <router-link :to="{ name: 'countries/view', params: { countryId: country.id }}">
                  <button class="btn btn-primary">
                    View <span class="glyphicon glyphicon-info-sign"></span>
                  </button>
                </router-link>&nbsp;
                <router-link :to="{ name: 'countries/edit', params: { countryId: country.id }}">
                  <button class="btn btn-warning">
                    Edit <span class="glyphicon glyphicon-edit"></span>
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CountryService from "@/services/CountryService.js";
  import loading from "vue-full-loading";

  export default {
    components: {
      loading
    },

    name: "CountryList",
    data: function() {
      return {
        countries: [],
        show: false,
        label: "Processing request",
      };
    },

    mounted() {
      this.findAll();
    },

    methods: {
      findAll: function() {
        this.show = true;
        CountryService.findAll().then(response => {
          this.show = false;
          this.countries = response.data.body;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>
