<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11"></div>
					<div class="col-md-1">
						<router-link :to="{ name: 'search-loads' }">
							<button class="btn btn-primary btn-block">Back</button>
	   					</router-link>
					</div>
				</div>
	   			<br><br>

				<div v-if="!bookingsLimitReached">
					<div class="row" v-if="!carrierAlreadyBidLoad && !carrierAlreadyBookedLoad">
						<div class="col-md-3"></div>
						<div class="col-md-6">
							<div v-if="company !== null">
								<div class="row" v-if="company.trucks.length > 0">
									<h4>Place Your Booking for this load {{ getLoadNumber }}</h4>
									<hr>
									<p>After winning the booking we'll notify you via SMS and email and you'll have 15 minutes to accept the offer. Be alert for new business.</p>
									<br>
									<div class="col-md-6">
										<form class="form" @submit.prevent="placeBooking">
											<div class="form-group">
												<label>Assign truck</label>
												<select class="form-control" v-model="booking.assignedTruckId" required>
													<option value="" disabled>Select option</option>
													<option v-for="truck in company.trucks" :key="truck.id" :value="truck.id">Truck plate number {{ truck.registrationNumber }} - {{ truck.type }} truck</option>
												</select>
											</div>
											<div class="form-group">
												<label>&nbsp;</label>
												<button class="btn btn-primary pull-right" type="submit">PLACE BOOKING</button>
											</div>
										</form>
									</div>
								</div>
								<div v-if="company.trucks.length === 0">
									<p class="text-danger">You haven't posted any truck or fleet. <a :href="VUE_APP_TMS_NEW_TRUCK" target="_blank">Login to Nelspruit Freight TMS and post your truck then come back and proceed with booking.</a></p>
								</div>
							</div>	
						</div>
						<div class="col-md-3"></div>
					</div>
					<div v-if="carrierAlreadyBookedLoad">
						<h4>You have already booked this load. <router-link :to="{ name: 'search-loads' }">Search other loads</router-link></h4>
					</div>
					<div v-if="carrierAlreadyBidLoad">
						<h4>You cannot place a booking because you have already bid this load <router-link :to="{ name: 'search-loads' }"> Search other loads</router-link></h4>
					</div>
				</div>
				<div v-if="bookingsLimitReached">
					<h4>Oops, this load is no longer accepting new bookings. <router-link :to="{ name: 'search-loads' }"> Search other loads</router-link></h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BookingService from "@/services/BookingService";
	import LoadBookingService from "@/services/LoadBookingService";
	import LoadBidService from "@/services/LoadBidService";
	import CompanyService from "@/services/CompanyService";
	import Booking from "@/models/Booking";
	
	export default{
		name: 'NewBooking',
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request',
				booking: new Booking,
				company: null,
				carrierAlreadyBookedLoad: false,
				carrierAlreadyBidLoad: false,
				bookingsLimitReached: null,
				VUE_APP_TMS_NEW_TRUCK: process.env.VUE_APP_TMS_NEW_TRUCK
			}
		},

		created(){
			this.checkBookingsLimitReached();
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},

			getUser: function() {
				return this.$store.state.auth.user.user;
		    }
		},

		methods: {
			findByAssociatedUserUsername: function(){
				this.show = true;
				CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
					this.company = response.data.body;
					if (this.company !== null) {
						this.checkCarrierAlreadyBookedLoad();
						this.checkCarrierAlreadyBidLoad();
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			placeBooking: function(){
				this.show = true;
			    this.booking.loadNumber = this.getLoadNumber;
			    this.booking.carrierUsername = this.$store.state.auth.user.user.username;
			    this.booking.awarded = false;
				BookingService.save(this.booking).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'view-load', params: { loadNumber: this.getLoadNumber }, query: { source: 'LOAD_SEARCH' }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			checkCarrierAlreadyBookedLoad: function(){
				this.show = true;
				LoadBookingService.checkCarrierAlreadyBookedLoad(this.getLoadNumber, this.company.id).then(response => {
					this.carrierAlreadyBookedLoad = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			checkCarrierAlreadyBidLoad: function(){
				this.show = true;
				LoadBidService.checkCarrierAlreadyBidLoad(this.getLoadNumber, this.company.id).then(response => {
					this.carrierAlreadyBidLoad = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			checkBookingsLimitReached: function(){
				this.show = true;
				BookingService.checkBookingsLimitReached(this.getLoadNumber).then(response => {
					this.bookingsLimitReached = response.data.body;
					if (!this.bookingsLimitReached) {
						this.findByAssociatedUserUsername();
					}
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},
		}
	};
</script>