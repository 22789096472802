<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10"></div>
					<div class="col-md-2">
						<router-link :to="{ name: 'search-loads' }">
							<button class="btn btn-primary btn-block">Back</button>
	   					</router-link>
					</div>
				</div>
	   			<br><br>
	   			
	   			<div v-if="!bidsLimitReached">
					<div class="row" v-if="!carrierAlreadyBookedLoad && !carrierAlreadyBidLoad">
						<div v-if="company !== null">
							<div class="col-md-6">
								<form class="form" v-if="company.trucks.length > 0" @submit.prevent="placeBid">
									<h4>Place your bid for this load {{ getLoadNumber }}</h4>
									<hr>
									<p>After winning the bid, we'll notify you via SMS and email and you'll have 15 minutes to accept the offer.</p>
									<p>Be on the lookout for business</p>
									<br>
									<div class="form-group">
										<label>Assign truck</label>
										<select class="form-control" v-model="bid.assignedTruckId" required>
											<option value="" disabled>Select option</option>
											<option v-for="truck in company.trucks" :key="truck.id" :value="truck.id">Truck plate number {{ truck.registrationNumber }} - {{ truck.type }} truck</option>
										</select>
									</div>
									<span v-if="load !== null">
										<h4>Load price is at <span class="label label-warning">{{ load.currency }}{{ load.price }}</span></h4>
									</span>
									<br>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Your bid amount</label>
												<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="bid.amount" placeholder="Enter bid amount"/>
											</div>	
										</div>
									</div>
									<div class="row">
										<div class="col-md-9"></div>
										<div class="col-md-3">
											<div class="form-group">
												<label>&nbsp;</label>
												<button class="btn btn-primary btn-block">PLACE BID</button>
											</div>
										</div>
									</div>
								</form>
								<div v-if="company.trucks.length === 0">
									<p class="text-danger">You haven't posted any truck! <a :href="VUE_APP_TMS_NEW_TRUCK" target="_blank">Login to Nelspruit Freight TMS and post your truck or fleet here to bid this load.</a></p>
									<br>
								</div>
							</div>
						</div>
					</div>
					<div v-show="carrierAlreadyBidLoad">
						<h4>You have already bid this load. <router-link :to="{ name: 'search-loads' }">Search more loads</router-link></h4>
					</div>
					<div v-show="carrierAlreadyBookedLoad">
						<h4>You have already booked this load. <router-link :to="{ name: 'search-loads' }">Search more loads</router-link></h4>
					</div>
				</div>
				<div v-if="bidsLimitReached">
					<h4>Oops, this load is no longer accepting new bids. <router-link :to="{ name: 'search-loads' }"> Search other loads</router-link></h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BidService from "@/services/BidService";
	import Bid from "@/models/Bid";
	import CompanyService from "@/services/CompanyService";
	import LoadBookingService from "@/services/LoadBookingService";
	import LoadBidService from "@/services/LoadBidService";
	import LoadService from "@/services/LoadService";

	export default{
		name: 'NewBid',
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request',
				bid: new Bid,
				company: null,
				load: null,
				carrierAlreadyBidLoad: false,
				carrierAlreadyBookedLoad: false,
				bidsLimitReached: null,
				VUE_APP_TMS_NEW_TRUCK: process.env.VUE_APP_TMS_NEW_TRUCK
			}
		},

		mounted(){
			this.checkBidsLimitReached();
		},

		computed: {
			getLoadNumber: function(){ 
				return this.$route.params.loadNumber; 
			},

			getUser: function() {
				return this.$store.state.auth.user.user;
		    }
		},

		methods: {
			findByLoadNumber: function(){
				this.show = true;
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			placeBid: function(){
				this.show = true;
			    this.bid.loadNumber = this.getLoadNumber;
			    this.bid.carrierUsername = this.$store.state.auth.user.user.username;
			    this.bid.awarded = false;
				BidService.save(this.bid).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'carrier/bids' });
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			findByAssociatedUserUsername: function(){
				this.show = true;
				CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
					this.company = response.data.body;
					if (this.company !== null) {
						this.checkCarrierAlreadyBookedLoad();
						this.checkCarrierAlreadyBidLoad();
						this.findByLoadNumber();
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			checkCarrierAlreadyBookedLoad: function(){
				this.show = true;
				LoadBookingService.checkCarrierAlreadyBookedLoad(this.getLoadNumber, this.company.id).then(response => {
					this.carrierAlreadyBookedLoad = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			checkCarrierAlreadyBidLoad: function(){
				this.show = true;
				LoadBidService.checkCarrierAlreadyBidLoad(this.getLoadNumber, this.company.id).then(response => {
					this.carrierAlreadyBidLoad = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			checkBidsLimitReached: function(){
				this.show = true;
				BidService.checkBidsLimitReached(this.getLoadNumber).then(response => {
					this.bidsLimitReached = response.data.body;
					if (!this.bidsLimitReached) {
						this.findByAssociatedUserUsername();
					}
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},
		}
	};
</script>