import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class CompanyService {
  save(company) {
    return axios.post(GENERIC_SERVICE_URL + "companies" + "/", {
        id: company.id,
        uuid: company.uuid,
        type: company.type,
        name: company.name,
        shortName: company.shortName,
        businessPartnerNumber: company.businessPartnerNumber,
        phoneNumbers: company.phoneNumbers,
        email: company.email,
        faxNumber: company.faxNumber,
        websiteUrl: company.websiteUrl,
        businessSectorId: company.businessSectorId,
        businessCategories: company.businessCategories,
        organizationType: company.organizationType,
        establishedYear: company.establishedYear,
        numberOfEmployees: company.numberOfEmployees,
        address: company.address,
        postalAddress: company.postalAddress,
        associatedUserUsername: company.associatedUserUsername,
        roadMotorTransportNumber: company.roadMotorTransportNumber,
        roadMotorTransportNumberExpiryDate: company.roadMotorTransportNumberExpiryDate,
        carrierType: company.carrierType,
        shareholders: company.shareholders,
        attachments: company.attachments,
        otherCreditFacilities: company.otherCreditFacilities,
        financialHistories: company.financialHistories,
        accounts: company.accounts,
        tradingName: company.tradingName,
        description: company.description,
        brokerNumber: company.brokerNumber,
        customsAgentPermitNumber: company.customsAgentPermitNumber,
      }
    );
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "companies");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "companies/" + id);
  }

  findByUuid(uuid) {
    return axios.get(GENERIC_SERVICE_URL + "companies/findByUuid/" + uuid);
  }

  findByAssociatedUserUsername(userId) {
    return axios.get(GENERIC_SERVICE_URL + "companies/findByAssociatedUserUsername/" + userId);
  }

  verify(uuid) {
    return axios.post(GENERIC_SERVICE_URL + "companies/verify/" + uuid);
  }

  findPremiumCarriers() {
    return axios.get(GENERIC_SERVICE_URL + "companies/findPremiumCarriers");
  }
}
export default new CompanyService();
