import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/auth/Login.vue";
import ShipperRegister from "@/views/auth/ShipperRegister.vue";
import CarrierRegister from "@/views/auth/CarrierRegister.vue";
import BrokerRegister from "@/views/auth/BrokerRegister.vue";
import OTP from "@/views/auth/OTP";
import CheckSubscription from "@/views/auth/CheckSubscription";
import AccountVerification from "@/views/auth/AccountVerification";

import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import SubscriptionExpired from "@/views/auth/SubscriptionExpired.vue";

import DashboardNavigation from "@/components/DashboardNavigation.vue";
import Settings from "@/views/administration/Settings";

import Dashboard from "@/views/dashboard/Dashboard.vue";
import HelpCenter from "@/views/help-center/HelpCenter.vue";
import NotFound from "@/components/NotFound.vue";

import UserProfile from "@/views/users/UserProfile.vue";
import UserList from "@/views/users/UserList.vue";
import ViewUser from "@/views/users/ViewUser.vue";
import NewUser from "@/views/users/NewUser.vue";

import RoleList from "@/views/roles/RoleList";
import NewRole from "@/views/roles/NewRole";
import ViewRole from "@/views/roles/ViewRole";

import NewBusinessDocuments from "@/views/attachments/NewBusinessDocuments.vue";

import PaymentList from "@/views/payments/PaymentList.vue";
import MyPayments from "@/views/payments/MyPayments.vue";
import ViewPayment from "@/views/payments/ViewPayment.vue";

import NewOrUpdateZipCode from "@/views/zipCodes/NewOrUpdateZipCode.vue";
import ZipCodeList from "@/views/zipCodes/ZipCodeList.vue";
import ViewZipCode from "@/views/zipCodes/ViewZipCode.vue";

import NewCountry from "@/views/countries/NewCountry.vue";
import CountryList from "@/views/countries/CountryList.vue";
import ViewCountry from "@/views/countries/ViewCountry.vue";

import NewProvince from "@/views/provinces/NewProvince.vue";
import ProvinceList from "@/views/provinces/ProvinceList.vue";
import ViewProvince from "@/views/provinces/ViewProvince.vue";

import NewCity from "@/views/cities/NewCity.vue";
import CityList from "@/views/cities/CityList.vue";
import ViewCity from "@/views/cities/ViewCity.vue";

import NewArea from "@/views/areas/NewArea.vue";
import AreaList from "@/views/areas/AreaList.vue";
import ViewArea from "@/views/areas/ViewArea.vue";


import CompanyList from "@/views/companies/CompanyList";
import NewCompany from "@/views/companies/NewCompany";
import ViewCompany from "@/views/companies/ViewCompany";
import AddressComponent from "@/views/locations/AddressComponent.vue";
import PostalAddressComponent from "@/views/locations/PostalAddressComponent.vue";

import NewPayment from "@/views/payments/NewPayment.vue";

import UserDigitalSignature from "@/views/users/UserDigitalSignature";
import UserPhoto from "@/views/users/UserPhoto";

import NewBankAccount from "@/views/bankAccounts/NewBankAccount";
import BankAccountList from "@/views/bankAccounts/BankAccountList";

import Chart from "@/views/charts/Chart.vue";

import ExchangeRateList from "@/views/exchangeRates/ExchangeRateList";
import NewExchangeRate from "@/views/exchangeRates/NewExchangeRate";
import ViewExchangeRate from "@/views/exchangeRates/ViewExchangeRate";

import OperationalDashboard from "@/views/dashboard/OperationalDashboard";
import PerformanceDashboard from "@/views/dashboard/PerformanceDashboard";

import SearchLoads from "@/views/loads/SearchLoads"
import ViewLoad from "@/views/loads/ViewLoad"
import ViewBackHaulLoad from "@/views/loads/ViewBackHaulLoad"
import PrivateLoads from "@/views/loads/PrivateLoads"

import NewBid from "@/views/bids/NewBid"
import NewBooking from "@/views/bookings/NewBooking"
import NewLoad from "@/views/loads/NewLoad"

import BidOffer from "@/views/bids/BidOffer";
import BookingOffer from "@/views/bookings/BookingOffer";
import LoadPaymentCheckout from "@/views/payments/LoadPaymentCheckout";
import UpdateLoadDeliveryStatus from "@/views/shipments/UpdateLoadDeliveryStatus";

import ViewTruck from "@/views/trucks/ViewTruck";
import SearchTrucks from "@/views/trucks/SearchTrucks";

import CheckProfile from "@/views/profile/CheckProfile";
import NewBusinessProfile from "@/views/profile/NewBusinessProfile";

import UploadLoads from "@/views/loads/UploadLoads";
import TmsIntegration from "@/views/tms/TmsIntegration";

import ApiIntegration from "@/views/apiIntegration/ApiIntegration";

import CarrierBrokerList from "@/views/brokers/CarrierBrokerList";
import CarrierLoadAlert from "@/views/loadAlerts/CarrierLoadAlert";
import ViewAlert from "@/views/loadAlerts/ViewAlert";
import LoadDensity from "@/views/loads/LoadDensity";
import NewLoadAlert from "@/views/loadAlerts/NewLoadAlert";
import LoadAlertLoads from "@/views/loads/LoadAlertLoads";
import MatchedLoads from "@/views/loads/MatchedLoads";

import ShipperLoadLeads from "@/views/loads/ShipperLoadLeads";
import AwardShipperBid from "@/views/loads/AwardShipperBid";

import MyLoadList from "@/views/loads/MyLoadList";
import CarrierMyLoadList from "@/views/loads/CarrierMyLoadList";
import CarrierLoadBid from "@/views/bids/CarrierLoadBid";

import AcceptOrDeclineBookingOffer from "@/views/bookings/AcceptOrDeclineBookingOffer";
import AcceptOrDeclineBidOffer from "@/views/bids/AcceptOrDeclineBidOffer";

import CarrierPaymentList from "@/views/payments/CarrierPaymentList";
import AuthorizePayment from "@/views/payments/AuthorizePayment";
import ReleasePayment from "@/views/payments/ReleasePayment";
import ReleasePayments from "@/views/payments/ReleasePayments";
import ReadyForAuthorizationPayments from "@/views/payments/ReadyForAuthorizationPayments";

import BusinessProfile from "@/views/profile/BusinessProfile";

import NewLoadInformation from "@/views/loads/NewLoadInformation";
import NewLoadTiming from "@/views/loads/NewLoadTiming";
import NewContactInformationForCarrier from "@/views/loads/NewContactInformationForCarrier";
import NewOtherLoadDetails from "@/views/loads/NewOtherLoadDetails";
import NewLoadPriceAndOffers from "@/views/loads/NewLoadPriceAndOffers";

import AwardBooking from "@/views/bookings/AwardBooking";
import AwardBid from "@/views/bids/AwardBid";
import Bids from "@/views/bids/Bids";
import NewCarrierLoadBid from "@/views/loads/NewCarrierLoadBid";

import CarrierBookings from "@/views/bookings/CarrierBookings"
import CarrierBids from "@/views/bids/CarrierBids"

import MyBookings from "@/views/bookings/MyBookings"
import ViewBooking from "@/views/bookings/ViewBooking"
import PayInvoice from "@/views/invoices/PayInvoice"

import MyBids from "@/views/bids/MyBids"

import TestPayment from "@/views/payments/TestPayment"
import PaymentUpdate from "@/views/payments/PaymentUpdate.vue";
import LoadPaymentResponse from "@/views/payments/LoadPaymentResponse.vue";
import MakePayment from "@/views/payments/MakePayment.vue";
import ProofOfPayment from "@/views/payments/ProofOfPayment.vue";

import SubscriptionList from "@/views/subscriptions/SubscriptionList.vue";
import NewSubscription from "@/views/subscriptions/NewSubscription.vue";
import MySubscription from "@/views/subscriptions/MySubscription.vue";
import SubscriptionPaymentResponse from "@/views/payments/SubscriptionPaymentResponse.vue";
import SubscriptionRenewalPaymentResponse from "@/views/payments/SubscriptionRenewalPaymentResponse.vue";
import NewSubscriptionConfiguration from "@/views/subscriptions/NewSubscriptionConfiguration.vue";
import ViewSubscription from "@/views/subscriptions/ViewSubscription.vue";

import MeasureDistance from "@/views/distance/MeasureDistance.vue";

import TestComponent from "@/views/TestComponent.vue";
import DeliveryLocation from "@/views/distance/DeliveryLocation.vue";

import NewLoadPickupAndDropOffAddress from "@/views/loads/NewLoadPickupAndDropOffAddress.vue";

import CarrierMyPayments from "@/views/payments/CarrierMyPayments.vue";

import VerifyCompany from "@/views/companies/VerifyCompany.vue";
import PublishLoad from "@/views/loads/PublishLoad.vue";

import ViewBid from "@/views/bids/ViewBid.vue";

import UpgradeSubscription from "@/views/subscriptions/UpgradeSubscription";
import DowngradeSubscription from "@/views/subscriptions/DowngradeSubscription";
import CancelSubscription from "@/views/subscriptions/CancelSubscription";


import CarrierLoadsDashboard from "@/views/dashboard/CarrierLoadsDashboard";
import ContactInformationForCarrier from "@/views/carrierShipments/ContactInformationForCarrier";
import CarrierPaymentsDashboard from "@/views/dashboard/CarrierPaymentsDashboard";

import ShipmentsDashboard from "@/views/dashboard/ShipmentsDashboard";
import CancelBooking from "@/views/bookings/CancelBooking";
import DeclineBookingConfirmation from "@/views/bookings/DeclineBookingConfirmation";

import NewSubscriptionInvoice from "@/views/subscriptions/NewSubscriptionInvoice";
import RenewSubscriptionInvoice from "@/views/subscriptions/RenewSubscriptionInvoice";
import UpgradeSubscriptionInvoice from "@/views/subscriptions/UpgradeSubscriptionInvoice";

import PaginationAndSortingTest from "@/views/PaginationAndSortingTest";
import TutorialList from "@/views/TutorialList";

import SubscriptionPlanList from "@/views/subscription-plans/SubscriptionPlanList";
import NewSubscriptionPlan from "@/views/subscription-plans/NewSubscriptionPlan";

import InvoicePaymentList from "@/views/payments/InvoicePaymentList";

import AllPaymentsComponent from "@/views/payments/AllPaymentsComponent";
import WithholdingPaymentsComponent from "@/views/payments/WithholdingPaymentsComponent";
import AuthorizedPaymentsComponent from "@/views/payments/AuthorizedPaymentsComponent";
import PaidPaymentsComponent from "@/views/payments/PaidPaymentsComponent";
import OutstandingPaymentsComponent from "@/views/payments/OutstandingPaymentsComponent";
import ViewPaymentDetails from "@/views/payments/ViewPaymentDetails";

import FinancialInstitutionList from "@/views/financial-institutions/FinancialInstitutionList";
import NewFinancialInstitution from "@/views/financial-institutions/NewFinancialInstitution";
import ViewFinancialInstitution from "@/views/financial-institutions/ViewFinancialInstitution";
import ViewTrailer from "@/views/trucks/ViewTrailer";
import SubscriptionUpgradePaymentResponse from "@/views/payments/SubscriptionUpgradePaymentResponse";

import MyLoads from "@/views/loads/MyLoads";



import LoadChargeList from "@/views/loadCharges/LoadChargeList";
import NewLoadCharge from "@/views/loadCharges/NewLoadCharge";
import EditLoadCharge from "@/views/loadCharges/EditLoadCharge";
import ViewLoadCharge from "@/views/loadCharges/ViewLoadCharge";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: NotFound
  },

  {
    path: "/",
    component: Login
  },

  {
    path: "/otp",
    name: "otp",
    component: OTP
  },

  {
    path: "/check-subscription",
    name: "check-subscription",
    component: CheckSubscription
  },

  {
    path: "/account-verification",
    name: "account-verification",
    component: AccountVerification
  },

  {
    path: "/password-reset",
    name: "password-reset",
    component: ChangePassword
  },

  {
    path: "/new-business-profile",
    name: "new-business-profile",
    component: NewBusinessProfile
  },

  {
    path: "/new-business-profile/address/:uuid",
    name: "new-business-profile/address",
    component: AddressComponent
  },
  
  {
    path: "/new-business-profile/postalAddress/:uuid",
    name: "new-business-profile/postalAddress",
    component: PostalAddressComponent
  },

  {
    path: "/new-business-profile/business-documents/:uuid",
    name: "new-business-profile/business-documents",
    component: NewBusinessDocuments
  },

  {
    path: "/new-business-profile/bank-accounts/:uuid",
    name: "new-business-profile/bank-accounts",
    component: NewBankAccount
  },

  {
    path: "/new-business-profile/subscriptions/:uuid",
    name: "new-business-profile/subscriptions",
    component: NewSubscription
  },

  {
    path: "/companies/subscriptions/paymentResponse/:companyId",
    name: "companies/subscriptions/paymentResponse",
    component: SubscriptionPaymentResponse
  },

  {
    path: "/init/abstract-bean-factory-initializer-response",
    name: "init/abstract-bean-factory-initializer-response",
    component: SubscriptionPaymentResponse
  },

  {
    path: "/init/abstract-bean-factory-initializer-renewal-response",
    name: "init/abstract-bean-factory-initializer-renewal-response",
    component: SubscriptionRenewalPaymentResponse
  },

  {
    path: "/new-subscription-invoice",
    name: "new-subscription-invoice",
    component: NewSubscriptionInvoice
  },

  {
    path: "/login",
    name: "login",
    component: Login
  },

  {
    path: "/carrier-register",
    name: "carrier-register",
    component: CarrierRegister
  },

  {
    path: "/shipper-register",
    name: "shipper-register",
    component: ShipperRegister
  },

  {
    path: "/broker-register",
    name: "broker-register",
    component: BrokerRegister
  },

  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword
  },

  {
    path: "/subscription-expired",
    name: "subscription-expired",
    component: SubscriptionExpired
  },

  {
    path: "/dashboardNavigation",
    name: "dashboardNavigation",
    component: DashboardNavigation,
    children: [
      {
        path: "/init/abstract-bean-factory-initializer-upgrade-response",
        name: "init/abstract-bean-factory-initializer-upgrade-response",
        component: SubscriptionUpgradePaymentResponse
      },

      {
        path: "/carrier-my-loads",
        name: "carrier-my-loads",
        component: CarrierLoadsDashboard
      },

      {
        path: "/decline-booking-confirmation/:bookingNumber",
        name: "decline-booking-confirmation",
        component: DeclineBookingConfirmation
      },

      {
        path: '/contact-information-for-carrier/:loadNumber',
        name: 'contact-information-for-carrier',
        component: ContactInformationForCarrier
      },

      {
        path: "/cancel-booking/:bookingNumber/:loadNumber",
        name: "cancel-booking",
        component: CancelBooking
      },

      {
        path: "/carrier-my-payments",
        name: "carrier-my-payments",
        component: CarrierPaymentsDashboard
      },

      {
        path: "/measureDistance",
        name: "measureDistance",
        component: MeasureDistance
      },

      {
        path: "/shipments",
        name: "shipments",
        component: ShipmentsDashboard
      },

      {
        path: "/upgrade-subscription",
        name: "upgrade-subscription",
        component: UpgradeSubscription
      },

      {
        path: "/downgrade-subscription",
        name: "downgrade-subscription",
        component: DowngradeSubscription
      },

      {
        path: "/cancel-subscription",
        name: "cancel-subscription",
        component: CancelSubscription
      },

      {
        path: "/testComponent",
        name: "testComponent",
        component: TestComponent
      },

      {
        path: "/deliveryLocation",
        name: "deliveryLocation",
        component: DeliveryLocation
      },

      {
        path: "/subscriptions",
        name: "subscriptions",
        component: SubscriptionList
      },

      {
        path: "/subscriptions/new",
        name: "subscriptions/new",
        component: NewSubscriptionConfiguration
      },

      {
        path: "/subscriptions/view/:subscriptionNumber",
        name: "subscriptions/view",
        component: ViewSubscription
      },

      {
        path: "/my-subscription",
        name: "my-subscription",
        component: MySubscription
      },

      {
        path: "/payments/paymentUpdate",
        name: "payments/paymentUpdate",
        component: PaymentUpdate
      },

      {
        path: "/post-init/abstract-singleton-proxy-factory-response",
        name: "post-init/abstract-singleton-proxy-factory-response",
        component: LoadPaymentResponse
      },

      {
        path: "/payments/makePayment",
        name: "payments/makePayment",
        component: MakePayment
      },

      {
        path: "/payments/proofOfPayment",
        name: "payments/proofOfPayment",
        component: ProofOfPayment
      },

      {
        path: "/business-profile",
        name: "business-profile",
        component: BusinessProfile
      },

      {
        path: "/profile/check",
        name: "profile/check",
        component: CheckProfile
      },

      {
        path: "/bookings/view/:bookingNumber",
        name: "bookings/view",
        component: ViewBooking
      },

      {
        path: "/shipper/testPayment",
        name: "shipper/testPayment",
        component: TestPayment
      },

      {
        path: "/new-load-basic-details",
        name: "new-load-basic-details",
        component: NewLoadInformation
      },

      {
        path: "/new-load-timing/:loadNumber",
        name: "new-load-timing",
        component: NewLoadTiming
      },

      {
        path: "/loads/new-load-pickup-and-drop-off-address/:loadNumber",
        name: "new-load-pickup-and-drop-off-address",
        component: NewLoadPickupAndDropOffAddress
      },

      {
        path: "/new-load-price-and-offers/:loadNumber",
        name: "new-load-price-and-offers",
        component: NewLoadPriceAndOffers
      },

      {
        path: "/new-load-contact-information-for-carrier/:loadNumber",
        name: "new-load-contact-information-for-carrier",
        component: NewContactInformationForCarrier
      },

      {
        path: "/loads/new/otherLoadDetails/:loadNumber",
        name: "loads/new/otherLoadDetails",
        component: NewOtherLoadDetails
      },

      {
        path: "/search-loads",
        name: "search-loads",
        component: SearchLoads,
      },

      {
        path: "/dashboard-search-loads",
        name: "dashboard-search-loads",
        component: SearchLoads, // loading same component does not refresh the data list
      },

      {
        path: "/matched-loads/:loadAlertNumber",
        name: "matched-loads",
        component: MatchedLoads
      },

      {
        path: "/private-loads",
        name: "private-loads",
        component: PrivateLoads
      },

      {
        path: "/view-bid/:bidNumber",
        name: "view-bid",
        component: ViewBid
      },

      {
        path: "/carrier/loads/my-broker",
        name: "carrier/loads/my-broker",
        component: CarrierBrokerList
      },

      {
        path: "/carrier/loads/my-loads",
        name: "carrier/loads/my-loads",
        component: CarrierMyLoadList
      },

      {
        path: "/carrier/loads/load-alerts",
        name: "carrier/loads/load-alerts",
        component: CarrierLoadAlert
      },

      {
        path: "/view-load-alert/:loadAlertNumber",
        name: "view-load-alert",
        component: ViewAlert
      },

      {
        path: "/carrier/loads/load-alerts/new",
        name: "carrier/loads/load-alerts/new",
        component: NewLoadAlert
      },

      {
        path: "/carrier/loads/load-alerts/view/:loadAlertNumber",
        name: "carrier/loads/load-alerts/view",
        component: LoadAlertLoads
      },

      // {
      //   path: "matched-loads/:loadAlertNumber",
      //   name: "matched-loads",
      //   component: MatchedLoads
      // },

      {
        path: "/load-density",
        name: "load-density",
        component: LoadDensity
      },

      {
        path: "/carrier/loads/bids",
        name: "carrier/loads/bids",
        component: CarrierLoadBid
      },

      {
        path: "/carrier/bookings/accept-or-decline-offer/:loadNumber/:bookingNumber",
        name: "carrier/bookings/accept-or-decline-offer",
        component: AcceptOrDeclineBookingOffer
      },

      {
        path: "/carrier/bids/accept-or-decline-offer/:loadNumber/:bidNumber",
        name: "carrier/bids/accept-or-decline-offer",
        component: AcceptOrDeclineBidOffer
      },

      {
        path: "/shipper/loads/leads",
        name: "shipper/loads/leads",
        component: ShipperLoadLeads
      },

      {
        path: "/my-loads",
        name: "my-loads",
        component: MyLoadList
      },

      {
        path: "/my-load-list",
        name: "my-load-list",
        component: MyLoads
      },

      {
        path: "/shipper/loads/leads/awardBid/:bidNumber",
        name: "shipper/loads/leads/awardBid",
        component: AwardShipperBid
      },

      {
        path: "/award-booking/:loadNumber/:bookingNumber",
        name: "award-booking",
        component: AwardBooking
      },

      {
        path: "/award-bid/:loadNumber/:bidNumber",
        name: "award-bid",
        component: AwardBid
      },

      {
        path: "/broker/loads/upload",
        name: "broker/loads/upload",
        component: UploadLoads
      },

      {
        path: "/broker/loads/tms-integration",
        name: "broker/loads/tms-integration",
        component: TmsIntegration
      },

      {
        path: "/broker/loads/api-integration",
        name: "broker/loads/api-integration",
        component: ApiIntegration
      },

      {
        path: "/operationalDashboard",
        name: "operationalDashboard",
        component: OperationalDashboard
      },

      {
        path: "/view-load/:loadNumber",
        name: "view-load",
        component: ViewLoad
      },

      {
        path: "/view-backhaul-load/:loadNumber",
        name: "view-backhaul-load",
        component: ViewBackHaulLoad
      },

      {
        path: "/loads/bid/:loadNumber",
        name: "loads/bid",
        component: NewBid
      },

      {
        path: "/book-load/:loadNumber",
        name: "book-load",
        component: NewBooking
      },

      {
        path: "/publish-load/:loadNumber",
        name: "publish-load",
        component: PublishLoad
      },

      {
        path: "/bid-load/:loadNumber",
        name: "bid-load",
        component: NewBid
      },

      {
        path: "/carrier/loads/bidding/:loadNumber",
        name: "carrier/loads/bidding",
        component: NewCarrierLoadBid
      },

      {
        path: "/carrier/bookings",
        name: "carrier/bookings",
        component: CarrierBookings
      },

      {
        path: "/carrier/bids",
        name: "carrier/bids",
        component: CarrierBids
      },

      {
        path: "/my-bookings",
        name: "my-bookings",
        component: MyBookings
      },


      {
        path: "/pay-invoice/:loadNumber/:bookingNumber",
        name: "pay-invoice",
        component: PayInvoice
      },

      {
        path: "/my-bids",
        name: "my-bids",
        component: MyBids
      },

      {
        path: "/carrier/my-payments",
        name: "carrier/my-payments",
        component: CarrierMyPayments
      },

      {
        path: "/shipper/loads/bids",
        name: "shipper/loads/bids",
        component: Bids
      },

      {
        path: "/loads/new",
        name: "loads/new",
        component: NewLoad
      },

      {
        path: "/loads/bids/offers/:bidNumber",
        name: "loads/bids/offers",
        component: BidOffer
      },

      {
        path: "/bookings/bookingOffer/:bookingNumber",
        name: "bookings/bookingOffer",
        component: BookingOffer
      },

      {
        path: "/loads/payment-checkout/:loadNumber/:bookingNumber",
        name: "loads/payment-checkout",
        component: LoadPaymentCheckout
      },

       {
        path: "/update-delivery-status/:loadNumber/:bookingNumber",
        name: "update-delivery-status",
        component: UpdateLoadDeliveryStatus
      },

      {
        path: "/carrier/loads/payments",
        name: "carrier/loads/payments",
        component: CarrierPaymentList
      },

      {
        path: "/payment-list",
        name: "payment-list",
        component: PaymentList,
        children: [
          {
            path: "/payments/authorize-payment/:invoiceNumber",
            name: "payments/authorize-payment",
            component: AuthorizePayment
          },

          {
            path: "/release-payment/:invoiceNumber",
            name: "release-payment",
            component: ReleasePayment
          },

          {
            path: "/payment-list",
            name: "payment-list",
            component: AllPaymentsComponent
          },

          {
            path: "/withholding-payments",
            name: "withholding-payments",
            component: WithholdingPaymentsComponent
          },

          {
            path: "/authorized-payments",
            name: "authorized-payments",
            component: AuthorizedPaymentsComponent
          },

          {
            path: "/paid-payments",
            name: "paid-payments",
            component: PaidPaymentsComponent
          },

          {
            path: "/outstanding-payments",
            name: "outstanding-payments",
            component: OutstandingPaymentsComponent
          },

          {
            path: "/view-payment-details/:invoiceNumber",
            name: "view-payment-details",
            component: ViewPaymentDetails
          },

          {
            path: "/ready-for-authorization-payments",
            name: "ready-for-authorization-payments",
            component: ReadyForAuthorizationPayments
          }
        ]
      },

      {
        path: "/my-payments",
        name: "my-payments",
        component: MyPayments
      },

      {
        path: "/payments/releasePayments",
        name: "payments/releasePayments",
        component: ReleasePayments
      },

      {
        path: "/search-trucks",
        name: "search-trucks",
        component: SearchTrucks
      },

      {
        path: "/trucks/view/:truckNumber",
        name: "trucks/view",
        component: ViewTruck
      },

      {
        path: "/performanceDashboard",
        name: "performanceDashboard",
        component: PerformanceDashboard
      },

      {
        path: "/charts",
        name: "charts",
        component: Chart
      },

      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard
      },

      {
        path: "/bankAccounts",
        name: "bankAccounts",
        component: BankAccountList
      },

      {
        path: "/bankAccounts/new",
        name: "bankAccounts/new",
        component: NewBankAccount
      },

      {
        path: "/bankAccounts/edit/:bankAccountId",
        name: "bankAccounts/edit",
        component: NewBankAccount
      },

      {
        path: "/proposals/viewProofOfPayment/:proposalId",
        name: "proposals/viewProofOfPayment",
        component: ViewPayment
      },

      {
        path: "/proposals/payments/:proposalId",
        name: "proposals/payments",
        component: NewPayment
      },

      {
        path: "/view-payment/:loadNumber",
        name: "view-payment",
        component: ViewPayment
      },

      {
        path: "/zipCodes",
        name: "zipCodes",
        component: ZipCodeList
      },

      {
        path: "/zipCodes/new",
        name: "zipCodes/new",
        component: NewOrUpdateZipCode
      },

      {
        path: "/zipCodes/view/:zipCodeId",
        name: "zipCodes/view",
        component: ViewZipCode
      },

      {
        path: "/zipCodes/edit/:zipCodeId",
        name: "zipCodes/edit",
        component: NewOrUpdateZipCode
      },

      {
        path: "/countries",
        name: "countries",
        component: CountryList
      },
      {
        path: "/countries/new",
        name: "countries/new",
        component: NewCountry
      },
      {
        path: "/countries/view/:countryId",
        name: "countries/view",
        component: ViewCountry
      },
      {
        path: "/countries/edit/:countryId",
        name: "countries/edit",
        component: NewCountry
      },

      {
        path: "/provinces",
        name: "provinces",
        component: ProvinceList
      },
      {
        path: "/provinces/new",
        name: "provinces/new",
        component: NewProvince
      },
      {
        path: "/provinces/view/:provinceId",
        name: "provinces/view",
        component: ViewProvince
      },
      {
        path: "/provinces/edit/:provinceId",
        name: "provinces/edit",
        component: NewProvince
      },

      {
        path: "/cities",
        name: "cities",
        component: CityList
      },
      {
        path: "/cities/new",
        name: "cities/new",
        component: NewCity
      },
      {
        path: "/cities/view/:cityId",
        name: "cities/view",
        component: ViewCity
      },
      {
        path: "/cities/edit/:cityId",
        name: "cities/edit",
        component: NewCity
      },

      {
        path: "/areas",
        name: "areas",
        component: AreaList
      },
      {
        path: "/areas/new",
        name: "areas/new",
        component: NewArea
      },
      {
        path: "/areas/view/:areaId",
        name: "areas/view",
        component: ViewArea
      },
      {
        path: "/areas/edit/:areaId",
        name: "areas/edit",
        component: NewArea
      },

      {
        path: "/roles",
        name: "roles",
        component: RoleList
      },
      {
        path: "/roles/new",
        name: "roles/new",
        component: NewRole
      },
      {
        path: "/roles/view/:roleId",
        name: "roles/view",
        component: ViewRole
      },
      {
        path: "/roles/edit/:roleId",
        name: "roles/edit",
        component: NewRole
      },

      {
        path: "/exchangeRates",
        name: "exchangeRates",
        component: ExchangeRateList
      },
      {
        path: "/exchangeRates/new",
        name: "exchangeRates/new",
        component: NewExchangeRate
      },
      {
        path: "/exchangeRates/view/:exchangeRateId",
        name: "exchangeRates/view",
        component: ViewExchangeRate
      },

      {
        path: "/userProfile",
        name: "userProfile",
        component: UserProfile
      },

      {
        path: "/companies",
        name: "companies",
        component: CompanyList
      },
      {
        path: "/companies/new",
        name: "companies/new",
        component: NewCompany
      },
      
      {
        path: "/companies/view/:companyId",
        name: "companies/view",
        component: ViewCompany
      },

      {
        path: "/companies/verify/:uuid",
        name: "companies/verify",
        component: VerifyCompany
      },

      {
        path: "/settings",
        name: "settings",
        component: Settings
      },
      {
        path: "/help-center",
        name: "help-center",
        component: HelpCenter
      },

      {
        path: "/users",
        name: "users",
        component: UserList
      },

      {
        path: "/users/new",
        name: "users/new",
        component: NewUser
      },

      {
        path: "/users/new/digitalSignature/:userId",
        name: "users/new/digitalSignature",
        component: UserDigitalSignature
      },

      {
        path: "/users/new/photo/:userId",
        name: "users/new/photo",
        component: UserPhoto
      },

      {
        path: "/users/view/:userId",
        name: "users/view",
        component: ViewUser
      },

      {
        path: "/users/edit/:userId",
        name: "users/edit",
        component: NewUser
      },

      {
        path: "/payments/new",
        name: "payments/new",
        component: NewPayment
      },

      {
        path: "/pagination-and-sorting-test",
        name: "pagination-and-sorting-test",
        component: PaginationAndSortingTest
      },

      {
        path: "/tutorials",
        name: "tutorials",
        component: TutorialList
      },

      {
        path: "/subscription-plans",
        name: "subscription-plans",
        component: SubscriptionPlanList
      },

      {
        path: "/new-subscription-plan",
        name: "new-subscription-plan",
        component: NewSubscriptionPlan
      },

      {
        path: "/invoice-list",
        name: "invoice-list",
        component: InvoicePaymentList
      },

      {
        path: "/view-trailer/:trailerNumber",
        name: "view-trailer",
        component: ViewTrailer
      },

      {
        path: "/financial-institution-list",
        name: "financial-institution-list",
        component: FinancialInstitutionList
      },

      {
        path: "/new-financial-institution",
        name: "new-financial-institution",
        component: NewFinancialInstitution
      },

      {
        path: "/view-financial-institution/:financialInstitutionId",
        name: "view-financial-institution",
        component: ViewFinancialInstitution
      },

      {
        path: "/renew-subscription-invoice",
        name: "renew-subscription-invoice",
        component: RenewSubscriptionInvoice
      },


      {
        path: "/upgrade-subscription-invoice/:subscriptionPlanNumber",
        name: "upgrade-subscription-invoice",
        component: UpgradeSubscriptionInvoice
      },

      {
        path: "/load-charge-list",
        name: "load-charge-list",
        component: LoadChargeList
      },

      {
        path: "/new-load-charge",
        name: "new-load-charge",
        component: NewLoadCharge
      },

      {
        path: "/edit-load-charge/:id",
        name: "edit-load-charge",
        component: EditLoadCharge
      },

      {
        path: "/view-load-charge/:id",
        name: "view-load-charge",
        component: ViewLoadCharge
      },
    ]
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = [ 
    "login", "otp", "forgotPassword", "profile/check", "register", "new-business-profile",
    "new-business-profile/address", "new-business-profile/postalAddress", "new-business-profile/business-documents",
    "new-business-profile/bank-accounts", "new-business-profile/subscriptions", 
    "init/abstract-bean-factory-initializer-response", "post-init/abstract-singleton-proxy-factory-response", 
    "password-reset", "subscription-suspended", 'new-subscription-invoice',
    "carrier-register", "shipper-register", "broker-register", "init/abstract-bean-factory-initializer-renewal-response", 
    "account-verification"
  ];
  const authorizedPages = !publicPages.includes(to.name);
  const loggedIn = JSON.parse(localStorage.getItem("user"));
  if (authorizedPages && !loggedIn) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    });
  } else {
    next();
  }
});

export default router;
