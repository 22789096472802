<template>
  <div>
    <div class="row">
        <div class="col-md-3" v-for="menu in getMenu" :key="menu.link">
          <router-link :to="{ name: menu.link }">
            <div class="panel">
              <div class="panel-body text-center">
              <p>
                <img :src="'/menuIcons/' + menu.icon + '.' + imageExtension" v-bind:alt="menu.name" height="40" width="40" class="img img-rounded img-responsive center-block"/>
              </p>
              <h4>{{ menu.name }}</h4>
              </div>
            </div>
          </router-link>
        </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default{
    data: function(){
      return {
        imageExtension: 'png'
      }
    },

    computed: {
      getMenu(){
        let menu = [
          {
            name: "Roles",
            link: "roles",
            icon: "employee"
          },

          {
            name: "Zip Codes",
            link: "zipCodes",
            icon: "zip-code"
          },

          {
            name: "Countries",
            link: "countries",
            icon: "province"
          },

          {
            name: "Provinces",
            link: "provinces",
            icon: "province"
          },

          {
            name: "Cities",
            link: "cities",
            icon: "province"
          },

          {
            name: "Areas",
            link: "areas",
            icon: "province"
          },

          {
            name: "Banks/Mobile Money",
            link: "financial-institution-list",
            icon: "payment"
          },

          {
            name: "Subscription Plans",
            link: "subscription-plans",
            icon: "subscription-plan"
          },

          {
            name: "Load Charges",
            link: "load-charge-list",
            icon: "payment"
          },
        ];
      return menu;
      }
    }
  }
</script>

<style type="text/css" scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
