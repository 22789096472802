<template>
	<div>
		<label for="category-filter">Filter by category:</label>
		<select id="category-filter" v-model="selectedCategory">
			<option v-for="category in categories" :key="category">{{ category }}</option>
		</select>
		<label for="search-input">Search:</label>
		<input type="text" id="search-input" v-model="searchQuery">
		<table class="developer-table">
			<thead>
				<tr>
					<th>Id</th>
					<th>Developer Name</th>
					<th>Category</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in paginatedItems" :key="item.id">
					<td>{{ item.id }}, {{ index }}</td>
					<td>{{ item.name }}</td>
					<td>{{ item.category }}</td>
				</tr>
			</tbody>
		</table>
		<div class="pagination">
			<button :disabled="currentPage === 1" @click="prevPage">Previous</button>
			<span>{{ currentPage }} / {{ totalPages }}</span>
			<button :disabled="currentPage === totalPages" @click="nextPage">Next</button>
       </div>
	</div>
</template>

<script type="text/javascript">
	export default {
			data: function() {
				return {
					categories: ["All", "Frontend Developer", "Backend Developer", "Full Stack Developer"],
					selectedCategory: "All",
					searchQuery: "",
					items: [
						{ id: 1, name: "John Smith", category: "Frontend Developer" },
						{ id: 2, name: "Sarah Johnson", category: "Backend Developer" },
						{ id: 3, name: "David Chen", category: "Full Stack Developer" },
						{ id: 4, name: "Emily Lee", category: "Backend Developer" },
						{ id: 5, name: "Michael Wong", category: "Full Stack Developer" },
						{ id: 6, name: "Rachel Patel", category: "Frontend Developer" },
						{ id: 7, name: "Kevin Davis", category: "Frontend Developer" },
						{ id: 8, name: "Jennifer Kim", category: "Backend Developer" },
						{ id: 9, name: "James Garcia", category: "Full Stack Developer" },
						{ id: 10, name: "Laura Nguyen", category: "Backend Developer" },
						{ id: 11, name: "Brandon Brown", category: "Full Stack Developer" },
						{ id: 12, name: "Samantha Taylor", category: "Frontend Developer" },
					],
					currentPage: 1,
					itemsPerPage: 3,
				};
			},

			computed: {
				filteredItems() {
					let filteredItems = this.items;
					if (this.selectedCategory !== "All") {
						filteredItems = filteredItems.filter(item => item.category === this.selectedCategory);
					}
					if (this.searchQuery !== "") {
						filteredItems = filteredItems.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
					}
					return filteredItems;
				},
				totalPages() {
					return Math.ceil(this.filteredItems.length / this.itemsPerPage);
				},
				paginatedItems() {
					const startIndex = (this.currentPage - 1) * this.itemsPerPage;
					return this.filteredItems.slice(startIndex, startIndex + this.itemsPerPage);
				},
			},

			methods: {
				nextPage() {
					if (this.currentPage < this.totalPages) {
						this.currentPage++;
					}
				},
				prevPage() {
					if (this.currentPage > 1) {
						this.currentPage--;
					}
				},
            },
        };
</script>