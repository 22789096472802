<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>       
    </div>

    <div v-if="company !== null">
      <div class="panel">
        <div class="panel-body">
            <h4 class="text-danger">To get paid, provide bank or mobile money accounts</h4>
            <br>
            <p v-if="message !== null" class="error-message text-center">{{ message }}</p>
            <form class="form" @submit.prevent="save">
              <span v-show="accountFields.length < 2">
                <button class="btn btn-success btn-sm pull-right" type="button" @click="addAccountField">Add <span class="glyphicon glyphicon-plus"></span></button>
              </span>

              <div v-if="accountFields.length > 0">
                <div v-for="(accountField, index) in accountFields" :key="accountField.id">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="paymentMethod">Payment Method <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="accountField.paymentMethod" required>
                          <option value="" disabled>Select option</option>
                          <option value="CARD">Card</option>
                          <option value="MOBILE">Mobile</option>
                        </select>
                      </div>              
                    </div>
                    <div class="col-md-2" v-if="accountField.paymentMethod === 'CARD'">
                      <div class="form-group">
                        <label for="bankName">Bank Name <span class="text-danger">*</span></label>
                        <input type="text" name="bankName" class="form-control" v-model="accountField.bankName" placeholder="Enter bank name" required>
                      </div>
                    </div>
                    <div class="col-md-2" v-if="accountField.paymentMethod === 'CARD'">
                      <div class="form-group">
                        <label for="bankCode">Bank Code <span class="text-danger">*</span></label>
                        <input type="text" name="bankCode" class="form-control" v-model="accountField.bankCode" placeholder="Enter bank code" required>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="accountName">Account Name <span class="text-danger">*</span></label>
                        <input type="text" name="accountName" class="form-control" v-model="accountField.accountName" placeholder="Enter account name" required>
                      </div>
                    </div>
                    <div class="col-md-2" v-if="accountField.paymentMethod === 'CARD'">
                      <div class="form-group">
                        <label for="accountNumber">Account Number <span class="text-danger">*</span></label>
                        <input type="number" name="accountNumber" class="form-control" v-model="accountField.accountNumber" placeholder="Enter account number" required>
                      </div>              
                    </div>
                  </div>  

                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="accountCurrency">Account Currency <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="accountField.accountCurrency" required>
                          <option value="" disabled>Select option</option>
                          <option value="USD">USD</option>
                          <option value="ZWG">ZWG</option>
                        </select>
                      </div>              
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="branch">Branch name</label>
                        <input type="text" name="branch" class="form-control" v-model="accountField.branch" placeholder="Enter branch name" required>
                      </div>              
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="branchCode">Branch code</label>
                        <input type="text" name="branchCode" class="form-control" v-model="accountField.branchCode" placeholder="Enter branch code" required>
                      </div>              
                    </div>
                  </div>

                  <div class="row" v-if="accountField.paymentMethod === 'MOBILE'">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="operatorName">Operator Name <span class="text-danger">*</span></label>
                        <input type="text" name="operatorName" class="form-control" v-model="accountField.operatorName" placeholder="Enter operator name e.g XYZ Telecoms" required>
                      </div>              
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="phoneNumber">Phone Number <span class="text-danger">*</span></label>
                        <input type="number" name="phoneNumber" class="form-control" v-model="accountField.phoneNumber" placeholder="Enter phone number" required>
                      </div>              
                    </div>
                  </div> 

                  <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                      <div class="form-group" v-show="accountFields.length > 1">
                        <label>&nbsp;</label><br>
                        <button class="btn btn-danger btn-sm pull-right" type="button" @click="accountFields.splice(index, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
                      </div>
                    </div>
                  </div>
                  <hr>    
                </div>
              </div>
              <div class="row">
                <div class="col-md-10"></div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="btn btn-success btn-block pull-right" type="sumbit">Submit</button>
                  </div>
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
    <div v-else>
      <h4 class="text-danger">Error showing bank accounts!</h4>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";
  import Company from "@/models/Company";

  export default {
    name: "NewBankAccount",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        errors: [],
        message: null,
        editing: false,
        accountFields: [{
          paymentMethod: null,
          accountName: null,
          accountNumber: null,
          accountCurrency: null,
          branch: null,
          branchCode: null,
          bankName: null,
          bankCode: null,
          operatorName: null,
          phoneNumber: null,
        }],
        financialInstitutions: [],
        company: new Company
      };
    },

    mounted(){

    },

    computed: {
      getUuid(){ return this.$route.params.uuid; }
    },

    methods: {
      addAccountField: function(){
        this.accountFields.push({
          paymentMethod: null,
          accountName: null,
          accountNumber: null,
          accountCurrency: null,
          branch: null,
          branchCode: null,
          bankName: null,
          bankCode: null,
          operatorName: null,
          phoneNumber: null,
        });
      },

      save: function() {
        this.show = true;
        this.company.uuid = this.getUuid;
        this.company.accounts = this.accountFields;
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode == 201) {
            this.show = false;
            this.$router.push({ name: "new-business-profile/subscriptions", params: { uuid: response.data.body.uuid }});
          }
        }).catch(error => {
          this.show = false;
          console.log(error);
          this.message = error.response.data.message;
        });
      }
    }
  };
</script>
