
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";
import axios from "axios";

class AccountConfirmationService{
	confirmAccount(token){
		return axios.post(GENERIC_SERVICE_URL + '/accountConfirmation/confirmAccount/' + token);
	}
}

export default new AccountConfirmationService;
