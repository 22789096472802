<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-11"></div>
          <div class="col-md-1">
            <span v-if="getRoles.includes('SHIPPER') || getRoles.includes('BROKER')">
              <router-link :to="{ name: 'my-payments' }">
                <button class="btn btn-default pull-right">Close</button>
              </router-link>
            </span>&nbsp;
            <span v-if="getRoles.includes('CARRIER')">
              <router-link :to="{ name: 'carrier/my-payments' }">
                <button class="btn btn-primary btn-block">Close</button>
              </router-link>
          </span>
          </div>
        </div>
        <br><br>

        <ul class="list-group" v-if="load !== null">
          <li class="list-group-item">Load Number: {{ load.loadNumber }}</li>
          <td class="list-group-item">Incoice: {{ load.invoice.invoiceNumber }}</td>
          <td class="list-group-item">Debit Reference: {{ load.invoice.payment.debitReference }}</td>
          <td class="list-group-item">Amount: {{ load.invoice.payment.currency }}{{ load.invoice.payment.amount }}</td>
          <td class="list-group-item">Payment Method: {{ load.invoice.payment.paymentMethod }}</td>
          <td class="list-group-item">Status
            <span class="label label-primary" v-show="load.invoice.payment.status === 'ACTIVE'">Submitted</span>
            <span class="label label-success" v-show="load.invoice.status === 'WITHHOLDING'">Pending</span>
            <span class="label label-success" v-show="load.invoice.payment.status === 'AUTHORIZED'">Authorized</span>
            <span class="label label-success" v-show="load.invoice.payment.status === 'RELEASED'">Released</span>
          </td>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import LoadService from "@/services/LoadService.js";
  import loading from "vue-full-loading";

  export default {
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        load: null
      };
    },

    computed: {
      getLoadNumber(){
        return this.$route.params.loadNumber;
      },

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    mounted: function(){
      this.findByLoadNumber();
    },

    methods: {
      findByLoadNumber: function(){
        LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
          this.load = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },
    }
  };
</script>