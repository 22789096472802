import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class RoleService {
  save(role) {
    return axios.post(GENERIC_SERVICE_URL + "roles", {
      roleId: role.roleId,
      name: role.name,
      description: role.description,
      status: role.status
    });
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "roles");
  }

  findById(entityId) {
    return axios.get(GENERIC_SERVICE_URL + "roles" + "/" + entityId);
  }
}

export default new RoleService();
