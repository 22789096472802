<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	   	<h4 class="text-success">Open Loads</h4>
		<div class="panel">
			<div class="panel-body">
				<form class="form">
					<div class="row">
						<div class="col-md-2">
								<div class="form-group">
									<label>Load type</label>
									<select class="form-control" name="type" v-model="LL_type">
										<option value="" disabled>Select option</option>
										<option value="NORMAL">Normal</option>
										<option value="ABNORMAL">Abnormal</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Load mode</label>
									<select class="form-control" name="loadMode" v-model="LL_loadMode">
										<option value="" disabled>Select option</option>
										<option value="FTL">Full truckload - FTL</option>
										<option value="LTL">Less than truckload - LTL</option>
										<option value="INTERMODAL">Intermodal</option>
										<option value="POWER_ONLY">Power-Only</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Trailer required</label>
									<select class="form-control" name="trailerTypeRequired" v-model="LL_trailerTypeRequired">
										<option value="" disabled>All trailers</option>
										<option value="FLATBED">Flatbed</option>
										<option value="VAN">Van</option>
										<option value="REEFER">Reefer</option>
										<option value="STEP_DECK">Step Deck</option>
										<option value="POWER_ONLY">Power Only</option>
										<option value="AUTO_CARRIER">Auto Carrier</option>
										<option value="B_TRAIN">B Train</option>
										<option value="BOX_TRUCK">Box Truck</option>
										<option value="CONESTOGA">Consetoga</option>
										<option value="CONTAINERS">Containers</option>
										<option value="DOUBLE_DROP">Double Drop</option>
										<option value="DRY_BULK">Dry Bulk</option>
										<option value="DUMP_TRAILER">Dump Trailer</option>
										<option value="HOPPER_BOTTOM">Hooper Bottom</option>
										<option value="HOTSHOT">Hotshot</option>
										<option value="LOWBOY">Lowboy</option>
										<option value="TANKER">Tanker</option>
									</select>
								</div>
							</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Pick Up Date</label>
								<input type="date" name="pickupDate" v-model="LL_pickupDate" class="form-control">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Drop Off Date</label>
								<input type="date" name="dropOffDate" v-model="LL_dropOffDate" class="form-control">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Pick Up</label>
								<select class="form-control" v-model="LL_pickUpCityId">
									<option value="" selected>All cities</option>
									<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Drop Off</label>
								<select class="form-control" v-model="LL_dropOffCityId">
									<option value="" selected>All cities</option>
									<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Weight (tonne)</label>
								<input type="number" name="weight" v-model="LL_weight" class="form-control" placeholder="Enter weight">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Price</label>
								<input type="number" name="price" v-model="LL_price" class="form-control" placeholder="Enter price">
							</div>
						</div>
						<div class="col-md-2"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-block btn-primary" @click="search()">Search</button>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-block" type="button" @click="clearFilter()" style="background-color: #000000; color: #ffffff;">Reset filter</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="row">
			<div class="col-md-3">
				<p>{{ loads.length }} loads posted</p>
				<p>{{ new Date().toDateString() }}</p>
			</div>
			<div class="col-md-6"></div>
			<div class="col-md-3">
				<select class="form-control" v-model="itemsPerPage">
					<option value="10">Items per page</option>
					<option value="20">20</option>
					<option value="30">30</option>
					<option value="40">40</option>
					<option value="50">50</option>
				</select>
			</div>
		</div>
		<div class="panel" v-for="load in paginatedLoads" :key="load.id">
			<div class="panel-body">
				<div class="row">
					<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }, query: { source: 'MY_LOADS'}}">
						<div class="col-md-3">
							<b><i><span class="label label-primary">PICKUP</span></i></b>
							<span v-if="load.pickupAddress !== null">
								<span v-if="load.pickupAddress.province !== null && load.pickupAddress.city !== null">
									<p>{{ load.pickupAddress !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress !== null ? load.pickupAddress.city.name: "Not available" }} on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
									</p>
								</span>
								<span v-else>
									<p><span class="text-danger">Pickup n/a</span> on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
									</p>
								</span>
							</span>
							<p><span class="badge">{{ load.trailerTypeRequired }}</span> required for <span class="badge">{{ load.loadMode }}</span>
							</p>
						</div>
						<div class="col-md-1">
							<br>
							<img src="@/assets/images/right-arrow.png" height="30" width="30">
						</div>
						<div class="col-md-3">
							<span v-if="load.dropOffAddress !== null">
								<b><i><span class="label label-warning">DELIVERY</span></i></b>&nbsp;
								<span class="badge">Estimated distance {{ load.estimatedDistance > 0 ? load.estimatedDistance / 1000 : 0 }} km</span>								
								<span v-if="load.pickupAddress !== null">
									<span v-if="load.pickupAddress.province !== null && load.pickupAddress.city !== null">
										<p>{{ load.dropOffAddress !== null ? load.dropOffAddress.province.name: "Not available" }}, {{ load.dropOffAddress !== null ? load.dropOffAddress.city.name: "Not available" }}
											{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}
										</p>
									</span>
									<span v-else>
										<p><span class="text-danger">Drop off n/a</span> on {{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}
										</p>
									</span>
								</span>
								<p>{{ load.weight }} {{ load.weightMeasurement }} of {{ load.commodity }}</p>
							</span>
						</div>
					</router-link>
					<div class="col-md-1">
						<br>
						<p>{{ load.currency }}${{ load.price }}</p>
						<p><span class="badge">{{ load.type }} load</span></p>
					</div>
					<div class="col-md-2">
						<p>Load # {{ load.loadNumber }}</p>
						<!-- <span class="label label-success" v-if="load.invoice === null && load.status === 'ACTIVE'">Listed on market</span>
						<span class="label label-warning" v-if="load.invoice === null && load.status === 'PENDING'">Not listed on the market</span>
						<span class="label label-primary" v-if="load.invoice !== null && load.invoice.payment !== null">No longer listed</span> -->
						<span class="label label-primary">{{ load.status.replace(/_/g, ' ') }}</span>
						<p>{{ load.currency }}${{ load.price }}</p>
					</div>
					<div class="col-md-2">
						<div class="row">
							<div class="col-md-6">
								<span class="label label-primary">{{ load.bookings !== null ? load.bookings.length: 0 }} Bookings</span>&nbsp;
								<span class="label label-warning">{{ load.bids !== null ? load.bids.length: 0 }} Bids</span>
							</div>
							<div class="col-md-6">
								<span v-if="load.status === 'PENDING' && load.carrier == null">
									<router-link :to="{ name: 'publish-load', params: { loadNumber: load.loadNumber }}">
										<button class="btn btn-primary btn-sm">Publish <span class="glyphicon glyphicon-ok-circle"></span></button>
									</router-link>
								</span>
								<span v-if="load.status === 'BOOKED' && load.carrier !== null">Booked to {{ load.carrier.name }}</span>
								<span v-if="load.status === 'TENDERED'">Tendered</span>
								<span v-if="load.status === 'DELIVERY_CONFIRMED'">Delivered</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-if="totalPages > 1">
			<div class="col-md-7"></div>
			<div class="col-md-3 pull-right">
				<div class="panel">
					<div class="panel-body">
						<button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="LL_currentPage === 1" @click="prevPage"></button>
						&nbsp;<span>Page {{ LL_currentPage }} of {{ totalPages }}</span>&nbsp;
						<button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="LL_currentPage === totalPages" @click="nextPage"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import CompanyService from "@/services/CompanyService";
	import CityService from "@/services/CityService";

	export default{
		name: 'MyLoadList',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				loads: [],
				company: null,
				cities: [],
				LL_currentPage: JSON.parse(localStorage.getItem('LL_currentPage')) !== null ? JSON.parse(localStorage.getItem('LL_currentPage')): 1,
				itemsPerPage: 10,
				LL_type: null,
				LL_loadMode: null,
				LL_trailerTypeRequired: null,
				LL_pickupDate: null,
				LL_dropOffDate: null,
				LL_pickUpCityId: null,
				LL_dropOffCityId: null,
				LL_weight: null,
				LL_price: null,
				filteredLoads: []
			}
		},

		mounted(){
			this.LL_type = JSON.parse(localStorage.getItem('LL_type'));
			this.LL_loadMode = JSON.parse(localStorage.getItem('LL_loadMode'));
			this.LL_trailerTypeRequired = JSON.parse(localStorage.getItem('LL_trailerTypeRequired'));
			this.LL_pickupDate = JSON.parse(localStorage.getItem('LL_pickupDate'));
			this.LL_dropOffDate = JSON.parse(localStorage.getItem('LL_dropOffDate'));
			this.LL_pickUpCityId = JSON.parse(localStorage.getItem('LL_pickUpCityId'));
			this.LL_dropOffCityId = JSON.parse(localStorage.getItem('LL_dropOffCityId'));
			this.LL_weight = JSON.parse(localStorage.getItem('LL_weight'));
			this.LL_price = JSON.parse(localStorage.getItem('LL_price'));

			this.findAllCities();
			this.findByAssociatedUserUsername();
			this.findByOwnerUsername();
		},

		methods: {
			findAllCities: function(){
				CityService.findAll().then(response => {
					this.cities = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			findByOwnerUsername: function(){
				LoadService.findByOwnerUsername(this.getUser.username).then(response => {
					this.loads = response.data.body;
					this.search();
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			findByAssociatedUserUsername: function() {
				this.show = true;
		        CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
		        	if (response.data.statusCode == 200) {
		        		this.company = response.data.body;
		        	}
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    prevPage(){
		    	if (this.LL_currentPage > 1) {
		    		const LL_currentPage = --this.LL_currentPage;
		    		localStorage.setItem('LL_currentPage', JSON.stringify(LL_currentPage));
		    	}
		    },

		    nextPage(){
		    	if (this.LL_currentPage < this.totalPages) {
		    		const LL_currentPage = ++this.LL_currentPage;
		    		localStorage.setItem('LL_currentPage', JSON.stringify(LL_currentPage));
		    	}
		    },

		    clearFilter(){
		    	localStorage.removeItem('LL_type');
		    	localStorage.removeItem('LL_loadMode');
		    	localStorage.removeItem('LL_trailerTypeRequired');
		    	localStorage.removeItem('LL_pickupDate');
		    	localStorage.removeItem('LL_dropOffDate');
		    	localStorage.removeItem('LL_pickUpCityId');
		    	localStorage.removeItem('LL_dropOffCityId');
		    	localStorage.removeItem('LL_weight');
		    	localStorage.removeItem('LL_price');
		    	localStorage.removeItem('LL_currentPage')
		    	localStorage.removeItem('LL_searchFields');
		    	window.location.reload();
		    },

		    search(){
		    	this.show = true;
		    	let searchFields = JSON.parse(localStorage.getItem('LL_searchFields') || '[]');
		    	if (this.LL_type !== null && this.LL_type !== undefined) {
				    let searchField = {
				    	'fieldName': 'LL_type',
				    	'value': this.LL_type
				    };
				    const index = searchFields.findIndex((field) => field.fieldName === 'LL_type');
				    if (index === -1) {
				    	searchFields.push(searchField);
				    }else{
				    	searchFields[index] = searchField;
				    }
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LL_loadMode !== null && this.LL_loadMode !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LL_loadMode',
		    			'value': this.LL_loadMode
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LL_loadMode');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LL_trailerTypeRequired !== null && this.LL_trailerTypeRequired !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LL_trailerTypeRequired',
		    			'value': this.LL_trailerTypeRequired
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LL_trailerTypeRequired');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LL_pickupDate !== null && this.LL_pickupDate !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LL_pickupDate',
		    			'value': this.LL_pickupDate
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LL_pickupDate');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LL_dropOffDate !== null && this.LL_dropOffDate !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LL_dropOffDate',
		    			'value': this.LL_dropOffDate
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LL_dropOffDate');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LL_pickUpCityId !== null && this.LL_pickUpCityId !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LL_pickUpCityId',
		    			'value': this.LL_pickUpCityId
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LL_pickUpCityId');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LL_dropOffCityId !== null && this.LL_dropOffCityId !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LL_dropOffCityId',
		    			'value': this.LL_dropOffCityId
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LL_dropOffCityId');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LL_weight !== null && this.LL_weight !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LL_weight',
		    			'value': this.LL_weight
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LL_weight');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}
		    	if (this.LL_price !== null && this.LL_price !== undefined) {
		    		let searchField = {
		    			'fieldName': 'LL_price',
		    			'value': this.LL_price
		    		};
		    		const index = searchFields.findIndex((field) => field.fieldName === 'LL_price');
		    		if (index === -1) {
		    			searchFields.push(searchField);
		    		}else{
		    			searchFields[index] = searchField;
		    		}
		    		localStorage.setItem('LL_searchFields', JSON.stringify(searchFields));
		    	}

		    	if (searchFields.length > 0) {
		    		let theLoads = this.loads;
		    		for(let i = 0; i < searchFields.length; i++){
		    			if (searchFields[i].fieldName === 'LL_type'){
		    				theLoads = theLoads.filter(load => {
		    					return load.type === searchFields[i].value;
		    				});
		    				localStorage.setItem('LL_type', JSON.stringify(this.LL_type));
		    			}
		    			if (searchFields[i].fieldName === 'LL_loadMode'){
		    				theLoads = theLoads.filter(load => {
		    					return load.loadMode === searchFields[i].value;
		    				});
		    				localStorage.setItem('LL_loadMode', JSON.stringify(this.LL_loadMode));
		    			}
		    			if (searchFields[i].fieldName === 'LL_trailerTypeRequired'){
		    				theLoads = theLoads.filter(load => {
		    					return load.trailerTypeRequired === searchFields[i].value;
		    				});
		    				localStorage.setItem('LL_trailerTypeRequired', JSON.stringify(this.LL_trailerTypeRequired));
		    			}
		    			if (searchFields[i].fieldName === 'LL_pickupDate'){
		    				theLoads = theLoads.filter(load => {
		    					return load.pickupDate === searchFields[i].value;
		    				});
		    				localStorage.setItem('LL_pickupDate', JSON.stringify(this.LL_pickupDate));
		    			}
		    			if (searchFields[i].fieldName === 'LL_dropOffDate'){
		    				theLoads = theLoads.filter(load => {
		    					return load.dropOffDate === searchFields[i].value;
		    				});
		    				localStorage.setItem('LL_dropOffDate', JSON.stringify(this.LL_dropOffDate));
		    			}
		    			if (searchFields[i].fieldName === 'LL_pickUpCityId'){
		    				theLoads = theLoads.filter(load => {
		    					return load.pickupAddress.city.id === searchFields[i].value;
		    				});
		    				localStorage.setItem('LL_pickUpCityId', JSON.stringify(this.LL_pickUpCityId));
		    			}
		    			if (searchFields[i].fieldName === 'LL_dropOffCityId'){
		    				theLoads = theLoads.filter(load => {
		    					return load.dropOffAddress.city.id === searchFields[i].value;
		    				});
		    				localStorage.setItem('LL_dropOffCityId', JSON.stringify(this.LL_dropOffCityId));
		    			}
		    			if (searchFields[i].fieldName === 'LL_weight'){
		    				theLoads = theLoads.filter(load => {
		    					return load.weight === parseFloat(searchFields[i].value);
		    				});
		    				localStorage.setItem('LL_weight', JSON.stringify(this.LL_weight));
		    			}
		    			if (searchFields[i].fieldName === 'LL_price'){
		    				theLoads = theLoads.filter(load => {
		    					return load.price === parseFloat(searchFields[i].value);
		    				});
		    				localStorage.setItem('LL_price', JSON.stringify(this.LL_price));
		    			}
		    			this.filteredLoads = theLoads;
		    		}
		    	}else{
		    		this.filteredLoads = this.loads;
		    	}
		    	this.show = false;
		    }
		},

		computed: {
			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    totalPages(){
		    	return Math.ceil(this.filteredLoads.length / this.itemsPerPage);
		    },

		    paginatedLoads(){
				const startIndex = (this.LL_currentPage - 1) * this.itemsPerPage;
				return this.filteredLoads.slice(startIndex, startIndex + this.itemsPerPage);
			}
		}
	};
</script>