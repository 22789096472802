<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<form class="form" @submit.prevent="save">
					<fieldset>
						<legend>Load Timing</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Pickup date</label>
									<input type="date" class="form-control" name="pickupDate" v-model="load.pickupDate" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Pickup time</label><br>
									<vue-timepicker v-model="load.pickupTime" format="HH:mm" required></vue-timepicker>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Drop off date</label>
									<input type="date" class="form-control" name="dropOffDate" v-model="load.dropOffDate" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Drop off time</label><br>
									<vue-timepicker v-model="load.dropOffTime" format="HH:mm" required></vue-timepicker>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Estimated loading duration</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="load.estimatedLoadingTime" placeholder="Enter estimated loading time" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Estimated loading duration measurement</label>
									<select class="form-control" v-model="load.estimatedLoadingTimeMeasurement" required>
										<option value="" disabled>Select option</option>
										<option value="HR">Hours</option>
										<option value="MIN">Miniutes</option>
										<option value="SEC">Seconds</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Estimated delivery duration</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="load.estimatedDeliveryTime" placeholder="Enter delivery time" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Estimated delivery duration measurement</label>
									<select class="form-control" v-model="load.estimatedDeliveryTimeMeasurement" required>
										<option value="" disabled>Select option</option>
										<option value="HR">Hours</option>
										<option value="MIN">Miniutes</option>
										<option value="SEC">Seconds</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import Load from "@/models/Load";
	import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

	export default{
		name: 'NewLoadTiming',
		components: {
			loading,
			VueTimepicker
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				load: new Load,
			}
		},
		
		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			}
		},

		methods: {
			save: function(){
				if (this.load.pickupTime != null) {
					var pickupHour = this.load.pickupTime.HH;
					if (pickupHour === '') {
						pickupHour = "00";
					}
					var pickupMin = this.load.pickupTime.mm;
					if (pickupMin === '') {
						pickupMin = '00';
					}
					this.load.pickupTime = pickupHour + ':' + pickupMin;
				}
				if (this.load.dropOffTime != null) {
					var dropOffHour = this.load.dropOffTime.HH;
					if (dropOffHour === '') {
						dropOffHour = "00";
					}
					var dropOffMin = this.load.dropOffTime.mm;
					if (dropOffMin === '') {
						dropOffMin = '00';
					}
					this.load.dropOffTime = dropOffHour + ':' + dropOffMin;
				}
				this.show = true;
				this.load.loadNumber = this.getLoadNumber;
				LoadService.save(this.load).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'new-load-pickup-and-drop-off-address', params: { loadNumber: this.getLoadNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},
		}
	};
</script>