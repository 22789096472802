<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div v-if="company !== null">
      <!-- beware, this code works but is not optimal and is not the best way to work around this -->
      <h4 class="text-danger" v-if="message != null">{{ message }}</h4>
      <ul class="list-group">
        <li class="list-group-item">
          <!-- each file type is now in its own form with its own submit event -->
          <form class="form" @submit.prevent="saveCR6">
            <div class="form-group">
              <div class="row">
                <div class="col-md-8">
                  <label>1. {{ attachmentTypes[0].description }}</label>
                  <span v-if="checkIfAttachmentAlreadyUploaded(attachmentTypes[0].name)">
                    <span v-if="!this.reUploadNATIONAL_IDENTITY">
                      <input type="text" ref="nationalIdentity" class="form-control" disabled :value="attachmentTypes[0].name + ' already uploaded'">
                      <p class="text-danger">View Document <a :href="PDF_API_URL + 'pdf/attachments/' + returnAttachmentAlreadyUploadedFileId(attachmentTypes[0].name)" target="_blank">{{ returnAttachmentAlreadyUploadedFileName(attachmentTypes[0].name) }}</a>
                      </p>
                    </span>
                  </span>
                  <span v-else>
                    <input type="file" ref="nationalIdentity" v-on:change="selectNATIONAL_IDENTITY" class="form-control" required accept="application/pdf;application/msword"/>
                  </span>
                  <span v-if="this.reUploadNATIONAL_IDENTITY">
                    <input type="file" ref="nationalIdentity" v-on:change="selectNATIONAL_IDENTITY" class="form-control" required accept="application/pdf;application/msword"/>
                  </span>
                </div>
                <div class="col-md-4">
                  <br>
                  <span v-if="checkIfAttachmentAlreadyUploaded(attachmentTypes[0].name)">
                    <span v-if="this.reUploadNATIONAL_IDENTITY">
                      <button class="btn btn-success" type="submit">Upload</button>
                    </span>
                    <span v-else>
                      <button class="btn btn-default" type="button" @click="reUpload(attachmentTypes[0].name)">Re-upload</button>&nbsp;&nbsp;<span class="text-danger"><b> FILE UPLOADED</b></span>
                    </span>
                  </span>
                  <span v-else>
                    <button class="btn btn-success" type="submit">Upload</button>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </li>

        <li class="list-group-item">
          <form class="form" @submit.prevent="saveCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION">
            <div class="form-group">
              <div class="row">
                <div class="col-md-8">
                  <label>2. {{ attachmentTypes[1].description }}</label>
                  <span v-if="checkIfAttachmentAlreadyUploaded(attachmentTypes[1].name)">
                    <span v-if="!this.reUploadPROOF_OF_RESIDENCE">
                      <input type="text" ref="proofOfResidence" class="form-control" disabled :value="attachmentTypes[1].name + ' already uploaded'">
                      <p class="text-danger">View Document <a :href="PDF_API_URL + 'pdf/attachments/' + returnAttachmentAlreadyUploadedFileId(attachmentTypes[1].name)" target="_blank">{{ returnAttachmentAlreadyUploadedFileName(attachmentTypes[1].name) }}</a>
                      </p>
                    </span>
                  </span>
                  <span v-else>
                    <input type="file" ref="proofOfResidence" v-on:change="selectPROOF_OF_RESIDENCE" class="form-control" required accept="application/pdf;application/msword"/>
                  </span>
                  <span v-if="this.reUploadPROOF_OF_RESIDENCE">
                    <input type="file" ref="proofOfResidence" v-on:change="selectPROOF_OF_RESIDENCE" class="form-control" required accept="application/pdf;application/msword"/>
                  </span>
                </div>
                <div class="col-md-4">
                  <br>
                  <span v-if="checkIfAttachmentAlreadyUploaded(attachmentTypes[1].name)">
                    <span v-if="this.reUploadPROOF_OF_RESIDENCE">
                      <button class="btn btn-success" type="submit">Upload</button>
                    </span>
                    <span v-else>
                      <button class="btn btn-default" type="button" @click="reUpload(attachmentTypes[1].name)">Re-upload</button>&nbsp;&nbsp;<span class="text-danger"><b> FILE UPLOADED</b></span>
                    </span>
                  </span>
                  <span v-else>
                    <button class="btn btn-success" type="submit">Upload</button>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </li>
      <br>
    </ul>
    </div>
  </div>
</template>

<script type="text/javascript">
  import axios from "axios";
  import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";
  import companyAttachments from "@/repository/CompanyAttachments.json";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default {
    name: "BusinessDocumentsComponent",
    components: {
      loading
    },

    props: {
      companyId: {
        type: Number,
        default: null
      },

      attachments: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },

    data: function() {
      return {
        organizationId: JSON.parse(localStorage.getItem("organizationId")),
        checkIfAttachmentUploaded: true,
        GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
        label: 'Processing request',
        show: false,
        errorMessage: null,
        message: null,
        errors: [],
        formData: new FormData(),
        // must come from an API endpoint so its easy to manage and make changes later on!!
        companyAttachmentListEnum: [
          "CERTIFICATE_OF_INCORPORATION_OR_REGISTRATION",
          "TAX_CLEARANCE_CERTIFICATE"
        ],
        attachmentTypes: [],
        company: null,
        reUploadNATIONAL_IDENTITY: false,
        reUploadPROOF_OF_RESIDENCE: false,
        organizationType: JSON.parse(localStorage.getItem("organizationType")),
        maxFileSize: 5,
        PDF_API_URL: PDF_API_URL
      };
    },

    computed: {
      getCompanyId(){ return this.$route.params.companyId; }
    },

    mounted(){
      this.attachmentTypes = companyAttachments;
      this.findCompanyById();
    },

    methods: {
      // methods that pick the target file field
      selectNATIONAL_IDENTITY: function(event) {
        if ((event.target.files[0].size / (1024 * 1024) > this.maxFileSize)) {
          alert('File exceeds size of ' + this.maxFileSize + 'MB');
          this.$refs.cr6.value = null;
          return; 
        }
        let attachmentId = null;
        if (this.company !== null) {
          for(var i = 0; i < this.company.businessDocuments.length; i++){
            if (this.company.businessDocuments[i].attachmentType === this.companyAttachmentListEnum[0]) {
              attachmentId = this.company.businessDocuments[i].id;
            }
          }
        }
        this.formData.append("attachmentId", attachmentId);
        this.formData.append("attachment", event.target.files[0]);
        this.formData.append("attachmentEnum", this.companyAttachmentListEnum[0]);
        this.formData.append("companyId", this.companyId);
      },

      selectPROOF_OF_RESIDENCE: function(event) {
        if ((event.target.files[0].size / (1024 * 1024) > this.maxFileSize)) {
          alert('File exceeds size of ' + this.maxFileSize + 'MB');
          this.$refs.certificateOfIncorporation.value = null;
          return; 
        }
        let attachmentId = null;
        if (this.company !== null) {
          for(var i = 0; i < this.company.businessDocuments.length; i++){
            if (this.company.businessDocuments[i].attachmentType === this.companyAttachmentListEnum[1]) {
              attachmentId = this.company.businessDocuments[i].id;
            }
          }
        }
        this.formData.append("attachmentId", attachmentId);
        this.formData.append("attachment", event.target.files[0]);
        this.formData.append("attachmentEnum", this.companyAttachmentListEnum[1]);
        this.formData.append("companyId", this.companyId);
      },

      uploadAttachment: function() {
        this.showLoader();
        axios.post(GENERIC_SERVICE_URL + "uploads/" + this.organizationId + "/companyAttachment", this.formData).then(response => {
          this.clearLoader();
          if (response.status === 200) {
            this.invalidateFormData();
            this.setNotificationData(response);
            window.location.reload();
          }
        }).catch(error => {
          console.log(error);
        });
      },

      // Methods to upload specific attachments
      saveCR6: function(event) {
        console.log(event);
        this.uploadAttachment();
      },

      saveCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION: function(event) {
        console.log(event);
        this.uploadAttachment();
      },

      invalidateFormData: function() {
        // perform clean up on the form data instance
        this.formData = new FormData();
        console.log("formData invalidated");
      },

      // make these functions global :: reuse, vioa the app instance
      clearNotificationData: function() {
        this.errors = null;
        this.errorMessage = null;
      },

      setNotificationData: function(response) {
        this.errors = response.data.errors;
        this.message = response.data.message;
      },

      clearLoader: function() {
        this.show = false;
      },

      showLoader: function() {
        this.show = true;
      },

      findCompanyById: function(){
        CompanyService.findById(this.companyId).then(response => {
          this.company = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      checkIfAttachmentAlreadyUploaded: function(attachmentType) {
        let check = false;
        if (this.company !== null) {
          for(var i = 0; i < this.company.businessDocuments.length; i++){
            if (this.company.businessDocuments[i].attachmentType === attachmentType) {
              check = true;
            }
          }
        }
        return check;
      },

      returnAttachmentAlreadyUploadedFileName: function(attachmentType) {
        let returnAttachmentAlreadyUploaded = null;
        if (this.company !== null) {
          for(var i = 0; i < this.company.businessDocuments.length; i++){
            if (this.company.businessDocuments[i].attachmentType === attachmentType) {
              returnAttachmentAlreadyUploaded = this.company.businessDocuments[i].fileName;
            }
          }
        }
        return returnAttachmentAlreadyUploaded;
      },

      returnAttachmentAlreadyUploadedFileId: function(attachmentType) {
        let attachmentAlreadyUploadedId = null;
        if (this.company !== null) {
          for(var i = 0; i < this.company.businessDocuments.length; i++){
            if (this.company.businessDocuments[i].attachmentType === attachmentType) {
              attachmentAlreadyUploadedId = this.company.businessDocuments[i].id;
            }
          }
        }
        return attachmentAlreadyUploadedId;
      },

      reUpload: function(attachmentType){
        if (attachmentType === "NATIONAL_IDENTITY") { 
          this.reUploadNATIONAL_IDENTITY = true;
        }

        if (attachmentType === "PROOF_OF_RESIDENCE") { 
          this.reUploadPROOF_OF_RESIDENCE = true;
        }

      }
    }
  };
</script>
