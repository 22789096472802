<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Download Authorized Payments</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'authorized-payments'}">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>
				<br>

				<form class="form" @submit.prevent="releasePayments">
					<div class="row">
						<div class="col-md-2">
							<label>From Date</label>
				          	<input type="date" name="fromDate" id="fromDate" class="form-control" v-model="fromDate" required>
				         </div>
				         <div class="col-md-2">
							<label>To Date</label>
				          	<input type="date" name="toDate" id="toDate" class="form-control" v-model="toDate" required>
				         </div>
				         <div class="col-md-2">
				         	<label>&nbsp;</label>
							<button class="btn btn-success btn-block" type="submit">Release these payment</button>
						</div>
						<div class="col-md-2">
							<label>&nbsp;</label><br>
							<a href="http://localhost:7002/api/v1/payments/export-to-excel" class="btn btn-primary">Download the excel</a>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import PaymentService from "@/services/PaymentService.js";
  import loading from "vue-full-loading";

  export default {
    components: {
      loading
    },

    name: "AuthorizePayment",
    data: function() {
      return {
        show: false,
        label: "Processing request...",
        fromDate: null,
        toDate: null,
      };
    },

    computed: {

    },

    created() { },

    methods: {
	    releasePayments: function(){
    		this.show = true;
	        PaymentService.releasePayments(this.fromDate, this.toDate).then(response => {
	        	if (response.data.statusCode == 201) {
	        		this.$router.push({ name: 'payments' });
	        	}
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },
    }
  };
</script>