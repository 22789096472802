import { LOAD_SERVICE_URL } from "@/config/LoadServiceConfig";
import axios from "axios";

class PrivateLoadService {
  getPrivateLoads(carrierId) { 
    return axios.get(LOAD_SERVICE_URL + "privateLoads/getPrivateLoads/" + carrierId); 
  }
}

export default new PrivateLoadService;
