<template>
  <div>

    <button class='btn btn-primary' @click='getDistance'>Calculate Distance</button>
    <br><br>
    <table class="table table-hover table-bordered">
      <tbody>
        <tr>
          <th class="bg-success">Distance</th>
          <td>{{ distance }}</td>
        </tr>

        <tr>
          <th class="bg-success">Summary</th>
          <td>{{ summary }}</td>
        </tr>

        <tr>
          <th class="bg-success">Duration</th>
          <td>{{ duration }}</td>
        </tr>

        <tr>
          <th class="bg-success">Source address</th>
          <td>{{ start_address }}</td>
        </tr>

        <tr>
          <th class="bg-success">Destination address</th>
          <td>{{ end_address }}</td>
        </tr>

        <tr>
          <th class="bg-success">copyrights</th>
          <td>{{ copyrights }}</td>
        </tr>
      </tbody>
    </table>

    <h3>Distance: {{ distance }} km</h3>
    <hr>

    <h4>pickupAddressLatitude: {{ pickupAddressLatitude }}</h4>
    <h4>pickupAddressLongitude: {{ pickupAddressLongitude }}</h4>
    <br>

    <h4>deliveryAddressLatitude: {{ deliveryAddressLatitude }}</h4>
    <h4>deliveryAddressLongitude: {{ deliveryAddressLongitude }}</h4>
    <div class="row">
      <div class="col-md-6">
        <PickupAddressComponent/>
      </div>

      <div class="col-md-6">
        <DestinationComponent/>
      </div>
    </div>
  </div>
</template>
 
<script>
  import PickupAddressComponent from "@/views/distance/PickupAddressComponent.vue"
  import DestinationComponent from "@/views/distance/DestinationComponent.vue"
  import * as VueGoogleMaps from 'vue2-google-maps';

export default {
  name: "GoogleMap",
  components: {
    PickupAddressComponent,
    DestinationComponent
  },

  data() {
    return {
      mapLoaded: false,
      placeholder: "enter location",
      distance: 0,
      summary: '',
      copyrights: '',
      duration: '',
      start_address: '',
      end_address: '',
      pickupAddress: {
        country: null,
        province: null,
        city: null,
        physicalAddress: null
      },
      deliveryAddress: {
        country: null,
        province: null,
        city: null,
        physicalAddress: null
      }
    };
  },
 
  mounted() {

  },

  methods: {
    // proprietray source code!!!
    getDistance() {
      this.$gmapApiPromiseLazy().then(() => {
        var directionsService = new this.google.maps.DirectionsService();
        var start = new this.google.maps.LatLng(parseFloat(this.pickupAddressLatitude), parseFloat(this.pickupAddressLongitude));
        var end = new this.google.maps.LatLng(parseFloat(this.deliveryAddressLatitude), parseFloat(this.deliveryAddressLongitude));
        var bounds = new this.google.maps.LatLngBounds();
        bounds.extend(start);
        bounds.extend(end);
        var request = {
            origin: start,
            destination: end,
            travelMode: this.google.maps.TravelMode.DRIVING
        };
        var googleInstance = this.google; // local var, (google instance cannot reach vue js this object!!!)
        var self = this; // reference back to the "real" object as opposed to the event.
        directionsService.route(request, function (response, status) {
            if (status == googleInstance.maps.DirectionsStatus.OK) {
                self.distance = response.routes[0].legs[0].distance.value;
                console.log('distance: ', self.distance);
                console.log('distanceKm: ', self.distance / 1000);
                self.summary = response.routes[0].summary;
                self.copyrights = response.routes[0].copyrights;
                self.duration = response.routes[0].legs[0].duration;
                self.start_address = response.routes[0].legs[0].start_address;
                self.end_address = response.routes[0].legs[0].end_address;
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });

        // var geocoder = new self.google.maps.Geocoder;
        this.getAddress(self.pickupAddressLatitude, self.pickupAddressLongitude, self);

      }).catch(error => { // I added the error handling
        console.log(error);
      });
    },

    getAddress: function(lat, lng, self){
       var latlng = { lat: lat, lng: lng };
       var streetNumber, plus_code, route, city, state, country, physicalAddress;
        // reverse geocoding
        var geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({ 'location': latlng }, function(response, status) {
          if (status === 'OK') {
            if (response[0]) {
              console.log('address: ', response[0].address_components);
              for(var i = 0; i < response[0].address_components.length; i++){
                for(var j = 0; j < response[0].address_components[i].types.length; j++){
                    if (response[0].address_components[i].types[j] === 'street_number'){
                        streetNumber = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'plus_code') {
                      plus_code = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'route'){
                        route = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'locality'){
                        city = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'administrative_area_level_1'){
                        state = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'country'){
                        country = response[0].address_components[i].long_name;
                    }
                }
              }
              console.log("streetNumber: " + streetNumber);
              console.log("plus_code: " + plus_code);
              console.log("route: " + route);
              console.log("city: " + city);
              console.log("state: " + state);
              console.log("country: " + country);
              console.log('formatted_address', response[0].formatted_address);
              physicalAddress = response[0].formatted_address;
              console.log('physicalAddress', physicalAddress);
              self.pickupAddress.country = country;
              self.pickupAddress.province = state;
              self.pickupAddress.city = city;
              self.pickupAddress.physicalAddress = response[0].formatted_address;
              console.log('pickupAddress: {}', self.pickupAddress);
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        });
      },
  },

  computed: {
    google: VueGoogleMaps.gmapApi,

    pickupAddressLatitude: function(){
      return this.$store.state.pickupAddressLatitude;
    },

    pickupAddressLongitude: function(){
      return this.$store.state.pickupAddressLongitude;
    },

    deliveryAddressLatitude: function(){
      return this.$store.state.deliveryAddressLatitude;
    },

    deliveryAddressLongitude: function(){
      return this.$store.state.deliveryAddressLongitude;
    },
  }
};
</script>