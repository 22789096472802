<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>
    
    <div class="panel">
      <img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>        
    </div>

    <div class="panel">
      <div class="panel-body">
        <h4>Provide Business Postal Address or Location</h4>
        <br>
        <div class="row">
          <div class="col-md-12">
            <MapPostalAddressComponent/>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10">
            <p><i>You can drag around the red marker to point to exact position you want on the map!</i></p>
            <p>And also zoom in and out to choose exact address from the map!</p>
          </div>
          <div class="col-md-2">
            <button class='btn btn-danger pull-right' @click="showArea">Show chosen location details</button>
          </div>
        </div>
        <br>

        <table class="table table-hover table-bordered">
          <tbody>
            <tr>
              <th class="bg-success">Location or address</th>
              <td>{{ locationRestriction.physicalAddress }}</td>
            </tr>
            <tr>
              <th class="bg-success">Copyrights</th>
              <td>{{ copyrights }} @Google Inc.</td>
            </tr> 
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-10">
          </div>
          <div class="col-md-2">
            <form @submit.prevent="save">
              <span v-if="locationRestriction.country !== null">
                <button class='btn btn-primary btn-block' type="submit">Submit</button>
              </span>
            </form>
          </div>
        </div>      

        <div v-if="!userOnline">
          <h4 class="text-danger">Snap. Looks like you are offline. Please check your internet connection and try again!</h4>
          <img src="@/assets/images/offline.png" width="100" height="100">
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
  import MapPostalAddressComponent from "@/views/locations/MapPostalAddressComponent.vue"
  import * as VueGoogleMaps from 'vue2-google-maps';
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService.js";

export default {
  name: "PostalAddressComponent",
  components: {
    loading,
    MapPostalAddressComponent
  },

  data() {
    return {
      show: false,
      label: 'Processing request',
      distance: '',
      summary: '',
      copyrights: '',
      duration: '',
      start_address: '',
      end_address: '',
      locationRestriction: {
        country: null,
        province: null,
        city: null,
        physicalAddress: null,
        latitude: null,
        longitude: null
      },
      destinationAddress: {
        country: null,
        province: null,
        city: null,
        physicalAddress: null,
        latitude: null,
        longitude: null
      },
      professional: null,
      message: null,
      userOnline: false,
      username: JSON.parse(localStorage.getItem('username')),
      company: null
    };
  },
 
  mounted() {
    this.userOnline = window.navigator.onLine;
    this.findByUuid();
  },

  methods: {
    showArea() {
      this.show = true;
      this.$gmapApiPromiseLazy().then(() => {
        // var directionsService = new this.google.maps.DirectionsService();
        var start = new this.google.maps.LatLng(parseFloat(this.pickupAddressLatitude), parseFloat(this.pickupAddressLongitude));
        var end = new this.google.maps.LatLng(parseFloat(this.destinationAddressLatitude), parseFloat(this.destinationAddressLongitude));
        var bounds = new this.google.maps.LatLngBounds();
        bounds.extend(start);
        bounds.extend(end);
        var request = {
            origin: start,
            destination: end,
            travelMode: this.google.maps.TravelMode.DRIVING
        };
        console.log(request);
        //var googleInstance = this.google; // local var, (google instance cannot reach vue js this object!!!)
        var self = this; // reference back to the "real" object as opposed to the event.
        // directionsService.route(request, function (response, status) {
        //     if (status == googleInstance.maps.DirectionsStatus.OK) {
        //         self.distance = response.routes[0].legs[0].distance;
        //         self.summary = response.routes[0].summary;
        //         self.copyrights = response.routes[0].copyrights;
        //         self.duration = response.routes[0].legs[0].duration;
        //         self.$store.commit('setEstimatedDrivingTime', response.routes[0].legs[0].duration.value);
        //         self.$store.commit('setEstimatedDistance', response.routes[0].legs[0].distance.value);
        //         self.start_address = response.routes[0].legs[0].start_address;
        //         self.end_address = response.routes[0].legs[0].end_address;
        //     } else {
        //         alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
        //     }
        // });
        this.getPickupAddress(self.pickupAddressLatitude, self.pickupAddressLongitude, self);
        // this.getDestinationAddress(self.destinationAddressLatitude, self.destinationAddressLongitude, self);
        this.show = false;
      }).catch(error => { // I added the error handling
        console.log(error);
      });
    },

    getPickupAddress: function(lat, lng, self){
       var latlng = { lat: lat, lng: lng };
       var city, state, country;
        var geocoder = new this.google.maps.Geocoder(); // reverse geocoding
        geocoder.geocode({ 'location': latlng }, function(response, status) {
          if (status === 'OK') {
            if (response[0]) {
              for(var i = 0; i < response[0].address_components.length; i++){
                for(var j = 0; j < response[0].address_components[i].types.length; j++){
                    if (response[0].address_components[i].types[j] === 'locality'){
                        city = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'administrative_area_level_1'){
                        state = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'country'){
                        country = response[0].address_components[i].long_name;
                    }
                }
              }
              self.locationRestriction.country = country;
              self.locationRestriction.province = state;
              self.locationRestriction.city = city;
              self.locationRestriction.physicalAddress = response[0].formatted_address;
              self.locationRestriction.latitude = self.pickupAddressLatitude;
              self.locationRestriction.longitude = self.pickupAddressLongitude;
              console.log('locationRestriction: {}', self.locationRestriction);
              self.$store.commit('setPickupAddress', self.locationRestriction);
              console.log('Yay locationRestriction: ', self.$store.state.locationRestriction);
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        });
      },

      // repeated this method! Need refactor
      getDestinationAddress: function(lat, lng, self){
       var latlng = { lat: lat, lng: lng };
       var city, state, country;
        var geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({ 'location': latlng }, function(response, status) {
          if (status === 'OK') {
            if (response[0]) {
              for(var i = 0; i < response[0].address_components.length; i++){
                for(var j = 0; j < response[0].address_components[i].types.length; j++){
                    if (response[0].address_components[i].types[j] === 'locality'){
                        city = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'administrative_area_level_1'){
                        state = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'country'){
                        country = response[0].address_components[i].long_name;
                    }
                }
              }
              self.destinationAddress.country = country;
              self.destinationAddress.province = state;
              self.destinationAddress.city = city;
              self.destinationAddress.physicalAddress = response[0].formatted_address;
              self.destinationAddress.latitude = self.destinationAddressLatitude;
              self.destinationAddress.longitude = self.destinationAddressLongitude;
              self.$store.commit('setDestinationAddress', self.destinationAddress);
              console.log('Yay destinationAddress: ', self.$store.state.destinationAddress);
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        });
    },

    findByUuid(){
      this.show = true;
      CompanyService.findByUuid(this.getUuid).then(response => {
        this.show = false;
        this.company = response.data.body;
      }).catch(error => {
        console.log(error);
        this.show = false;
      });
    },

    save: function() {
        this.show = true;
        this.company.uuid = this.getUuid;
        this.company.address = null;
        this.company.postalAddress = this.locationRestriction;
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode === 201) {
            this.$router.push({ name: "new-business-profile/business-documents", params: { uuid: this.getUuid }});
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
  },

  computed: {
    getUuid() {
      return this.$route.params.uuid;
    },

    google: VueGoogleMaps.gmapApi,
    pickupAddressLatitude: function(){
      return this.$store.state.pickupAddressLatitude;
    },

    pickupAddressLongitude: function(){
      return this.$store.state.pickupAddressLongitude;
    },

    destinationAddressLatitude: function(){
      return this.$store.state.destinationAddressLatitude;
    },

    destinationAddressLongitude: function(){
      return this.$store.state.destinationAddressLongitude;
    },
  }
};
</script>