<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10"></div>
          <div class="col-md-2">
            <router-link :to="{ name: 'roles/new' }">
              <button class="btn btn-success pull-right">New Role <span class="glyphicon glyphicon-plus"></span></button>
            </router-link>
          </div>
        </div>

        <h4>Roles list</h4>
        <table class="table table-responsive table-bordered">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="role in roles" :key="role.id">
              <td>{{ role.id }}</td>
              <td>{{ role.name }}</td>
              <td>{{ role.description }}</td>
              <td>
                <span class="label label-success" v-if="role.status == 'ACTIVE'">Active</span>
                <span class="label label-default" v-if="role.status == 'INACTIVE'">Inactive</span>
                <span class="label label-danger" v-if="role.status == 'DELETED'">Deleted</span>
              </td>
              <td>
                <router-link :to="{ name: 'roles/view', params: { roleId: role.id } }">
                  <button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
                </router-link>&nbsp;
                <router-link :to="{ name: 'roles/edit', params: { roleId: role.id } }">
                  <button class="btn btn-warning">Edit <span class="glyphicon glyphicon-edit"></span></button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import RoleService from "@/services/RoleService";
  import loading from "vue-full-loading";

  export default {
    name: "BankList",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing...",
        roles: []
      };
    },
    created() {
      this.getAllBanks();
    },
    methods: {
      getAllBanks() {
        this.show = true;
        RoleService.findAll().then(response => {
          this.show = false;
          this.roles = response.data.body;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>
