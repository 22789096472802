<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div v-if="company !== null">
      <form class="form" @submit.prevent="update">
        <div class="form-group">
          <label>Business Name</label>
          <input type="text" name="name" id="name" class="form-control" v-model="company.name" :placeholder="company.name" :disabled="isDisabled">
        </div>

        <div class="form-group">
          <label>Business Trading Name</label>
          <input type="text" name="tradingName" id="tradingName" class="form-control" v-model="company.tradingName" :placeholder="company.tradingName !== null ? company.tradingName: 'Not available'" :disabled="isDisabled">
        </div>

        <div class="form-group">
          <label>Business partner or Registration number</label>
          <input type="text" name="businessPartnerNumber" id="businessPartnerNumber" class="form-control" v-model="company.businessPartnerNumber" :placeholder="company.businessPartnerNumber !== null ? company.businessPartnerNumber: 'Not available'" :disabled="isDisabled">
        </div>

        <div v-if="company.phoneNumbers !== null">
          <label class="text-danger">Contact Numbers</label><br><br>
          <div v-if="company.phoneNumbers.length > 0">
            <div v-for="(phoneNumberField, phoneNumberIndex) in company.phoneNumbers" :key="phoneNumberField.id">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group" v-show="isDisabled">
                    <label>Country Code</label>
                    <input type="text" class="form-control" :placeholder="phoneNumberField.country !== null ? phoneNumberField.country.code: 'Not available'" :disabled="isDisabled">
                  </div>
                  <div class="form-group" v-show="!isDisabled">
                    <label for="countryId">Country Code</label>
                    <select class="form-control" v-model="phoneNumberField.countryId" :disabled="isDisabled">
                      <option value="" disabled>Select option</option>
                      <option v-for="country in countries" :key="country.id" :value="country.id">+{{ country.code }} - {{ country.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="number">Business phone number</label>
                    <input type="number" name="number" class="form-control" v-model="phoneNumberField.number" maxlength="9" oninput="javascript: if (this.value.length > this.maxLength) { return this.value = this.value.slice(0, this.maxLength); }" placeholder="Enter phone number" :disabled="isDisabled" required>
                  </div>              
                </div>
                <div class="col-md-2">
                  <div class="form-group" v-show="company.phoneNumbers.length > 1">
                    <label>&nbsp;</label><br>
                    <button class="btn btn-danger btn-sm pull-left" type="button" @click="company.phoneNumbers.splice(phoneNumberIndex, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
                  </div>
                </div>
              </div>          
            </div>
          </div>  
        </div>

        <div class="form-group">
          <label>Business email</label>
          <input type="text" name="email" id="email" class="form-control" v-model="company.email" :placeholder="company.email" :disabled="isDisabled">
        </div>

        <div class="form-group">
          <label>Created on</label>
          <input type="text" name="createdAt" id="createdAt" class="form-control" v-model="company.faxNumber" :placeholder="company.createdAt" disabled>
        </div>

        <div class="form-group">
          <label>Last updated on</label>
          <input type="text" name="updatedAt" id="updatedAt" class="form-control" v-model="company.updatedAt" :placeholder="company.updatedAt" disabled>
        </div>

        <div class="form-group">
          <label>Business description</label>
          <textarea name="description" class="form-control" v-model="company.description" :placeholder="company.description" rows="5" :disabled="isDisabled"></textarea>
        </div>

        <span v-if="company.type === 'BROKER'">
          <div class="form-group">
            <label>Broker Number</label>
            <input type="text" name="brokerNumber" class="form-control" v-model="company.brokerNumber" :placeholder="company.brokerNumber" :disabled="isDisabled">
          </div>

          <div class="form-group">
            <label>Customs Agent Permit Number</label>
            <input type="text" name="customsAgentPermitNumber" class="form-control" v-model="company.customsAgentPermitNumber" :placeholder="company.customsAgentPermitNumber" rows="5" :disabled="isDisabled">
          </div>
        </span>

        <span v-if="company.type === 'CARRIER'">
          <div class="form-group">
            <label>Carrier Number</label>
            <input type="text" name="carrierNumber" class="form-control" v-model="company.carrierNumber" :placeholder="company.carrierNumber" :disabled="isDisabled">
          </div>

          <div class="form-group">
            <label>Road Motor Transport Number</label>
            <input type="text" name="roadMotorTransportNumber" class="form-control" v-model="company.roadMotorTransportNumber" :placeholder="company.roadMotorTransportNumber" rows="5" :disabled="isDisabled">
          </div>
        </span>

        <span v-if="company.type === 'SHIPPER'">
          <div class="form-group">
            <label>Shipper Number</label>
            <input type="text" name="shipperNumber" class="form-control" v-model="company.shipperNumber" :placeholder="company.shipperNumber" :disabled="isDisabled">
          </div>
        </span>

        <div class="form-group">
          <label>Status</label>
          <select class="form-control" :disabled="isDisabled">
            <option value="" disabled>Select option</option>
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        </div>
      </form>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService.js";
  import loading from "vue-full-loading";
  import CountryService from "@/services/CountryService.js";

  export default {
    name: "BasicDetailsComponent",
    components: {
      loading
    },

    props: {
      company: {
        type: Object,
        default: null
      }
    },

    data: function() {
      return {
        isDisabled: true,
        label: "Processing request",
        show: false,
        countries: [],
      }
    },

    mounted(){
      this.findAllCountries();
    },

    methods: {
      enableEdit: function(){
        if (this.isDisabled) {
          this.isDisabled = false;
        }
      },

      update: function(){
        this.show = true;
        this.company.companyId = this.getCompanyId;
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode == 201) {
            this.show = false;
            this.isDisabled = true;
            this.$router.push({ name: 'companies' });
          }}).catch(error => {
            console.log(error);
        });
      },

      findAllCountries: function() {
        return CountryService.findAll().then(response => {
          this.countries = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
      },

      addPhoneNumberField: function(){
        this.company.phoneNumbers.push({
          id: null,
          zipCodeId: null,
          phoneNumber: null
        });
      },
    },

    computed: {
      getCompanyId: function(){
        return this.$route.params.companyId;
      }
    }
  };
</script>
