<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <h4>Load Charge List</h4>        
          </div>
          <router-link :to="{ name: 'new-load-charge' }">
            <div class="col-md-2">
              <button class="btn btn-success btn-block">New Load Charge <span class="glyphicon glyphicon-plus"></span></button>
            </div>
          </router-link>
        </div>
        <br>

        <table class="table table-responsive table-bordered">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Percentage Charge</th>
            <th>Currency</th>
            <th>Effective Year</th>
            <th>Start Date</th>
            <th>EndDate</th>
            <th>Expired</th>
            <th>Enabled</th>
            <th>Active</th>
            <th>Current</th>
            <th>Description</th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="nssa in nssaList" :key="nssa.id">
              <td>{{ nssa.id }}</td>
              <td>{{ nssa.percentageCharge }}</td>
              <td>{{ nssa.currency !== null ? nssa.currency.name: 'NA' }}</td>
              <td>{{ nssa.effectiveYear }}</td>
              <td>{{ nssa.startDate }}</td>
              <td>{{ nssa.endDate }}</td>
              <td>{{ nssa.expired }}</td>
              <td>{{ nssa.enabled }}</td>
              <td>{{ nssa.active }}</td>
              <td>{{ nssa.current }}</td>
              <td>{{ nssa.description }}</td>
              <td>
                <span class="label label-success" v-if="nssa.status === 'ACTIVE'">{{ nssa.status }}</span>
                <span class="label label-danger" v-if="nssa.status === 'INACTIVE'">{{ nssa.status }}</span>
              </td>
              <td>
                <router-link :to="{ name: 'edit-nssa', params: { id: nssa.id } }">
                  <button class="btn btn-warning">Edit</button>
                  </router-link>&nbsp;
                <router-link :to="{ name: 'view-nssa', params: { id: nssa.id } }">
                <button class="btn btn-primary">View</button></router-link>&nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import LoadChargeService from "@/services/LoadChargeService";
  import loading from "vue-full-loading";

  export default {
    name: "APWCList",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing request",
        nssaList: []
      };
    },

    mounted() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        LoadChargeService.findAll().then(response => {
          this.show = false;
          this.nssaList = response.data.body;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>
