<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <router-link :to="{ name: 'zipCodes' }">
          <button class="btn btn-primary"><span class="fa fa-step-backward"></span> Back</button>
        </router-link>
        <br/><br/>

        <p class="error-message text-center" v-if="errorMessage !== null">
          {{ errorMessage }}
        </p>

        <h4>{{ editing ? "Edit": "New" }} Zip Code</h4>
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label for="code">Code</label>
            <input
              type="number"
              v-model="zipCode.code"
              class="form-control"
              placeholder="Enter Zip Code"
            />
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.code }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <input
              type="text"
              v-model="zipCode.description"
              class="form-control"
              placeholder="Enter Description"
            />
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.description }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" v-model="zipCode.status" required>
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.status }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="submit">&nbsp;</label>
            <button class="btn btn-success" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import ZipCodeService from "@/services/ZipCodeService";
  import ZipCode from "@/models/ZipCode";
  import loading from "vue-full-loading";

  export default {
    name: "NewOrUpdateZipCode",
    components: {
      loading
    },

    data: function() {
      return {
        zipCode: new ZipCode(),
        errors: [],
        errorMessage: null,
        show: false,
        label: "Processing request",
        editing: false
      };
    },

    computed: {},

    created: function() {},

    mounted(){
      if("zipCodeId" in this.$route.params){
        this.editing = true;
        this.findById();
      }
    },

    methods: {
      save: function() {
        if (this.editing) {
          this.zipCode.zipCodeId = this.$route.params.zipCodeId;
        }
        
        ZipCodeService.save(this.zipCode).then(response => {
          if (response.data.statusCode == 201) {
            this.$router.push({ name: "zipCodes" });
          }
        }).catch(error => {
          console.log(error);
        });
      },

      findById: function(){
        ZipCodeService.findById(this.$route.params.zipCodeId).then(response => {
          this.zipCode = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>
