<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <h4>Welcome, {{ getUser.username }}</h4>
        <p>Manage system operations here</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <router-link :to="{ name: 'companies'}">
          <div class="panel">
            <div class="panel-body">
                <h4><span class="label label-primary">{{ companies.length }}</span> Companies</h4>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-2">
        <router-link :to="{ name: 'users'}">
          <div class="panel">
            <div class="panel-body">
                <h4><span class="label label-primary">{{ users.length }}</span> Users</h4>
            </div>
          </div>
        </router-link>
      </div>
      
      <div class="col-md-2">
        <router-link :to="{ name: 'subscriptions'}">
          <div class="panel">
            <div class="panel-body">
                <h4><span class="label label-primary">{{ subscriptions.length }}</span> Subscriptions</h4>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-2">
        <router-link :to="{ name: 'invoice-list'}">
          <div class="panel">
            <div class="panel-body">
                <h4><span class="label label-warning">{{ invoices.length }}</span> Invoices</h4>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-2">
        <router-link :to="{ name: 'payment-list'}">
          <div class="panel">
            <div class="panel-body">
                <h4><span class="label label-success">{{ payments.length }}</span> Payments</h4>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-2">
        <router-link :to="{ name: ''}">
          <div class="panel">
            <div class="panel-body">
                <h4><span class="label label-default">{{ loads.length }}</span> Loads</h4>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-2">
        <router-link :to="{ name: ''}">
          <div class="panel">
            <div class="panel-body">
                <h4><span class="label label-primary">{{ bids.length }}</span> Bids</h4>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-2">
        <router-link :to="{ name: ''}">
          <div class="panel">
            <div class="panel-body">
                <h4><span class="label label-primary">{{ bookings.length }}</span> Bookings</h4>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";
  import UserService from "@/services/UserService";
  import SubscriptionService from "@/services/SubscriptionService";
  import InvoiceService from "@/services/InvoiceService";
  import PaymentService from "@/services/PaymentService";
  import LoadService from "@/services/LoadService";
  import BidService from "@/services/BidService";
  import BookingService from "@/services/BookingService";

  export default {
    name: "AdminDashboard",
    components: {
      loading
    },
    
    data: function() {
      return {
        show: false,
        label: "Processing request",
        companies: [],
        users: [],
        subscriptions: [],
        invoices: [],
        payments: [],
        loads: [],
        bids: [],
        bookings: []
      };
    },

    mounted: function() {
      this.findAllCompanies();
      this.findAllUsers();
      this.findAllSubscriptions();
      this.findAllInvoices();
      this.findAllPayments();
      this.findAllLoads();
      this.findAllBids();
      this.findAllBookings();
    },

    computed: {
      getUser(){
        return this.$store.state.auth.user.user;
      }
    },

    methods: {
      findAllCompanies(){
        this.show = true;
        CompanyService.findAll().then(response => {
          this.companies = response.data.body.filter(company => {
            return company.type !== 'FINANCIAL_INSTITUTION'
          });
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        })
      },

      findAllUsers(){
        this.show = true;
        UserService.findAll().then(response => {
          this.users = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        })
      },

      findAllSubscriptions(){
        this.show = true;
        SubscriptionService.findAll().then(response => {
          this.subscriptions = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        })
      },

      findAllInvoices(){
        this.show = true;
        InvoiceService.findAll().then(response => {
          this.invoices = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        })
      },

      findAllPayments(){
        this.show = true;
        PaymentService.findAll().then(response => {
          this.payments = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        })
      },

      findAllLoads(){
        this.show = true;
        LoadService.findAll().then(response => {
          this.loads = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        })
      },

      findAllBids(){
        this.show = true;
        BidService.findAll().then(response => {
          this.bids = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        })
      },

      findAllBookings(){
        this.show = true;
        BookingService.findAll().then(response => {
          this.bookings = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        })
      }
    }
  };
</script>

