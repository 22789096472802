<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Load alert details</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'carrier/loads/load-alerts' }">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>
				<br>

				<div class="list-group" v-if="loadAlert !== null">
					<li class="list-group-item">Load alert name: {{ loadAlert.name }}</li>
					<li class="list-group-item">Load alert number: {{ loadAlert.loadAlertNumber }}</li>
					<li class="list-group-item">Trailer type: {{ loadAlert.trailerType }}</li>
					<li class="list-group-item">Load type: {{ loadAlert.loadType }}</li>
					<li class="list-group-item">Pickup address:
						<div class="list-group" v-if="loadAlert.pickupAddress !== null">
							<li class="list-group-item">City : {{ loadAlert.pickupAddress.city !== null ? loadAlert.pickupAddress.city.name: 'Not available' }}</li>
							<li class="list-group-item">Province : {{ loadAlert.pickupAddress.province !== null ? loadAlert.pickupAddress.province.name: 'Not available' }}</li>
						</div>
					</li>
					<li class="list-group-item">Dead Head Origin: {{ loadAlert.deadHeadOrigin }} {{ loadAlert.deadHeadOriginMeasurement }}</li>
					<li class="list-group-item">Dead Head Destination: {{ loadAlert.deadHeadDestination }} {{ loadAlert.deadHeadDestinationMeasurement }}</li>
					<li class="list-group-item">Delivery address:
						<div class="list-group" v-if="loadAlert.deliveryAddress !== null">
							<li class="list-group-item">City : {{ loadAlert.deliveryAddress.city !== null ? loadAlert.deliveryAddress.city.name: 'Not available' }}</li>
							<li class="list-group-item">Province : {{ loadAlert.deliveryAddress.province !== null ? loadAlert.deliveryAddress.province.name: 'Not available' }}</li>
						</div>
					</li>
					<li class="list-group-item">Pickup Date: {{ loadAlert.pickupDate }}</li>
					<li class="list-group-item">Drop Off Date: {{ loadAlert.dropOffDate }}</li>
					<li class="list-group-item">Load Mode: {{ loadAlert.loadMode }}</li>
					<li class="list-group-item">Length: {{ loadAlert.length }} {{ loadAlert.lengthMeasurement }}</li>
					<li class="list-group-item">Weight: {{ loadAlert.weight }}</li>
					<li class="list-group-item">Price: {{ loadAlert.currency }}{{ loadAlert.price }}</li>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadAlertService from "@/services/LoadAlertService";

	export default{
		name: 'NewLoadAlert',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				loadAlert: null
			}
		},

		mounted(){
			this.findByLoadAlertNumber();
		},

		computed: {
			getLoadAlertNumber: function() {
				return this.$route.params.loadAlertNumber;
			}
		},

		methods: {
			findByLoadAlertNumber: function(){
				this.show = true;
				LoadAlertService.findByLoadAlertNumber(this.getLoadAlertNumber).then(response => {
					if (response.data.statusCode === 200) {
						this.loadAlert = response.data.body;
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>