<template>
  <div>
    <fieldset>
      <legend><h4>Search delivery address</h4></legend>
        <div class="row">
        <div class="col-md-8">
          <GmapAutocomplete 
            class="form-control"
            @place_changed="setPlace"
            :options="{fields: ['geometry', 'formatted_address', 'address_components']}">
          </GmapAutocomplete>
        </div>
        <div class="col-md-2">
          <button class="btn btn-primary" @click="addMarker">Pin delivery address</button>
        </div>
      </div>
      <br>
      <gmap-map
        :center="center"
        :zoom="12"
        style="width:100%;  height: 370px;">
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @dragend="updateMarker(index,$event.latLng)"
        @click="center=m.position"/>
      </gmap-map>
    </fieldset>
  </div>
</template>
 
<script>

export default {
  name: "DestinationComponent",
  data() {
    return {
      // default to Harare to keep it simple :: this should be based on the user location (fetch default country location cordinates)
      center: { lat: -17.8216, lng: 31.0492 },
      markers: [],
      places: [],
      currentPlace: null,
      yay: null,

      latitude: null,
      longitude: null,
    };
  },
 
  mounted() {
    this.geolocate();
  },
 
  methods: {

    updateMarker(index,location){
      this.markers[index] = {
        location:{
          lat: location.lat(),
          lng: location.lng()
        }
      }
      this.latitude = location.lat();
      this.latitude = location.lng();
      this.$store.commit('setDestinationAddressLatitude', location.lat());
      this.$store.commit('setDestinationAddressLongitude', location.lng());
    },

    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },

    addMarker() {
      if (this.currentPlace === null) {
        alert('Please enter a delivery address');
      }
      if (this.currentPlace !== null) {
        this.$store.commit('setDestinationAddressLatitude', this.currentPlace.geometry.location.lat());
        this.$store.commit('setDestinationAddressLongitude', this.currentPlace.geometry.location.lng());
      }

      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },

    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {        
        this.center = { lat: position.coords.latitude, lng: position.coords.longitude };
      });
    }
  },
};
</script>