<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <h4>Carrier assigned</h4>
	    <div class="panel">
	    	<div class="panel-body">
	    		<table class="table table-responsive table-bordered">
			    	<thead class="bg-primary" style="background: #000000; color: #ffffff;">
				        <th>Load #</th>
				        <th>Booking #</th>
				        <th>Load Price</th>
				        <th>Pickup</th>
				        <th>Drop Off</th>
				        <th>Assigned Truck</th>
				        <th>Load Status</th>
				        <th>Actions</th>
				      </thead>
				      <tbody>
				        <tr v-for="loadBooking in getFilteredLoadBookings" :key="loadBooking.id">
				        	<td>
				        		<router-link :to="{ name: 'view-load', params: { loadNumber: loadBooking.load.loadNumber }}">
				        			{{ loadBooking.load.loadNumber }}
				        		</router-link>
				        	</td>
				        	<td>{{ loadBooking.booking.bookingNumber }}</td>
				        	<td>{{ loadBooking.load.currency }}{{ loadBooking.load.price }}</td>
				          	<td>
				          		{{ loadBooking.load.pickupAddress !== null ? loadBooking.load.pickupAddress.city.name + ' ' + loadBooking.load.pickupAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		{{ loadBooking.load.dropOffAddress !== null ? loadBooking.load.dropOffAddress.city.name + ' ' + loadBooking.load.dropOffAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		<router-link :to="{ name: 'trucks/view', params: { truckNumber: loadBooking.booking.assignedTruck.truckNumber }}">
				          			{{ loadBooking.booking.assignedTruck !== null ? loadBooking.booking.assignedTruck.registrationNumber: '' }}
				          		</router-link>
				          	</td>
				          	<td>{{ loadBooking.load.status }}</td>
				          	<td>
				          		<span v-show="loadBooking.load.invoice !== null && !loadBooking.load.released && loadBooking.load.status === 'BOOKED'">
				          			<div class="row">
				          				<div class="col-md-3">
				          					<div class="form-group">
				          						<label>Collection Date</label>
				          						<input type="date" name="releasedDate" v-model="loadBooking.load.releasedDate">
				          					</div>
				          				</div>&nbsp;&nbsp;
				          				<div class="col-md-3">
				          					<label>Time</label>
				          					<vue-timepicker v-model="loadBooking.load.releasedTime" format="HH:mm" required></vue-timepicker>
				          				</div>
				          				<div class="col-md-1"></div>
				          				<div class="col-md-2">
				          					<label>&nbsp;</label><br>
				          					<button class="btn btn-success btn-sm" @click="release(loadBooking.load)">Authorize</button>
				          				</div>
				          			</div>
				          		</span>
				          		<span>&nbsp;
				          			<!-- <a :href="PDF_API_URL + 'pdf/generateBillOfLading/' + loadBooking.load.loadNumber " target="_blank" class="btn btn-primary">Preview BOL</a> -->
				          		</span>
				          	</td>
				          </tr>
				    </tbody>
				</table>
	    	</div>
	    </div>    
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService.js";
	import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

	export default{
		name: 'CarrierAssigned',
		components: {
			loading,
			VueTimepicker
		},

		props: {
			loadBookings: {
				type: Array,
				default: function(){
					return []
				}
			}
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				PDF_API_URL: PDF_API_URL
			}
		},

		computed: {
		    getFilteredLoadBookings(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && !loadBooking.load.released
		    	});
		    }
		},

		methods: {
			release: function(load){
				this.show = true;
				if (load.releasedTime != null) {
					var releasedHour = load.releasedTime.HH;
					if (releasedHour === '') {
						releasedHour = "00";
					}
					var releasedMin = load.releasedTime.mm;
					if (releasedMin === '') {
						releasedMin = '00';
					}
					load.releasedTime = releasedHour + ':' + releasedMin;
				}
				LoadService.release(load).then(response => {
					if (response.data.statusCode === 201){
						window.location.reload();
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>