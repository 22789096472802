<template>
  <div>
    <h4>Search pickup address</h4>
    <div class="row">
      <div class="col-md-8">
        <GmapAutocomplete 
          class="form-control"
          @place_changed="setPlace"
          :options="{fields: ['geometry', 'formatted_address', 'address_components']}">
        </GmapAutocomplete>
      </div>
      <div class="col-md-2">
        <button class="btn btn-primary" @click="addMarker">Pin pickup address</button>
      </div>
    </div>
    <br>
    <gmap-map
      :center="center"
      :zoom="12"
      style="width:100%;  height: 400px;">
    <gmap-marker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      :draggable="true"
      @dragend="updateMarker(index,$event.latLng)"
      @click="center=m.position"/>
    </gmap-map>
    
  </div>
</template>
 
<script>

export default {
  name: "PickupAddressComponent",
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: -17.7902952, lng: 31.073527 },
      markers: [],
      places: [],
      currentPlace: null,
      yay: null,

      latitude: null,
      longitude: null,
    };
  },
 
  mounted() {
    this.geolocate();
  },
 
  methods: {

    updateMarker(index,location){
      this.markers[index] = {
        location:{
          lat: location.lat(),
          lng: location.lng()
        }
      }
      this.latitude = location.lat();
      this.latitude = location.lng();
      console.log('latitude: ', this.latitude);
      // console.log('longitude: ', this.latitude);

      // also commit to localStorage such that user is available 
      this.$store.commit('setPickupAddressLatitude', location.lat());
      console.log('get latitude: ', this.$store.state.pickupAddressLatitude);

      this.$store.commit('setPickupAddressLongitude', location.lng());
      console.log('get longitude: ', this.$store.state.pickupAddressLongitude);
    },

    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },

    addMarker() {
      if (this.currentPlace === null) {
        alert('Please enter a pickup address');
      }
      if (this.currentPlace !== null) {
        // console.log('lat: ', this.currentPlace.geometry.location.lat());
        // console.log('longitude: ', this.currentPlace.geometry.location.lng());

        this.$store.commit('setPickupAddressLatitude', this.currentPlace.geometry.location.lat());
        console.log('get latitude 2: ', this.$store.state.pickupAddressLatitude);
        this.$store.commit('setPickupAddressLongitude', this.currentPlace.geometry.location.lng());
        console.log('get longitude 2: ', this.$store.state.pickupAddressLongitude);
      }

      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },

    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {        
        this.center = { lat: position.coords.latitude, lng: position.coords.longitude };
      });
    }
  },
};
</script>