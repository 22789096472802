<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div v-if="booking !== null">
			<div class="panel">
				<div class="panel-body">
					<fieldset>
						<legend>Load payment checkout</legend>
						<p>Pay <span class="text-danger">{{ booking.carrier != null ? booking.carrier.name: '' }}</span> for the load carriage they are booked. After you pay, the payment will be withheld in escrow and will be released once the carrier has delivered the load and you have confirmed delivery of the same</p>
						<hr>
						<h4>We accept the following payment methods</h4>
						<div class="row">
							<div class="col-md-1">
								<br>
								<img src="@/assets/images/ecocash.png" class="img img-rounded img-responsive center-block" height="120" width="120">
							</div>
							<div class="col-md-2">
								&nbsp;
								<img src="@/assets/images/zimswitch.png" class="img img-rounded img-responsive center-block" height="120" width="120">
							</div>
							<div class="col-md-2">
								&nbsp;
								<img src="@/assets/images/vpayments.png" class="img img-rounded img-responsive center-block"  height="170" width="220">
							</div>
							<div class="col-md-2">
								<br>
								<img src="@/assets/images/telecash.png" class="img img-rounded img-responsive center-block" height="120" width="120">
							</div>
							<div class="col-md-2">
								<img src="@/assets/images/onemoney.png" class="img img-rounded img-responsive center-block" height="120" width="120">
							</div>
							<div class="col-md-2">
								<img src="@/assets/images/visa.png" class="img img-rounded img-responsive center-block" height="120" width="120">
							</div>
							<div class="col-md-1">
								<img src="@/assets/images/mastercard.png" class="img img-rounded img-responsive center-block" height="120" width="120">
							</div>
						</div>
						<hr>

						<fieldset v-if="load !== null">
							<legend>Payment Details</legend>
							<div class="list-group">
								<li class="list-group-item">Load Number - {{ load.loadNumber }}</li>
								<li class="list-group-item">Load Price - {{ load.currency }}{{ load.price }}</li>
								<span v-if="awardedBid !== null">
									<li class="list-group-item text-danger" v-if="awardedBid.amount > load.price">You are paying {{ awardedBid.currency }}{{ awardedBid.amount }} since load has been awarded bid</li>
								</span>
								<li class="list-group-item">Carrier - {{ booking.carrier.name }}</li>
								<br>
								<form class="form" @submit.prevent="getRedirectUrl">
									<div class="row">
										<div class="col-md-10"><p class="text-danger">You will be redirected to payment gateway and automatically redirected back when payment is processed.</p></div>
										<div class="col-md-2">
											<button class="btn btn-primary btn-block" type="submit">Process payment</button>
										</div>
									</div>
									<br><br>
								</form>
							</div>
						</fieldset>
					</fieldset>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import PaymentService from "@/services/PaymentService.js";
  import LoadService from "@/services/LoadService.js";
  import BookingService from "@/services/BookingService.js";
  import loading from "vue-full-loading";

  export default {
    components: {
      loading
    },

    name: "LoadPaymentCheckout",
    data: function() {
      return {
        show: false,
        label: "Processing request",
        load: null,
        redirectURL: null,
        awardedBid: null,
        booking: null
      };
    },

    computed: {
    	getLoadNumber() {
    		return this.$route.params.loadNumber;
    	},

    	getBookingNumber() {
    		return this.$route.params.bookingNumber;
    	},

    	getUser: function() {
    		return this.$store.state.auth.user.user;
    	},
    },

    mounted() {
    	this.findByBookingNumber();
    	this.getLoadByLoadNumber();
    	this.findByLoadLoadNumberWhereBidIsAwarded();
    },

    methods: {
    	findByBookingNumber: function(){
    		this.show = true;
			BookingService.findByBookingNumber(this.getBookingNumber).then(response => {
				this.booking = response.data.body;
				this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
		},

		getLoadByLoadNumber: function(){
			this.show = true;
			LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
				this.load = response.data.body;
				this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
		},

		findByLoadLoadNumberWhereBidIsAwarded: function(){
			this.show = true;
			LoadService.findByLoadLoadNumberWhereBidIsAwarded(this.getLoadNumber).then(response => {
				this.awardedBid = response.data.body;
				this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
		},

    	getRedirectUrl: function(){
    		localStorage.removeItem('bookingNumber');
    		localStorage.setItem('bookingNumber', JSON.stringify(this.getBookingNumber));
    		this.show = true;
    		var amountToPay = this.load.price;
    		if (this.awardedBid !== null) {
    			amountToPay = this.awardedBid.amount > this.load.price ? this.awardedBid.amount: this.load.price;
    		}
    		let paymentPostDto = {
    			payerUsername: this.getUser.username,
			    amount: amountToPay,
			    currency: this.load.invoice.currency,
			   	description: this.load.invoice.narration,
			    method: "ONLINE",
			    gateway: "PAYNOW",
			    paymentType: "LXD"
			}
			PaymentService.getRedirectUrl(paymentPostDto).then(response => {
				console.log('bookingNumber: ', JSON.parse(localStorage.getItem('bookingNumber')));
				if (response.status === 201) {
					localStorage.setItem("loadNumber", JSON.stringify(this.getLoadNumber));
					this.redirectURL = response.data.body;
					if (this.redirectURL !== null) {
						window.location.replace(this.redirectURL);
					}
				}
				this.show = false;
			}).catch(error => {
				console.log(error);
				this.show = true;
			});
		}
	}
  };
</script>