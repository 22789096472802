<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>
    
    <div class="row">
      <div class="col-md-7">
        <h1 class="black-color">Elastic capacity for your brokerage</h1>
        <p class="black-color">Nelspruit Freight for Brokers is a more efficient way to expand your truckload capacity while remaining the broker of record on shipments. Access vetted carriers in our digital freight network, & use our technology to automate the brokering process.</p>
        <div class="row" style="color: #000000;">
          <div class="col-md-4">
            <div class="panel">
              <div class="panel-body">
                <h4 class="text-center">Basic</h4>
                <hr>
                <h1>$50/month</h1>
                <ul>
                  <li>Truck/Lane Searching</li> 
                  <li>Load Posting</li>
                  <li>Decision Tools</li>
                </ul>
                <br><br><br><p></p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="panel">
              <div class="panel-body">
                <h4 class="text-center text-primary">Advanced</h4>
                <hr>
                <h1>$75/month</h1>
                <h4>Basic + </h4>
                <ul>
                  <li>Carrier Performance Rating</li>
                  <li>Rate Insights</li>
                  <li>Real-Time Updates</li>
                  <li>Load Insights</li>
                  <li>Post Book It Now Loads</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="panel">
              <div class="panel-body">
                <h4 class="text-center" style="color: green;">Premium</h4>
                <hr>
                <h1 style="color: #000000;">$120/month</h1>
                <h4 style="color: #000000;">Advanced +</h4>
                <ul>
                  <li>Carrier Monitoring</li>
                  <li>Predictive Carrier Sourcing</li>
                </ul>
                <br><br><br>
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/images/freightBroker.jpeg" width="100%" height="400px" class="img img-rounded img-responsive">
      </div>

      <br><br><br><br><br><br>
      <div class="col-md-5">
        <div class="panel">
          <div class="panel-body">
            <p class="text-danger text-center" v-if="message !== null">{{ message }}</p>
            <br>
            <form class="form" v-on:submit.prevent="register">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="salutation">Salutation</label>
                    <select v-model="user.salutation" class="form-control" required>
                      <option value="">Select option</option>
                      <option value="MR">Mr</option>
                      <option value="MRS">Mrs</option>
                      <option value="MISS">Miss</option>
                      <option value="MS">Ms</option>
                      <option value="DOCTOR">Doc</option>
                      <option value="PROFESSOR">Prof</option>
                      <option value="SIR">Sir</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <label>First Name</label>
                  <input type="text" v-model="user.firstName" placeholder="Enter first name" class="form-control" required/>
                </div>
                <div class="col-md-4">
                  <label>Last Name</label>
                  <input type="text" v-model="user.lastName" placeholder="Enter last name" class="form-control" required/>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Username</label>
                    <input type="text" v-model="user.username" placeholder="Enter desired username" class="form-control" autocomplete="on" required/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" v-model="user.email" placeholder="Enter email address" class="form-control" autocomplete="on" required/>
                  </div>
                </div>
              </div>

              <div v-if="phoneNumberFields.length > 0">
                <div v-for="(phoneNumberField, phoneNumberIndex) in phoneNumberFields" :key="phoneNumberField.id">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="phoneNumbers">Country Code</label>
                        <select class="form-control" v-model="phoneNumberField.countryId" required>
                          <option value="" disabled>Select option</option>
                          <option v-for="country in countries" :key="country.id" :value="country.id">+{{ country.code }} - {{ country.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="number">Phone Number</label>
                        <input type="number" name="number" class="form-control" v-model="phoneNumberField.number" minlength="6" maxlength="10" placeholder="Enter phone number" required>
                      </div>              
                    </div>
                    <div class="col-md-2">
                      <div class="form-group" v-show="phoneNumberFields.length > 1">
                        <label>&nbsp;</label><br>
                        <button class="btn btn-danger btn-sm pull-left" type="button" @click="phoneNumberFields.splice(phoneNumberIndex, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
                      </div>
                    </div>
                  </div>          
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Password</label>
                    <input :type="fieldType" v-model="user.password" placeholder="Enter password" class="form-control" autocomplete="on" required/>
                    <p class="text-danger"><i>Use mixture of character, special symbols and digits</i></p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Confirm Password</label>
                    <input :type="fieldType" v-model="user.confirmPassword" placeholder="Enter password confirmation" class="form-control" autocomplete="on" required/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>&nbsp;</label><br>
                    <button class="btn btn-primary" @click="showPasswords" type="button">{{ btnText }}</button>
                  </div>
                </div>
              </div>
              <p>By creating an account you agree to our <a href="#">Terms of Use.</a></p>
              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="btn btn-success btn-block">Submit</button>
                  </div>
                </div>
              </div>
            </form>
            <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import User from "@/models/User.js";
  import ZipCodeService from "@/services/ZipCodeService.js";
  import CountryService from "@/services/CountryService.js";
  
  export default {
    components: {
      loading,
    },

    data: function() {
      return {
        errors: [],
        message: null,
        show: false,
        label: "Registering profile",
        user: new User,
        phoneNumberFields: [{
          zipCodeId: null,
          number: null
        }],
        zipCodes: [],
        btnText: "Show passwords",
        fieldType: 'password',
        countries: []
      };
    },

    mounted() {
      localStorage.clear(); // logout all sessions 
      this.findAllZipCodes();
      this.findAllCountries();
    },

    methods: {
      findAllZipCodes: function() {
        this.show = true;
        return ZipCodeService.findAll().then(response => {
          this.zipCodes = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findAllCountries: function() {
        this.show = true;
        return CountryService.findAll().then(response => {
          this.countries = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      addPhoneNumberField: function(){
        this.phoneNumberFields.push({
          id: null,
          countryId: null,
          number: null
        });
      },

      register() {
        this.show = true;
        if (this.user.password !== this.user.confirmPassword) {
          alert("Passwords do not match");
          this.show = false;
          return;
        }
        this.user.personType = "USER";
        let roles = [{ name: 'BROKER' }];
        this.user.roles = roles;
        localStorage.setItem("type", JSON.stringify(roles[0].name));
        localStorage.setItem("username", JSON.stringify(this.user.username));
        this.user.phoneNumbers = this.phoneNumberFields;
        this.$store.dispatch("auth/register", this.user).then(() => {
          this.show = false;
          this.$router.push({ name: 'otp'});
        }, error => {
          this.show = false;
          this.errors = error.response.data.errors;
          this.message = error.response.data.message;
        });
      },

      showPasswords: function(){
        if (this.fieldType === 'password') {
          this.btnText = "Hide passwords";
          this.fieldType = 'text';
        }else{
          this.fieldType = 'password';
          this.btnText = "Show passwords";
        }
      },
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    }
  };
</script>

<style type="text/css" scoped>
  .black-color{
    color: #000000;
  }
</style>
