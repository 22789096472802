import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class ExchangeRateService {
  save(exchangeRate) {
    return axios.post(GENERIC_SERVICE_URL + "exchangeRates", {
      exchangeRateId: exchangeRate.exchangeRateId,
      date: exchangeRate.date,
      currency: exchangeRate.currency,
      indices: exchangeRate.indices,
      bid: exchangeRate.bid,
      ask: exchangeRate.ask,
      midRate: exchangeRate.midRate,
      bidZwl: exchangeRate.bidZwl,
      askZwl: exchangeRate.askZwl,
      midZwl: exchangeRate.midZwl,
      interbankRate: exchangeRate.interbankRate,
      status: exchangeRate.status
    });
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "exchangeRates");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "exchangeRates" + "/" + id);
  }
}

export default new ExchangeRateService();
