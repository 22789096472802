import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class LoadChargeService {
  save(nssa) {
    return axios.post(GENERIC_SERVICE_URL + "loadCharges", {
        id: nssa.id,
        percentageCharge: nssa.percentageCharge,
        currencyId: nssa.currencyId,
        effectiveYear: nssa.effectiveYear,
        startDate: nssa.startDate,
        endDate: nssa.endDate,
        expired: nssa.expired,
        enabled: nssa.enabled,
        active: nssa.active,
        current: nssa.current,
        status: nssa.status,
        description: nssa.description,
      }
    );
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "loadCharges");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "loadCharges/" + id);
  }

  findByActiveLoadCharge() {
    return axios.get(GENERIC_SERVICE_URL + "loadCharges/findByActiveLoadCharge");
  }
}

export default new LoadChargeService;