<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img
                  src="@/assets/images/station-loading.gif"
                  height="100"
                  width="100"
                />
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

        <router-link :to="{ name: 'users' }" replace><button class="btn btn-primary">
            <span class="glyphicon glyphicon-step-backward"></span> Back
          </button>
        </router-link>

        <h4>{{ editing ? "Edit": "Register" }} User Account</h4>
        <p class="error-message text-center">{{ errorMessage }}</p>

        <form class="form" @submit.prevent="register">
          <div class="form-group">
            <label>Lending institute user</label><br>
            <input type="checkbox" name="tenantUser" id="tenantUser" v-model="tenantUser">
          </div>

          <div v-show="tenantUser">
            <div class="form-group">
              <label>Lending institute</label>
              <select class="form-control" v-model="user.tenantId">
                <option value="" disabled>Select option</option>
                <option
                  v-for="tenant in tenants"
                  :key="tenant.id"
                  :value="tenant.id"
                  >{{ tenant.bankCode }}</option
                >
              </select>
            </div>
          </div>

          <!-- <div class="form-group">
            <label>Autogenerate username</label><br />
            <input type="checkbox" v-model="autogenerateUsername" />
          </div> -->

          <!-- <div class="form-group" v-show="autogenerateUsername === false"> -->
          <div class="form-group">
            <label>Username</label>
            <input
              class="form-control"
              v-model="user.username"
              placeholder="Username"
              autocomplete="on"
            />
          </div>

          <div class="form-group">
            <label>Email</label>
            <input
              type="email"
              class="form-control"
              v-model="user.email"
              placeholder="Email"
              autocomplete="on"
              required
            />
          </div>

          <!-- <div class="form-group">
            <label>Autogenerate password</label><br />
            <input type="checkbox" v-model="autogeneratePassword" />
          </div> -->

          <!-- <div class="form-group" v-show="autogeneratePassword === false"> -->
          <div class="form-group">
            <label>Password</label>
            <input
              class="form-control"
              v-model="user.password"
              placeholder="Password"
              type="password"
              autocomplete="on"
            />
          </div>

          <div class="form-group" v-show="autogeneratePassword === false">
            <label>Comfirm Password</label>
            <input
              class="form-control"
              v-model="user.confirmPassword"
              placeholder="Confirm password"
              type="password"
              autocomplete="on"
            />
          </div>

          <div class="form-group">
            <label>Roles</label>
            <select class="form-control" multiple v-model="userRoles" required>
              <option value="" disabled>Select roles</option>
              <option
                v-for="role in allRoles"
                :key="role.id"
                :value="role.id"
                >{{ role.name }}</option
              >
            </select>
          </div>
          <br />

          <div class="form-group">
            <label>Other User Details</label><br />
            <input type="checkbox" v-model="otherUserDetails" />
          </div>

          <div class="" v-show="otherUserDetails">
            <div>
              <div class="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  v-model="user.firstName"
                  class="form-control"
                  placeholder="Enter First Name"
                  autocomplete="on"
                />
                <span v-if="errors.length > 0">
                  <span v-for="error in errors" v-bind:key="error.firstName">
                    <span v-if="error.firstName" class="text-danger">{{
                      error.firstName
                    }}</span>
                  </span>
                </span>
              </div>

              <div class="form-group">
                <label>Middle Name</label>
                <input
                  type="text"
                  v-model="user.middleName"
                  class="form-control"
                  placeholder="Enter Middle Name"
                  autocomplete="on"
                />
              </div>

              <div class="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  v-model="user.lastName"
                  class="form-control"
                  placeholder="Enter Last Name"
                  autocomplete="on"
                />
                <span v-if="errors.length > 0">
                  <span v-for="error in errors" :key="error.lastName">
                    <span class="text-danger" v-if="error.lastName">{{
                      error.lastName
                    }}</span>
                  </span>
                </span>
              </div>

              <div class="form-group">
                <label>Known As / Nickname</label>
                <input
                  type="text"
                  v-model="user.nickname"
                  class="form-control"
                  placeholder="Enter nickname"
                  autocomplete="on"
                />
              </div>

              <div class="form-group">
                <label>Date Of Birth</label>
                <input
                  type="date"
                  v-model="user.dateOfBirth"
                  class="form-control"
                />
              </div>

              <div v-if="phoneNumberFields.length !== 0">
                <button class="btn btn-success btn-sm pull-right" @click="addPhoneNumberField" type="button">Add more phone number <span class="glyphicon glyphicon-plus"></span></button><br>
                <div v-show="phoneNumberFields.length > 0">
                  <div v-for="(phoneNumberField, phoneNumberIndex) in phoneNumberFields" :key="phoneNumberIndex">
                    <div class="form-group">
                      <label for="phoneNumbers">Phone Number</label>
                      <input
                        type="number"
                        v-model="phoneNumberField.phoneNumber"
                        id="phoneNumberFields"
                        class="form-control"
                        placeholder="Enter Phone Number"/>
                    </div>
                    <div class="form-group" v-show="phoneNumberFields.length > 1">
                      <label>&nbsp;</label>
                      <button class="btn btn-danger btn-sm pull-right" @click="phoneNumberFields.splice(index, 1)" type="button">Remove phone number <span class="glyphicon glyphicon-minus"></span></button><br>
                    </div>
                  </div>
                </div>
              </div><br>

              <div class="form-group">
                <label>Fax Number</label>
                <input
                  type="text"
                  v-model="user.faxNumber"
                  class="form-control"
                  placeholder="Enter Fax Number"
                  autocomplete="on"
                />
              </div>

              <div class="form-group">
                <label>Skype Address</label>
                <input
                  type="text"
                  v-model="user.skypeAddress"
                  class="form-control"
                  placeholder="Enter Skype Address"
                  autocomplete="on"
                />
              </div>

              <div class="form-group">
                <label>Gender</label>
                <select v-model="user.gender" class="form-control">
                  <option value="">Select gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                </select>
              </div>

              <div class="form-group">
                <label>Salutation</label>
                <select v-model="user.salutation" class="form-control">
                  <option value="">Select salutation</option>
                  <option value="MR">Mr</option>
                  <option value="MR">Mrs</option>
                  <option value="MR">Miss</option>
                  <option value="MR">Ms</option>
                </select>
              </div>

              <div class="form-group">
                <label>Marital Status</label>
                <select v-model="user.maritalStatus" class="form-control">
                  <option value="">Select option</option>
                  <option value="SINGLE">Single</option>
                  <option value="MARRIED">Married</option>
                  <option value="DIVORCED">Divorced</option>
                  <option value="WIDOWED">Widowed</option>
                </select>
              </div>

              <div class="form-group">
                <label>National ID Number</label>
                <input
                  type="text"
                  v-model="user.nationalIdNumber"
                  class="form-control"
                  placeholder="Enter National ID Number"
                  autocomplete="on"
                />
              </div>

              <div class="form-group">
                <label>Passport Number</label>
                <input
                  type="text"
                  v-model="user.passportNumber"
                  class="form-control"
                  placeholder="Enter Passport Number"
                  autocomplete="on"
                />
              </div>

              <div class="form-group">
                <label>Drivers Licence Number</label>
                <input
                  type="text"
                  v-model="user.driversLicenceNumber"
                  class="form-control"
                  placeholder="Enter Drivers Licence Number"
                  autocomplete="on"
                />
              </div>

              <div class="form-group">
                <label>Nationality</label>
                <select v-model="user.nationality" class="form-control">
                  <option value="">Select option</option>
                  <option value="ZIMBABWEAN">ZIMBABWEAN</option>
                  <option value="SOUTH_AFRICAN">SOUTH AFRICAN</option>
                </select>
              </div>

              <div class="form-group">
                <label>Place Of Birth</label>
                <select v-model="user.placeOfBirth" class="form-control">
                  <option value="">Select option</option>
                  <option
                    v-for="city in cities"
                    v-bind:key="city.id"
                    :value="city.id"
                    >{{ city.name }}</option
                  >
                </select>
              </div>

              <div class="form-group">
                <label>Country Of Birth</label>
                <select v-model="user.countryOfBirth" class="form-control">
                  <option value="">Select option</option>
                  <option
                    v-for="country in countries"
                    v-bind:key="country.id"
                    :value="country.id"
                    >{{ country.name }}</option
                  >
                </select>
              </div>

              <div class="form-group">
                <label>City</label>
                <select
                  v-model="homeAddress.cityId"
                  class="form-control"
                >
                  <option value="">Select option</option>
                  <option
                    v-for="city in cities"
                    v-bind:key="city.id"
                    :value="city.id"
                    >{{ city.name }}</option
                  >
                </select>
              </div>

              <div class="form-group">
                <label>Physical Address</label>
                <textarea
                  rows="5"
                  class="form-control"
                  v-model="homeAddress.physicalAddress"
                  name="physicalAddress"
                  id="physicalAddress"
                  placeholder="Enter Physical Address"
                ></textarea>
              </div>

              <div class="form-group">
                <label>Ethnic Group</label>
                <select v-model="user.ethnicGroup" class="form-control">
                  <option value="">Select option</option>
                  <option value="AFRICAN">AFRICAN</option>
                </select>
              </div>

              <div class="form-group">
                <label>Religion</label>
                <input
                  type="text"
                  name="religion"
                  v-model="user.religion"
                  class="form-control"
                  placeholder="Enter religion"
                />
              </div>
            </div>
          </div>

            <div class="form-group">
              <label>Account Status</label>
              <select v-model="user.enabled" class="form-control" required>
                <option value="">Select active</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <router-link :to="{ name: 'users' }">
                    <button
                      color="success"
                      class="btn btn-default form-control"
                      type="button">
                      Cancel
                    </button>
                  </router-link>
                </div>
                <div class="col-md-6">
                  <button
                    color="success"
                    class="btn btn-success form-control"
                    type="submit">
                    Save and Next
                  </button>
                </div>
              </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import User from "@/models/User.js";
import RoleService from "@/services/RoleService.js";
import TenantService from "@/services/TenantService.js";
import OccupationService from "@/services/OccupationService.js";
import CountryService from "@/services/CountryService.js";
import CityService from "@/services/CityService.js";
import UserService from "@/services/UserService.js";
import loading from "vue-full-loading";

export default {
  name: "NewUser",
  components: {
    loading
  },

  data: function() {
    return {
      show: false,
      label: "Processing request. Please wait...",
      user: new User,
      errorMessage: null,
      roles: [],
      autogeneratePassword: true,
      autogenerateUsername: true,
      allRoles: [],
      otherUserDetails: false,
      phoneNumbers: [
        {
          phoneNumber: null
        }
      ],
      formData: new FormData(),
      zipCodes: [],
      occupations: [],
      cities: [],
      countries: [],
      homeAddress: {
        zipCodeId: null,
        countryId: null,
        provinceId: null,
        cityId: null,
        areaId: null,
        physicalAddress: null
      },
      userRoles: [],
      errors: [],
      tenants: [],
      phoneNumberFields: [{
        zipCodeId: null,
        phoneNumber: null
      }],
      tenantUser: false,
      editing: false,
    };
  },

  created: function() {
    this.findAllTenants();
    this.findAllRoles();
    this.findAllOccupations();
    this.findAllCountries();
    this.findAllCities();
  },

  mounted(){
    if ("userId" in this.$route.params) {
      this.editing = true;
      this.findById();
    }
  },

  computed: {
    getUserId: function(){
      return this.$route.params.userId;
    }
  },

  methods: {
    addPhoneNumberField: function(){
      this.phoneNumberFields.push({
        zipCodeId: null,
        phoneNumber: null
      });
    },

    findById: function() {
      UserService.findById(this.getUserId).then(response => { 
        this.user = response.data.body;
      }).catch(error => {
        console.log(error);
      });
    },

    findAllTenants: function() {
      TenantService.findAll()
        .then(response => {
          this.tenants = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findAllRoles: function() {
      RoleService.findAll()
        .then(response => {
          this.allRoles = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findAllCountries: function() {
      CountryService.findAll()
        .then(response => {
          this.countries = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findAllCities: function() {
      CityService.findAll()
        .then(response => {
          this.cities = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findAllOccupations: function() {
      OccupationService.findAll()
        .then(response => {
          this.occupations = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    register() {
      console.log(this.user.tenantId);
      this.user.personType = "USER";
      this.user.phoneNumbers = this.phoneNumberFields;
      this.user.homeAddress = this.homeAddress;
      let myRoles = [];
      for (var i = 0; i < this.userRoles.length; i++) {
        myRoles.push({
          roleId: this.userRoles[i]
        });
      }
      this.user.roles = myRoles;
      this.show = true;
      this.$store
        .dispatch("auth/register", this.user)
        .then(response => {
          this.show = false;
          if (response.data.statusCode === 201) {
            this.$router.push({ name: "users/new/digitalSignature", params: { userId: response.data.body.id } });
          }
          if (response.data.statusCode === 422) {
            this.errorMessage = response.data.message;
            this.errors = response.data.errors;
          }
          if (response.data.statusCode === 400) {
            this.errorMessage = response.data.message;
          }
          if (response.data.statusCode === 500) {
            this.show = false;
            this.errorMessage = response.data.message;
          }
        })
        .catch(error => {
          this.show = false;
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style type="text/css">
.error-message {
  color: red;
}
</style>
