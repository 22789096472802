export default class Company {
  constructor(id, uuid, type, name, tradingName, businessPartnerNumber, phoneNumbers, email, 
    address, postalAddress, associatedUserUsername, roadMotorTransportNumber, roadMotorTransportNumberExpiryDate, 
    carrierType, customsAgentPermitNumber, description, brokerNumber, accounts){
    this.id = id;
    this.uuid = uuid;
    this.type = type;
    this.name = name;
    this.tradingName = tradingName;
    this.businessPartnerNumber = businessPartnerNumber;
    this.phoneNumbers = phoneNumbers;
    this.email = email;
    this.address = address;
    this.postalAddress = postalAddress;
    this.associatedUserUsername = associatedUserUsername;
    this.roadMotorTransportNumber = roadMotorTransportNumber;
    this.roadMotorTransportNumberExpiryDate = roadMotorTransportNumberExpiryDate;
    this.carrierType = carrierType;
    this.customsAgentPermitNumber = customsAgentPermitNumber;
    this.description = description;
    this.brokerNumber = brokerNumber;
    this.customsAgentPermitNumber = customsAgentPermitNumber;
    this.accounts = accounts;
  }
}
