<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-1">
            <button class="btn btn-warning btn-block" @click="filterInvoices('ALL')">All <span class="badge">{{ allInvoices }}</span></button>  
          </div>
          <div class="col-md-2">
            <button class="btn btn-warning btn-block" @click="filterInvoices('UNPAID')">Unpaid <span class="badge">{{ unpaidInvoices }}</span></button>  
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary btn-block" @click="filterInvoices('WITHHOLDING')">Withholding <span class="badge">{{ withholdingInvoices }}</span></button>  
          </div>
          <div class="col-md-2">
            <button class="btn btn-default btn-block" @click="filterInvoices('AUTHORIZED')">Authorized <span class="badge">{{ authorizedInvoices }}</span></button>  
          </div>
          <div class="col-md-2">
            <button class="btn btn-danger btn-block" @click="filterInvoices('PENDING')">Pending/Disputed <span class="badge">{{ pendingInvoices }}</span></button>
          </div>
          <div class="col-md-1">
            <button class="btn btn-success btn-block" @click="filterInvoices('PAID')">Paid <span class="badge">{{ paidInvoices }}</span></button>
          </div>
          <div class="col-md-2">
            <button class="btn btn-danger btn-block" @click="filterInvoices('CANCELLED')">Cancelled <span class="badge">{{ cancelledInvoices }}</span></button>  
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <h4>{{ invoiceStatus !== null ? invoiceStatus: 'All' }} Invoices</h4>
        <table class="table table-responsive table-bordered">
          <thead class="bg-primary">
              <th>ID</th>
              <th>Billed To</th>
              <th>Customer</th>
              <th>Invoice Number</th>
              <th>Amount</th>
              <th>Narration</th>
              <th>Payment</th>
              <th>Status</th>
              <th>Actions</th>
            </thead>
            <tbody>
              <tr v-for="invoice in filteredInvoices" :key="invoice.id">
                <td>{{ invoice.id }}</td>
                <td>{{ invoice.billedTo !== null ? invoice.billedTo.name: 'Freight Board' }}</td>
                <td>{{ invoice.customer !== null ? invoice.customer.name: 'Freight Board' }}</td>
                <td>{{ invoice.invoiceNumber }}</td>
                <td>{{ invoice.currency }}{{ invoice.total }}</td>
                <td>{{ invoice.narration }}</td>
                <td>
                  <router-link :to="{ name: 'view-payment-details', params: { invoiceNumber: invoice.invoiceNumber }, query: { filter: 'invoice-list'}}">
                    View payment
                  </router-link>
                </td>
                <td>{{ invoice.status }}</td>
                <td>
                  <a :href="PDF_API_URL + 'pdf/generateInvoice/' + invoice.id " target="_blank" class="btn btn-primary">View Invoice</a>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import InvoiceService from "@/services/InvoiceService.js";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default{
    name: 'InvoiceList',
    components: {
      loading,
    },

    data: function(){
      return {
        label: "Processing request",
        show: false,
          invoices: [],
          loadBookings: [],
          invoiceStatus: null,
          filteredInvoices: [],
          PDF_API_URL: PDF_API_URL,
      }
    },

    mounted(){
      this.findAll();
    },

    computed: {
      getUser: function() {
        return this.$store.state.auth.user.user;
        },

        getRoles: function() {
          let roles = [];
          for (var i = 0; i < this.getUser.authorities.length; i++) {
            roles.push(this.getUser.authorities[i].authority);
          }
          return roles;
        },

        allInvoices(){
          return this.invoices.length;
        },

        unpaidInvoices(){
          return this.invoices.filter(invoice => {
            return invoice.status === 'UNPAID';
          }).length;
        },

        paidInvoices(){
          return this.invoices.filter(invoice => {
            return invoice.status === 'PAID';
          }).length;
        },

        withholdingInvoices(){
          return this.invoices.filter(invoice => {
            return invoice.status === 'WITHHOLDING';
          }).length;
        },

        authorizedInvoices(){
          return this.invoices.filter(invoice => {
            return invoice.status === 'AUTHORIZED';
          }).length;
        },

        pendingInvoices(){
          return this.invoices.filter(invoice => {
            return invoice.status === 'PENDING';
          }).length;
        },

        cancelledInvoices(){
          return this.invoices.filter(invoice => {
            return invoice.status === 'CANCELLED';
          }).length;
        }
    },

    methods: {
      findAll: function() {
        this.show = true;
        InvoiceService.findAll().then(response => {
          this.invoices = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      filterInvoices(status){
        localStorage.setItem('invoiceStatus', JSON.stringify(status));
        if (this.invoices !== null) {
          if (status === 'ALL') {
            this.invoiceStatus = 'All';
            this.filteredInvoices = this.invoices;
          }

          if (status === 'UNPAID') {
            this.invoiceStatus = 'Unpaid';
            this.filteredInvoices = this.invoices.filter(invoice => {
              return invoice.status === status;
            });
          }

          if (status === 'PAID') {
            this.invoiceStatus = 'Paid';
            this.filteredInvoices = this.invoices.filter(invoice => {
              return invoice.status === status;
            });
          }

          if (status === 'WITHHOLDING') {
            this.invoiceStatus = 'Withholding';
            this.filteredInvoices = this.invoices.filter(invoice => {
              return invoice.status === status;
            });
          }

          if (status === 'AUTHORIZED') {
            this.invoiceStatus = 'Withholding';
            this.filteredInvoices = this.invoices.filter(invoice => {
              return invoice.status === status;
            });
          }

          if (status === 'PENDING') {
            this.invoiceStatus = 'Pending';
            this.filteredInvoices = this.invoices.filter(invoice => {
              return invoice.status === status;
            });
          }

          if (status === 'CANCELLED') {
            this.invoiceStatus = 'Cancelled';
            this.filteredInvoices = this.invoices.filter(invoice => {
              return invoice.status === status;
            });
          }
        }
      }
    }
  };
</script>