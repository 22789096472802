<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <p>No Brokers/Shippers have been saved. Save your favorite brokers/shippers and find their loads quickly.</p>

    <ul class="nav nav-tabs">
      <li class="active"><a href="#saved" data-toggle="tab">Saved</a></li>
      <li><a href="#hidden" data-toggle="pill">Hidden</a></li>
    </ul>
    <br><br>

    <div class="tab-content">
      <div class="tab-pane fade in active" id="viewed">
        <SavedBrokers/>
      </div>

      <div class="tab-pane fade in" id="contacted">
      	<HiddenBrokers/>
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import SavedBrokers from "@/components/brokers/SavedBrokers";
	import HiddenBrokers from "@/components/brokers/HiddenBrokers";

	export default{
		name: 'CarrierMyLoadList',
		components: {
			loading,
			SavedBrokers,
			HiddenBrokers,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    bids: []
			}
		},

		created(){
		},

		methods: {
			
		}
	};
</script>

