<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-3">
						<h4>Tutorial List</h4>
					</div>
					<div class="col-md-6"></div>
					<div class="col-md-3">
						<select class="form-control" v-model="itemsPerPage">
							<option value="">Items per page</option>
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="30">30</option>
						</select>
					</div>
				</div>
				<table class="table table-bordered table-hover">
					<thead>
						<tr>
							<th>ID</th>
							<th>Developer Name</th>
							<th>Category</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in paginatedItems" :key="item.id">
							<td>{{ item.id }}</td>
							<td>{{ item.name }}</td>
							<td>{{ item.category }}</td>
						</tr>
					</tbody>
				</table>
				<div class="pagination">
					<button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="currentPage === 1" @click="prevPage">
					</button>
						&nbsp;<span>Page {{ currentPage }} of {{ totalPages }}&nbsp;</span>
					<button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="currentPage === totalPages" @click="nextPage">
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default{
		data: function(){
			return {
				currentPage: 1,
				itemsPerPage: 3,
				items: [
					{ id: 1, name: "John Smith", category: "Frontend Developer" },
					{ id: 2, name: "Sarah Johnson", category: "Backend Developer" },
					{ id: 3, name: "David Chen", category: "Full Stack Developer" },
					{ id: 4, name: "Emily Lee", category: "Backend Developer" },
					{ id: 5, name: "Michael Wong", category: "Full Stack Developer" },
					{ id: 6, name: "Rachel Patel", category: "Frontend Developer" },
					{ id: 7, name: "Kevin Davis", category: "Frontend Developer" },
					{ id: 8, name: "Jennifer Kim", category: "Backend Developer" },
					{ id: 9, name: "James Garcia", category: "Full Stack Developer" },
					{ id: 10, name: "Laura Nguyen", category: "Backend Developer" },
					{ id: 11, name: "Brandon Brown", category: "Full Stack Developer" },
					{ id: 12, name: "Samantha Taylor", category: "Frontend Developer" },
				],
			}
		},

		computed: {
			totalPages(){
				return Math.ceil(this.items.length / this.itemsPerPage);
			},

			paginatedItems(){
				// so now we need to pass page and size to backend api and fetchd data?
				const startIndex = (this.currentPage - 1) * this.itemsPerPage;
				return this.items.slice(startIndex, startIndex + this.itemsPerPage);
			}
		},

		methods: {
			prevPage(){
				if (this.currentPage > 1) {
					this.currentPage--;
				}
			},

			nextPage(){
				if (this.currentPage < this.totalPages) {
					this.currentPage++;
				}
			}
		}
	}
</script>